import { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Typography } from '@components/base';
import ProgressBarModal from '../progress-bar-modal';

import css from './index.module.scss';
import { pushEventTracking } from '@helpers/utils';
import { EVENT_TRACKING_TYPES } from '@helpers/constants';

interface StepProgressBarProps {
  label: any;
  currentStep: number;
  orientation?: 'horizontal' | 'vertical';
  modalRequired?: boolean;
  alternativeLabel?: boolean;
}

const stepStyle = {
  '.Mui-completed': {
    '.MuiSvgIcon-root': {
      color: '#009999'
    },
    '.MuiStepConnector-line': {
      borderColor: '#009999'
    }
  },
  '.MuiSvgIcon-root.Mui-active': {
    color: '#028783',
    fontSize: 35,
    position: 'relative',
    bottom: 7
  },
  '.MuiStepIcon-text': {
    display: 'none'
  }
};

const StepProgressBar = (props: StepProgressBarProps) => {
  const {
    label,
    currentStep,
    orientation = 'horizontal',
    modalRequired = true,
    alternativeLabel = true
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [modalStates, setOpenModalStates] = useState({
    label: [],
    currentStep: 0,
    orientation: 'vertical'
  });

  const fetchModalDetails = (data: any, modalState: boolean) => {
    setOpenModal(modalState);
    let logsBetweenStates: any = [];
    if (data?.logs?.length) {
      logsBetweenStates = [...data.logs];
      if (data?.index > 0 && data?.index < label?.length) {
        logsBetweenStates.push(label[data?.index]);
        logsBetweenStates.unshift(label[data?.index - 1]);
      } else if (data?.index == 0) {
        logsBetweenStates.push(label[data?.index + 1]);
        logsBetweenStates.unshift(label[data?.index]);
      } else if (data?.index == label?.length - 1) {
        logsBetweenStates.push(label[data?.index]);
        logsBetweenStates.unshift(label[data?.index - 1]);
      }
    }
    setOpenModalStates((prevState) => ({
      ...prevState,
      label: logsBetweenStates,
      currentStep:
        data?.index >= currentStep
          ? Math.min(logsBetweenStates?.length - 2, logsBetweenStates?.length)
          : 100
    }));
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Stepper
          activeStep={currentStep}
          alternativeLabel={alternativeLabel}
          sx={stepStyle}
          orientation={orientation}>
          {label.map((item: any, index: number) => (
            <Step key={index}>
              <StepLabel
                onClick={
                  modalRequired && item?.logs?.length
                    ? () => {
                        fetchModalDetails({ ...item, index }, true);
                        pushEventTracking(EVENT_TRACKING_TYPES.PROGRESS_BAR_DETAILED_CLICK);
                      }
                    : undefined
                }
                style={{
                  cursor: modalRequired && item?.logs?.length ? 'pointer' : 'default'
                }}>
                <Typography
                  variant="pdoc"
                  className={`${currentStep == index ? css.currentState : css.primaryText}`}>
                  {item?.label}
                </Typography>
                <Typography variant="p" className={css.secondaryText}>
                  {item?.date}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <ProgressBarModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        data={modalStates.label}
        currentStep={modalStates.currentStep}
      />
    </>
  );
};

export default StepProgressBar;
