import css from './index.module.scss';
import { Chip, IconNode, Typography } from '@components/base';
import Images from '@assets/images';
import { endOfDay, format } from 'date-fns';
import { DATE_FORMAT, PAYMENT_TERMS } from '@helpers/constants';
import { useMemo } from 'react';
import { ICustomerInfo } from '@helpers/types/customer';

interface CustomerDetailCardProps {
  customerInfo: ICustomerInfo;
}

const CustomerDetailCard = (props: CustomerDetailCardProps) => {
  const { customerInfo } = props;
  const formattedDate = customerInfo.updated_at
    ? useMemo(
        () => format(endOfDay(new Date(customerInfo.updated_at).getTime()), DATE_FORMAT),
        [customerInfo.updated_at]
      )
    : null;

  const paymentTerm = customerInfo.ecgc_terms_of_payment
    ? useMemo(() => PAYMENT_TERMS.find((p) => p.value === customerInfo.ecgc_terms_of_payment), [])
    : null;
  return (
    <div className={css.mainWrapper}>
      <div className={css.firstColumn}>
        <div className={css.editWrapper}>
          <div className={css.dateWrapper}>
            <Typography variant="span" className={css.labelText}>
              Created on:{' '}
            </Typography>
            <Chip variant="filled-blue" label={formattedDate} clickable={false} />
          </div>
          {/* <IconNode
            src={Images.editGrey}
            alt="edit icon"
            component="button"
            className={css.buttonWrapper}
          /> */}
        </div>
        <Typography variant="h4">{customerInfo.name}</Typography>
        <div className={css.locationWrapper}>
          <Typography variant="span">Location: </Typography>
          <Typography variant="p">{customerInfo.country}</Typography>
        </div>
        <div className={css.contactWrapper}>
          <Typography variant="span">Contact Details</Typography>
          <div className={css.numberWrapper}>
            <IconNode src={Images.telephone} />
            <div className={css.numberWrapper}>
              <Typography variant="p">{customerInfo.number}</Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={css.secondColumn}>
        <div className={css.detailWrapper}>
          <Typography variant="span" className={css.text}>
            ECGC Details
          </Typography>
          {/* {customerInfo.ecgc_approval_letter?.length ? (
            <a
              href={customerInfo.ecgc_approval_letter}
              download={customerInfo.ecgc_approval_letter}
              target="_blank"
              rel="noopener noreferrer">
              {' '}
              <IconNode
                src={Images.downloadGrey}
                alt="download ecgc approval letter"
                component="button"
                className={css.buttonWrapper}
              />
            </a>
          ) : (
            <IconNode
              src={Images.downloadLightGrey}
              alt="download ecgc approval letter disabled"
              component="button"
            />
          )} */}

          {/* <Chip variant="filled-blue" label="To be Initiated" clickable={false} /> */}
        </div>
        <div className={css.columnWrapper}>
          <div className={css.rowWrapper}>
            <div className={css.textColumnWrapper}>
              <Typography variant="span">Approved on</Typography>
              <Typography variant="p">
                {customerInfo.ecgc_approval_start_date
                  ? format(
                      endOfDay(new Date(customerInfo.ecgc_approval_start_date).getTime()),
                      DATE_FORMAT
                    )
                  : `-`}
              </Typography>
            </div>
            <div className={css.textColumnWrapper}>
              <Typography variant="span">Valid Till</Typography>
              <Typography variant="p">
                {customerInfo.ecgc_approval_end_date
                  ? format(
                      endOfDay(new Date(customerInfo.ecgc_approval_end_date).getTime()),
                      DATE_FORMAT
                    )
                  : `-`}
              </Typography>
            </div>
          </div>
          <div className={css.rowWrapper}>
            <div className={css.textColumnWrapper}>
              <Typography variant="span">Amount Limit</Typography>
              <Typography variant="p">
                {customerInfo.ecgc_approved_amount
                  ? Number(customerInfo.ecgc_approved_amount).toLocaleString()
                  : '-'}
              </Typography>
            </div>
            <div className={css.textColumnWrapper}>
              <Typography variant="span">Terms of payment</Typography>
              <Typography variant="p">{paymentTerm?.label ? paymentTerm?.label : '-'}</Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={css.thirdColumn}>
        <div className={css.amountWrapper}>
          <Typography variant="span">Total Amount Due / Total Amount</Typography>
          <Typography variant="h4">
            {Number(customerInfo.total_amount_due).toLocaleString()} /{' '}
            {Number(customerInfo.total_amount).toLocaleString()}
          </Typography>
        </div>
        <div className={css.orderWrapper}>
          <div className={css.rowWrapper}>
            <Typography variant="span">Total Orders</Typography>
            {/* <IconNode src={Images.infoGrey} alt="info icon" /> */}
          </div>
          <Typography variant="h4">{customerInfo.total_order}</Typography>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetailCard;
