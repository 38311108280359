import { IconNode } from '@components/base';
import css from './index.module.scss';
import Image from '@assets/images';

import NavItems from './nav-items';
import { NavLinkProps } from 'react-router-dom';
import { useMemo, useEffect, useState } from 'react';
import SessionService from '@helpers/session-helper';
import { Typography, Button } from '@components/base';
import { getToken, parseJWT } from '@helpers/auth-helper';
import { EVENT_TRACKING_TYPES, USER_TYPES } from '@helpers/constants';
import { getPermissionFromLocalStorage, pushEventTracking } from '@helpers/utils';

interface NavigationBarProps {
  items: NavLinkProps[];
  navItemsForCustomer: NavLinkProps[];
}

interface NavigationBarUserState {
  family_name: string;
  given_name: string;
  email: string;
  phone_number: string;
  user_id: string;
}

function NavigationBar(props: NavigationBarProps) {
  const userType = getPermissionFromLocalStorage()?.user_type;
  const navItems = useMemo(() => props.items, [props.items]);
  const navItemsForCustomer = useMemo(() => props.navItemsForCustomer, [props.navItemsForCustomer]);
  const navItemsToBeDisplayed = useMemo(() => {
    if (userType == USER_TYPES.customer) {
      return navItemsForCustomer;
    }
    if (userType == USER_TYPES.internal) {
      return navItems;
    }
    return [];
  }, [navItems, navItemsForCustomer]);
  const [user, setUser] = useState<NavigationBarUserState>();

  useEffect(() => {
    const accessToken = getToken('access');
    const jwtData = parseJWT(accessToken);
    setUser(jwtData);
  }, []);

  return (
    <nav className={css.navWrapper}>
      <IconNode
        src={Image.elchemyLogo}
        alt="elchemy-logo"
        className={css.brandLogo}
        loading="eager"
      />
      <ul className={css.navItemWrapper}>
        <NavItems items={navItemsToBeDisplayed} />
      </ul>
      <div className={css.navActionWrapper}>
        <Typography variant="p">
          Welcome, {user?.given_name ? `${user.given_name} ${user.family_name}` : `User`}
        </Typography>
        <Button
          onClick={() => {
            SessionService.clearSession(true);
            pushEventTracking(EVENT_TRACKING_TYPES.LOGOUT);
          }}>
          Logout
        </Button>
      </div>
    </nav>
  );
}

export default NavigationBar;
export type { NavigationBarProps };
