import css from './index.module.scss';
import React, { useMemo } from 'react';
import { Typography, IconNode } from '@components/base';
import { DataGrid } from '@components/common';
import Images from '@assets/images';
import { FieldValue } from '@helpers/types/coa';
import { getFormattedDate } from '@helpers/utils';

interface ItemDetailsPropTypes {
  data: {
    item_details: any;
    item_details_skeleton: FieldValue[];
  };
  handleNavigation: (index: number) => void;
}

const ItemDetails = (props: any) => {
  const { data, handleNavigation } = props;

  const tableDataSource = useMemo(() => {
    const dataSource: any = [];
    data?.items?.forEach((item: any) => {
      item.packaging_document_data.forEach((pack: any) => {
        const temp: any = {};
        temp['no_of_package'] = `${pack.start_package_no}-${pack.end_package_no}`;
        temp['batch'] = pack.batch_number.batch_number;
        temp['packing'] = `${pack.package_details?.package_display_name}`;
        temp['gross_wt'] = `${pack.gross_wt} ${pack.package_details.unit_of_weight}`;
        temp['tare_wt'] = `${pack.tare_wt} ${pack.package_details.unit_of_weight}`;
        temp['net_wt'] = `${pack.net_wt} ${pack.package_details.unit_of_weight}`;
        temp['item_name'] = item.name;
        temp['item_id'] = item.order_item_id;
        dataSource.push(temp);
      });
    });
    return dataSource;
  }, [data?.items]);

  const [itemDataColumn, itemDataRow] = useMemo(() => {
    const columns: any = [
      {
        Header: 'Sr No',
        accessor: 'index'
      },
      {
        Header: 'Item Name',
        accessor: 'item_name'
      },
      {
        Header: 'No. of Package',
        accessor: 'no_of_package'
      },
      {
        Header: 'Batch',
        accessor: 'batch'
      },
      {
        Header: 'Packing',
        accessor: 'packing'
      },
      {
        Header: 'Gross Wt.',
        accessor: 'gross_wt'
      },
      {
        Header: 'Tare Wt.',
        accessor: 'tare_wt'
      },
      {
        Header: 'Net Wt.',
        accessor: 'net_wt'
      }
    ];

    const rows: any = tableDataSource?.length
      ? tableDataSource?.map((item: any, index: number) => {
          return {
            index: index + 1,
            item_name: item.item_name,
            no_of_package: item.no_of_package,
            batch: item.batch,
            packing: item.packing,
            gross_wt: item.gross_wt,
            tare_wt: item.tare_wt,
            net_wt: item.net_wt
          };
        })
      : [];

    return [columns, rows];
  }, [data]);

  return (
    <div className={css.boxWrapper}>
      <div className={css.orderContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="p">Item Details</Typography>
          <IconNode
            className={css.editIcon}
            src={Images.editRed}
            onClick={() => handleNavigation(1)}
          />
        </div>
        <div className={css.rowWrapper}>
          <DataGrid tableStyle={{ width: '100%' }} columns={itemDataColumn} data={itemDataRow} />
        </div>
        <div className={css.rowWrapper}>
          <div className={css.columnWrapper}>
            <Typography variant="label">Total Gross Weight</Typography>
            <Typography variant="p">
              {data?.total_gross_wt} {data?.unit_of_weight}
            </Typography>
          </div>
          <div className={css.columnWrapper}>
            <Typography variant="label">Total Net Weight</Typography>
            <Typography variant="p">
              {data?.total_net_wt} {data?.unit_of_weight}
            </Typography>
          </div>
        </div>
        <div className={css.rowWrapper}>
          <div className={css.columnWrapper}>
            <Typography variant="label">Total no of Packages</Typography>
            <Typography variant="p">{data?.total_no_of_packages ?? '-'}</Typography>
          </div>
          <div className={css.columnWrapper}>
            <Typography variant="label">Total pallets packed</Typography>
            <Typography variant="p">{data?.total_pallets_packed ?? '-'}</Typography>
          </div>
        </div>
        <div className={css.rowWrapper}>
          <div className={css.columnWrapper}>
            <Typography variant="label">Purchase Order Number</Typography>
            <Typography variant="p">{data?.po_reference_no ?? '-'}</Typography>
          </div>
          <div className={css.columnWrapper}>
            <Typography variant="label">Purchase Date</Typography>
            <Typography variant="p">{getFormattedDate(data?.po_reference_date) ?? '-'}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;
