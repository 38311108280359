import css from './index.module.scss';
import { Button, Typography } from '@components/base';
import { useFormContext, useFieldArray } from 'react-hook-form';
import GradeDetailContainer from './grade-detail-container';
import { IGradeForm } from '@helpers/types/grade';

interface GradeDetailFormProps {
  editMode?: boolean;
  onFormSubmit: (data: IGradeForm) => void;
  onBackClick?: () => void;
  onCancelClick?: () => void;
}

const GradeDetailForm = (props: GradeDetailFormProps) => {
  const { editMode, onFormSubmit, onCancelClick, onBackClick } = props;
  const {
    handleSubmit,
    formState: { isSubmitting, isDirty }
  } = useFormContext<IGradeForm>();

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      <Typography variant="h2">Grades</Typography>
      <GradeDetailContainer />
      <div className={css.actionButtonsWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div className={css.mainButtonsWrapper}>
          <Button variant="outlined-secondary" onClick={onBackClick}>
            Back
          </Button>
          <Button type="submit" disabled={isSubmitting || (editMode ? !isDirty : false)}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default GradeDetailForm;
