import { memo } from 'react';
import css from './index.module.scss';
import { Typography } from '@components/base';
import { LinkButton } from '@components/common';
import { To } from 'react-router-dom';

interface ListPlaceholderProps {
  title: string;
  supportingText: string;
  buttonText: string;
  to: To;
}

const ListPlaceholder = (props: ListPlaceholderProps) => {
  const { to, title, supportingText, buttonText } = props;
  return (
    <div className={css.supplierEmptyWrapper}>
      <div className={css.supplierEmptyTextWrapper}>
        <Typography variant="span" className={css.noSupplierText}>
          {title}
        </Typography>
        <Typography variant="span" className={css.noSupplierSecondText}>
          {supportingText}
        </Typography>
      </div>
      {buttonText?.length ? (
        <LinkButton to={to} variant="contained" className={css.addSupplierButton}>
          {buttonText}
        </LinkButton>
      ) : (
        <></>
      )}
    </div>
  );
};

export default memo(ListPlaceholder);
