import { useState, useEffect, useMemo } from 'react';
import css from './index.module.scss';
import { Button, Typography } from '@components/base';
import { NoDocumentPlaceholder, DataGrid, DocumentRow, UploadDocument } from '@components/common';
import AddProduct from './components/add-product';
import {
  fetchSupplierProductList,
  documentGradeUpload,
  documentGradeReUpload,
  documentGradeDelete
} from '@services/supplier.service';
import notify from '@helpers/toastify-helper';
import { ISupplierInfo } from '@helpers/types/supplier';
import { SupplierProductGrade } from '@helpers/types/supplier-grade';
import { CellProps, Column } from 'react-table';
import { SUPPLIER_GRADE_DOCUMENT_TYPE } from '@helpers/constants';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { addDocument } from '@helpers/yup';
import { yupResolver } from '@hookform/resolvers/yup';

interface SupplierProductListPropsType {
  supplierInfo?: ISupplierInfo;
}

interface RowGridType {
  document_name: object;
  product_grade: string;
  proofs: object;
  document_object: object;
}

const Products = (props: SupplierProductListPropsType) => {
  const [openModal, setModal] = useState(false);
  const [productList, setProductList] = useState([]);
  const [documentTabState, setDocumentTabState] = useState<any>({
    supplierDocumentUploadModal: false,
    editDocument: false,
    isLoading: false
  });

  const { supplierDocumentUploadModal, editDocument, isLoading } = documentTabState;

  useEffect(() => {
    getProductList();
  }, []);

  const formMethods = useForm<any>({
    resolver: yupResolver(addDocument),
    defaultValues: {
      document_id: '',
      document_type: null,
      document_name: '',
      document_object: null,
      supplier_product_grade_id: null
    },
    shouldUnregister: true
  });

  const {
    setValue,
    formState: { isSubmitting }
  } = formMethods;

  const handleAddSupplierDocument = () => {
    setDocumentTabState((prevState: any) => ({
      ...prevState,
      supplierDocumentUploadModal: !prevState.supplierDocumentUploadModal,
      editDocument: false
    }));
  };

  const handleSupplierDocumentModalClose = async () => {
    setDocumentTabState((prevState: any) => ({
      ...prevState,
      supplierDocumentUploadModal: false,
      editDocument: false
    }));
  };

  const getProductList = async () => {
    const productList = await fetchSupplierProductList({
      supplier_id: props.supplierInfo?.supplier_id
    });
    if (productList?.success) {
      setProductList(productList?.data?.results);
    } else {
      notify({ message: 'Unable to fetch customer list', severity: 'error' });
    }
  };

  const handleFormSubmission: any = async (data: any) => {
    const { supplierInfo } = props;
    const formData = new FormData();
    formData.append('document_type', data.document_type!.value);
    formData.append('document_name', data.document_name);
    formData.append('document_object', data.document_object as Blob);
    if (!editDocument) {
      const response = await documentGradeUpload(data.supplier_product_grade_id, formData);
      if (response.success) {
        notify({ message: 'Document added successfully', dismissible: true });
        handleSupplierDocumentModalClose();
        getProductList();
      } else {
        notify({ message: response.error ?? 'Unable to add document', dismissible: true });
      }
    } else {
      const response = await documentGradeReUpload(data.document_id, formData);
      if (response.success) {
        getProductList();
        notify({ message: 'Document updated successfully', dismissible: true });
        handleSupplierDocumentModalClose();
      } else {
        notify({ message: response.error ?? 'Unable to update document', dismissible: true });
      }
    }
  };

  const handleReupload = (document: any) => () => {
    const documentType = SUPPLIER_GRADE_DOCUMENT_TYPE.find(
      (doc) => doc.value === document.document_type
    );
    if (documentType && document.document_object) {
      setDocumentTabState((prevState: any) => ({
        ...prevState,
        supplierDocumentUploadModal: true,
        editDocument: true
      }));
      setValue('document_id', document.supplier_product_grade_document_id, { shouldDirty: false });
      setValue('document_type', documentType, { shouldDirty: false });
      setValue('document_name', document.document_name, { shouldDirty: false });
      setValue('document_object', null, { shouldDirty: false });
      setValue('supplier_product_grade_id', document.supplier_product_grade_id, {
        shouldDirty: false
      });
    } else {
      setValue('document_type', documentType, { shouldDirty: false });
      setValue('supplier_product_grade_id', document.supplier_product_grade_id, {
        shouldDirty: false
      });
      handleAddSupplierDocument();
    }
  };

  const handleDocumentDelete = (document: any) => async () => {
    const { supplierInfo } = props;
    setDocumentTabState((prevState: any) => ({ ...prevState, isLoading: true }));
    const response = await documentGradeDelete(document.supplier_product_grade_document_id);
    setDocumentTabState((prevState: any) => ({ ...prevState, isLoading: false }));
    if (response.success) {
      getProductList();
      notify({ message: 'Document deleted successfully', dismissible: true });
    } else {
      notify({ message: response.error ?? 'Unable to delete document', dismissible: true });
    }
  };

  const [documentColumn, documentData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: 'Document Name',
        accessor: 'document_name',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          const documentType = `${value.product_name} - ${value.document_type}`;
          if (documentType)
            return (
              <DocumentRow.Title title={documentType} documentAvailable={value?.document_object} />
            );
          return null;
        }
      },
      {
        Header: 'Product Grade',
        accessor: 'product_grade'
      },
      {
        Header: 'Proofs',
        accessor: 'proofs',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <DocumentRow.Upload
              documentAvailable={value?.document_object}
              onClick={handleReupload(value)}
            />
          );
        }
      },
      {
        Header: '',
        accessor: 'document_object',
        Cell: (props: CellProps<any>) => {
          const { value } = props;
          return (
            <DocumentRow.Action
              document={value?.document_object}
              title={value.document_name}
              onClick={handleDocumentDelete(value)}
            />
          );
        }
      }
    ];

    let data: any[] = [];
    productList.forEach((prod: any) => {
      if (prod?.documents?.length) {
        prod.documents = SUPPLIER_GRADE_DOCUMENT_TYPE.map((item: any) => {
          const availableDoc = prod.documents.find((p: any) => p.document_type === item.value);
          return {
            document_type: item.value,
            product_name: prod.product_name,
            grade_name: prod.grade_name,
            document_object: availableDoc?.document_type,
            supplier_product_grade_id: prod.supplier_product_grade_id,
            ...availableDoc
          };
        });
      } else {
        prod.documents = SUPPLIER_GRADE_DOCUMENT_TYPE.map((item: any) => {
          return {
            product_name: prod.product_name,
            grade_name: prod.grade_name,
            document_object: null,
            document_type: item.value,
            supplier_product_grade_id: prod.supplier_product_grade_id
          };
        });
      }

      data = data.concat(prod.documents);
    });
    const row: Array<RowGridType> = data?.map((prod: any) => ({
      document_name: prod,
      product_grade: prod.grade_name,
      proofs: prod,
      document_object: prod
    }));

    return [column, row];
  }, [productList]);

  return (
    <div className={css.productsContainer}>
      <div className={css.productsHeader}>
        <Typography variant="h4">Product List</Typography>
        <Button onClick={() => setModal(true)}>Add Product</Button>
      </div>
      {productList.length ? (
        <DataGrid columns={documentColumn} data={documentData} />
      ) : (
        <NoDocumentPlaceholder
          title="No products added yet"
          supportingText="When a products are added by you, they will show up here."
          buttonText="Add Product"
          onClick={() => setModal(true)}
        />
      )}

      {openModal && (
        <AddProduct
          open={openModal}
          onClose={() => setModal(false)}
          getProductListOfSupplier={getProductList}
        />
      )}

      <FormProvider {...formMethods}>
        {supplierDocumentUploadModal && (
          <UploadDocument
            open={supplierDocumentUploadModal}
            onClose={handleSupplierDocumentModalClose}
            onFormSubmit={handleFormSubmission}
            editMode={editDocument}
            availableDocumentType={SUPPLIER_GRADE_DOCUMENT_TYPE}
          />
        )}
      </FormProvider>
    </div>
  );
};

export default Products;
