import { SERVER_CONFIG } from '@helpers/constants';
import { getListItemSecondaryActionClassesUtilityClass } from '@mui/material';
import httpClient from './index.service';

async function uploadEcgc(taskId: string, reqBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/update-customer-credit-report/`,
      reqBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function uploadCustomerAcceptance(taskId: string, reqBody: any) {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.order}/${taskId}/upload-customer-order-acceptance/`,
      reqBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function uploadVesselDetails(taskId: string, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.uploadVesselDetails}${taskId}/vessel-booking-date/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function getTaskForAnOrder(
  orderId?: string,
  pageNumber?: number,
  userId?: string,
  isCompleted?: string | null
) {
  const queryParams = [];
  if (orderId?.length) {
    queryParams.push(`order_id=${orderId}`);
  }
  if (userId?.length) {
    queryParams.push(`user_id=${userId}`);
  }
  if (isCompleted?.length) {
    queryParams.push(`is_completed=${isCompleted}`);
  }

  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.getAllTasks}/?${queryParams?.length ? queryParams.join('&') : ``}`,
      {
        params: {
          ...(pageNumber && { page: pageNumber })
        }
      }
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function postLiftingDate(taskId: string, supplierId: string, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.uploadVesselDetails}${taskId}/${SERVER_CONFIG.liftingDate}${supplierId}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function getTaskList(orderId: string) {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.taskList}?order_id=${orderId}`);
    return response;
  } catch (error) {
    return error;
  }
}

async function postTransportDetails(orderId: string, supplierId: string, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${orderId}/${SERVER_CONFIG.localTransportation}/${supplierId}/`,
      requestBody
    );
    return response;
  } catch (error) {
    return error;
  }
}

async function addCourierDetails(taskId: string, body: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.courrierDetail}`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function addAllSuppliers(taskId: string) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/check-suppliers-added/`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function getProductList(taskId: string) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${taskId}/add-products-suppliers/`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function postProudctData(taskId: string, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/add-products-suppliers/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function addContainerData(taskId: string, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.addContainerData}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function addBLDetails(taskId: string, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.addBLDetails}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function generateCOA(taskId: string, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.generateCOA}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function proofOfExport(taskId: string, requestBody: any) {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.proofOfExport}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function generateCOO(taskId: string) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.generateCOO}/`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function verifyPackagingDetails(taskId: string, requestBody?: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.packageDetailsTasks.verify}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function approvePackagingDetails(taskId: string, requestBody?: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.packageDetailsTasks.approve}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function rectifyPackagingDetails(taskId: string, requestBody?: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.packageDetailsTasks.rectify}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function addBatchNumber(taskId: string, requestBody?: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.addBatchNumber}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function generatePreShipmentPackingList(taskId: string, requestBody?: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.generatePreShipmentPackingList}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function generatePostShipmentPackingList(taskId: string, requestBody?: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.generatePostShipmentPackingList}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function uploadBlDraft(taskId: string, reqBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.uploadBlDraft}/`,
      reqBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function getShippingLineNames(search_text: string) {
  const queryParams = [];
  if (search_text?.length) {
    queryParams.push(`search_text=${search_text}`);
  }
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.formFilterOptions.shippingLines}?${
        queryParams?.length ? queryParams.join('&') : ``
      }`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function addOperationCosts(taskId: string, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.addOperationCosts}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

async function uploadLCDetails(taskId: string, requestBody: any) {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.uploadLCDetails}${taskId}/upload-lc-details/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export {
  getTaskList,
  addAllSuppliers,
  addCourierDetails,
  getTaskForAnOrder,
  postLiftingDate,
  uploadCustomerAcceptance,
  uploadEcgc,
  postTransportDetails,
  uploadVesselDetails,
  getProductList,
  postProudctData,
  addContainerData,
  addBLDetails,
  generateCOA,
  proofOfExport,
  generateCOO,
  verifyPackagingDetails,
  approvePackagingDetails,
  rectifyPackagingDetails,
  addBatchNumber,
  generatePreShipmentPackingList,
  generatePostShipmentPackingList,
  uploadBlDraft,
  getShippingLineNames,
  addOperationCosts,
  uploadLCDetails
};
