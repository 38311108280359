import React, { useContext } from 'react';
import css from '../index.module.scss';
import { IconNode, Typography } from '@components/base';
import Images from '@assets/images';
import OrderContext from '@pages/order/generate-purchase-order/OrderContext';
import { useParams } from 'react-router-dom';

const Remarks = (props: any) => {
  const { editRed } = Images;
  const orderInfoContext = useContext(OrderContext);
  const urlParams = useParams();
  const { supplierId } = urlParams;
  const { remarks, remarksInfo } = orderInfoContext;
  const { handleTabState } = props;

  const handleEditClick = () => handleTabState(2);

  return (
    <div className={css.boxWrapper}>
      <div className={css.remarkBoxContainer}>
        <div className={css.fieldWrapper}>
          <Typography variant="pdoc">Remarks</Typography>
          <IconNode src={editRed} onClick={handleEditClick} />
        </div>
        <div className={css.fieldWrapper}>
          <ol className={remarksInfo?.string?.length ? css.remarkListContainer : css.remarkListContainerNoMargin}>
            {remarksInfo?.string?.length ? remarksInfo?.string?.split('\n')?.map((remark: string, index: number) => {
              return <li key={index}>{remark}</li>;
            }) : `No Remarks Added`}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Remarks;
