import { Button } from '@components/base';
import { BoxContainer, SelectLabel, TextAreaLabel, TextField } from '@components/common';
import { fetchOrderInfo } from '@services/order.service';
import { useContext } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import OrderContext from '../../OrderContext';
import css from './index.module.scss';

interface IPartiesFormProps {
  formSubmit: () => void;
  cancelClick: () => void;
}

const PartiesForm = (props: any) => {
  const { formSubmit, cancelClick } = props;
  const { control, handleSubmit } = useFormContext();
  const urlParams = useParams();
  const { supplierId } = urlParams;
  const orderData = useContext(OrderContext);
  // const taskData = orderData?.tasksList?.filter((item: any) => item?.supplier_id === supplierId)[0];
  const { purchaser, supplier } = orderData;

  return (
    <form noValidate className={css.purchaseOrderFormWrapper} onSubmit={handleSubmit(formSubmit)}>
      <BoxContainer title="Purchaser">
        <div className={css.purchaserWrapper}>
          <div className={css.nameFieldWrapper}>
            <Controller
              name="purchaserName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={purchaser.purchaser_name ?? ''}
                  label="Name"
                  disabled
                  placeholder="Enter name"
                />
              )}
            />
            <Controller
              name="purchaserGstNumber"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={purchaser?.purchaser_gst_no ?? ''}
                  label="GST No."
                  disabled
                  placeholder="Enter GST No."
                />
              )}
            />
          </div>
          <Controller
            name="address"
            control={control}
            render={({ field, fieldState }) => (
              <TextAreaLabel
                {...field}
                value={purchaser?.address ?? ''}
                label="Address"
                disabled
                placeholder="Enter address"
              />
            )}
          />
        </div>
      </BoxContainer>
      <BoxContainer title="Supplier">
        <div className={css.purchaserWrapper}>
          <Controller
            name="supplierName"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                isDisabled
                value={{
                  label: supplier?.supplier_name ?? '',
                  value: supplier?.supplier_name ?? ''
                }}
                label="Name"
              />
            )}
          />
          <div className={css.nameFieldWrapper}>
            <Controller
              name="vendorId"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  disabled
                  value={supplier?.vendor_id ?? ''}
                  label="Vendor ID"
                  placeholder="Enter vendor ID"
                />
              )}
            />
            <Controller
              name="supplierGstNumber"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="GST No."
                  value={supplier?.supplier_gst_no ?? ''}
                  disabled
                  placeholder="Enter GST No."
                />
              )}
            />
          </div>
          <Controller
            name="officeAddress"
            control={control}
            render={({ field, fieldState }) => (
              <TextAreaLabel
                {...field}
                disabled
                value={supplier?.office_address}
                label="Office Address"
              />
            )}
          />
          <Controller
            name="factoryLocation"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                value={supplier?.factory_location ?? ''}
                disabled
                label="Factory Location"
                placeholder="Enter factory location"
              />
            )}
          />
        </div>
      </BoxContainer>
      <div className={css.formActionWrapper}>
        <Button onClick={cancelClick} variant="text">
          Cancel
        </Button>
        <Button type="submit">Save and Proceed</Button>
      </div>
    </form>
  );
};

export default PartiesForm;
