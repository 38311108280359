import { useMemo, useState } from 'react';
import css from './index.module.scss';
import { ProductInfo } from '@helpers/types/product';
import { Typography, Loader } from '@components/base';
import { DataGrid, DocumentRow, NoDocumentPlaceholder, UploadDocument } from '@components/common';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DocumentForm } from '@helpers/types/document';
import { addDocument } from '@helpers/yup';
import { deleteGradeDocument, updateProductInfo } from '@services/product.service';
import { GradeInfo } from '@helpers/types/grade';
import notify from '@helpers/toastify-helper';
import { CellProps, Column } from 'react-table';

interface DocumentsTabProps {
  productInfo: ProductInfo;
  onChange?: (param: ProductInfo) => void;
}

interface DocumentsTabStates {
  modalState: boolean;
  editable: boolean;
  isLoading: boolean;
}

interface DocumentGrid {
  name: GradeInfo;
  proof: GradeInfo;
  doc_object: GradeInfo;
}
interface ProductDocmentData {
  name: string;
  msds_doc: string;
  product_id: string;
}

interface RowGrid {
  name: ProductDocmentData;
  proof: ProductDocmentData;
  doc_object: ProductDocmentData;
}

/**
 * Currently only one document is recorded against each product, hence this type is hardcoded
 * Once multiple documents are added, change it to dynamic
 */
const DOCUMENT_TYPE = 'MSDS';

const DocumentsTab = (props: DocumentsTabProps) => {
  const { productInfo } = props;
  const [documentState, setDocumentState] = useState<DocumentsTabStates>({
    modalState: false,
    editable: false,
    isLoading: false
  });

  const documentForm = useForm<DocumentForm>({
    resolver: yupResolver(addDocument),
    defaultValues: {
      document_id: '',
      document_type: null,
      document_name: '',
      document_object: null
    },
    shouldUnregister: true
  });

  const {
    setValue,
    formState: { isSubmitting }
  } = documentForm;

  const { grades } = productInfo;

  const { modalState, editable, isLoading } = documentState;
  const toggleModalState = () => {
    setDocumentState((prevState) => ({
      ...prevState,
      modalState: !prevState.modalState,
      editable: false
    }));
  };

  const handleUploadDocument = (data: ProductDocmentData) => async () => {
    setDocumentState((prevState) => ({
      ...prevState,
      modalState: true,
      editable: true
    }));
    setValue('document_id', `${data.product_id}`, { shouldDirty: false });
    setValue('document_type', { label: 'MSDS Doc', value: 'MSDS' }, { shouldDirty: false });
    setValue('document_name', data.name, { shouldDirty: false });
    setValue('document_object', null, { shouldDirty: false });
  };

  const handleFormSubmit: SubmitHandler<DocumentForm> = async (param) => {
    const { productInfo, onChange } = props;
    const { grades } = productInfo;
    const formData = new FormData();
    formData.append('msds_doc', param.document_object as Blob);
    if (editable) {
      const response = await updateProductInfo(param.document_id, formData);
      if (response.success) {
        const { data } = response;
        toggleModalState();
        onChange?.(response.data);
        notify({ message: 'Document added successfully', dismissible: true });
      } else {
        notify({
          message: response.error ?? 'Unable to add document',
          dismissible: true,
          severity: 'error'
        });
      }
    }
  };

  /**
   * Commenting since delete is not required, if delete is to be enable, make sure use update product and not grade
   */

  // const handleDocumentDelete = (grade: GradeInfo) => async () => {
  //   const { productInfo, onChange } = props;
  //   setDocumentState((prevState) => ({ ...prevState, isLoading: true }));
  //   const response = await deleteGradeDocument(`${grade.grade_id}`);
  //   setDocumentState((prevState) => ({ ...prevState, isLoading: false }));
  //   if (response.success) {
  //     const { grades } = productInfo;
  //     const deletedDocIndex = grades.findIndex((doc) => doc.grade_id === grade.grade_id);
  //     if (deletedDocIndex !== -1) {
  //       grades[deletedDocIndex].msds_doc = null;
  //       onChange?.({ ...productInfo, grades: grades });
  //       notify({ message: 'Document deleted successfully', dismissible: true });
  //     }
  //   } else {
  //     notify({ message: 'Unable to delete document', dismissible: true, severity: 'error' });
  //   }
  // };

  const [documentColumn, documentData] = useMemo(() => {
    const { productInfo } = props;
    const { grades } = productInfo;
    const column: Column<DocumentGrid>[] = [
      {
        Header: 'Document Name',
        accessor: 'name',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return <DocumentRow.Title title={value.name} documentAvailable={value.msds_doc} />;
        }
      },
      {
        Header: 'Proofs',
        accessor: 'proof',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return (
            <DocumentRow.Upload
              documentAvailable={value.msds_doc}
              onClick={handleUploadDocument(value)}
            />
          );
        }
      },
      {
        Header: '',
        accessor: 'doc_object',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return <DocumentRow.Action document={value.msds_doc} title={value.name} />;
        }
      }
    ];
    const productDocData = {
      name: DOCUMENT_TYPE,
      msds_doc: productInfo.msds_doc,
      product_id: productInfo.product_id
    };
    const row: any[] = [
      {
        name: productDocData,
        proof: productDocData,
        doc_object: productDocData
      }
    ];
    return [column, row];
  }, [props.productInfo]);

  return (
    <div className={css.documentTabWrapper}>
      <div className={css.documentWrapper}>
        <div className={css.productDocumentHeaderWrapper}>
          <Typography variant="h4">Documents</Typography>
        </div>
        {grades.length ? (
          <DataGrid columns={documentColumn} data={documentData} />
        ) : (
          <NoDocumentPlaceholder
            title="No Product Documents Added Yet"
            onClick={toggleModalState}
          />
        )}
      </div>
      <FormProvider {...documentForm}>
        <UploadDocument
          open={modalState}
          editMode={editable}
          onClose={toggleModalState}
          availableDocumentType={[{ label: 'MSDS', value: 'MSDS' }]}
          onFormSubmit={handleFormSubmit}
        />
      </FormProvider>
      <Loader open={isSubmitting || isLoading} />
    </div>
  );
};
export default DocumentsTab;
