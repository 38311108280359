import { IconNode, Typography } from '@components/base';
import { PeopleType, USER_TYPES } from '@helpers/constants';
import notify from '@helpers/toastify-helper';
import { IActivity, IActivityStates } from '@helpers/types/activity';
import activityLogs from '@services/activity.service';
import { fetchOrderInfo } from '@services/order.service';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import UserCard from './components/user-card';
import css from './index.module.scss';
import Images from '@assets/images';
import { getFormattedDate, getFormattedTime } from '@helpers/utils';
import { ListPlaceholder } from '@components/common';

const ActivityCard = () => {
  const params = useParams();
  const [activityState, setActivityState] = useState<IActivityStates>({
    activityList: [],
    isLoading: false
  });

  const { sales2 } = Images;

  const { activityList } = activityState;

  useEffect(() => {
    (async () => {
      setActivityState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
      const response = await activityLogs(params.id as string);
      if (response.success) {
        // const { data: peopleInfo } = response;
        setActivityState((prevState) => ({
          ...prevState,
          isLoading: !prevState.isLoading,
          activityList: response?.data ?? []
        }));
      } else {
        setActivityState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
        notify({
          message: response.error ?? 'Unable to fetch data',
          severity: 'error',
          dismissible: true
        });
      }
    })();
  }, []);

  return (
    <div className={css.contentWrapper}>
      {activityList?.map((val: any, index: number) => {
        return (
          <React.Fragment key={index}>
            <div className={css.titleWrapper}>
              <div className={css.wrapper}>
                <div className={css.image}>
                  <IconNode
                    // width="40px"
                    // height="40px"
                    src={sales2}
                    className={css.successIcon}
                  />
                </div>
                <div>
                  <Typography variant="h5">{val.activity_log}</Typography>
                </div>
              </div>
              <Typography variant="p" className={css.secondaryText}>
                <div>{getFormattedDate(val?.timestamp)}</div>
                <div>{getFormattedTime(val?.timestamp)}</div>
              </Typography>
            </div>
          </React.Fragment>
        );
      })}
      {activityList.length == 0 && (
        <ListPlaceholder
          to={``}
          title="No Activity performed on this order yet"
          supportingText="When any activity is performed on this order, it will show up here."
          buttonText=""
        />
      )}
    </div>
  );
};

export default ActivityCard;
