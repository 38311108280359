import { Fragment, useCallback } from 'react';
import css from './index.module.scss';
import { Typography, Button, IconNode, Divider } from '@components/base';
import { BoxContainer, TextField, AddressFields } from '@components/common';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import Images from '@assets/images';

const SupplierBankFields = () => {
  const { control } = useFormContext();
  const { fields, remove, append } = useFieldArray({
    name: 'bankdetails',
    control
  });

  const appendBankDetailFields = useCallback(
    () =>
      append({
        bank_id: '',
        bank_name: '',
        branch_name: '',
        bank_account_holder_name: '',
        account_number: '',
        ifsc_code: '',
        swift_code: '',
        address_line1: '',
        address_line2: '',
        zip_code: '',
        city: null,
        state: null,
        country: null,
        address_suffix: null
      }),
    []
  );

  return (
    <BoxContainer title="Bank Details">
      <div className={css.accordionBodyWrapper}>
        {fields.map((field, index) => {
          const deleteButtonState = (field as any).bank_id ? true : false;
          const removeBankDetailField = () => remove(index);
          return (
            <Fragment key={field.id}>
              <div className={css.bankDetailCountWrapper}>
                <Typography variant="h4">{`Bank ${index + 1}`}</Typography>
                {fields.length > 1 && (
                  <Button
                    disabled={deleteButtonState}
                    variant="text"
                    startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}
                    onClick={removeBankDetailField}>
                    Delete
                  </Button>
                )}
              </div>
              <div className={css.detailWrapper}>
                <Typography variant="body">Details</Typography>
                <div className={css.fieldsWrapper}>
                  <div className={css.fieldWrapper}>
                    <Controller
                      name={`bankdetails.${index}.bank_name`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          autoFocus
                          type="text"
                          inputMode="text"
                          enterKeyHint="next"
                          label="Bank Name"
                          placeholder="Enter bank name"
                          error={fieldState.invalid}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="Error Icon" />
                            )
                          }
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name={`bankdetails.${index}.branch_name`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          type="text"
                          inputMode="text"
                          enterKeyHint="next"
                          label="Branch Name"
                          placeholder="Enter branch name"
                          error={fieldState.invalid}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="Error Icon" />
                            )
                          }
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={css.fieldWrapper}>
                    <Controller
                      name={`bankdetails.${index}.bank_account_holder_name`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          type="text"
                          inputMode="text"
                          enterKeyHint="next"
                          autoComplete="cc-name"
                          label="Bank Account Holder Name"
                          placeholder="Enter bank account holder name"
                          error={fieldState.invalid}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="Error Icon" />
                            )
                          }
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name={`bankdetails.${index}.account_number`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          type="text"
                          inputMode="text"
                          enterKeyHint="next"
                          autoComplete="cc-number"
                          label="Bank Account Number"
                          placeholder="Enter account number"
                          error={fieldState.invalid}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="Error Icon" />
                            )
                          }
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={css.fieldWrapper}>
                    <Controller
                      name={`bankdetails.${index}.ifsc_code`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          type="text"
                          inputMode="text"
                          enterKeyHint="next"
                          label="IFSC Code"
                          placeholder="Enter IFSC code"
                          error={fieldState.invalid}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="Error Icon" />
                            )
                          }
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                    <Controller
                      name={`bankdetails.${index}.swift_code`}
                      control={control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          required
                          type="text"
                          inputMode="text"
                          enterKeyHint="next"
                          label="SWIFT Code"
                          placeholder="Enter SWIFT Code"
                          error={fieldState.invalid}
                          endIcon={
                            fieldState.invalid && (
                              <IconNode src={Images.alertError} alt="Error Icon" />
                            )
                          }
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className={css.detailWrapper}>
                <Typography variant="body">Bank Address</Typography>
                <AddressFields
                  firstAddressLine={`bankdetails.${index}.address_line1`}
                  secondAddressLine={`bankdetails.${index}.address_line2`}
                  zipCode={`bankdetails.${index}.zip_code`}
                  city={`bankdetails.${index}.city`}
                  state={`bankdetails.${index}.state`}
                  country={`bankdetails.${index}.country`}
                  suffix={`bankdetails.${index}.address_suffix`}
                />
              </div>
              <Divider className={css.divider} />
            </Fragment>
          );
        })}
        <Button
          variant="text"
          style={{ width: 'fit-content' }}
          startIcon={<IconNode src={Images.plusRed} alt="plus icon" />}
          onClick={appendBankDetailFields}>
          Add Bank
        </Button>
      </div>
    </BoxContainer>
  );
};

export default SupplierBankFields;
