import { useCallback, useEffect, useState } from 'react';
import css from './index.module.scss';
import { IconNode, Typography, Button, Alert, Loader } from '@components/base';
import { LinkButton, Seo, TextField } from '@components/common';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { CLIENT_ROUTES } from '@router/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { loginSchema } from '@helpers/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Images from '@assets/images';
import { googleLogin, userLogin } from '@services/login.service';
import { ILoginCredential } from '@helpers/types';
import SessionService from '@helpers/session-helper';
import notify from '@helpers/toastify-helper';
import { EVENT_TRACKING_TYPES, USER_TYPES } from '@helpers/constants';
import { pushEventTracking } from '@helpers/utils';

const SignIn = () => {
  const [signInState, setSignInState] = useState({
    showLoader: false,
    showErrorMessage: false
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { customerEmail = '', customerPassword = '' } = location.state || {};
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      email: customerEmail,
      password: customerPassword
    },
    resolver: yupResolver(loginSchema)
  });

  const handleFormSubmit: SubmitHandler<ILoginCredential> = useCallback(async (data) => {
    setSignInState((prevState) => ({ ...prevState, showErrorMessage: false }));
    const response = await userLogin(data);
    if (response?.success) {
      pushEventTracking(EVENT_TRACKING_TYPES.LOGIN_SUCCESS);
      const { access, refresh, email, uidb64, token, first_time_login, user_auth_info, role } =
        response.data;
      if (first_time_login) {
        navigate(`/${CLIENT_ROUTES.passwordSetup}`, {
          replace: true,
          state: { email, uidb64, token }
        });
        return;
      }
      const user_authorization_data = {
        ...user_auth_info,
        user_type: role === USER_TYPES.customer ? USER_TYPES.customer : USER_TYPES.internal
      };
      SessionService.saveSession(access, refresh, user_authorization_data);
    } else {
      setSignInState((prevState) => ({ ...prevState, showErrorMessage: true }));
      pushEventTracking(EVENT_TRACKING_TYPES.LOGIN_FAILED);
    }
  }, []);

  const handleGoogleLogin = useCallback(async (credentialResponse: CredentialResponse) => {
    setSignInState((prevState) => ({ ...prevState, showErrorMessage: false, showLoader: true }));
    const response = await googleLogin(`${credentialResponse.credential}`);
    setSignInState((prevState) => ({ ...prevState, showLoader: false }));
    if (response?.success) {
      pushEventTracking(EVENT_TRACKING_TYPES.LOGIN_SUCCESS);
      pushEventTracking(EVENT_TRACKING_TYPES.GOOGLE_LOGIN_SUCCESS);
      const { access, refresh, user_auth_info, role } = response.data;
      const user_authorization_data = {
        ...user_auth_info,
        user_type: role === USER_TYPES.customer ? USER_TYPES.customer : USER_TYPES.internal
      };
      SessionService.saveSession(access, refresh, user_authorization_data);
    } else {
      pushEventTracking(EVENT_TRACKING_TYPES.LOGIN_FAILED);
      pushEventTracking(EVENT_TRACKING_TYPES.GOOGLE_LOGIN_FAILED);
      notify({
        message: 'The email you selected does not appear to be registered.',
        severity: 'error'
      });
    }
  }, []);

  const handleGoogleLoginError = useCallback(() => {
    notify({
      message: 'Login failed. Please try again.',
      severity: 'error'
    });
  }, []);

  return (
    <div className={css.loginGroupSection}>
      <Typography variant="h1">Log In</Typography>
      <Seo title="Sign In" />
      <form className={css.formWrapper} onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              type="email"
              inputMode="email"
              enterKeyHint="next"
              autoComplete="email"
              label="Email"
              placeholder="Enter your email"
              autoFocus={!customerEmail?.length}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error" />}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              type="password"
              enterKeyHint="done"
              autoComplete="current-password"
              label="Password"
              placeholder="Enter your password"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error" />}
            />
          )}
        />
        {signInState.showErrorMessage && (
          <Alert severity="error" title="Invalid Credentials" icon={false}>
            Please re-enter your credentials and try again.
          </Alert>
        )}
        <LinkButton
          style={{ alignSelf: 'flex-end' }}
          to={`${CLIENT_ROUTES.forgotPassword}`}
          eventTrackingName={EVENT_TRACKING_TYPES.FORGOT_PASSWORD_CLICK}>
          Forgot password
        </LinkButton>
        <div className={css.fieldActionButton}>
          <Button
            type="submit"
            disabled={isSubmitting}
            eventTrackingName={EVENT_TRACKING_TYPES.LOGIN_BTN_CLICK}>
            Log In
          </Button>
          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={handleGoogleLoginError}
            width="360px"
            text="continue_with"
            logo_alignment="center"
          />
        </div>
      </form>
      <Loader open={isSubmitting || signInState.showLoader} />
    </div>
  );
};

export default SignIn;
