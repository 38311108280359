import {
  SyntheticEvent,
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  useCallback,
  useContext,
  useMemo
} from 'react';
import css from './index.module.scss';
import {
  Badge,
  Drawer,
  Button,
  IconNode,
  Loader,
  Tab,
  Tabs,
  Typography,
  ToolTip
} from '@components/base';
import {
  InfiniteScroll,
  LinkButton,
  ListPlaceholder,
  OrderCard,
  PaginationScroller,
  SearchBar,
  SearchPlaceholder,
  Seo
} from '@components/common';
import Filter, { FormType } from './components/filter';
import { CLIENT_ROUTES } from '@router/routes';
import Images from '@assets/images';
import { ListParam, fetchAllOrder } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { OrderInfo } from '@helpers/types/order';
import debounce from 'lodash.debounce';
import {
  getCommaSepratedValue,
  getPermissionFromLocalStorage,
  pushEventTracking
} from '@helpers/utils';
import AccessWrapper from '@authorization/access-wrapper';
import { USER_TYPES } from '@helpers/constants';
import { useScrollToTop } from '@helpers/hooks';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { redirect, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';

interface OrderHomeStates {
  activeTab: number;
  orderList: OrderInfo[];
  isLoading: boolean;
  orderCount: number;
  hasNext: boolean;
  currentPageNumber: number;
  retry: boolean;
  searchText: string;
  isSearchLoading: boolean;
  state: string | null;
  showFilterDrawer: boolean;
  filters: FormType | null;
  filterData: any;
}

const STATE_MAPPING = [
  {
    value: 0,
    key: null
  },
  {
    value: 8,
    key: 'order_placed_state'
  },
  {
    value: 1,
    key: 'order_confirmed_state'
  },
  {
    value: 2,
    key: 'products_manufactured_state'
  },
  {
    value: 3,
    key: 'export_clearance_obtained_state'
  },
  {
    value: 4,
    key: 'shipment_departure_from_pol_state'
  },
  {
    value: 5,
    key: 'shipment_arrival_at_pod_state'
  },
  {
    value: 6,
    key: 'order_completed_state'
  },
  {
    value: 7,
    key: 'my_order'
  }
];

const OrderHome = (props: any) => {
  const { actions } = props.modulePermissions;
  const debounceSearch = useRef<any>();
  const rootContainer = useRef<HTMLElement>(null);
  const topRef = useRef<HTMLElement>(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [itemLimit, setItemLimit] = useState(10);
  const userType = useMemo(() => {
    return getPermissionFromLocalStorage()?.user_type;
  }, []);
  const initialPageNumber = parseInt(searchParams.get('page') || '1', 10);
  const [orderState, setOrderState] = useState<OrderHomeStates>({
    showFilterDrawer: false,
    activeTab: 0,
    orderList: [],
    isLoading: false,
    orderCount: 0,
    hasNext: false,
    currentPageNumber: initialPageNumber,
    retry: false,
    searchText: '',
    isSearchLoading: false,
    state: '',
    filters: null,
    filterData: null
  });

  const {
    activeTab,
    orderList,
    orderCount,
    hasNext,
    currentPageNumber,
    retry,
    searchText,
    isLoading,
    isSearchLoading,
    state,
    filterData,
    filters
  } = orderState;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('showSuccess') === 'true') {
      pushEventTracking('RFQ_SUCCESS');
      notify({
        message: 'Your RFQ has been placed successfully',
        severity: 'success'
      });
      navigate('/order');
    }
  }, [location.search]);

  const handleFilterClick = () => {
    setOrderState((prevState) => ({
      ...prevState,
      showFilterDrawer: !prevState.showFilterDrawer
    }));
  };

  const handleTabChange = async (_event: SyntheticEvent, newValue: number) => {
    const queryKey = STATE_MAPPING.find((state) => state.value === newValue);
    setOrderState((prevState) => ({
      ...prevState,
      activeTab: newValue,
      state: queryKey?.key ? queryKey.key : null
    }));
    if (queryKey?.key) {
      setOrderState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await fetchAllOrder(1, searchText, undefined, undefined, queryKey.key);
      setOrderState((prevState) => ({ ...prevState, isLoading: false }));
      if (response.success) {
        const { data } = response;
        setOrderState((prevState) => ({
          ...prevState,
          orderList: [...data.results],
          orderCount: data.count,
          hasNext: !!data.next,
          currentPageNumber: 1,
          retry: false,
          searchLoading: false
        }));
      } else if (response.error) {
        setOrderState((prevState) => ({ ...prevState, retry: true, searchLoading: false }));
        notify({ message: response.error ?? 'Unable to fetch order list', severity: 'error' });
      }
    } else {
      setOrderState((prevState) => ({ ...prevState, isLoading: true }));
      await getOrderList(1, undefined, undefined, undefined, false);
      setOrderState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const getOrderList = async (
    page = 1,
    searchText?: string,
    param?: ListParam,
    reqBody?: any,
    append = true
  ) => {
    const response = await fetchAllOrder(
      page,
      searchText,
      undefined,
      undefined,
      append ? state : undefined,
      param,
      reqBody
    );
    if (response.success) {
      const { data } = response;
      setOrderState((prevState) => ({
        ...prevState,
        orderList: data.results,
        orderCount: data.count,
        hasNext: !!data.next,
        currentPageNumber: page,
        retry: false,
        searchLoading: false,
        isLoading: false
      }));
    } else if (response.error) {
      setOrderState((prevState) => ({ ...prevState, retry: true, searchLoading: false }));
      notify({ message: response.error ?? 'Unable to fetch order list', severity: 'error' });
    }
  };

  const fetchSearchResult = useCallback(async (searchText: string) => {
    const response = await fetchAllOrder(1, searchText, undefined, undefined, state);
    if (response.success) {
      const { data } = response;
      setOrderState((prevState) => ({
        ...prevState,
        orderList: data.results,
        orderCount: data.count,
        hasNext: !!data.next,
        currentPageNumber: 1,
        retry: false,
        isSearchLoading: false
      }));
    } else {
      setOrderState((prevState) => ({
        ...prevState,
        retry: true,
        isSearchLoading: false
      }));
      notify({ message: response.error ?? 'Unable to fetch customer list ', severity: 'error' });
    }
  }, []);

  const handleOrderSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setOrderState((prevState) => ({
      ...prevState,
      searchText: value.trim(),
      isSearchLoading: true
    }));
    debounceSearch.current?.cancel();
    debounceSearch.current = debounce(fetchSearchResult, 800);
    debounceSearch.current(value);
  };
  const date = new Date();
  const handleFilterApply = async (data: FormType) => {
    const trader = data.business_type_trader ? 'TR' : null;
    const manufacturer = data.business_type_manufacturer ? 'MR' : null;
    const indian = data.supplier_type_indian ? 'INDIAN' : null;
    const foreign = data.supplier_type_foreign ? 'FOREIGN' : null;
    const orderTypeMTT = data.order_type_MTT ? 'MTT' : null;
    const orderTypeInd = data.order_type_indian ? 'INDIAN' : null;
    const incoTermsCIF = data.inco_term_type_CIF ? 'CIF' : null;
    const incoTermsFOB = data.inco_term_type_FOB ? 'FOB' : null;
    const incoTermsCFR = data.inco_term_type_CFR ? 'CFR' : null;
    const incoTermsDDP = data.inco_term_type_DDP ? 'DDP' : null;
    const orderStateDate = data.startOrderDate
      ? `${moment(data.startOrderDate).format('YYYY-MM-DD')}`
      : null;
    const orderEndDate = data.startOrderDate
      ? data.endOrderDate
        ? `${moment(data.endOrderDate).format('YYYY-MM-DD')}`
        : `${moment(date).format('YYYY-MM-DD')}`
      : null;
    const startBLAWBDate = data.startBLAWBDate
      ? `${moment(data.startBLAWBDate).format('YYYY-MM-DD')}`
      : null;
    const endBLAWBDate = data.startBLAWBDate
      ? data.endBLAWBDate
        ? `${moment(data.endBLAWBDate).format('YYYY-MM-DD')}`
        : `${moment(date).format('YYYY-MM-DD')}`
      : null;
    let business_type_list = '';
    if (trader) business_type_list = trader;
    if (manufacturer)
      business_type_list
        ? (business_type_list += `,${manufacturer}`)
        : (business_type_list = manufacturer);

    let incoterms_list = '';
    if (incoTermsCIF) incoterms_list = incoTermsCIF;
    if (incoTermsFOB)
      incoterms_list ? (incoterms_list += `,${incoTermsFOB}`) : (incoterms_list = incoTermsFOB);
    if (incoTermsCFR)
      incoterms_list ? (incoterms_list += `,${incoTermsCFR}`) : (incoterms_list = incoTermsCFR);
    if (incoTermsDDP)
      incoterms_list ? (incoterms_list += `,${incoTermsDDP}`) : (incoterms_list = incoTermsDDP);

    let supplier_type_list = '';
    if (indian) supplier_type_list = indian;
    if (foreign)
      supplier_type_list ? (supplier_type_list += `,${foreign}`) : (supplier_type_list = foreign);
    let supply_type_list = '';
    if (orderTypeMTT) supply_type_list = orderTypeMTT;
    if (orderTypeInd)
      supply_type_list
        ? (supply_type_list += `,${orderTypeInd}`)
        : (supply_type_list = orderTypeInd);

    const reqBody = {
      supplier_name_list: data.supplier.map((item) => item.supplier_name).join(','),
      product_list: data.product.map((item) => item.name).join(','),
      customer_name_list: data.customer.map((customer) => customer.name).join(','),
      users_list: data.selectedOrderPOC.map((user: any) => user.user_id).join(','),
      supply_type_list,
      incoterms_list,
      order_created_start_date: orderStateDate,
      order_created_end_date: orderEndDate,
      bl_or_awb_start_date: startBLAWBDate,
      bl_or_awb_end_date: endBLAWBDate
      //business_type_list,
      //supplier_type_list,
      //country: data.country.map((item) => item.value).join(',')
    };

    setOrderState((prevState) => ({
      ...prevState,
      orderList: [],
      loading: true,
      filters: data,
      filterData: reqBody
    }));
    //await getOrderList({ page: 1, ...reqBody });
    setOrderState((prevState) => ({ ...prevState, isLoading: true }));
    await getOrderList(1, undefined, undefined, reqBody);
    setOrderState((prevState) => ({ ...prevState, isLoading: false }));
    handleFilterClick();
  };
  const handlePageChange = async (newPage: number) => {
    setOrderState((prev) => ({ ...prev, isLoading: true }));
    await getOrderList(newPage, searchText, undefined, filterData, true);
    setOrderState((prevState) => ({ ...prevState, isLoading: false }));
    setSearchParams({ page: newPage.toString() });
  };

  const validatePageChange = (event: any, value: number) => {
    if (isNaN(value)) return;
    if (value === 0 || value > Math.ceil(orderCount / itemLimit)) return;
    handlePageChange(value);
  };

  useEffect(() => {
    handlePageChange(currentPageNumber);
  }, [currentPageNumber]);

  useScrollToTop({ topRef, dependencyArray: [currentPageNumber] });

  const handleClearFilter = async () => {
    setOrderState((prevState) => ({
      ...prevState,
      filters: null,
      filterData: null,
      isLoading: true
    }));
    await getOrderList(1, undefined, undefined, undefined);
    setOrderState((prevState) => ({
      ...prevState,
      isLoading: false
    }));
  };

  return (
    <AccessWrapper show={actions?.read} showUnauthorised>
      <main ref={rootContainer} className={css.mainWrapper}>
        <Seo title="Orders" />
        <div className={css.header}>
          <div
            className={userType == USER_TYPES.internal ? css.textWrapper : css.textWrapperExternal}>
            <Typography variant="h2">Orders</Typography>
            <Badge>{orderCount}</Badge>
          </div>
          <AccessWrapper show={actions?.create}>
            <LinkButton
              variant="contained"
              to={`${CLIENT_ROUTES.addOrder}`}
              className={css.addOrderButton}>
              Add Order
            </LinkButton>
          </AccessWrapper>
        </div>
        <AccessWrapper show={actions?.read} showTo={[USER_TYPES.internal]} byPassShowWithShowTo>
          <div className={css.tabsWrapper}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label="All" value={0} />
              <Tab label="Order Placed" value={8} />
              <Tab label="Order Confirmed" value={1} />
              <Tab label="Products Manufactured" value={2} />
              <Tab label="Export Clearance Obtained" value={3} />
              <Tab label="Departure from POL" value={4} />
              <Tab label="Arrival at POD" value={5} />
              <Tab label="Completed" value={6} />
              <Tab label="My Orders" value={7} />
            </Tabs>
          </div>
        </AccessWrapper>

        <div className={css.filterWrapper}>
          <AccessWrapper show={actions?.read} showTo={[USER_TYPES.internal]} byPassShowWithShowTo>
            <div className={css.filterButtonWrapper}>
              <Button
                className={css.filterButton}
                variant="outlined"
                startIcon={<IconNode src={Images.filter} alt="filter icon" />}
                onClick={handleFilterClick}>
                {`Filter ${filters ? '(1)' : ''}`}
              </Button>
              {filters && (
                <Button
                  variant="text"
                  onClick={handleClearFilter}
                  startIcon={<IconNode src={Images.crossRed} alt="clear icon" />}>
                  Clear Filters
                </Button>
              )}
            </div>
          </AccessWrapper>

          <AccessWrapper show={actions?.read} showTo={[USER_TYPES.customer]} byPassShowWithShowTo>
            <LinkButton
              variant="outlined-secondary"
              to="/contact-us"
              eventTrackingName="RFQ_BTN_CLICK">
              Place RFQ
            </LinkButton>
          </AccessWrapper>

          <div className={css.searchBar}>
            <SearchBar
              isLoading={isSearchLoading}
              placeholder="Search"
              className={css.search}
              onChange={handleOrderSearch}
            />
            <div>
              <ToolTip title={'Search By: Products, PI Number, PO Number'} placement="right">
                <div>
                  <IconNode src={Images.alertGrey} alt="eye icon" />
                </div>
              </ToolTip>
            </div>
          </div>

          <div className={css.paginationFilter}>
            <ToolTip title="Previous Page">
              <ArrowBackIosIcon
                sx={{
                  height: '40px',
                  cursor: currentPageNumber > 1 ? 'pointer' : 'default',
                  color: currentPageNumber > 1 ? '#000000' : '#e2e2e2'
                }}
                onClick={() => validatePageChange(null, currentPageNumber - 1)}
              />
            </ToolTip>
            <Typography className={css.pageOfTotalPage}>
              {currentPageNumber} of {Math.ceil(orderCount / itemLimit)}
            </Typography>
            <ToolTip title="Next Page">
              <ArrowForwardIosIcon
                sx={{
                  height: '40px',
                  cursor:
                    currentPageNumber < Math.ceil(orderCount / itemLimit) ? 'pointer' : 'default',
                  color:
                    currentPageNumber < Math.ceil(orderCount / itemLimit) ? '#000000' : '#e2e2e2'
                }}
                onClick={() => validatePageChange(null, currentPageNumber + 1)}
              />
            </ToolTip>
          </div>
        </div>
        <section className={css.orderSection} ref={topRef}>
          {orderList.length ? (
            orderList.map((order: any, index: number) => (
              <OrderCard
                //key={order.order_id}
                key={index}
                readableOrderId={order.readable_order_id}
                orderId={order.order_id}
                items={order.order_item}
                customerName={order.customer?.name ? order.customer?.name : '-'}
                createdBy={order.created_by}
                currentState={order.current_state}
                totalAmount={order.total ? getCommaSepratedValue(order.total) : '-'}
                orderInfo={order}
                actions={actions}
                pendingTasks={order?.pending_tasks?.length ? order.pending_tasks : []}
              />
            ))
          ) : searchText ? (
            <SearchPlaceholder searchText={searchText} />
          ) : (
            !isLoading && (
              <ListPlaceholder
                to={`${CLIENT_ROUTES.addOrder}`}
                title="No Order Added Yet"
                supportingText={
                  userType == USER_TYPES.internal
                    ? `When an order is added by you, they will show up here.`
                    : ``
                }
                buttonText={userType == USER_TYPES.internal ? `Add Order` : ``}
              />
            )
          )}
        </section>
        {!isLoading && (
          <PaginationScroller
            variant="text"
            defaultPage={1}
            count={orderCount}
            pageLimit={itemLimit}
            page={currentPageNumber}
            onChange={validatePageChange}
          />
        )}
        <Drawer open={orderState.showFilterDrawer} anchor="right" onClose={handleFilterClick}>
          <Filter value={null} onApply={handleFilterApply} onClose={handleFilterClick} />
        </Drawer>
        <Loader open={isLoading} />
      </main>
    </AccessWrapper>
  );
};

export default OrderHome;
