import Images from '@assets/images';
import { Accordion, Chip, Typography } from '@components/base';
import { Avatar } from '@components/common';
import { useState } from 'react';
import css from './index.module.scss';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import Footer from '../components/footer';
import UploadLCDetailsCard from './components';

interface uploadLCDetailsProps {
  taskData: Partial<ITaskTabProps>;
  onComplete?: () => void;
}

const LCDetailsCard = (props: uploadLCDetailsProps) => {
  const { taskData } = props;
  const [openModal, setopenModal] = useState(false);
  const getCompleteStatus = taskData?.is_completed || false;
  const [taskIsCompleted, setTaskIsCompleted] = useState<boolean>(getCompleteStatus);
  const handleStatusFromModal = (data: boolean) => {
    setTaskIsCompleted(data);
  };

  return (
    <>
      <div className={css.mainWrapper}>
        <Accordion defaultExpanded>
          <Accordion.Header className={css.accordionHeader}>
            <div className={css.header}>
              <div className={css.wrapper}>
                {getCompleteStatus || taskIsCompleted ? (
                  <Avatar src={Images.alertSuccessFaint} alt="" />
                ) : (
                  <Avatar src={Images.illustration} alt="" />
                )}
                <Typography variant="h5">
                  #{taskData?.readable_task_id} - Upload LC Details
                </Typography>
              </div>
              <div className={css.pocWrapper}>
                {!getCompleteStatus && taskIsCompleted === false && (
                  <Chip
                    label="Add Details"
                    variant="outlined"
                    clickable={true}
                    className={css.uploadButton}
                    onClick={() => setopenModal(true)}
                  />
                )}
              </div>
            </div>
          </Accordion.Header>
        </Accordion>
        <Footer
          createdDate={props.taskData?.created_at as Date}
          updatedDate={props.taskData?.updated_at as Date}
        />
        {openModal && (
          <UploadLCDetailsCard
            open={openModal}
            onClose={() => setopenModal(false)}
            sendStatusToCard={handleStatusFromModal}
            taskId={taskData.task_id as string}
            onComplete={props.onComplete}
          />
        )}
      </div>
    </>
  );
};

export default LCDetailsCard;
