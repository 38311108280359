import { Typography } from '@components/base';
import { PerformaInvoiceStates } from '@helpers/types/pi';
import css from '../index.module.scss';
import { PurchaseOrderStates } from '@helpers/types/po';

interface IRemark {
  previewData: PerformaInvoiceStates;
}

const Remarks = (props: IRemark) => {
  const { previewData } = props;
  const { remarks } = previewData;

  return (
    <div className={css.boxWrapper}>
      <div className={css.remarkBoxContainer}>
        <div className={css.fieldWrapper}>
          <Typography variant="pdoc">Standard Remarks</Typography>
        </div>
        <div className={css.fieldWrapper}>
          <ol className={remarks.length ? css.remarkListContainer : css.remarkListContainerNoMargin}>
            {remarks.length ? remarks.split('\n').map((val: string, index: number) => {
              return <li key={index}>{val}</li>;
            }) : 'No Remarks Added'}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Remarks;
