import Images from '@assets/images';
import { Chip, Loader } from '@components/base';
import Typography from '@components/base/typography';
import InfiniteScroll from '@components/common/infinite-scroller';
import { getToken, parseJWT } from '@helpers/auth-helper';
import { TASKS_MAP } from '@helpers/constants';
import notify from '@helpers/toastify-helper';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { getTaskForAnOrder } from '@services/task.service';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import ReviewApprovePICard from './PI-review-approve-card';
import AddCourierDetailsCard from './add-courier-details-card';
import AssignmentCard from './assignment-card';
import GeneratePICard from './generate-PI-card';
import css from './index.module.scss';
import LiftingDatesCard from './lifting-dates-card';
import ShipmentDocs from './post-shipment-docs';
import RODTEPDrawbackCard from './ro-dtep-drawback-card';
import UpdateGateInCard from './update-gate-in-card';
import MaterialLiftingConfirmation from './update-material-lifting-confirmation';
import UpdateShipmentClearanceCard from './update-shipment-clearance-card';
import UploadOrderAcceptance from './upload-customer-order-acceptance';
import VesselCard from './vessel-dispatch-card';
import GeneratePOCard from './generate-PO-card';
import ReviewApprovePOCard from './PO-review-approve-card';
import RectifyPOCard from './rectify-PO-order';
import PurchaseOrderReview from '@pages/order/review-purchase-order/review-performa-invoice/preview-tab';
import RectifyOrderCard from './rectify-order-card';
import ApproveOrderCard from './approve-order-card';
import GenerateCRL from './generate-CRL-Card';
import GenerateBOECard from './generate-BOE-card';
import PaymentCard from './payment-card';
import AddTransportDetailCard from './add-transport-details';
import GenerateCICard from './generate-CI-Card';
import RectifyPICard from './rectify-PI-card';
import AddAllSuppliers from './add-all-suppliers-task';
import AddContainerData from './add-container-data';
import AddBLDetails from './add-bl-details';
import GenerateCommercialInvoiceCard from './generate-commercial-invoice';
import GenerateCOACard from './generate-coa-card';
import ProofOfExport from './proof-of-export';
import GenerateCOO from './generate-COO';
import TaskCard from '@pages/task/task-home/components/task-card';
import { Verify, Rectify, Review } from './packaging-details';
import GeneratePackingList from './generate-packing-list';
import GeneratePostPackingList from './generate-post-shipment-packing-list';
import AddBatch from './batch-number';
import { TASK_STATUS_MAPPING } from '@helpers/utils';
import NoDocumentPlaceHolder from '@components/common/no-document-placeholder';
import GenerateSICard from './generate-SI-card';
import UploadBlDraft from './upload-bl-draft';
import AddOperationCosts from './add-operation-costs';
import { AppContext } from '@helpers/hooks/AppContext';
import AddEstimate from './add-estimate';
import LCDetailsCard from './upload-lc-details';

interface ITasksProps {
  tasksList: Partial<ITaskTabProps>[];
  isLoading: boolean;
  tasksCount: number;
  hasNext: boolean;
  currentPageNumber: number;
  retry: boolean;
  searchLoading: boolean;
  state: number;
}

const TaskTab = (props: any) => {
  const { orderInfo, getOrderInfo, getPendingActions } = props;
  const rootContainer = useRef<HTMLElement>(null);
  const { appDispatch } = useContext(AppContext);
  const [tasksState, setTasksState] = useState<ITasksProps>({
    tasksList: [],
    isLoading: false,
    tasksCount: 0,
    hasNext: false,
    currentPageNumber: 1,
    retry: false,
    searchLoading: false,
    state: 1
  });

  const { tasksList, isLoading, hasNext, currentPageNumber, retry, state } = tasksState;

  useEffect(() => {
    (async () => {
      setTasksState((prevState) => ({ ...prevState, isLoading: true }));
      await getAllTasks(1);
      setTasksState((prevState) => ({ ...prevState, isLoading: false }));
    })();
  }, []);

  const getAllTasks = async (page = 1) => {
    try {
      const accessToken = getToken('access');
      const jwtData = parseJWT(accessToken);
      const response = await getTaskForAnOrder(
        orderInfo.orderInfo.order_id,
        page,
        jwtData?.user_id,
        TASK_STATUS_MAPPING.find((item: any) => item.state === state)?.isCompleted
      );
      if (response?.success) {
        const { data } = response;
        setTasksState((prevState) => ({
          ...prevState,
          //Handled task refresh issue, in case of task being completed call the getAllTasks Func with no param or pass page=1
          tasksList: page === 1 ? data.results : [...prevState.tasksList, ...data.results],
          hasNext: !!data.next,
          currentPageNumber: page ?? 1,
          retry: false,
          tasksCount: data.count,
          searchLoading: false
        }));
      } else {
        // notify({
        //   message: response.error ?? 'Something went wrong',
        //   severity: 'error',
        //   dismissible: true
        // });
        setTasksState((prevState) => ({
          ...prevState,
          retry: true,
          searchLoading: false,
          loading: false
        }));
      }
    } catch (err) {
      setTasksState((prevState) => ({ ...prevState, retry: true, searchLoading: false }));
      notify({ message: 'Unable to fetch tasks list', severity: 'error' });
    }
  };

  /**
   *
   * @param state number
   * @param page number will be set to 1 only
   * Need to refactor this once pagination is added
   */

  const getFilteredTasks = async (state: number, page = 1) => {
    setTasksState((prevState) => ({ ...prevState, state: state }));
    try {
      const accessToken = getToken('access');
      const jwtData = parseJWT(accessToken);
      const response = await getTaskForAnOrder(
        orderInfo.orderInfo.order_id,
        page,
        jwtData?.user_id,
        TASK_STATUS_MAPPING.find((item: any) => item.state === state)?.isCompleted
      );
      if (response?.success) {
        const { data } = response;
        setTasksState((prevState) => ({
          ...prevState,
          //Handled task refresh issue, in case of task being completed call the getAllTasks Func with no param or pass page=1
          tasksList: page === 1 ? data.results : [...data.results],
          hasNext: !!data.next,
          currentPageNumber: page ?? 1,
          retry: false,
          tasksCount: data.count,
          searchLoading: false
        }));
      } else {
        // notify({
        //   message: response.error ?? 'Something went wrong',
        //   severity: 'error',
        //   dismissible: true
        // });
        setTasksState((prevState) => ({
          ...prevState,
          retry: true,
          searchLoading: false,
          loading: false
        }));
      }
    } catch (err) {
      setTasksState((prevState) => ({ ...prevState, retry: true, searchLoading: false }));
      notify({ message: 'Unable to fetch tasks list', severity: 'error' });
    }
  };

  const refreshData = () => {
    getAllTasks();
    getOrderInfo();
    getPendingActions();
  };
  return (
    <main className={css.taskSection} ref={rootContainer}>
      <div className={css.taskChipWrapper}>
        <Chip
          label="All"
          variant={state == 0 ? `filled` : `outlined`}
          onClick={() => getFilteredTasks(0)}
        />
        <Chip
          label="In Progress"
          variant={state == 1 ? `filled` : `outlined`}
          onClick={() => getFilteredTasks(1)}
        />
        <Chip
          label="Past/Completed"
          variant={state == 2 ? `filled` : `outlined`}
          onClick={() => getFilteredTasks(2)}
        />
      </div>
      {tasksList?.length > 0 ? (
        <InfiniteScroll
          rootRef={rootContainer.current!}
          currentIndex={currentPageNumber}
          hasMore={hasNext}
          hideEndText={tasksList.length <= 10}
          onIntersect={getAllTasks}
          retry={retry}>
          {tasksList?.map((task, index) => (
            <Fragment key={index}>
              {(() => {
                switch (task?.task_type) {
                  case TASKS_MAP.assignPersonnel:
                    return (
                      <AssignmentCard
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.generatePI:
                    return (
                      <GeneratePICard
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                      />
                    );

                  case TASKS_MAP.addRodtepDrawback:
                    return (
                      <RODTEPDrawbackCard
                        taskId={task.task_id as string}
                        onComplete={refreshData}
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                      />
                    );

                  case TASKS_MAP.upadteGateIn:
                    return (
                      <UpdateGateInCard
                        taskId={task.task_id as string}
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                      />
                    );

                  case TASKS_MAP.updateShipmentClearance:
                    return (
                      <UpdateShipmentClearanceCard
                        onComplete={refreshData}
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                      />
                    );

                  case TASKS_MAP.addLiftingDates:
                    return (
                      <LiftingDatesCard
                        taskData={tasksList.filter((item) => item.task_id == task.task_id)}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.reviewPI:
                    return (
                      <ReviewApprovePICard
                        taskData={tasksList.filter((item) => item.task_id == task.task_id)}
                      />
                    );

                  case TASKS_MAP.rectifyPI:
                    return (
                      <RectifyPICard
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                      />
                    );

                  case TASKS_MAP.postShipmentDoc:
                    return (
                      <ShipmentDocs
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        shipmentType="post-shipment"
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.preShipmentDoc:
                    return (
                      <ShipmentDocs
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        shipmentType="pre-shipment"
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.exportClearance:
                    return (
                      <ShipmentDocs
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        shipmentType="export-clearance"
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.paymentProof:
                    return (
                      <PaymentCard
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.addTransportDetails:
                    return (
                      <AddTransportDetailCard
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                      />
                    );

                  case TASKS_MAP.materialLiftingConfirmation:
                    return (
                      <MaterialLiftingConfirmation
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.generateCI:
                    return (
                      <GenerateCICard
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                      />
                    );

                  case TASKS_MAP.addCourierDetails:
                    return (
                      <AddCourierDetailsCard
                        onComplete={refreshData}
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                      />
                    );

                  case TASKS_MAP.vesselDispatch:
                    return (
                      <VesselCard
                        taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.uploadCustomerOrderAcceptance:
                    return (
                      <UploadOrderAcceptance
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.addAllSuppliers:
                    return (
                      <AddAllSuppliers
                        taskId={task.task_id as string}
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.rectifyOrder:
                    return (
                      <RectifyOrderCard
                        taskData={tasksList.filter((item) => item.task_id == task.task_id)}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.reviewOrder:
                    return (
                      <ApproveOrderCard
                        taskData={tasksList.filter((item) => item.task_id == task.task_id)}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.generateCRL:
                    return (
                      <GenerateCRL
                        taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                      />
                    );

                  case TASKS_MAP.generateBOE:
                    return (
                      <GenerateBOECard
                        taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                      />
                    );

                  case TASKS_MAP.generatePO:
                    return (
                      <GeneratePOCard
                        generatedBy="sas"
                        taskStatus="critical"
                        priority="high"
                        taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                      />
                    );

                  case TASKS_MAP.rectifyPO:
                    return (
                      <RectifyPOCard
                        taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                      />
                    );

                  case TASKS_MAP.reviewPO:
                    return (
                      <ReviewApprovePOCard
                        taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                      />
                    );

                  case TASKS_MAP.uploadECGCandDNB:
                    return (
                      <TaskCard
                        taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                        onComplete={refreshData}
                      />
                    );
                  case TASKS_MAP.addContainerData:
                    return (
                      <AddContainerData
                        taskData={tasksList.find((item) => item.task_id == task.task_id) ?? {}}
                        onComplete={refreshData}
                      />
                    );
                  case TASKS_MAP.addBLDetails:
                    return (
                      <AddBLDetails
                        taskData={tasksList.find((item) => item.task_id == task.task_id) ?? {}}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.generateCommercialInvoice:
                    return (
                      <GenerateCommercialInvoiceCard
                        taskData={tasksList.find((item) => item.task_id == task.task_id) ?? {}}
                        onComplete={refreshData}
                      />
                    );
                  case TASKS_MAP.generateCOA:
                    return (
                      <GenerateCOACard
                        taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                      />
                    );
                  case TASKS_MAP.proofOfExport:
                    return (
                      <ProofOfExport
                        taskData={tasksList.filter((item: any) => item.task_id === task.task_id)}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.generateCOO:
                    return (
                      <GenerateCOO
                        taskData={tasksList.filter((item) => item.task_id === task.task_id)}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.verifyPackingDetails:
                    return (
                      <Verify
                        taskData={tasksList.find((item) => item.task_id === task.task_id) ?? {}}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.approvePackingDetails:
                    return (
                      <Review
                        taskData={tasksList.find((item) => item.task_id == task.task_id) ?? {}}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.rectifyPackingDetails:
                    return (
                      <Rectify
                        taskData={tasksList.find((item) => item.task_id === task.task_id) ?? {}}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.addBatchNumber:
                    return (
                      <AddBatch
                        taskData={tasksList.find((item) => item.task_id === task.task_id) ?? {}}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.generatePreShipmentPackingList:
                    return (
                      <GeneratePackingList
                        taskData={tasksList.find((item) => item.task_id === task.task_id) ?? {}}
                      />
                    );

                  case TASKS_MAP.generatePostShipmentPackingList:
                    return (
                      <GeneratePostPackingList
                        taskData={tasksList.find((item) => item.task_id === task.task_id) ?? {}}
                      />
                    );

                  case TASKS_MAP.generateShippingInstructions:
                    return (
                      <GenerateSICard
                        taskData={tasksList.find((item) => item.task_id === task.task_id) ?? {}}
                      />
                    );

                  case TASKS_MAP.addEstimate:
                    return (
                      <AddEstimate
                        taskData={tasksList.find((item) => item.task_id == task.task_id) ?? {}}
                      />
                    );
                  case TASKS_MAP.uploadBLDraft:
                    return (
                      <UploadBlDraft
                        taskData={tasksList.find((item) => item.task_id === task.task_id) ?? {}}
                        onComplete={refreshData}
                      />
                    );

                  case TASKS_MAP.addOperationCosts:
                    return (
                      <AddOperationCosts
                        taskData={
                          tasksList.find((item: any) => item.task_id === task.task_id) ?? {}
                        }
                        onComplete={refreshData}
                      />
                    );
                  case TASKS_MAP.uploadLCDetails:
                    return (
                      <LCDetailsCard
                        taskData={
                          tasksList.find((item: any) => item.task_id === task.task_id) ?? {}
                        }
                        onComplete={refreshData}
                      />
                    );
                  default:
                    return <></>;
                }
              })()}
            </Fragment>
          ))}
        </InfiniteScroll>
      ) : (
        <NoDocumentPlaceHolder
          title="No task to show"
          supportingText="When a task is assigned to you, it will appear here"
        />
      )}
      <Loader open={isLoading} />
    </main>
  );
};

export default TaskTab;
