import { Divider, Typography } from '@components/base';
import OrderContext from '@pages/order/generate-purchase-order/OrderContext';
import { format } from 'date-fns';
import { useContext } from 'react';
import css from '../index.module.scss';
import moment from 'moment';

const Invoice = (props: any) => {
  const orderInfo = useContext(OrderContext);
  // const taskData = orderInfo?.tasksList?.filter((item: any) => item?.supplier_id === supplierId)[0];
  const { isRectify } = props;
  const renderField = (label: string, value: string) => {
    return (
      <div>
        <Typography variant="pdoc">{label}</Typography>
        <Typography variant="h4">{value}</Typography>
      </div>
    );
  };
  return (
    <div className={css.boxWrapper}>
      <div className={css.invoiceContainer}>
        <div className={css.fieldWrapper}>
          <div>
            <Typography>Purchase Order Date</Typography>
            <div className={css.invoiceDate}>
              {/* {isRectify
                ? `${moment(orderInfo?.poData?.document_date).format('DD-MM-YYYY')}` ?? ''
                : orderInfo?.poData?.document_date ?? ''} */}
              {`${moment(orderInfo?.poData?.document_date).format('DD-MM-YYYY')}`}
            </div>
          </div>
        </div>
        <Divider className={css.invoiceDivider} />
        <div className={css.fieldWrapper}>
          {/* {renderField("Purhase Order Number", "332245")} */}
        </div>
      </div>
    </div>
  );
};

export default Invoice;
