import { Typography } from '@components/base';
import css from './index.module.scss';
import { IFilteredValue, IRemarks } from '../../../orderOverview';

interface IRemarkProps {
  previewData: {
    remarks: IRemarks;
  };
  filteredValue?: IFilteredValue;
}

const Remarks = (props: IRemarkProps) => {
  const { previewData, filteredValue } = props;
  const { remarks } = previewData;

  const piRemarks = remarks?.piRemarks ? JSON?.parse(remarks?.piRemarks).string : '';

  const addAdditionalClass = (key: string) => {
    return filteredValue?.key == key ? css.filtered : '';
  };

  return (
    <div className={css.detailWrapper}>
      <div className={css.detailsTitle}>
        <Typography variant="h4">Remarks</Typography>
      </div>
      <div className={css.detailContainer}>
        <div className={css.rowWrapper}>
          <div className={`${css.fieldWrapper} ${addAdditionalClass('customerAdditionalRemarks')}`}>
            <Typography
              variant="label"
              className={`${css.fieldLabel} ${addAdditionalClass('customerAdditionalRemarks')}`}>
              Customer Additional Remarks
            </Typography>
            {remarks?.customerAdditionalRemarks ? (
              <Typography
                variant="body"
                className={`${addAdditionalClass('customerAdditionalRemarks')}`}>
                {remarks?.customerAdditionalRemarks}
              </Typography>
            ) : (
              <Typography
                variant="label"
                className={`${css.notAvailable} ${addAdditionalClass(
                  'customerAdditionalRemarks'
                )}`}>
                Not Available
              </Typography>
            )}
          </div>
        </div>
        <div className={css.rowWrapper}>
          <div className={`${css.fieldWrapper} ${addAdditionalClass('ciRemarks')}`}>
            <Typography
              variant="label"
              className={`${css.fieldLabel} ${addAdditionalClass('ciRemarks')}`}>
              CI Remarks
            </Typography>
            <div className={css.fieldValueContainer}>
              {remarks?.ciRemarks ? (
                <Typography variant="body" className={` ${addAdditionalClass('ciRemarks')}`}>
                  {remarks?.ciRemarks}
                </Typography>
              ) : (
                <Typography
                  variant="label"
                  className={`${css.notAvailable} ${addAdditionalClass('ciRemarks')}`}>
                  Not Available
                </Typography>
              )}
            </div>
          </div>
        </div>
        <div className={css.rowWrapper}>
          <div className={`${css.fieldWrapper} ${addAdditionalClass('piRemarks')}`}>
            <Typography
              variant="label"
              className={`${css.fieldLabel} ${addAdditionalClass('piRemarks')}`}>
              PI Remarks
            </Typography>
            <div className={css.fieldValueContainer}>
              {piRemarks ? (
                <Typography variant="body" className={`${addAdditionalClass('piRemarks')}`}>
                  {piRemarks}
                </Typography>
              ) : (
                <Typography
                  variant="label"
                  className={`${css.notAvailable} ${addAdditionalClass('piRemarks')}`}>
                  Not Available
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Remarks;
