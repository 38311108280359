import css from './index.module.scss';
import { Typography } from '@components/base';
import { BoxContainer } from '@components/common';

interface ProductInfoProps {
  productName: string;
  casNumber: string;
  packagingDetails: string;
  description: string;
  remarks: string;
  hsCode?: string;
}

const ProductInfo = (props: ProductInfoProps) => {
  const { productName, casNumber, packagingDetails, description, remarks, hsCode } = props;

  return (
    <div className={css.productInfoContainer}>
      <BoxContainer title="Details">
        <div className={css.detailBoxWrapper}>
          <div className={css.rowWrapper}>
            <div className={css.textColumnWrapper}>
              <Typography variant="subheading1">Product Name</Typography>
              <Typography variant="p">{productName}</Typography>
            </div>
            <div className={css.textColumnWrapper}>
              <Typography variant="subheading1">#CAS</Typography>
              <Typography variant="p">{casNumber}</Typography>
            </div>
          </div>
          {packagingDetails && (
            <div className={css.packagingDetailsWrapper}>
              <Typography variant="subheading1">Packaging Details</Typography>
              <Typography variant="p" className={css.label}>
                {packagingDetails}
              </Typography>
            </div>
          )}
          {description && (
            <div className={css.packagingDetailsWrapper}>
              <Typography variant="subheading1">Description</Typography>
              <Typography variant="p" className={css.label}>
                {description}
              </Typography>
            </div>
          )}
          {remarks && (
            <div className={css.packagingDetailsWrapper}>
              <Typography variant="subheading1">Additional Remarks</Typography>
              <Typography variant="p" className={css.label}>
                {remarks}
              </Typography>
            </div>
          )}
          {hsCode && (
            <div className={css.packagingDetailsWrapper}>
              <Typography variant="subheading1">HS Code</Typography>
              <Typography variant="p" className={css.label}>
                {hsCode}
              </Typography>
            </div>
          )}
        </div>
      </BoxContainer>
    </div>
  );
};
export default ProductInfo;
