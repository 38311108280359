import { SelectLabel, SideBar } from '@components/common';
import css from './index.module.scss';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { fetchOrderInfo } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import Order from './components/preview-tab/components/order';
import Item from './components/preview-tab/components/item';
import { IconNode, Loader, Select } from '@components/base';
import Parties from './components/preview-tab/components/parties';
import PackagingDetails from './components/preview-tab/components/packagingDetails';
import ShipmentDetails from './components/preview-tab/components/shipmentDetails';
import BLDetails from './components/preview-tab/components/BLDetails';
import PaymentDetails from './components/preview-tab/components/paymentDetails';
import Remarks from './components/preview-tab/components/remarks';
import Images from '@assets/images';
import Estimate from './components/preview-tab/components/Estimate';
import { fetchAmountInWords } from '@services/util.service';
import { IContainer, IFilteredValue, IObject, OrderState } from './orderOverview';
import { getFormattedDate } from '@helpers/utils';

const InternalOrderOverview = (props: any) => {
  const params = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const partiesRef = useRef(null);
  const orderDetailsRef = useRef(null);
  const itemDetailsRef = useRef(null);
  const packagingDetailsRef = useRef(null);
  const shipmentDetailsRef = useRef(null);
  const blDetailsRef = useRef(null);
  const paymentDetailsRef = useRef(null);
  const remarksRef = useRef(null);
  const estimateRef = useRef(null);
  const [filteredValue, setFilteredValue] = useState<IFilteredValue | undefined>();

  const [sectionRefs, setSectionRefs] = useState<IFilteredValue[]>([]);

  const sidebarItems = [
    { label: 'Parties', value: 0, src: Images.parties, alt: 'parties icon' },
    { label: 'Order Details', value: 1, src: Images.orderDetails, alt: 'order details icon' },
    { label: 'Item Details', value: 2, src: Images.itemDetails, alt: 'item details icon' },
    {
      label: 'Packaging Details',
      value: 3,
      src: Images.packagingDetails,
      alt: 'packaging details icon'
    },
    {
      label: 'Shipment Details',
      value: 4,
      src: Images.shipmentDetails,
      alt: 'shipment details icon'
    },
    { label: 'BL Details', value: 5, src: Images.blDetails, alt: 'BL details icon' },
    { label: 'Payment Details', value: 6, src: Images.paymentDetails, alt: 'payment details icon' },
    { label: 'Remarks', value: 7, src: Images.remarks, alt: 'remarks icon' },
    { label: 'Estimates', value: 8, src: Images.estimates, alt: 'estimates icon' }
  ];

  const [orderState, setOrderState] = useState<OrderState>({
    isLoading: false,
    consigneeSameAsCustomer: false,
    currency: '',
    parties: {
      exporterInfo: {
        name: '',
        gstNumber: '',
        address: '',
        phoneNumber: '',
        emailId: ''
      },
      customerInfo: {
        name: '',
        address: '',
        customerPOC: {
          name: '',
          emailId: '',
          phoneNumber: ''
        }
      },
      consigneeInfo: {
        name: '',
        address: '',
        phoneNumber: '',
        emailId: ''
      }
    },
    orderDetails: {
      modeOfTransportation: '',
      incoTerms: '',
      countryOfOrigin: '',
      countryOfFinalDestination: '',
      portOfLoading: '',
      portOfDischarge: '',
      placeOfDelivery: '',
      purchaseOrderReferenceNumber: '',
      purchaseOrderDate: ''
    },
    itemDetails: {
      items: [],
      unitOfWeight: '',
      totalAmount: '',
      totalAmountInWords: '',
      customItems: [],
      piDocumentURL: '',
      ciDocumentURL: ''
    },
    packagingDetails: {
      grossWeight: '',
      netWeight: '',
      noOfPallets: '',
      palletization: false
    },
    shipmentDetails: {
      container: [],
      vesselName: '',
      voyageNumber: '',
      shippingLine: ''
    },
    blDetails: {
      awbServiceName: '',
      blNumber: '',
      blDate: '',
      courier: {
        serviceName: '',
        trackingNumber: '',
        placeOfDelivery: '',
        courierDate: ''
      },
      telex: {
        telexMessage: '',
        telexRemarks: ''
      }
    },
    paymentDetails: {
      paymentTerm: '',
      orderPayment: []
    },
    remarks: {
      customerAdditionalRemarks: '',
      piRemarks: '',
      ciRemarks: ''
    },
    taskEstimates: []
  });

  useEffect(() => {
    (async () => {
      setOrderState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
      const response = await fetchOrderInfo(params.id as string);
      if (response.success) {
        const { data } = response;
        setOrderState((prevState) => ({
          ...prevState,
          currency: data?.currency,
          consigneeSameAsCustomer: data?.consignee_same_as_customer,
          parties: {
            exporterInfo: {
              name: data?.elchemy_entity.name,
              gstNumber: data?.elchemy_entity.gst_number,
              address: data?.elchemy_entity.address,
              phoneNumber: data?.elchemy_entity.phone_number,
              emailId: data?.elchemy_entity.email_id
            },
            customerInfo: {
              name: data?.customer_name ? data?.customer_name : data?.customer?.name,
              address: data?.entityList?.address,
              customerPOC: {
                name: data?.customer_poc?.given_name + ' ' + data?.customer_poc?.family_name,
                emailId: data?.customer_poc?.email,
                phoneNumber: data?.customer_poc?.phone_number
              }
            },
            consigneeInfo: {
              name: data?.consignee_name,
              address: data?.consignee_office_address,
              phoneNumber: data?.consignee_phone_number,
              emailId: data?.consignee_email_id
            }
          },
          orderDetails: {
            modeOfTransportation: data?.transportation_mode_display_value,
            incoTerms: data?.inco_terms_display_value,
            countryOfOrigin: data?.country_of_origin_display_value,
            countryOfFinalDestination: data?.country_of_final_destination_display_value,
            portOfLoading:
              data?.port_of_loading_display_value +
              ' - ' +
              data?.port_of_loading_country_display_value,
            portOfDischarge:
              data?.port_of_discharge_display_value +
              ' - ' +
              data?.port_of_discharge_country_display_value,
            placeOfDelivery: data?.place_of_delivery,
            purchaseOrderReferenceNumber: data?.po_reference_no,
            purchaseOrderDate: data?.po_reference_date
          },
          itemDetails: {
            items: data?.order_item,
            unitOfWeight: data?.unit_of_weight,
            totalAmount: data?.total,
            customItems:
              (data?.order_ci && data.order_ci.custom_items) ||
              (data?.order_pi && data.order_pi.custom_items),
            piDocumentURL: data?.pi_document_url,
            ciDocumentURL: data?.ci_document_url
          },
          packagingDetails: {
            grossWeight: data?.total_gross_wt_in_KG,
            netWeight: data?.total_net_wt_in_KG,
            noOfPallets: data?.total_pallets_packed,
            palletization: data?.palletization
          },
          shipmentDetails: {
            container: data?.container_info,
            vesselName: data?.vessel_name,
            voyageNumber: data?.voyage_number,
            shippingLine: data?.shipping_line
          },
          blDetails: {
            awbServiceName: data?.awb_service_name,
            blNumber: data?.bl_number ?? data?.awb_number,
            blDate: data?.bl_date ?? data?.awb_date,
            courier: {
              serviceName: data?.documents_courier_service_name,
              trackingNumber: data?.documents_courier_tracking_number,
              placeOfDelivery: data?.documents_courier_place_of_delivery,
              courierDate: data?.documents_courier_date
            },
            telex: {
              telexMessage: data?.telex_message,
              telexRemarks: data?.telex_remarks
            }
          },
          paymentDetails: {
            paymentTerm: data?.payment_terms_display_value,
            orderPayment: data?.order_payment
          },
          remarks: {
            customerAdditionalRemarks: data?.additional_remarks,
            piRemarks: data?.pi_remarks ?? data?.order_pi?.remarks,
            ciRemarks: data?.ci_remarks ?? data?.order_ci?.remarks
          },
          taskEstimates: data?.task_estimates
        }));
        const updatedSectionRefs = [
          { label: 'Parties', ref: partiesRef, value: 0, key: 'parties' },
          { label: 'Order Details', ref: orderDetailsRef, value: 1, key: 'orderDetails' },
          { label: 'Item Details', ref: itemDetailsRef, value: 2, key: 'itemDetails' },
          {
            label: 'Packaging Details',
            ref: packagingDetailsRef,
            value: 3,
            key: 'packagingDetails'
          },
          { label: 'Shipment Details', ref: shipmentDetailsRef, value: 4, key: 'shipmentDetails' },
          { label: 'BL Details', ref: blDetailsRef, value: 5, key: 'blDetails' },
          { label: 'Payment Details', ref: paymentDetailsRef, value: 6, key: 'paymentDetails' },
          { label: 'Remarks', ref: remarksRef, value: 7, key: 'remarks' },
          { label: 'Estimates', ref: estimateRef, value: 8, key: 'taskEstimates' },
          {
            label: 'Mode of Transportation',
            ref: orderDetailsRef,
            value: 1,
            key: 'modeOfTransportation'
          },
          { label: 'Inco Terms', ref: orderDetailsRef, value: 1, key: 'incoTerms' },
          { label: 'Country of Origin', ref: orderDetailsRef, value: 1, key: 'countryOfOrigin' },
          {
            label: 'Country of Final Destination',
            ref: orderDetailsRef,
            value: 1,
            key: 'countryOfFinalDestination'
          },
          { label: 'Port of Loading', ref: orderDetailsRef, value: 1, key: 'portOfLoading' },
          { label: 'Port of Discharge', ref: orderDetailsRef, value: 1, key: 'portOfDischarge' },
          { label: 'Place of Delivery', ref: orderDetailsRef, value: 1, key: 'placeOfDelivery' },
          {
            label: 'PO Reference Number',
            ref: orderDetailsRef,
            value: 1,
            key: 'purchaseOrderReferenceNumber'
          },
          { label: 'PO Date', ref: orderDetailsRef, value: 1, key: 'purchaseOrderDate' },
          { label: 'Gross Weight', ref: packagingDetailsRef, value: 3, key: 'grossWeight' },
          { label: 'Net Weight', ref: packagingDetailsRef, value: 3, key: 'netWeight' },
          { label: 'No of Pallets', ref: packagingDetailsRef, value: 3, key: 'noOfPallets' },
          { label: 'Palletization', ref: packagingDetailsRef, value: 3, key: 'palletization' },
          { label: 'Vessel Name', ref: shipmentDetailsRef, value: 4, key: 'vesselName' },
          { label: 'Voyage Number', ref: shipmentDetailsRef, value: 4, key: 'voyageNumber' },
          { label: 'Payment Term', ref: paymentDetailsRef, value: 6, key: 'paymentTerm' },
          {
            label: 'Customer Additional Remarks',
            ref: remarksRef,
            value: 7,
            key: 'customerAdditionalRemarks'
          },
          { label: 'PI Remarks', ref: remarksRef, value: 7, key: 'piRemarks' },
          { label: 'CI Remarks', ref: remarksRef, value: 7, key: 'ciRemarks' }
        ];
        if (data?.customItems) {
          updatedSectionRefs.push({
            label: 'Custom Items',
            ref: itemDetailsRef,
            value: 2,
            key: 'customItems'
          });
        }
        if (data?.container) {
          updatedSectionRefs.push(
            {
              label: 'Container Details',
              ref: shipmentDetailsRef,
              value: 4,
              key: 'containerDetails'
            },
            {
              label: 'Container Numbers',
              ref: shipmentDetailsRef,
              value: 4,
              key: 'containerNumbers'
            }
          );
        }
        if (data?.shipping_line) {
          updatedSectionRefs.push({
            label: 'Shipping Line',
            ref: shipmentDetailsRef,
            value: 4,
            key: 'shippingLine'
          });
        }
        if (data?.transportation_mode === 'SEA') {
          updatedSectionRefs.push(
            {
              label: 'BL Number',
              ref: blDetailsRef,
              value: 5,
              key: 'blNumber'
            },
            {
              label: 'BL Date',
              ref: blDetailsRef,
              value: 5,
              key: 'blDate'
            }
          );
        }
        if (data?.transportation_mode === 'AIR') {
          updatedSectionRefs.push(
            {
              label: 'AWB Service Name',
              ref: blDetailsRef,
              value: 5,
              key: 'awbServiceName'
            },
            {
              label: 'AWB Number',
              ref: blDetailsRef,
              value: 5,
              key: 'blNumber'
            },
            {
              label: 'AWB Date',
              ref: blDetailsRef,
              value: 5,
              key: 'blDate'
            }
          );
        }
        if (data?.documents_courier_service_name) {
          updatedSectionRefs.push(
            {
              label: 'Courier Service Name',
              ref: blDetailsRef,
              value: 5,
              key: 'courier.serviceName'
            },
            {
              label: 'Courier Tracking Number',
              ref: blDetailsRef,
              value: 5,
              key: 'courier.trackingNumber'
            },
            {
              label: 'Courier Place of Delivery',
              ref: blDetailsRef,
              value: 5,
              key: 'courier.placeOfDelivery'
            },
            {
              label: 'Courier Date',
              ref: blDetailsRef,
              value: 5,
              key: 'courier.courierDate'
            }
          );
        }
        if (data?.telex_message) {
          updatedSectionRefs.push(
            {
              label: 'Telex Message',
              ref: blDetailsRef,
              value: 5,
              key: 'telex.telexMessage'
            },
            {
              label: 'Telex Remarks',
              ref: blDetailsRef,
              value: 5,
              key: 'telex.telexRemarks'
            }
          );
        }
        setSectionRefs(updatedSectionRefs);
      } else {
        notify({
          message: response.error ?? 'Unable to fetch data',
          severity: 'error',
          dismissible: true
        });
      }
      setOrderState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (Number(orderState.itemDetails.totalAmount) > 0) {
        const response = await fetchAmountInWords({
          amount: orderState?.itemDetails?.totalAmount.toString(),
          currency_type: orderState?.currency
        });
        if (response?.success) {
          setOrderState((prevState) => ({
            ...prevState,
            itemDetails: {
              ...prevState.itemDetails,
              totalAmountInWords: response.data.amount_in_words
            }
          }));
        }
      }
    })();
  }, [orderState.itemDetails.totalAmount]);

  const handleNavigation = (currentStep: number) => {
    setActiveTab(currentStep);
  };

  const handleMouseEnter = () => {
    setIsSidebarOpen(true);
  };

  const handleMouseLeave = () => {
    setIsSidebarOpen(false);
  };

  const handleSidebarClick = (value: number) => {
    const sectionRef = sectionRefs[value]?.ref?.current;
    if (sectionRef) {
      setTimeout(() => {
        (sectionRef as unknown as HTMLElement).scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
    handleNavigation(value);
    setIsSidebarOpen(false);
  };

  const handleSidebarEnterKey = (value: number) => {
    const sectionRef = sectionRefs[value]?.ref?.current;
    handleNavigation(value);
    setIsSidebarOpen(false);
  };

  const handleSectionChange = (e: any) => {
    const value = e?.value;
    setFilteredValue(e);
    handleSidebarClick(value);
  };

  const orderDetailsFields: IObject[] = [
    {
      label: 'Mode of Transportation',
      key: 'modeOfTransportation',
      value: orderState?.orderDetails?.modeOfTransportation
    },
    { label: 'Inco Terms', key: 'incoTerms', value: orderState?.orderDetails?.incoTerms },
    {
      label: 'Country of Origin',
      key: 'countryOfOrigin',
      value: orderState?.orderDetails?.countryOfOrigin
    },
    {
      label: 'Country of Final Destination',
      key: 'countryOfFinalDestination',
      value: orderState?.orderDetails?.countryOfFinalDestination
    },
    {
      label: 'Port of Loading',
      key: 'portOfLoading',
      value: orderState?.orderDetails?.portOfLoading
    },
    {
      label: 'Port of Discharge',
      key: 'portOfDischarge',
      value: orderState?.orderDetails?.portOfDischarge
    },
    {
      label: 'Place of Delivery',
      key: 'placeOfDelivery',
      value: orderState?.orderDetails?.placeOfDelivery
    },
    {
      label: 'PO Reference Number',
      key: 'purchaseOrderReferenceNumber',
      value: orderState?.orderDetails?.purchaseOrderReferenceNumber
    },
    {
      label: 'PO Date',
      key: 'purchaseOrderDate',
      value: getFormattedDate(orderState?.orderDetails?.purchaseOrderDate)
    }
  ];

  const packagingDetailsFields: IObject[] = [
    {
      label: 'Gross Weight',
      key: 'grossWeight',
      value:
        Number(orderState?.packagingDetails?.grossWeight) > 0
          ? `${orderState?.packagingDetails?.grossWeight} KG`
          : ''
    },
    {
      label: 'Net Weight',
      key: 'netWeight',
      value:
        Number(orderState?.packagingDetails?.netWeight) > 0
          ? `${orderState?.packagingDetails?.netWeight} KG`
          : ''
    },
    {
      label: 'No of Pallets',
      key: 'noOfPallets',
      value: orderState?.packagingDetails?.noOfPallets
    },
    {
      label: 'Palletization',
      key: 'palletization',
      value: orderState?.packagingDetails?.palletization ? 'Yes' : 'No'
    }
  ];
  const shipmentDetailsFields: IObject[] = [
    { label: 'Vessel Name', key: 'vesselName', value: orderState.shipmentDetails?.vesselName },
    {
      label: 'Voyage Number',
      key: 'voyageNumber',
      value: orderState.shipmentDetails?.voyageNumber
    },
    {
      label: 'Shipping Line',
      key: 'shippingLine',
      value: orderState.shipmentDetails?.shippingLine
    },
    {
      label: 'Container Details',
      key: 'containerDetails',
      value: orderState.shipmentDetails?.container
        ?.map((item: IContainer) => `${item.no_of_container} X ${item.container_type}`)
        .join(',')
    },
    {
      label: 'Container Numbers',
      key: 'containerNumbers',
      value: orderState.shipmentDetails?.container
        ?.map((item: IContainer) => item.container_numbers)
        .join(', ')
    }
  ];

  if (orderState.isLoading) return <Loader open={orderState?.isLoading} />;

  return (
    <main className={css.mainWrapper}>
      <div
        className={`${css.sidebar} ${isSidebarOpen ? css.sidebarOpen : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {isSidebarOpen && (
          <SideBar
            rootclassname={css.sidebarContainer}
            activeStep={activeTab}
            onClick={handleSidebarClick}
            onEnter={handleSidebarEnterKey}>
            {sidebarItems.map((item) => (
              <div key={item.value} className={css.sidebarIconItems}>
                <SideBar.Item
                  label={item.label}
                  value={item.value}
                  startIcon={
                    <IconNode
                      src={item.src}
                      alt={item.alt}
                      component="button"
                      className={css.closeButton}
                    />
                  }
                />
              </div>
            ))}
          </SideBar>
        )}
        {!isSidebarOpen && (
          <div className={css.sidebarIcon}>
            {sidebarItems.map((item) => (
              <>
                <IconNode
                  src={item.src}
                  alt={item.alt}
                  component="button"
                  className={css.closeButton}
                />
              </>
            ))}
          </div>
        )}
      </div>
      <div
        className={`${css.orderOverviewContainer} ${
          isSidebarOpen ? css.sidebarOpenOrderOverviewContainer : ''
        }`}>
        <div className={css.details}>
          <SelectLabel
            defaultValue={1}
            options={sectionRefs}
            getOptionLabel={(option: any) => option.label}
            getOptionValue={(option: any) => option.value}
            placeholder="Start Searching"
            isSearchable
            isClearable
            onChange={handleSectionChange}
          />
        </div>
        <div ref={partiesRef} className={css.details}>
          <Parties partiesData={orderState} />
        </div>
        <div ref={orderDetailsRef} className={css.details}>
          <Order
            filteredValue={filteredValue?.value == 1 ? filteredValue : undefined}
            orderDetailsFields={orderDetailsFields}
          />
        </div>
        <div ref={itemDetailsRef} className={css.details}>
          <Item previewData={orderState} />
        </div>
        <div ref={packagingDetailsRef} className={css.details}>
          <PackagingDetails
            filteredValue={filteredValue?.value == 3 ? filteredValue : undefined}
            packagingDetailsFields={packagingDetailsFields}
          />
        </div>
        <div ref={shipmentDetailsRef} className={css.details}>
          <ShipmentDetails
            filteredValue={filteredValue?.value == 4 ? filteredValue : undefined}
            shipmentDetailsFields={shipmentDetailsFields}
          />
        </div>
        <div ref={blDetailsRef} className={css.details}>
          <BLDetails
            previewData={orderState}
            filteredValue={filteredValue?.value == 5 ? filteredValue : undefined}
          />
        </div>
        <div ref={paymentDetailsRef} className={css.details}>
          <PaymentDetails
            previewData={orderState}
            filteredValue={filteredValue?.value == 6 ? filteredValue : undefined}
          />
        </div>
        <div ref={remarksRef} className={css.details}>
          <Remarks
            previewData={orderState}
            filteredValue={filteredValue?.value == 7 ? filteredValue : undefined}
          />
        </div>
        <div ref={estimateRef} className={css.details}>
          <Estimate previewData={orderState.taskEstimates} />
        </div>
      </div>
    </main>
  );
};

export default InternalOrderOverview;
