import { useState, useEffect, useCallback, useRef } from 'react';
import css from './index.module.scss';
import { Loader, Typography } from '@components/base';
import { Seo, SideBar } from '@components/common';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  typeOfOrderSchema,
  orderDetailSchema,
  orderItemSchema,
  consigneeSchema,
  customerSchema,
  bankInfoSchema
} from '@helpers/yup/add-order.schema';
import TypeOfOrderForm from '../components/type-of-order-form';
import ExporterConsigneeForm from '../components/exporter-consignee-form';
import ItemDetailForm from '../components/item-detail-form';
import CustomerForm from '../components/customer-form';
import OrderDetailForm from '../components/order-detail-form';
import EntityBankDetailRemarkForm from '../components/entity-bank-detail-remark-form';
import { PreviewForm } from '@components/common';
import { fetchAllCustomers, fetchCustomerDetail } from '@services/customer.service';
import { fetchAllProducts } from '@services/product.service';
import { ICustomerInfo } from '@helpers/types/customer';
import { ProductInfo } from '@helpers/types/product';
import notify from '@helpers/toastify-helper';
import {
  ITypeOfOrder,
  IOrderCustomerForm,
  IExportConsigneeForm,
  IOrderItemForm,
  IOrderDetailForm,
  IOrderBankDetailForm
} from '@helpers/types/add-order';
import {
  fetchEntityBankList,
  fetchEntityList,
  createOrder,
  fetchOrderInfoFinance,
  rectifyOrder
} from '@services/order.service';
import { EntityBankDetails, SelfEntity } from '@helpers/types/entity';
import { useNavigate, useParams } from 'react-router-dom';
import { CLIENT_ROUTES } from '@router/routes';
import {
  SUPPLY_TYPE,
  countries,
  INCO_TERMS,
  QC_LOCATION,
  TRANSPORTATION_MODE,
  CURRENCY,
  UNITS_OF_WEIGHTS,
  CONTAINER_TYPE
} from '@helpers/constants';
import { fetchCountryList } from '@services/util.service';
import { IPackageSchema } from '@helpers/types/packing';
import { useScrollToTop } from '@helpers/hooks';
import AccessWrapper from '@authorization/access-wrapper';

interface AddOrderStates {
  isLoading: boolean;
  activeStep: number;
  customerList: ICustomerInfo[];
  productList: ProductInfo[];
  entityList: SelfEntity[];
  enityBankList: EntityBankDetails[];
  typeOfOrder: ITypeOfOrder | null;
  customerInfo: IOrderCustomerForm | null;
  consigneeInfo: IExportConsigneeForm | null;
  itemInfo: IOrderItemForm | null;
  orderInfo: IOrderDetailForm | null;
  bankInfo: IOrderBankDetailForm | null;
  rectifyRemarks?: string;
  countryOptions?: Array<{ label: string; value: string }>;
}

const AddOrder = (props: any) => {
  const { actions } = props.modulePermissions;
  const [orderState, setOrderState] = useState<AddOrderStates>({
    isLoading: false,
    activeStep: 0,
    customerList: [],
    productList: [],
    entityList: [],
    enityBankList: [],
    typeOfOrder: null,
    customerInfo: null,
    consigneeInfo: null,
    itemInfo: null,
    orderInfo: null,
    bankInfo: null,
    rectifyRemarks: '',
    countryOptions: []
  });

  const {
    isLoading,
    activeStep,
    typeOfOrder,
    consigneeInfo,
    itemInfo,
    orderInfo,
    bankInfo,
    customerInfo,
    customerList,
    productList,
    entityList,
    enityBankList,
    countryOptions
  } = orderState;

  const navigate = useNavigate();

  const params = useParams();

  const typeOfOrderForm = useForm<ITypeOfOrder>({
    resolver: yupResolver(typeOfOrderSchema),
    defaultValues: {
      supply_type: null,
      readable_order_id: ''
    }
  });

  const customerInfoForm = useForm<IOrderCustomerForm>({
    resolver: yupResolver(customerSchema),
    defaultValues: {
      customer: null,
      customer_poc: null,
      customer_address: null
    }
  });

  const exporterConsigneeForm = useForm<IExportConsigneeForm>({
    resolver: yupResolver(consigneeSchema),
    defaultValues: {
      elchemy_entity: null,
      consignee_same_as_customer: false,
      consignee_name: '',
      consignee_phone_number: '',
      consignee_landline: '',
      consignee_email_id: '',
      consignee_office_address: ''
    }
  });

  const topRef = useRef(null);

  useScrollToTop({ topRef, dependencyArray: [activeStep] });

  const {
    formState: { isSubmitting: consigneeIsSubmitting }
  } = exporterConsigneeForm;

  const itemDetailForm = useForm<IOrderItemForm>({
    resolver: yupResolver(orderItemSchema),
    defaultValues: {
      currency: null,
      unit_of_weight: null,
      items: [
        {
          name: null,
          quantity: '',
          rate_per_unit: '',
          product_grade: null,
          description: '',
          supplier: null,
          hs_code: '',
          cas_number: '',
          country_of_origin: null,
          package: [
            {
              type: null,
              no_of_package: null,
              weight: null,
              unit: null
            }
          ],
          is_pre_shipment_sample_required: false
        }
      ]
    }
  });

  const orderDetailForm = useForm<IOrderDetailForm>({
    resolver: yupResolver(orderDetailSchema),
    defaultValues: {
      transportation_mode: null,
      inco_terms: null,
      country_of_origin: null,
      country_of_final_destination: null,
      port_of_loading: null,
      port_of_discharge: null,
      place_of_delivery: '',
      payment_terms: [
        {
          payment_term: null,
          percentage: null,
          days: null
        }
      ],
      is_qc_test: false,
      place_of_quality_test: null,
      palletization: false,
      container: [
        {
          container_type: null,
          no_of_container: null
        }
      ]
    }
  });

  const bankDetailForm = useForm<IOrderBankDetailForm>({
    resolver: yupResolver(bankInfoSchema),
    defaultValues: {
      elchemy_bank: null,
      additional_remarks: ''
    }
  });

  useEffect(() => {
    (async () => {
      setOrderState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
      const consumerResponse = await fetchAllCustomers();
      const productResponse = await fetchAllProducts();
      const entityResponse = await fetchEntityList();
      const countryResponse = await fetchCountryList();
      if (countryResponse.data && entityResponse.data) {
        const { data: consumerList } = consumerResponse;
        const { data: productList } = productResponse;
        const { data: entityList } = entityResponse;
        const { data: countryList } = countryResponse;
        setOrderState((prevState) => ({
          ...prevState,
          isLoading: !prevState.isLoading,
          customerList: consumerList.results,
          productList: productList.results,
          entityList: entityList.results,
          countryOptions: countryList.filter_options
        }));
      } else {
        setOrderState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
        notify({ message: 'Unable to fetch data', severity: 'error', dismissible: true });
      }
    })();
  }, []);

  useEffect(() => {
    checkIfRectifyForm();
  }, [orderState.customerList, orderState.productList, orderState.entityList]);

  const handleNavigation = (currentStep: number) => {
    if (currentStep === 0)
      setOrderState((prevState) => ({
        ...prevState,
        activeStep: currentStep,
        typeOfOrder: null,
        customerInfo: null,
        consigneeInfo: null,
        itemInfo: null,
        orderInfo: null,
        bankInfo: null
      }));
    else if (currentStep === 1)
      setOrderState((prevState) => ({
        ...prevState,
        activeStep: currentStep,
        customerInfo: null,
        consigneeInfo: null,
        itemInfo: null,
        orderInfo: null,
        bankInfo: null
      }));
    else if (currentStep === 2)
      setOrderState((prevState) => ({
        ...prevState,
        activeStep: currentStep,
        consigneeInfo: null,
        itemInfo: null,
        orderInfo: null,
        bankInfo: null
      }));
    else if (currentStep === 3)
      setOrderState((prevState) => ({
        ...prevState,
        activeStep: currentStep,
        itemInfo: null,
        orderInfo: null,
        bankInfo: null
      }));
    else if (currentStep === 4)
      setOrderState((prevState) => ({
        ...prevState,
        activeStep: currentStep,
        orderInfo: null,
        bankInfo: null
      }));
    else if (currentStep === 5)
      setOrderState((prevState) => ({
        ...prevState,
        activeStep: currentStep,
        bankInfo: null
      }));
    else if (currentStep === 6)
      setOrderState((prevState) => ({
        ...prevState,
        activeStep: currentStep
      }));
  };

  const handleSideNavigation = (value: number) => {
    handleNavigation(value);
  };

  const handleCancelClick = useCallback(() => {
    navigate(`/${CLIENT_ROUTES.order}`);
  }, []);

  const handleBackClick = (currentStep: number) => () => {
    handleNavigation(currentStep - 1);
  };

  const handleTypeOfOrderFormSubmit: SubmitHandler<ITypeOfOrder> = (data) => {
    setOrderState((prevState) => ({ ...prevState, typeOfOrder: data, activeStep: 1 }));
  };

  const handleCustomerInfoFormSubmit: SubmitHandler<IOrderCustomerForm> = (data) => {
    const { getValues, setValue } = exporterConsigneeForm;
    const same_as_customer = getValues('consignee_same_as_customer');
    if (same_as_customer) {
      const address = data.customer!.addresses.find((item) => item.address_type === 'OFC');
      setValue('consignee_name', data.customer!.name);
      setValue('consignee_phone_number', data.customer!.number);
      setValue('consignee_landline', data.customer!.number);
      setValue('consignee_email_id', data.customer!.email);
      address && setValue('consignee_office_address', address.readable_address);
    }
    setOrderState((prevState) => ({ ...prevState, customerInfo: data, activeStep: 2 }));
  };

  const handleExporterConsigneeFormSubmit: SubmitHandler<IExportConsigneeForm> = async (param) => {
    const response = await fetchEntityBankList(param.elchemy_entity!.entity_id);
    if (response.success) {
      const { data } = response;
      setOrderState((prevState) => ({
        ...prevState,
        enityBankList: data.results,
        consigneeInfo: param,
        activeStep: 3
      }));
    } else if (response.error) {
      notify({ message: response.error ?? 'Unable to fetch exporter details', severity: 'error' });
    }
  };

  const handleItemDetailFormSubmit: SubmitHandler<IOrderItemForm> = (data) => {
    setOrderState((prevState) => ({ ...prevState, itemInfo: data, activeStep: 4 }));
  };

  const handleOrderDetailFormSubmit: SubmitHandler<IOrderDetailForm> = (data) => {
    //Validation for payment terms
    let totalPercentage = 0,
      isError = false;
    data?.payment_terms?.forEach((item: any) => {
      totalPercentage += parseInt(item.percentage);
      if (item?.payment_term?.days && (!item?.days || parseInt(item?.days) <= 0)) {
        notify({
          message: `Enter valid number of days for ${item?.payment_term?.payment_term}`,
          severity: 'error'
        });
        isError = true;
        return;
      }
    });
    if (totalPercentage !== 100) {
      notify({
        message: 'Total percentage in payment terms should be 100',
        severity: 'error'
      });
      isError = true;
      return;
    }
    if (!isError) {
      setOrderState((prevState) => ({ ...prevState, orderInfo: data, activeStep: 5 }));
    }
  };

  const handleBankDetailFormSubmit: SubmitHandler<IOrderBankDetailForm> = (data) => {
    setOrderState((prevState) => ({ ...prevState, bankInfo: data, activeStep: 6 }));
  };

  const handleSendForApproval = async () => {
    orderInfo?.container?.forEach((item) => {
      item.container_type = item.container_type?.value;
      item.no_of_container = item.no_of_container ? Math.ceil(item.no_of_container) : 0;
    });
    const requestBody: any = {
      supply_type: typeOfOrder?.supply_type?.value,
      customer: customerInfo?.customer?.customer_id,
      customer_poc: customerInfo?.customer_poc?.user_id,
      customer_address: customerInfo?.customer_address?.address_id,
      transportation_mode: orderInfo?.transportation_mode?.value,
      inco_terms: orderInfo?.inco_terms?.value,
      country_of_origin: orderInfo?.country_of_origin?.value,
      country_of_final_destination: orderInfo?.country_of_final_destination?.value,
      port_of_loading: orderInfo?.port_of_loading?.value,
      port_of_discharge: orderInfo?.port_of_discharge?.value,
      place_of_delivery: orderInfo?.place_of_delivery,
      payment_term: orderInfo?.payment_terms?.map((item: any) => {
        const obj: any = {};
        obj['payment_term'] = item.payment_term?.payment_term;
        obj['percentage'] = parseInt(item.percentage);
        if (item.days) {
          obj['days'] = parseInt(item.days);
        }
        return obj;
      }),
      is_qc_test: orderInfo?.is_qc_test,
      place_of_quality_test: orderInfo?.place_of_quality_test?.value,
      elchemy_entity: consigneeInfo?.elchemy_entity?.entity_id,
      elchemy_bank: bankInfo?.elchemy_bank?.bank_id,
      consignee_same_as_customer: consigneeInfo?.consignee_same_as_customer,
      consignee_name: consigneeInfo?.consignee_name,
      consignee_phone_number: consigneeInfo?.consignee_phone_number,
      consignee_landline: consigneeInfo?.consignee_landline,
      consignee_email_id: consigneeInfo?.consignee_email_id,
      consignee_office_address: consigneeInfo?.consignee_office_address,
      additional_remarks: bankInfo?.additional_remarks,
      currency: itemInfo?.currency?.value,
      unit_of_weight: itemInfo?.unit_of_weight?.value,
      palletization: orderInfo?.palletization,
      payment_terms: 'LC60',
      order_item: itemInfo?.items?.map((item) => {
        return {
          supplier_product_grade: item.supplier?.supplier_product_grade_id,
          quantity: item.quantity,
          rate: item.rate_per_unit,
          //Key changed as per BE requirement
          order_item_description: item.description,
          order_item_id: item.order_item_id,
          product_grade: item.product_grade?.grade_id,
          hs_code: item.hs_code,
          country_of_origin: item.country_of_origin?.value,
          package: item.package?.map((p: IPackageSchema) => {
            const obj: any = {};
            if (p) {
              obj['type'] = p.type?.value ?? null;
              obj['no_of_package'] = p.no_of_package;
              obj['weight'] = p.weight;
              obj['unit_of_weight'] = p.unit?.value ?? null;
              obj['id'] = p.id;
            }
            return obj;
          }),
          is_pre_shipment_sample_required: item.is_pre_shipment_sample_required
        };
      })
    };
    if (orderInfo?.transportation_mode?.value !== 'AIR') {
      requestBody.container = orderInfo?.container;
    }
    setOrderState((prevState) => ({ ...prevState, isLoading: true }));
    let response;
    if (orderState.rectifyRemarks && params?.taskid) {
      response = await rectifyOrder(params.taskid, requestBody);
    } else {
      response = await createOrder(requestBody);
    }
    setOrderState((prevState) => ({ ...prevState, isLoading: false }));
    if (response.success) {
      const { data } = response;
      if (orderState.rectifyRemarks) {
        navigate(`/${CLIENT_ROUTES.order}/${params?.orderid}`);
        notify({
          message: 'Order successfully rectified and sent for approval',
          dismissible: true
        });
      } else {
        navigate(`/${CLIENT_ROUTES.order}/${data.order_id}`);
        notify({ message: 'Order successfully added and sent for approval', dismissible: true });
      }
    } else if (response.error) {
      notify({
        message: response.error,
        severity: 'error'
      });
    } else
      notify({
        title: 'We have little problem',
        message: 'There was an error understanding your request.',
        severity: 'error'
      });
  };

  const handlePreviewEditClick = (value: number) => {
    handleNavigation(value);
  };

  const { taskid, orderid } = params;

  const checkIfRectifyForm = () => {
    if (taskid && orderid) {
      getOrderDetails();
    }
  };

  const getOrderDetails = async () => {
    if (orderid) {
      const orderData = await fetchOrderInfoFinance(orderid);
      if (orderData?.success) {
        setOrderState((prevState) => ({
          ...prevState,
          rectifyRemarks: orderData.data?.order_rectify_remarks
        }));
        const customerResponse = await fetchCustomerDetail(orderData.data?.customer?.customer_id);
        if (customerResponse?.success) {
          customerInfoForm.reset({
            customer: customerResponse.data,
            customer_poc: customerResponse.data?.pocs ?? null,
            customer_address: customerResponse.data.addresses
              ? customerResponse.data.addresses
              : null
          });
        }
        typeOfOrderForm.reset({
          supply_type: orderData.data.supply_type
            ? SUPPLY_TYPE.find((s) => s.value === orderData.data.supply_type)
            : null,
          readable_order_id: orderData.data.readable_order_id
            ? orderData.data.readable_order_id
            : null
        });
        exporterConsigneeForm.reset({
          elchemy_entity: orderData.data.elchemy_entity ? orderData.data.elchemy_entity : null,
          consignee_same_as_customer: orderData.data.consignee_same_as_customer
            ? orderData.data.consignee_same_as_customer
            : null,
          consignee_name: orderData.data.consignee_name ? orderData.data.consignee_name : null,
          consignee_phone_number: orderData.data.consignee_phone_number
            ? orderData.data.consignee_phone_number
            : null,
          consignee_landline: orderData.data.consignee_landline
            ? orderData.data.consignee_landline
            : null,
          consignee_email_id: orderData.data.consignee_email_id
            ? orderData.data.consignee_email_id
            : null,
          consignee_office_address: orderData.data.consignee_office_address
            ? orderData.data.consignee_office_address
            : null
        });
        const itemListResponse = await fetchAllProducts(
          undefined,
          null,
          undefined,
          orderData.data.order_item.map((item: any) => item.product_id)
        );
        if (itemListResponse?.success) {
          itemDetailForm.reset({
            items: orderData.data.order_item
              ? orderData.data.order_item.map((oi: any) => ({
                  name: oi.product_name
                    ? itemListResponse?.data?.results?.find((p: any) => p.name == oi.product_name)
                    : null,
                  quantity: oi.quantity,
                  rate_per_unit: oi.rate,
                  product_grade: {
                    grade_id: oi.product_grade,
                    name: oi.product_grade_name ? oi.product_grade_name : null,
                    description: oi.product_grade_description ? oi.product_grade_description : null,
                    packaging_details: oi.product_grade_packaging_details
                      ? oi.product_grade_packaging_details
                      : null,
                    msds_doc: oi.msds ? oi.msds : null
                  },
                  description: oi.order_item_description ? oi.order_item_description : null,
                  supplier: oi.supplier_list_of_selected_grade
                    ? oi.supplier_list_of_selected_grade.find(
                        (s: any) => s.supplier_name == oi.supplier_name
                      )
                    : null,
                  order_item_id: oi.order_item_id,
                  hs_code: oi.hs_code,
                  cas_number: oi.CAS_number,
                  country_of_origin: {
                    value: oi.country_of_origin,
                    label: oi.country_of_origin_display_value
                  },
                  package: oi.package?.map((p: IPackageSchema) => {
                    const obj: any = {};
                    if (p) {
                      obj['type'] = { label: p.type_label, value: p.type };
                      obj['no_of_package'] = p.no_of_package;
                      obj['weight'] = p.weight;
                      obj['unit'] = { label: p?.unit_of_weight, value: p?.unit_of_weight };
                      obj['id'] = p.id;
                    }
                    return obj;
                  }),
                  is_pre_shipment_sample_required: oi.is_pre_shipment_sample_required
                }))
              : null,
            currency: orderData.data.currency
              ? CURRENCY.find((item: any) => item.value == orderData.data.currency)
              : null,
            unit_of_weight: orderData.data.unit_of_weight
              ? UNITS_OF_WEIGHTS.find((item: any) => item.value == orderData.data.unit_of_weight)
              : null
          });
        }

        orderDetailForm.reset({
          transportation_mode: orderData.data.transportation_mode
            ? TRANSPORTATION_MODE.find((t) => t.value === orderData.data.transportation_mode)
            : null,
          inco_terms: orderData.data.inco_terms
            ? INCO_TERMS.find((t) => t.value === orderData.data.inco_terms)
            : null,
          country_of_origin: orderData.data.country_of_origin
            ? countries.find((t) => t.label === orderData.data.country_of_origin)
            : null,
          country_of_final_destination: orderData.data.country_of_final_destination
            ? countries.find((t) => t.label === orderData.data.country_of_final_destination)
            : null,
          port_of_loading: orderData.data.port_of_loading
            ? {
                label: `${orderData.data.port_of_loading_display_value} - ${orderData.data.port_of_loading_country_display_value}`,
                value: orderData.data.port_of_loading
              }
            : null,
          port_of_discharge: orderData.data.port_of_discharge
            ? {
                label: `${orderData.data.port_of_discharge_display_value} - ${orderData.data.port_of_discharge_country_display_value}`,
                value: orderData.data.port_of_discharge
              }
            : null,
          place_of_delivery: orderData.data.place_of_delivery,
          payment_terms: orderData.data.payment_term.map((item: any) => {
            return {
              payment_term: {
                payment_term: item.payment_term,
                days: item.days ? true : false,
                percentage: item.percentage ? true : false
              },
              percentage: item.percentage,
              days: item.days ? item.days : null
            };
          }),
          is_qc_test: orderData.data.is_qc_test,
          place_of_quality_test: orderData.data.place_of_quality_test
            ? QC_LOCATION.find((t) => t.value === orderData.data.place_of_quality_test)
            : null,
          palletization: orderData.data.palletization,
          container: orderData.data.container
            ? orderData.data.container?.map((item: any) => {
                return {
                  container_type: item.container_type
                    ? CONTAINER_TYPE.find((type: any) => type.value === item.container_type)
                    : null,
                  no_of_container: item.no_of_container ? item.no_of_container : null
                };
              })
            : []
        });
        bankDetailForm.reset({
          elchemy_bank: orderData.data.elchemy_bank ? orderData.data.elchemy_bank : null,
          additional_remarks: orderData.data.additional_remarks
        });
      } else {
        notify({
          message: orderData.error ?? 'Unable to fetch order details',
          severity: 'error'
        });
      }
    }
  };

  /**
   * Filter country options based on search text used in order detail form
   */
  const filterCountryOptions = async (event?: any, param?: any) => {
    if (param) {
      const countryList = await fetchCountryList({ search_text: param });

      if (countryList?.success) {
        setOrderState((prevState: any) => ({
          ...prevState,
          countryOptions: countryList.data.filter_options
        }));
      } else {
        notify({
          message: countryList.error ?? 'Unable to fetch country list',
          severity: 'error'
        });
      }
    } else {
      const countryList = await fetchCountryList();
      if (countryList?.success) {
        setOrderState((prevState: any) => ({
          ...prevState,
          countryOptions: countryList.data.filter_options
        }));
      } else {
        setOrderState((prevState: any) => ({ ...prevState, countryOptions: [] }));
      }
    }
  };

  return (
    <AccessWrapper show={actions?.create} showUnauthorised>
      <main className={css.mainWrapper}>
        {orderState.rectifyRemarks?.length ? (
          <Seo title="Rectify Order" />
        ) : (
          <Seo title="Add Order" />
        )}

        <div className={css.headerWrapper}>
          {orderState.rectifyRemarks?.length ? (
            <Typography variant="h2">Rectify Order</Typography>
          ) : (
            <Typography variant="h2">Add Order</Typography>
          )}

          <Typography variant="span">0{activeStep + 1} of 07</Typography>
        </div>
        <section className={css.subMainWrapper} ref={topRef}>
          <div className={css.sidebarWrapper}>
            <SideBar
              activeStep={activeStep}
              onClick={handleSideNavigation}
              onEnter={handleSideNavigation}>
              <SideBar.Item label="Type of Order" value={0} />
              <SideBar.Item label="Customer" value={1} disabled={!typeOfOrder} />
              <SideBar.Item label="Export and Consignee" value={2} disabled={!customerInfo} />
              <SideBar.Item label="Item and Packing Details" value={3} disabled={!consigneeInfo} />
              <SideBar.Item label="Order Details" value={4} disabled={!itemInfo} />
              <SideBar.Item
                label="Entity Bank Details and Remarks"
                value={5}
                disabled={!orderInfo}
              />
              <SideBar.Item label="Preview" value={6} disabled={!bankInfo} />
            </SideBar>
          </div>
          <FormProvider {...typeOfOrderForm}>
            {activeStep === 0 && (
              <TypeOfOrderForm
                onCancelClick={handleCancelClick}
                onFormSubmit={handleTypeOfOrderFormSubmit}
                rectifyRemarks={orderState.rectifyRemarks}
              />
            )}
          </FormProvider>
          <FormProvider {...customerInfoForm}>
            {activeStep === 1 && (
              <CustomerForm
                customerOptions={orderState.customerList}
                onFormSubmit={handleCustomerInfoFormSubmit}
                onBackClick={handleBackClick(1)}
                onCancelClick={handleCancelClick}
                rectifyRemarks={orderState.rectifyRemarks}
              />
            )}
          </FormProvider>
          <FormProvider {...exporterConsigneeForm}>
            {activeStep === 2 && (
              <ExporterConsigneeForm
                entityList={entityList}
                currentCustomer={customerInfo}
                onFormSubmit={handleExporterConsigneeFormSubmit}
                onBackClick={handleBackClick(2)}
                onCancelClick={handleCancelClick}
                rectifyRemarks={orderState.rectifyRemarks}
              />
            )}
          </FormProvider>
          <FormProvider {...itemDetailForm}>
            {activeStep === 3 && (
              <ItemDetailForm
                productList={productList}
                onFormSubmit={handleItemDetailFormSubmit}
                onCancelClick={handleCancelClick}
                onBackClick={handleBackClick(3)}
                rectifyRemarks={orderState.rectifyRemarks}
                countryOptions={countryOptions}
                filterCountryOptions={filterCountryOptions}
              />
            )}
          </FormProvider>
          <FormProvider {...orderDetailForm}>
            {activeStep === 4 && (
              <OrderDetailForm
                onFormSubmit={handleOrderDetailFormSubmit}
                onCancelClick={handleCancelClick}
                onBackClick={handleBackClick(4)}
                rectifyRemarks={orderState.rectifyRemarks}
                countryOptions={countryOptions}
              />
            )}
          </FormProvider>
          <FormProvider {...bankDetailForm}>
            {activeStep === 5 && (
              <EntityBankDetailRemarkForm
                bankOptions={enityBankList}
                onFormSubmit={handleBankDetailFormSubmit}
                onCancelClick={handleCancelClick}
                onBackClick={handleBackClick(5)}
                rectifyRemarks={orderState.rectifyRemarks}
              />
            )}
          </FormProvider>
          {activeStep === 6 &&
            typeOfOrder &&
            customerInfo &&
            consigneeInfo &&
            itemInfo &&
            orderInfo &&
            bankInfo && (
              <PreviewForm
                onEditClick={handlePreviewEditClick}
                orderInfo={typeOfOrder}
                customerInfo={customerInfo}
                consigneeInfo={consigneeInfo}
                itemInfo={itemInfo}
                orderDetail={orderInfo}
                entityBankDetail={bankInfo}
                onCancelClick={handleCancelClick}
                onBackClick={handleBackClick(6)}
                onFormSubmit={handleSendForApproval}
                rectifyRemarks={orderState.rectifyRemarks}
              />
            )}
        </section>
        <Loader open={isLoading || consigneeIsSubmitting} />
      </main>
    </AccessWrapper>
  );
};

export default AddOrder;
