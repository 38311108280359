import { Accordion, Button, IconNode, Typography } from '@components/base';
import { Avatar, DataGrid } from '@components/common';
import css from './index.module.scss';
import Images from '@assets/images';
import Footer from '../components/footer';
import { useState, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ContainerDetailsModal from './ContainerDetailsModal';
import { containerDataSchema } from '@helpers/yup/order-tasks.schema';
import { CONTAINER_TYPE } from '@helpers/constants';
import { ITaskTabProps } from '@helpers/types/task-tabs';

interface AddContainerDataProps {
  taskData: Partial<ITaskTabProps>;
  onComplete?: () => void;
}

interface containerDataForm {
  container_carrier_name: string;
  container: Array<any>;
}

const AddContainerData = (props: AddContainerDataProps) => {
  const { taskData, onComplete } = props;
  const { alertSuccessFaint, illustrationEdit } = Images;
  const [containerDataModal, setContainerDataModal] = useState(false);
  const containerDataForm = useForm<containerDataForm>({
    resolver: yupResolver(containerDataSchema),
    defaultValues: {
      container_carrier_name: '',
      container: []
    },
    shouldUnregister: true
  });

  const [containerDataColumn, containerData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: 'Container Type',
        accessor: 'container_type'
      },
      {
        Header: 'Container Number',
        accessor: 'container_number'
      }
    ];

    const row: Array<any> = taskData?.container_data?.length
      ? taskData?.container_data?.map((item: any) => ({
          container_type: item.container_type
            ? CONTAINER_TYPE.find((con: any) => con.value === item.container_type)?.label
            : '-',
          container_number: item.container_number ? item.container_number : '-'
        }))
      : [];

    return [column, row];
  }, [taskData?.container_data]);

  return (
    <>
      <div className={css.mainWrapper}>
        <Accordion defaultExpanded>
          <Accordion.Header className={css.accordionHeader}>
            <div className={css.header}>
              <div className={css.wrapper}>
                {taskData?.is_completed ? (
                  <IconNode src={alertSuccessFaint} className={css.successIcon} />
                ) : (
                  <Avatar src={illustrationEdit} alt="edit-icon" />
                )}
                <Typography variant="h5">
                  #{taskData.readable_task_id} - Add Container Details
                </Typography>
              </div>
              <div className={css.pocWrapper}>
                {!taskData?.is_completed && (
                  <Button
                    variant="text"
                    className={css.addBtn}
                    onClick={() => setContainerDataModal(true)}>
                    Add
                  </Button>
                )}
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Content>
            {taskData?.is_completed ? (
              <div className={css.tableWrapper}>
                <DataGrid
                  tableStyle={{ width: '55%' }}
                  columns={containerDataColumn}
                  data={containerData}
                />
              </div>
            ) : (
              <></>
            )}
          </Accordion.Content>
        </Accordion>
        <Footer
          createdDate={taskData.created_at as Date}
          updatedDate={taskData.updated_at as Date}
        />
      </div>
      <FormProvider {...containerDataForm}>
        <ContainerDetailsModal
          open={containerDataModal}
          onClose={() => setContainerDataModal(false)}
          taskData={taskData}
          onComplete={onComplete}
        />
      </FormProvider>
    </>
  );
};

export default AddContainerData;
