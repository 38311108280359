import { useState } from 'react';
import ActivityCard from './activity-card';
import { Chip } from '@components/base';
import PendingTasks from '../pending-tasks';

import css from './index.module.scss';
import AccessWrapper from '@authorization/access-wrapper';
import { USER_TYPES } from '@helpers/constants';

interface ActivityLogTabProps {
  orderId: string;
  actions: IActions;
}

const ActivityLogTab = (props: ActivityLogTabProps) => {
  const { orderId, actions } = props;
  const [state, setState] = useState(0);
  return (
    <div>
      <AccessWrapper show={actions?.read} showTo={[USER_TYPES.internal]}>
        <div className={css.taskChipWrapper}>
          <Chip
            label="Activity"
            variant={state == 0 ? `filled` : `outlined`}
            onClick={() => setState(0)}
          />

          <Chip
            label="Pending tasks"
            variant={state == 1 ? `filled` : `outlined`}
            onClick={() => setState(1)}
          />
        </div>
      </AccessWrapper>
      {state === 0 && <ActivityCard />}
      <AccessWrapper show={actions?.read} showTo={[USER_TYPES.internal]}>
        {state === 1 && <PendingTasks orderId={orderId} />}
      </AccessWrapper>
    </div>
  );
};

export default ActivityLogTab;
