import css from './index.module.scss';
import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';

import { getAllOrderPhotos, uploadOrderPhotos } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { Button, Loader } from '@components/base';
import { InfiniteScroll, ListPlaceholder } from '@components/common';
import AccessWrapper from '@authorization/access-wrapper';
import { SUPPORT_EMAIL, getPermissionFromLocalStorage } from '@helpers/utils';
import { USER_TYPES } from '@helpers/constants';

interface StuffingPhotosState {
  isLoading: boolean;
  imageData: Array<object>;
  hasNext: boolean;
  currentPageNumber: number;
  retry: boolean;
  count: number;
}

interface StuffingPhotosProps {
  orderId: string;
  actions: IActions;
}

const StuffingPhotos = (props: StuffingPhotosProps) => {
  const { orderId, actions } = props;
  const inputRef = useRef<any>(null);
  const rootContainer = useRef<HTMLElement>(null);
  const userType = useMemo(() => {
    return getPermissionFromLocalStorage()?.user_type;
  }, []);

  const [stuffingState, setStuffingState] = useState<StuffingPhotosState>({
    isLoading: false,
    imageData: [],
    hasNext: false,
    currentPageNumber: 1,
    retry: false,
    count: 0
  });

  const { isLoading, imageData, hasNext, currentPageNumber, retry } = stuffingState;

  useEffect(() => {
    (async () => {
      setStuffingState((prevState: StuffingPhotosState) => ({ ...prevState, loading: true }));
      await getImages();
    })();
  }, []);

  const handleClick = () => {
    if (inputRef) inputRef?.current?.click();
  };

  const handleSubmit = async (data: any) => {
    setStuffingState((prevState: StuffingPhotosState) => ({ ...prevState, isLoading: true }));
    if (data?.target?.files?.length) {
      const formData = new FormData();
      formData.append('document_type', 'STUFFING');
      for (let i = 0; i < data.target.files.length; i++) {
        formData.append(`files[]`, data.target.files[i]);
      }
      const uploadResponse = await uploadOrderPhotos(orderId, formData);
      setStuffingState((prevState: StuffingPhotosState) => ({
        ...prevState,
        isLoading: false,
        count: uploadResponse.data.count
      }));
      if (uploadResponse?.success) {
        getImages(1, false);
      } else {
        notify({
          title: 'We have little problem',
          message:
            uploadResponse.error ?? 'Unable to get stuffing photos, please try after some time!',
          severity: 'error'
        });
      }
    }
  };

  const getImages = useCallback(async (page = 1, repeat = true) => {
    // setStuffingState((prevState: StuffingPhotosState) => ({ ...prevState, isLoading: true }));
    const imageList = await getAllOrderPhotos(orderId, page);
    if (imageList?.data) {
      setStuffingState((prevState: StuffingPhotosState) => ({
        ...prevState,
        imageData: repeat
          ? [...prevState.imageData, ...imageList.data.results]
          : imageList.data.results,
        hasNext: !!imageList.data.next,
        currentPageNumber: page ?? 1,
        retry: false,
        isLoading: false
      }));
    } else if (imageList.error) {
      notify({
        title: 'We have little problem',
        message: imageList.error ?? 'There was an error understanding your request.',
        severity: 'error'
      });
      setStuffingState((prevState: StuffingPhotosState) => ({
        ...prevState,
        isLoading: false,
        retry: true
      }));
    }
  }, []);

  return (
    <main ref={rootContainer}>
      <div className={css.headerWrapper}>
        <AccessWrapper show={actions?.create}>
          <Button onClick={handleClick}>Upload</Button>
        </AccessWrapper>
        {/* <Badge>{count}</Badge> */}
      </div>
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        type="file"
        onChange={handleSubmit}
        multiple
        accept=".jpg,.jpeg,.png"
      />
      <div className={css.imageWrapper}>
        {imageData?.length ? (
          <InfiniteScroll
            rootRef={rootContainer.current!}
            currentIndex={currentPageNumber}
            hasMore={hasNext}
            hideEndText={imageData.length <= 10}
            onIntersect={(index: number) => getImages(index)}
            retry={retry}>
            <ImageList rowHeight={550}>
              {imageData.map((item: any) => (
                <ImageListItem key={item.order_image_id} cols={item.cols || 1}>
                  <img src={item.image_object} alt={item.document_type} />
                </ImageListItem>
              ))}
            </ImageList>
          </InfiniteScroll>
        ) : (
          <ListPlaceholder
            to={``}
            title="No Stuffing Photos Added Yet"
            supportingText={
              userType === USER_TYPES.customer
                ? `Contact ${SUPPORT_EMAIL} incase of any issues with stuffing photos`
                : `When a new stuffing photo is added, you will see it here.`
            }
            buttonText=""
          />
        )}
      </div>
      <Loader open={isLoading} />
    </main>
  );
};

export default StuffingPhotos;
