import css from './index.module.scss';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Typography } from '@components/base';
import { BoxContainer, TextField } from '@components/common';
import IconNode from '@components/base/icon-node';
import Images from '@assets/images';

interface PackagingFormProps {
  onFormSubmit: (data: any) => void;
  onCancelClick: () => void;
  rectifyRemarks?: string;
}

const BatchForm = (props: any) => {
  const { onFormSubmit, onCancelClick } = props;
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext<any>();

  const { fields: itemFields } = useFieldArray({
    name: `items`,
    control
  });

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      <BoxContainer title="Item details">
        <ItemWrapper itemFields={itemFields} control={control} />
      </BoxContainer>
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div>
          <Button type="submit" disabled={isSubmitting}>
            Save and Proceed
          </Button>
        </div>
      </div>
    </form>
  );
};

interface ItemWrapperProps {
  itemFields: any;
  control: any;
}

const ItemWrapper = (props: any) => {
  const { itemFields, control } = props;
  return (
    <>
      {itemFields.map((item: any, index: number) => {
        return (
          <div className={css.itemWrapper} key={index}>
            <Controller
              name={`items.${index}.name`}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label="Item Name"
                  placeholder="Item Name"
                  className={css.fieldWrapper}
                  {...field}
                  disabled
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />

            <BatchWrapper index={index} item={item} />
          </div>
        );
      })}
    </>
  );
};

interface PackageWrapperProps {
  index: number;
  item: any;
}

const BatchWrapper = (props: any) => {
  const { index, item } = props;
  const { control } = useFormContext<any>();

  const {
    fields: batchFields,
    append: batchAppend,
    remove: batchRemove
  } = useFieldArray({
    name: `items.${index}.batch`,
    control
  });

  const handleAddBatchField = () => {
    batchAppend({
      batch_number: null
    });
  };

  return (
    <div className={css.pacakageSectionWrapper}>
      <Typography variant="p" className={css.titleWrapper}>
        Batch Number
      </Typography>
      <div className={css.boxWrapper}>
        {batchFields.map((batch: any, batchIndex: number) => {
          const handleDeleteClick = () => batchRemove(index);
          return (
            <div key={batchIndex} className={css.rowWrapper}>
              <Controller
                name={`items.${index}.batch.${batchIndex}.batch_number`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Batch Number"
                    placeholder="Batch Number"
                    className={css.fieldWrapper}
                    {...field}
                    error={fieldState.invalid}
                    endIcon={
                      fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                    }
                    helperText={fieldState.error?.message}
                  />
                )}
              />
              {batchFields.length > 1 && (
                <Button
                  variant="text"
                  title="Delete Item"
                  onClick={handleDeleteClick}
                  startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}></Button>
              )}
            </div>
          );
        })}
        <Button
          variant="text"
          onClick={handleAddBatchField}
          startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
          Add Batch Number
        </Button>
      </div>
    </div>
  );
};

export default BatchForm;
