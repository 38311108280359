import { useEffect, useReducer, useRef } from 'react';
import { BoxContainer } from '@components/common';
import { Button } from '@components/base';
import FieldHeader from './components/field-header';
import FieldValue from './components/field-input';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { COA_ITEM_DETAILS_DEFAULT } from '@helpers/constants';
import { FormPropsStates } from '@helpers/types/coa';

import css from './index.module.scss';
import { useScrollToTop } from '@helpers/hooks';

const OrderDetails = (props: FormPropsStates) => {
  const { onFormSubmit, handleCancelClick } = props;
  const { control, resetField, handleSubmit, watch } = useFormContext<any>();

  const {
    fields: skeletonFields,
    append: skeletonAppend,
    remove: skeletonRemove,
    update: skeletonUpdate
  } = useFieldArray({
    name: 'item_details_skeleton',
    control
  });

  const skeletonWatch = watch('item_details_skeleton');

  const {
    fields: bodyFields,
    append: bodyAppend,
    remove: bodyRemove,
    update: bodyUpdate
  } = useFieldArray({
    name: 'item_details',
    control
  });

  const rowWatch = watch('item_details');

  useEffect(() => {
    if (COA_ITEM_DETAILS_DEFAULT?.length && !watch('item_details_skeleton')?.length) {
      skeletonAppend(
        COA_ITEM_DETAILS_DEFAULT.map((item: any) => ({
          field_name: item
        }))
      );
    }
  }, []);

  useEffect(() => {
    if (skeletonWatch?.length) {
      resetBody();
    }
  }, [skeletonWatch]);

  const handleskeletonAppendAddField = () => {
    skeletonAppend({
      field_name: ''
    });
  };

  const handleItemDetailsAppendAddField = () => {
    const keyNames = skeletonWatch?.map((item: any) => item.field_name);
    const obj = createEmptyObject(keyNames ?? []);
    bodyAppend(obj);
  };

  const createEmptyObject = (keysArray: any) => {
    const emptyObject: any = {};

    keysArray?.forEach((key: any) => {
      emptyObject[key] = '';
    });

    return emptyObject;
  };

  const resetBody = () => {
    if (skeletonWatch?.length) {
      const keyNames = skeletonWatch?.map((item: any) => item.field_name);
      if (!rowWatch?.length) {
        const obj = createEmptyObject(keyNames);
        bodyAppend(obj);
      } else {
        const currentRowData = rowWatch;
        resetField('item_details');
        const newData = currentRowData?.map((item: any) => {
          const obj: any = {};
          keyNames.forEach((key: any) => {
            if (key?.length) obj[key] = item[key] ?? '';
          });
          return obj;
        });
        bodyAppend(newData);
      }
    }
  };
  return (
    <form noValidate className={css.coaFormWrapper} onSubmit={handleSubmit(onFormSubmit)}>
      <BoxContainer title="Table Details">
        <div className={css.orderDetailsWrapper}>
          <FieldHeader
            data={skeletonWatch ?? []}
            remove={skeletonRemove}
            handleAddField={handleskeletonAppendAddField}
            onSubmit={resetBody}
          />
          <FieldValue
            data={rowWatch ?? []}
            handleAddField={handleItemDetailsAppendAddField}
            remove={bodyRemove}
          />

          <div className={css.formActionWrapper}>
            <Button variant="text" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button type="submit">Save and Proceed</Button>
          </div>
        </div>
      </BoxContainer>
    </form>
  );
};

export default OrderDetails;
