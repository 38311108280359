import { Button, IconNode, Typography } from '@components/base';
import css from './index.module.scss';
import { getCommaSepratedValue, openDocumentSignedLink } from '@helpers/utils';
import { ICustomItem, IItems, ItemDetails } from '../../../orderOverview';
import Images from '@assets/images';

interface IItemProps {
  previewData: {
    itemDetails: ItemDetails;
    currency: string;
  };
}

const Item = (props: IItemProps) => {
  const { previewData } = props;
  const { itemDetails, currency } = previewData;
  return (
    <div className={css.detailWrapper}>
      <div className={css.detailsTitle}>
        <Typography variant="h4">Item Details</Typography>
      </div>
      <div className={css.detailContainer}>
        <div className={css.itemTable}>
          <div className={css.itemHeader}>
            <Typography variant="label" className={css.itemHeaderlabel}>
              S.No.
            </Typography>
            <Typography variant="label" className={css.itemHeaderlabel}>
              Item Details
            </Typography>
            <Typography variant="label" className={css.itemHeaderlabel}>
              Qty. in {itemDetails.unitOfWeight}
            </Typography>
            <Typography variant="label" className={css.itemHeaderlabel}>
              Price/Unit
            </Typography>
            <Typography variant="label" className={css.itemHeaderlabel}>
              Amount
            </Typography>
          </div>
          {itemDetails?.items &&
            itemDetails.items?.map((item: IItems, index: number) => (
              <>
                <div className={css.itemHeader}>
                  <Typography variant="body">{index + 1}</Typography>
                  <div className={css.itemDetails}>
                    <Typography variant="body">{item.product_name}</Typography>
                    {item?.order_item_description && (
                      <Typography variant="subheading2" className={css.itemDescription}>
                        {item.order_item_description}
                      </Typography>
                    )}
                    {item.package_details && (
                      <div>
                        <Typography variant="subheading2" className={css.itemPackagingDetails}>
                          {item.package_details}
                        </Typography>
                      </div>
                    )}
                    {item?.country_of_origin_display_value && (
                      <div>
                        <Typography variant="subheading2" className={css.itemOrigin}>
                          Origin: {item.country_of_origin_display_value}
                        </Typography>
                      </div>
                    )}
                    {item?.hs_code && (
                      <div>
                        <Typography variant="subheading2" className={css.itemHSCode}>
                          HS Code: {item.hs_code}
                        </Typography>
                      </div>
                    )}
                    {item?.is_pre_shipment_sample_required && (
                      <div>
                        <Typography variant="subheading2" className={css.itemPSS}>
                          PreShipment Sample Required:{' '}
                          {item.is_pre_shipment_sample_required ? 'Yes' : 'No'}
                        </Typography>
                      </div>
                    )}
                  </div>
                  <div>
                    <Typography variant="body">{item.quantity || '-'}</Typography>
                  </div>
                  <div>
                    <Typography variant="body">
                      {currency} {getCommaSepratedValue(item.rate) || '-'}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body">
                      {currency} {getCommaSepratedValue(item.total_amount) || '-'}
                    </Typography>
                  </div>
                </div>
              </>
            ))}
          {itemDetails?.customItems &&
            itemDetails.customItems?.map((item: ICustomItem, index: number) => (
              <>
                <div className={css.itemHeader}>
                  <Typography variant="body">{itemDetails.items.length + 1}</Typography>
                  <div className={css.itemDetails}>
                    <Typography variant="pdoc">{item.custom_item_name}</Typography>
                  </div>
                  <div>
                    <Typography variant="body">{item.custom_item_quantity || '-'}</Typography>
                  </div>
                  <div>
                    <Typography variant="body">
                      {getCommaSepratedValue(item.custom_item_rate) || '-'}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body">
                      {getCommaSepratedValue(item.custom_item_amount) || '-'}
                    </Typography>
                  </div>
                </div>
              </>
            ))}
          <div className={css.itemTotalHeader}>
            <div></div>
            <Typography variant="body" className={css.itemTotalDetails}>
              Total
            </Typography>
            <div>
              <Typography variant="body" className={css.itemTotalAmount}>
                {currency} {getCommaSepratedValue(itemDetails.totalAmount) || '-'}
              </Typography>
            </div>
            <div>
              <Typography variant="body" className={css.itemTotalAmountInWords}>
                {(itemDetails.totalAmountInWords as string) || '-'}
              </Typography>
            </div>
          </div>
        </div>

        {(itemDetails?.piDocumentURL || itemDetails?.ciDocumentURL) && (
          <div className={css.invoice}>
            <Typography variant="h4">Invoices</Typography>
            {itemDetails?.piDocumentURL && (
              <Button
                variant="outlined-secondary"
                onClick={() => openDocumentSignedLink(itemDetails.piDocumentURL)}
                className={css.invoiceButton}
                startIcon={
                  <IconNode
                    src={Images.fileInvoice}
                    imageClassName={css.fileInvoiceIcon}
                    alt="Invoice icon"
                  />
                }>
                Proforma Invoice
              </Button>
            )}
            {itemDetails?.ciDocumentURL && (
              <Button
                variant="outlined-secondary"
                onClick={() => openDocumentSignedLink(itemDetails.ciDocumentURL)}
                className={css.invoiceButton}
                startIcon={
                  <IconNode
                    src={Images.fileInvoice}
                    imageClassName={css.fileInvoiceIcon}
                    alt="Invoice icon"
                  />
                }>
                Commercial Invoice
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Item;
