import React from 'react';
import Images from '@assets/images';
import { Accordion, Button, Typography } from '@components/base';
import { Avatar, InputDatePicker } from '@components/common';
import notify from '@helpers/toastify-helper';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { updateMaterialLifting } from '@services/order.service';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import css from './index.module.scss';
import Footer from '../components/footer';
import { getFormattedDate } from '@helpers/utils';

interface GateInProps {
  taskData: Array<Partial<ITaskTabProps>>;
  onComplete?: () => void;
}

interface FormType {
  material_lifting_confirmation: Date | null;
}

const MaterialLiftingConfirmation = (props: GateInProps) => {
  const { taskData, onComplete } = props;
  const taskItem = taskData[0];

  const { control, getValues } = useForm<FormType>({
    defaultValues: {
      material_lifting_confirmation: null
    }
  });

  const handleSelectChange = async () => {
    const values = getValues('material_lifting_confirmation');
    if (!values) {
      notify({ message: 'Please select a date', severity: 'error' });
      return;
    }
    const body = {
      actual_lifting_date: `${moment(values).format('YYYY-MM-DD')}`
    };

    const response = await updateMaterialLifting(
      taskItem.task_id as string,
      taskItem?.supplier_id as string,
      body
    );
    const { data, error, success } = response;
    if (success) {
      notify({ message: data.message, severity: 'success', dismissible: true });
      if (onComplete) {
        onComplete();
      }
    } else {
      notify({ message: error ?? 'Something went wrong!', severity: 'error', dismissible: true });
    }
  };

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {taskItem?.is_completed ? (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              ) : (
                <Avatar src={Images.illustration} alt="" />
              )}
              <Typography variant="h5">
                #{taskItem?.readable_task_id} - Update Material Lifting Date (
                {taskData[0]?.supplier_name})
              </Typography>
            </div>
            <div className={css.pocWrapper}>
              <div className={css.uploadWrapper}>
                {!taskData[0]?.is_completed && (
                  <Button variant="text" className={css.detailsButton} onClick={handleSelectChange}>
                    Confirm
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content className={css.accordionContent}>
          <div className={css.dateTimeWrapper}>
            <div className={css.dateWrapper}>
              <Typography variant="subheading1" className={css.datePicker}>
                {taskItem?.is_completed ? 'Selected' : 'Select a'} Date
              </Typography>
              <div>
                {taskItem?.is_completed ? (
                  <Typography>
                    {getFormattedDate(taskItem?.actual_lifting_date as string)}
                  </Typography>
                ) : (
                  <Controller
                    name="material_lifting_confirmation"
                    control={control}
                    render={({ field, fieldState }) => (
                      <InputDatePicker
                        {...field}
                        required
                        value={field.value}
                        onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        rootClassName={css.fieldSpacing}
                      />
                    )}
                  />
                )}
              </div>
            </div>

            <div className={css.dateWrapper}>
              <Typography variant="subheading1" className={css.datePicker}>
                Tentative Lifting Data
              </Typography>
              <div>
                <Typography>
                  {getFormattedDate(taskItem?.tentative_lifting_date as string)}
                </Typography>
              </div>
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
    </div>
  );
};

export default MaterialLiftingConfirmation;
