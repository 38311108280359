import Images from '@assets/images';
import { Accordion, Button, Typography } from '@components/base';
import { Avatar } from '@components/common';
import { useState } from 'react';
import css from './index.module.scss';
import UploadDocModal from './upload-doc-modal';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import Footer from '../components/footer';

interface TaskCardProps {
  documents?: any;
  taskData: Partial<ITaskTabProps>;
  onComplete?: () => void;
}

const UploadBlDraft = (props: TaskCardProps) => {
  const { settingGrey } = Images;
  const [openModal, setOpenModal] = useState(false);
  const getCompleteStatus = props.taskData?.is_completed;
  const [taskIsCompleted, setTaskIsCompleted] = useState<boolean | undefined>(getCompleteStatus);
  const { taskData } = props;
  const taskId = taskData.task_id;

  const handleStatusFromModal = (data: boolean) => {
    setTaskIsCompleted(data);
  };

  const taskName = taskData?.transportation_mode === 'AIR' ? 'AWB Draft' : 'BL Draft';

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {getCompleteStatus || taskIsCompleted ? (
                <Avatar src={Images.alertSuccessFaint} alt="taskIcon" />
              ) : (
                <Avatar src={settingGrey} alt="taskIcon" />
              )}
              <Typography variant="h5">#{taskData.readable_task_id} - Upload {taskName}</Typography>
            </div>
            <div className={css.pocWrapper}>
              <div className={css.uploadWrapper}>
                {!getCompleteStatus && taskIsCompleted === false && (
                  <Button
                    variant="text"
                    className={css.detailsButton}
                    onClick={() => setOpenModal(true)}>
                    Upload
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Accordion.Header>
      </Accordion>
      <Footer
        createdDate={props.taskData.created_at as Date}
        updatedDate={props.taskData.updated_at as Date}
      />
      {openModal && taskId && (
        <UploadDocModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          taskId={taskId}
          taskName={taskName}
          sendStatusToCard={handleStatusFromModal}
          onComplete={props.onComplete}
        />
      )}
    </div>
  );
};

export default UploadBlDraft;
