import { Chip, Typography } from '@components/base';
import { Avatar, LinkButton } from '@components/common';
import { IAddress } from '@helpers/types/address';
import { Poc } from '@helpers/types/point-of-contact';
import { useMemo, useEffect, useState } from 'react';
import css from './index.module.scss';
import { Link } from 'react-router-dom';
import { getFormattedDate } from '@helpers/utils';
import moment from 'moment';
import { chipVariantType } from '@helpers/types';

interface CustomerCardProps {
  customerId: string;
  customerName: string;
  pocDetails: Poc[];
  customerPhoneNumber: string;
  customerEmail: string;
  customerCountry: string;
  addresses: IAddress[];
  lastOrdered: string | null;
  ecgcEndDate: string | null;
}

interface CustomerCardState {
  state: string;
  css: chipVariantType;
}

const CustomerCard = (props: CustomerCardProps) => {
  const {
    customerId,
    customerName,
    pocDetails,
    customerPhoneNumber,
    customerEmail,
    customerCountry,
    lastOrdered,
    ecgcEndDate
  } = props;

  const officeAddress = useMemo(() => {
    const { addresses } = props;
    const ofcAddress = addresses.filter((address) => address.address_type === 'OFC');
    if (ofcAddress.length) {
      const addressString = ofcAddress.map((address) => address.readable_address).join('');
      return addressString;
    }
  }, [props.addresses]);

  const [ecgcStatus, setEcgcStatus] = useState<CustomerCardState>({
    state: 'To be Initiated',
    css: 'filled-blue'
  });

  useEffect(() => {
    if (!ecgcEndDate) return;
    if (moment().diff(ecgcEndDate, 'day') > 0) {
      setEcgcStatus({
        state: 'Expired',
        css: 'filled-red'
      });
    } else if (moment().diff(ecgcEndDate, 'day') < 0) {
      setEcgcStatus({
        state: 'Valid',
        css: 'filled-green'
      });
    } else {
      setEcgcStatus({
        state: 'To be Initiated',
        css: 'filled-blue'
      });
    }
  }, [ecgcEndDate]);

  const pocName = useMemo(() => {
    return `${pocDetails[0]?.given_name} ${pocDetails[0]?.family_name}`;
  }, [props.pocDetails]);

  return (
    <div className={css.mainWrapper}>
      <Link to={customerId} className={css.customerCardLink}>
        <div className={css.header}>
          <Typography variant="h4">{customerName}</Typography>
          <Typography className="link-button-text">View More Details</Typography>
        </div>
        <div className={css.mainContent}>
          <div className={css.pocWrapper}>
            <Avatar src={pocName} alt={pocName} />
            <div className={css.poctextWrapper}>
              <Typography variant="span">Point of Contact</Typography>
              <Typography variant="p">{pocName}</Typography>
            </div>
          </div>
          <div className={css.textWrapper}>
            <Typography variant="span">Contact Number</Typography>
            <Typography variant="p">{customerPhoneNumber}</Typography>
          </div>
          <div className={css.textWrapper}>
            <Typography variant="span">Email ID</Typography>
            <Typography variant="p">{customerEmail}</Typography>
          </div>
          <div className={css.textWrapper}>
            <Typography variant="span">Country</Typography>
            <Typography variant="p">{customerCountry}</Typography>
          </div>
          <div className={css.textWrapper}>
            <Typography variant="span">Last ordered</Typography>
            <Typography variant="p">{lastOrdered ? getFormattedDate(lastOrdered) : '-'}</Typography>
          </div>
        </div>
        <div className={css.footer}>
          <div className={css.textWrapper}>
            <Typography variant="span">Office Address</Typography>
            <Typography variant="p" className={css.addressWrapper}>
              {officeAddress}
            </Typography>
          </div>
          <div className={css.ecgcColumn}>
            <Typography variant="span">ECGC </Typography>
            <Chip
              label={ecgcStatus.state}
              variant={ecgcStatus.css ? ecgcStatus.css : 'filled-green'}
              clickable={false}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CustomerCard;
