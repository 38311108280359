/* eslint-disable @typescript-eslint/no-var-requires */
import { Typography } from '@components/base';
import { IItemDetail, IItemDetailsInfo, PerformaInvoiceStates } from '@helpers/types/pi';
import { PurchaseOrderStates } from '@helpers/types/po';
import {
  generateReadablePackingDetails,
  getCommaSepratedValue,
  getReadablePaymentTerm
} from '@helpers/utils';
import { useParams } from 'react-router-dom';
import { DELIVERY_TERMS, PO_PAYMENT_TERMS } from '@helpers/constants';
import { useEffect, useState } from 'react';
import { fetchAmountInWords } from '@services/util.service';

import css from '../index.module.scss';
const writtenNumber = require('written-number');

interface IItem {
  itemData: PurchaseOrderStates;
}

const Item = (props: IItem) => {
  const { itemData } = props;
  const { itemInfo, itemDetailsInfo, orderDetails, purchaseOrder } = itemData;
  const { items, totalAmount } = itemInfo;
  const customItems = itemDetailsInfo?.custom_items;
  const urlParams = useParams();
  const { supplierid } = urlParams;
  const [totalAmountInWords, setTotalAmountInWords] = useState('-');
  useEffect(() => {
    (async () => {
      if (itemInfo.totalAmount) {
        const response = await fetchAmountInWords({
          amount: itemInfo.totalAmount.toString().replaceAll(',', ''),
          currency_type: purchaseOrder?.currency ? purchaseOrder?.currency : 'USD'
        });
        if (response?.success) {
          setTotalAmountInWords(response.data.amount_in_words);
        }
      }
    })();
  }, [itemInfo.totalAmount]);
  return (
    <div className={css.boxWrapper}>
      <div className={css.itemDetailsContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="pdoc">Order Details</Typography>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Vendor ID</Typography>
            <Typography variant="body">{orderDetails?.vendor_id}</Typography>
          </div>
          <div>
            <Typography variant="label">Terms of Delivery</Typography>

            <Typography variant="body">
              {DELIVERY_TERMS.find((item: any) => orderDetails?.inco_terms == item.value)?.label ??
                '-'}
            </Typography>
          </div>
          {/* <div>
            <Typography variant="label">Destination</Typography>
            <Typography variant="body">{orderDetails?.place_of_delivery}</Typography>
          </div> */}
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Payment Terms</Typography>
            {getReadablePaymentTerm(orderDetails?.payment_terms)?.map(
              (item: any, index: number) => {
                return (
                  <Typography variant="body" key={index}>
                    {item} {orderDetails?.payment_term_suffix
                ? (orderDetails?.payment_term_suffix as any)?.name: ''}
                  </Typography>
                );
              }
            )}
          </div>
          <div>
            <Typography variant="label">Country of Origin</Typography>
            <Typography variant="body">{orderDetails?.country_of_origin}</Typography>
          </div>
        </div>
        <div className={css.fieldTable}>
          <div className={css.tableHeader}>
            <Typography>S.No.</Typography>
            <Typography>Item Details</Typography>
            <Typography>Qty. in {purchaseOrder?.currency}</Typography>
            <Typography>Price/Unit</Typography>
            <Typography>Amount</Typography>
          </div>
          {items
            ?.filter((item: any) => item.supplier_id === supplierid)
            ?.map((val: any, index: number) => {
              return (
                <div className={css.tableContent} key={index}>
                  <Typography>{index + 1}</Typography>
                  <div>
                    {val?.include_grade_name_in_docs === true ? (
                      <Typography variant="p">
                        {val?.product_name} - {val?.product_grade_name}
                      </Typography>
                    ) : (
                      <Typography variant="p">{val?.product_name}</Typography>
                    )}
                    <div className={css.secondaryText}>{`${val.order_item_description_po}`}</div>
                    {val?.CAS_number && (
                      <div className={css.secondaryText}>
                        CAS Number: {''}
                        {val.CAS_number}
                      </div>
                    )}
                    {val?.hs_code && (
                      <div className={css.secondaryText}>
                        HS Code: {''}
                        {val.hs_code}
                      </div>
                    )}
                    {val?.package && (
                      <div className={css.secondaryText}>
                        Packaging Details: {''}
                        {generateReadablePackingDetails(val.package)?.join(', ')}{' '}
                      </div>
                    )}
                    <>
                      <br />
                      <Typography variant="label">
                        Pre-Shipment Sample Required:{' '}
                        {val?.is_pre_shipment_sample_required ? 'Yes' : 'No'}
                      </Typography>
                    </>
                  </div>
                  <Typography>{val.quantity_po}</Typography>
                  <Typography>{`${purchaseOrder?.currency} ${val.rate_po}`}</Typography>
                  <Typography>{`${purchaseOrder?.currency} ${val.total_amount_po}`}</Typography>
                </div>
              );
            })}
          {customItems?.map((val: any, index: number) => {
            return (
              <div className={css.tableContent} key={index}>
                <Typography>{items.length + index + 1}</Typography>
                <div>
                  <Typography>{val.custom_item_name}</Typography>
                  <div className={css.secondaryText}>{val.custom_item_description} </div>
                </div>
                <Typography>{val.custom_item_quantity}</Typography>
                <Typography>{val.custom_item_rate}</Typography>
                <Typography>
                  {Number(val.custom_item_quantity) * Number(val.custom_item_quantity)}
                </Typography>
              </div>
            );
          })}
          <div className={css.totalContainer}>
            <Typography variant="h4">Total</Typography>
            {purchaseOrder?.gst_rate != '0' && (
              <div className={css.gstDetails}>
                <Typography variant="pdoc">{`GST ${purchaseOrder?.gst_rate}% ${
                  purchaseOrder?.gst_rate == '0.1' ? `under Merchant Export` : ``
                } ${purchaseOrder?.currency} ${purchaseOrder?.gst_amount}`}</Typography>
              </div>
            )}
            <div className={css.totalAmountContainer}>
              <Typography variant="h4">{`${purchaseOrder?.currency} ${itemInfo.totalAmount}`}</Typography>
            </div>
          </div>
        </div>

        <div className={css.invoiceWords}>
          <Typography variant="label">Invoice Amount in Words</Typography>
          <Typography variant="body"> {totalAmountInWords}</Typography>
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Total Amount</Typography>
            <Typography variant="body">{`${purchaseOrder?.currency} ${itemInfo.totalAmount}`}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
