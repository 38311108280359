import { IRemarksFormData, IShippingFormData } from '@helpers/types/si';
import css from './index.module.scss';
import SIPreview from './si-preview-tab';
import { ITaskItemProps } from '@helpers/types/task-tabs';

interface IPreviewTabProps {
  data: ITaskItemProps;
  shippingFormData: IShippingFormData;
  remarksFormData: IRemarksFormData[];
  onBackClick: () => void;
  handleCancelClick: () => void;
  handleFormSubmit: () => Promise<any>;
}

const PreviewTab = (props: IPreviewTabProps) => {
  const {
    data,
    shippingFormData,
    remarksFormData,
    onBackClick,
    handleCancelClick,
    handleFormSubmit
  } = props;
  return (
    <main className={css.siWrapper}>
      <SIPreview
        data={data}
        shippingFormData={shippingFormData}
        remarksFormData={remarksFormData}
        handleFormSubmit={handleFormSubmit}
        handleCancelClick={handleCancelClick}
        onBackClick={onBackClick}
      />
    </main>
  );
};

export default PreviewTab;
