import { useEffect, useMemo, useState, FC } from 'react';
import { getShipmentTrackingDetails } from '@services/order.service';
import { DataGrid, ListPlaceholder } from '@components/common';
import { Chip, Typography } from '@components/base';
import css from './index.module.scss';
import notify from '@helpers/toastify-helper';
import { SUPPORT_EMAIL, getFormattedDate, getPermissionFromLocalStorage } from '@helpers/utils';
import { USER_TYPES } from '@helpers/constants';

interface TrackingProps {
  orderId: string;
}

interface RowGridType {
  actual_date: JSX.Element | string | null;
  delay: JSX.Element;
  is_reached: JSX.Element | string;
  location: string;
  ship_activity: string;
  tentative_date: JSX.Element | string | null;
  vessel_name: string;
}

const Tracking = (props: TrackingProps) => {
  const { orderId } = props;
  const [iframeUrl, setIframeUrl] = useState();
  const [state, setState] = useState(0);
  const [trackingTable, setTrackingTable] = useState([]);
  useEffect(() => {
    fetchShipmentTrackingDetails();
  }, []);

  const userType = useMemo(() => {
    return getPermissionFromLocalStorage()?.user_type;
  }, []);

  const fetchShipmentTrackingDetails = async () => {
    const response = await getShipmentTrackingDetails(orderId);
    if (response.success) {
      if (response.data?.iframe_url?.length) {
        setIframeUrl(response.data.iframe_url);
      } else {
        notify({
          message: response?.data?.message ?? 'Something went wrong!',
          severity: 'success'
        });
      }
      if (response.data?.tracking_table_data?.length) {
        setTrackingTable(response.data.tracking_table_data);
      }
    } else {
      notify({ message: response.error ?? 'Something went wrong!', severity: 'error' });
    }
  };

  const [documentColumn, documentData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name'
      },
      {
        Header: 'Ship Activity',
        accessor: 'ship_activity'
      },
      {
        Header: 'Location',
        accessor: 'location'
      },
      {
        Header: 'Status',
        accessor: 'delay'
      },
      {
        Header: 'Tentative Date',
        accessor: 'tentative_date'
      },
      {
        Header: 'Actual Date',
        accessor: 'actual_date'
      },
      {
        Header: '',
        accessor: 'is_reached'
      }
    ];

    const row: Array<RowGridType> = trackingTable?.map((prod: any) => ({
      actual_date: prod.actual_date ? (
        !prod.is_reached ? (
          <Typography variant="label">{getFormattedDate(prod.actual_date)}</Typography>
        ) : (
          getFormattedDate(prod.actual_date)
        )
      ) : (
        '-'
      ),
      delay:
        prod.delay == '-' ? (
          <div>{'-'}</div>
        ) : (
          <Chip
            label={(() => {
              let label;
              if (prod.delay < 0) {
                label = `Early by ${Math.abs(prod.delay)} days`;
              } else if (prod.delay > 0) {
                label = `Delayed by ${prod.delay} days`;
              } else if (prod.delay === 0) {
                label = 'On Time';
              } else {
                label = '-';
              }
              return label;
            })()}
            className="chip-center"
            variant={
              prod.delay < 0 ? 'filled-blue' : prod.delay === 0 ? 'filled-green' : 'filled-red'
            }
            clickable={false}
          />
        ),
      is_reached: prod.is_reached ? (
        <Chip label="Reached" className="chip-center" variant="filled-green" clickable={false} />
      ) : (
        ``
      ),
      location: prod.location ? (
        !prod.is_reached ? (
          <Typography variant="label">{prod.location}</Typography>
        ) : (
          prod.location
        )
      ) : (
        '-'
      ),
      ship_activity: prod.ship_activity ? (
        !prod.is_reached ? (
          <Typography variant="label">{prod.ship_activity}</Typography>
        ) : (
          prod.ship_activity
        )
      ) : (
        '-'
      ),
      tentative_date: prod.tentative_date ? (
        !prod.is_reached ? (
          <Typography variant="label">{getFormattedDate(prod.tentative_date)}</Typography>
        ) : (
          getFormattedDate(prod.tentative_date)
        )
      ) : (
        '-'
      ),
      vessel_name: prod.vessel_name ? (
        !prod.is_reached ? (
          <Typography variant="label">{prod.vessel_name}</Typography>
        ) : (
          prod.vessel_name
        )
      ) : (
        '-'
      )
    }));

    return [column, row];
  }, [trackingTable]);

  return (
    <div>
      {trackingTable && trackingTable.length > 0 && (
        <div className={css.taskChipWrapper}>
          <Chip
            label="Map View"
            variant={state === 0 ? 'filled' : 'outlined'}
            onClick={() => setState(0)}
          />
          <Chip
            label="Table View"
            variant={state === 1 ? 'filled' : 'outlined'}
            onClick={() => setState(1)}
          />
        </div>
      )}

      {state === 0 &&
        (iframeUrl ? (
          <iframe
            src={iframeUrl}
            id="IframeShipsgoLiveMap"
            style={{ height: 500, width: '100%' }}></iframe>
        ) : (
          <ListPlaceholder
            to={``}
            title="Tracking not available for this order yet"
            supportingText={
              userType === USER_TYPES.customer
                ? `Contact ${SUPPORT_EMAIL} incase of any issues with tracking`
                : `If you have BL for this order, share it with tech team so tracking is enabled`
            }
            buttonText=""
          />
        ))}

      {state === 1 && trackingTable && trackingTable.length > 0 && (
        <DataGrid tableStyle={{ width: '100%' }} columns={documentColumn} data={documentData} />
      )}
    </div>
  );
};

export default Tracking;
