/* eslint-disable @typescript-eslint/no-empty-function */
import { Loader } from '@components/base';
import { DataGrid, DocumentRow } from '@components/common';
import {
  SHIPMENT_DOCUMENTS_TYPE_MAP,
  UPLOAD_DOCS_MAP,
  getLabelFromValue
} from '@helpers/constants';
import notify from '@helpers/toastify-helper';
import { ShipmentDocForm, ShipmentDocument } from '@helpers/types/document';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { addDocument } from '@helpers/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  updateOrderOtherDoc,
  updateShipmentDocuments,
  uploadOrderOtherDoc,
  uploadShipmentDocuments
} from '@services/order.service';
import { useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { CellProps, Column } from 'react-table';
import css from './documents.module.scss';
import UploadDocument from './upload-document';

interface DocumentsTabProps {
  documents: ShipmentDocument[];
  onChange?: (param: ShipmentDocument) => void;
  taskId: string;
  getApiCall?: () => void;
  shipmentType?: string;
  taskItem: Partial<ITaskTabProps>;
  orderId: string;
}

interface DocumentsTabStates {
  modalState: boolean;
  isLoading: boolean;
  currentDoc: Partial<ShipmentDocument>;
  editMode: boolean;
}

interface DocumentGrid {
  name: Partial<ShipmentDocument>;
  proof: Partial<ShipmentDocument>;
  doc_object: Partial<ShipmentDocument>;
}

const DocumentsTab = (props: DocumentsTabProps) => {
  const { documents, getApiCall = () => {}, taskItem, orderId } = props;
  const [documentState, setDocumentState] = useState<DocumentsTabStates>({
    modalState: false,
    isLoading: false,
    currentDoc: {},
    editMode: false
  });

  const documentForm = useForm<Partial<ShipmentDocForm>>({
    resolver: yupResolver(addDocument),
    defaultValues: {
      document_id: '',
      document_type: null,
      document_name: '',
      document_description: '',
      document_object: null,
      reason_for_update: null,
      edit_mode: false
    },
    shouldUnregister: true
  });

  const {
    setValue,
    formState: { isSubmitting }
  } = documentForm;

  const { modalState, isLoading, currentDoc, editMode } = documentState;

  const toggleModalState = () => {
    setDocumentState((prevState) => ({
      ...prevState,
      modalState: !prevState.modalState
    }));
  };

  const handleUploadDocument = (data: ShipmentDocument) => async () => {
    setDocumentState((prevState) => ({
      ...prevState,
      modalState: true,
      currentDoc: data,
      editMode: data?.is_uploaded
    }));

    setValue('document_type', { label: data.doc_key, value: data.doc_key }, { shouldDirty: false });
    setValue('document_object', null, { shouldDirty: false });
    setValue('edit_mode', data?.is_uploaded, { shouldDirty: false });
  };

  const handleFormSubmit: SubmitHandler<Partial<ShipmentDocForm>> = async (param) => {
    const postId = currentDoc?.document_primary_key
      ? currentDoc?.document_primary_key
      : currentDoc?.object_primary_key;

    const formData = new FormData();
    formData.append('remarks', param.document_description as string);
    formData.append('document_object', param.document_object as Blob);
    formData.append('type', param.document_type.value);
    if (param?.reason_for_update) {
      formData.append('reason_for_update', param.reason_for_update);
    }
    switch (currentDoc?.doc_type) {
      case SHIPMENT_DOCUMENTS_TYPE_MAP.order_related: {
        const res = await handleApiCall(
          currentDoc?.is_uploaded as boolean,
          postId as string,
          SHIPMENT_DOCUMENTS_TYPE_MAP.order_related,
          formData
        );
        res?.success
          ? notify({
              message: 'Document Uploaded Successfully',
              severity: 'success',
              dismissible: true
            })
          : notify({
              message: res.error ?? 'Something went wrong',
              severity: 'error',
              dismissible: true
            });
        break;
      }

      case SHIPMENT_DOCUMENTS_TYPE_MAP.order_item_related: {
        formData.append('order_item', currentDoc.order_item_id);
        const res = await handleApiCall(
          currentDoc?.is_uploaded as boolean,
          postId as string,
          SHIPMENT_DOCUMENTS_TYPE_MAP.order_item_related,
          formData
        );
        res.success
          ? notify({
              message: 'Document Uploaded Successfully',
              severity: 'success',
              dismissible: true
            })
          : notify({
              message: res.error ?? 'Something went wrong',
              severity: 'error',
              dismissible: true
            });
        break;
      }

      default:
        break;
    }
    setDocumentState((prevState) => ({
      ...prevState,
      modalState: false
    }));
  };

  const handleApiCall = async (
    isUploaded: boolean,
    postId: string,
    doc_type: 'order_related' | 'order_item_related',
    requestBody: any
  ) => {
    setDocumentState((prevState) => ({
      ...prevState,
      isLoading: !prevState.isLoading
    }));

    if (!isUploaded) {
      const res = await uploadOrderOtherDoc(orderId, requestBody);
      setDocumentState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
      getApiCall();
      return res;
    } else {
      const res = await updateOrderOtherDoc(orderId, requestBody, postId);
      setDocumentState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
      getApiCall();
      return res;
    }
  };

  const [documentColumn, documentData] = useMemo(() => {
    const column: Column<DocumentGrid>[] = [
      {
        Header: 'Document Name',
        accessor: 'name',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return (
            <DocumentRow.Title
              title={value?.doc_name}
              documentAvailable={value?.doc_link}
              required={value?.compulsory}
            />
          );
        }
      },
      {
        Header: 'Proofs',
        accessor: 'proof',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return (
            <>
              {value.uploadable && (
                <DocumentRow.Upload
                  documentAvailable={value?.doc_link}
                  onClick={handleUploadDocument(value)}
                  disabled={taskItem.is_completed}
                />
              )}
            </>
          );
        }
      },
      {
        Header: 'View',
        accessor: 'doc_object',
        Cell: (props: CellProps<DocumentGrid>) => {
          const { value } = props;
          return <DocumentRow.View document={value?.doc_link} title={''} />;
        }
      }
    ];

    const row: DocumentGrid[] = documents?.map((doc) => ({
      name: doc,
      proof: doc,
      doc_object: doc
    }));
    return [column, row];
  }, [props.documents]);

  return (
    <div className={css.documentTabWrapper}>
      <div className={css.documentWrapper}>
        {documents?.length > 0 && <DataGrid columns={documentColumn} data={documentData} />}
      </div>
      <FormProvider {...documentForm}>
        <UploadDocument
          open={modalState}
          editMode={editMode}
          onClose={toggleModalState}
          availableDocumentType={{
            label: currentDoc?.doc_name as string,
            value: currentDoc?.doc_key as string
          }}
          onFormSubmit={handleFormSubmit}
        />
      </FormProvider>
      <Loader open={isSubmitting || isLoading} />
    </div>
  );
};

export default DocumentsTab;
