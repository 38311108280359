import { useContext, useState, useEffect, useCallback } from 'react';
import css from './index.module.scss';
import { Loader, Typography } from '@components/base';
import { SideBar } from '@components/common';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { AppContext } from '@helpers/hooks/AppContext';
import { getOrderItemDetails } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { yupResolver } from '@hookform/resolvers/yup';
import BatchForm from './components/batch-form';
import { CLIENT_ROUTES } from '@router/routes';
import Preview from './preview';
import { addBatchNumber } from '@services/task.service';
import { useNavigate } from 'react-router-dom';
import { addBatchNumberSchema } from '@helpers/yup/order-tasks.schema';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';

const AddBatchNumber = () => {
  const [formState, setFormState] = useState<any>({
    activeStep: 0,
    isLoading: false,
    itemDetails: []
  });
  useBeforeUnloadAndNavigate();

  const { activeStep, isLoading, itemDetails } = formState;

  const navigate = useNavigate();

  const { appState } = useContext(AppContext);
  const { setPackageData } = appState;

  useEffect(() => {
    fetchItemDetails();
  }, []);

  const itemDetailForm = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(addBatchNumberSchema),
    defaultValues: {
      items: [
        {
          name: null,
          order_item_id: null,
          batch: [
            {
              batch_number: null
            }
          ]
        }
      ]
    }
  });

  const fetchItemDetails = async () => {
    setFormState((prevState: any) => ({
      ...prevState,
      isLoading: true
    }));
    const response = await getOrderItemDetails(setPackageData?.order_id);
    setFormState((prevState: any) => ({
      ...prevState,
      isLoading: false
    }));
    if (response?.success) {
      itemDetailForm.reset({
        items: response?.data?.results?.map((item: any) => ({
          name: item?.product_name,
          order_item_id: item?.order_item_id,
          batch: [
            {
              batch_number: null
            }
          ]
        }))
      });
    } else {
      notify({
        severity: 'error',
        message: response?.message ?? 'Something went wrong'
      });
    }
  };

  const handleNavigation = (currentStep: number) => {
    setFormState((prevState: any) => ({
      ...prevState,
      activeStep: currentStep
    }));
  };

  const handleSidebarClick = (value: number) => {
    handleNavigation(value);
  };

  const handleSidebarEnterKey = (value: number) => {
    handleNavigation(value);
  };

  const handleBackClick = (currentStep: number) => () => {
    handleNavigation(currentStep - 1);
  };

  const handleCancelClick = useCallback(() => {
    navigate(`/${CLIENT_ROUTES.order}/${setPackageData?.order_id}`);
  }, []);

  const itemDetailFormSubmit: SubmitHandler<any> = (data: any) => {
    setFormState((prevState: any) => ({
      ...prevState,
      itemDetails: data
    }));
    handleNavigation(1);
  };

  const batchFormSubmitHandler = async () => {
    const body = {
      order_items: itemDetails?.items?.map((item: any) => {
        return {
          order_item_id: item.order_item_id,
          batch: item.batch.map((b: any) => ({
            batch_number: b.batch_number
          }))
        };
      })
    };
    setFormState((prevState: any) => ({
      ...prevState,
      isLoading: true
    }));

    const response = await addBatchNumber(setPackageData.task_id, body);

    setFormState((prevState: any) => ({
      ...prevState,
      isLoading: false
    }));
    if (response?.success) {
      notify({
        severity: 'success',
        message: `Batch number added successfully`
      });
      navigate(`/${CLIENT_ROUTES.order}/${setPackageData.order_id}`, { replace: true });
    } else {
      notify({
        severity: 'error',
        message: response?.error ?? 'Something went wrong'
      });
    }
  };

  return (
    <main className={css.formWrapper}>
      <div className={css.titleWrapper}>
        <Typography variant="h2">Add Batch Number</Typography>
        <Typography variant="subheading1">0{activeStep + 1} of 02</Typography>
      </div>
      <div className={css.formContainer}>
        <div className={css.sideBarWrapper}>
          <SideBar
            activeStep={activeStep}
            onClick={handleSidebarClick}
            onEnter={handleSidebarEnterKey}>
            <SideBar.Item label="Batch Number" value={0} />
            <SideBar.Item label="Preview" value={1} disabled={activeStep! <= 1} />
          </SideBar>
        </div>
        {activeStep === 0 && (
          <FormProvider {...itemDetailForm}>
            <BatchForm onFormSubmit={itemDetailFormSubmit} onCancelClick={handleCancelClick} />
          </FormProvider>
        )}
        {activeStep === 1 && (
          <Preview
            data={formState}
            handleBackClick={handleNavigation}
            onSubmit={batchFormSubmitHandler}
            handleCancelClick={handleCancelClick}
            handleNavigation={handleNavigation}
          />
        )}
      </div>
      <Loader open={isLoading} />
    </main>
  );
};

export default AddBatchNumber;
