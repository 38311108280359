import css from './index.module.scss';
import { Typography } from '@components/base';
import POReview from './components/preview-tab';

const GeneratePurchaseOrderPrview = (props: any) => {
  const { onFormSubmit, onBackClick, cancelClick, isRectify, handleTabState } = props;

  return (
    <main className={css.purchaseOrderWrapper}>
      <div className={css.titleWrapper}>
        <Typography variant="h2">Review Purchase Order</Typography>
      </div>
      <div className={css.purchaseOrderContainer}>
        <POReview
          handleTabState={handleTabState}
          isRectify={isRectify}
          onFormSubmit={onFormSubmit}
          onCancelClick={cancelClick}
          onBackClick={onBackClick}
        />
      </div>
    </main>
  );
};
export default GeneratePurchaseOrderPrview;
