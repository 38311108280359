import React, { useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import Button from '@components/base/button';
import { DataGrid, DocumentRow, MultiplePlaceholder } from '@components/common';
import { CLIENT_ROUTES } from '@router/routes';
import { Chip, Loader, Typography } from '@components/base';
import { getEmailHistory } from '@services/email.service';
import notify from '@helpers/toastify-helper';
import { getFormattedDate, getFormattedTime } from '@helpers/utils';
import { EmailStatus } from '@helpers/constants';
import EmailModal from './components/emailModal';
import ListPlaceholder from '@components/common/list-placeholder';

const CommunicationTab = (props: any) => {
  const orderId = props.orderId;
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [emailModalData, setEmailModalData] = useState({});
  const [emailState, setEmailState] = useState({
    state: 1,
    results: [],
    isLoading: false
  });

  const { state, isLoading, results } = emailState;

  const openEmailTab = () => {
    window.open(`/email/compose/${orderId}/new`);
  };

  const getFilteredEmails = async (orderId: string) => {
    const query: number[] = [];
    if (state === 0) {
      query.push(2);
    } else if (state === 1) {
      query.push(1);
      query.push(4);
    } else {
      query.push(3);
      query.push(5);
    }
    setEmailState({ ...emailState, isLoading: true });
    try {
      const response: any = await getEmailHistory(orderId, query);
      if (response.success) {
        setEmailState({ ...emailState, results: response.data.results, isLoading: false });
      } else {
        setEmailState({ ...emailState, isLoading: false });
        notify({ message: 'Something went Wrong!', severity: 'error', dismissible: true });
      }
    } catch (error) {
      setEmailState({ ...emailState, isLoading: false });
      notify({ message: 'Something went Wrong!', severity: 'error', dismissible: true });
    }
  };

  useEffect(() => {
    getFilteredEmails(orderId);
  }, [state]);

  const [tobesentColoumn, tobesentData] = useMemo(() => {
    const emailList: any = results;
    const column = [
      {
        Header: 'Sender Details',
        accessor: 'sender_details',
        Cell: (props: any) => {
          const { value } = props;
          return (
            <div>
              <Typography>{value?.email_to[0]}</Typography>
              {value?.email_to.length > 1 && (
                <MultiplePlaceholder
                  placement="top"
                  label={`+ ${value?.email_to.length - 1}`}
                  names={value.email_to}
                />
              )}
            </div>
          );
        }
      },
      {
        Header: 'Subject',
        accessor: 'subject',
        Cell: (props: any) => {
          const { value } = props;
          return (
            <div>
              <Typography>{value?.subject}</Typography>
            </div>
          );
        }
      },
      {
        Header: '',
        accessor: 'viewButton',
        Cell: (props: any) => {
          const { value } = props;
          return (
            <div>
              <Button
                onClick={() =>
                  window.open(
                    `/email/compose/${orderId}/${value?.id}`,
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
                variant="text">
                Review and Send
              </Button>
            </div>
          );
        }
      }
    ];
    const row = emailList.map((email: any) => ({
      sender_details: email,
      subject: email,
      viewButton: email
    }));
    return [column, row];
  }, [emailState]);

  const [historyColoumn, historyData] = useMemo(() => {
    const emailList: any = results;
    const column = [
      {
        Header: 'Sender Details',
        accessor: 'sender_details',
        Cell: (props: any) => {
          const { value } = props;
          return (
            <div
              className={css.senderEmail}
              onClick={() => {
                setEmailModalVisible(true);
                setEmailModalData(value.email);
              }}>
              <Typography>{value?.email_to[0]}</Typography>
              {value?.email_to.length > 1 && (
                <MultiplePlaceholder
                  placement="top"
                  label={`+ ${value?.email_to.length - 1}`}
                  names={value.email_to}
                />
              )}
            </div>
          );
        }
      },
      {
        Header: 'Subject',
        accessor: 'subject',
        Cell: (props: any) => {
          const { value } = props;
          return (
            <div
              onClick={() => {
                setEmailModalVisible(true);
                setEmailModalData(value.email);
              }}>
              <Typography>{value?.subject}</Typography>
            </div>
          );
        }
      },
      {
        Header: 'Date and Time',
        accessor: 'dateAndTime',
        Cell: (props: any) => {
          const { value } = props;
          const emailTime = value?.email;
          return emailTime ? (
            <div
              onClick={() => {
                setEmailModalVisible(true);
                setEmailModalData(value.email);
              }}>
              <Typography>{getFormattedDate(emailTime?.sent_on)}</Typography>
              <Typography>{getFormattedTime(emailTime?.sent_on)}</Typography>
            </div>
          ) : (
            <div
              onClick={() => {
                setEmailModalVisible(true);
                setEmailModalData(value.email);
              }}>
              <Typography>{getFormattedDate(value?.created_at)}</Typography>
              <Typography>{getFormattedTime(value?.created_at)}</Typography>
            </div>
          );
        }
      }
    ];
    const row = emailList.map((email: any) => ({
      sender_details: email,
      subject: email,
      dateAndTime: email
    }));
    return [column, row];
  }, [emailState]);

  const [outboxColoumn, outboxData] = useMemo(() => {
    const emailList: any = results;
    const column = [
      {
        Header: 'Sender Details',
        accessor: 'sender_details',
        Cell: (props: any) => {
          const { value } = props;
          const statusNumber: number = value.status;
          const status = EmailStatus[statusNumber as keyof typeof EmailStatus];
          const color = status === 'Failed' ? 'red' : 'blue';
          return (
            <div
              className={css.senderEmail}
              onClick={() => {
                setEmailModalVisible(true);
                setEmailModalData(value);
              }}>
              <Typography>{value?.email_to[0]}</Typography>
              {value?.email_to.length > 1 && (
                <MultiplePlaceholder
                  placement="top"
                  label={`+ ${value?.email_to.length - 1}`}
                  names={value.email_to}
                />
              )}
              <Chip variant={`filled-${color}`} label={status} clickable={false} />
            </div>
          );
        }
      },
      {
        Header: 'Subject',
        accessor: 'subject',
        Cell: (props: any) => {
          const { value } = props;
          return (
            <div
              onClick={() => {
                setEmailModalVisible(true);
                setEmailModalData(value);
              }}>
              <Typography>{value?.subject}</Typography>
            </div>
          );
        }
      },
      {
        Header: 'Date and Time',
        accessor: 'dateAndTime',
        Cell: (props: any) => {
          const { value } = props;
          const emailTime = value?.email;
          return emailTime ? (
            <div
              onClick={() => {
                setEmailModalVisible(true);
                setEmailModalData(value);
              }}>
              <Typography>{getFormattedDate(emailTime?.sent_on)}</Typography>
              <Typography>{getFormattedTime(emailTime?.sent_on)}</Typography>
            </div>
          ) : (
            <div
              onClick={() => {
                setEmailModalVisible(true);
                setEmailModalData(value.email);
              }}>
              <Typography>{getFormattedDate(value?.created_at)}</Typography>
              <Typography>{getFormattedTime(value?.created_at)}</Typography>
            </div>
          );
        }
      }
    ];
    const row = emailList.map((email: any) => ({
      sender_details: email,
      status: email,
      subject: email,
      dateAndTime: email
    }));
    return [column, row];
  }, [emailState]);

  return (
    <div>
      <Typography variant="subheading1" className={css.note}>
        This feature is currently for INTERNAL TESTING purposes only. All emails will be redirected
        to <strong>product@elchemy.com</strong>.
      </Typography>

      <div className={css.headerWrapper}>
        <div className={css.emailChipWrapper}>
          <Chip
            label="History"
            variant={state === 0 ? `filled` : `outlined`}
            onClick={() => setEmailState((prevState) => ({ ...prevState, state: 0 }))}
          />
          <Chip
            label="To be sent"
            variant={state === 1 ? `filled` : `outlined`}
            onClick={() => setEmailState((prevState) => ({ ...prevState, state: 1 }))}
          />
          <Chip
            label="Outbox"
            variant={state === 2 ? `filled` : `outlined`}
            onClick={() => setEmailState((prevState) => ({ ...prevState, state: 2 }))}
          />
        </div>
        <Button variant="contained" onClick={openEmailTab} className={css.componeBtn}>
          Compose Email
        </Button>
      </div>
      {state === 0 &&
        (results?.length ? (
          <DataGrid tableStyle={{ width: '100%' }} columns={historyColoumn} data={historyData} />
        ) : (
          <ListPlaceholder
            title="No Email History"
            supportingText="No emails have been sent or received for this order."
            buttonText=""
            to={`${CLIENT_ROUTES.email}/compose/${orderId}/new`}
          />
        ))}
      {state === 1 &&
        (results?.length ? (
          <DataGrid tableStyle={{ width: '100%' }} columns={tobesentColoumn} data={tobesentData} />
        ) : (
          <ListPlaceholder
            title="No Email to be sent"
            supportingText="No emails are scheduled to be sent for this order."
            buttonText=""
            to={`${CLIENT_ROUTES.email}/compose/${orderId}/new`}
          />
        ))}
      {state === 2 &&
        (results?.length ? (
          <DataGrid tableStyle={{ width: '100%' }} columns={outboxColoumn} data={outboxData} />
        ) : (
          <ListPlaceholder
            title="No Email in Outbox"
            supportingText="No emails have been sent for this order."
            buttonText=""
            to={`${CLIENT_ROUTES.email}/compose/${orderId}/new`}
          />
        ))}
      {emailModalVisible && (
        <EmailModal
          open={emailModalVisible}
          onClose={() => setEmailModalVisible(false)}
          email={emailModalData}
        />
      )}
      <Loader open={isLoading} />
    </div>
  );
};

export default CommunicationTab;
