import { useState, useEffect, useMemo, useContext, ChangeEvent } from 'react';
import { getDocuments } from '@services/order.service';

import { Button, IconNode, ToolTip, Typography, Checkbox } from '@components/base';
import notify from '@helpers/toastify-helper';
import css from './index.module.scss';
import Images from '@assets/images';
import { DEFAULT_CROP_LIMIT } from '@helpers/constants';
import { CellProps, Column } from 'react-table';
import {
  cropValues,
  getDiffBetweenTwoDates,
  getFormattedDate,
  getFormattedTime,
  openDocumentSignedLink,
  sanitizeValue
} from '@helpers/utils';
import { DataGrid, ListPlaceholder } from '@components/common';
import ConfirmationModal from './confirmation-modal';
import { sendDocumentsToCustomerViaDoms } from '@services/customer.service';
import { AppContext } from '@helpers/hooks/AppContext';
import { useNavigate } from 'react-router-dom';
import { ICustomerPoc } from '@helpers/types/customer';

interface DocumentTabProps {
  orderId: string;
  customerPocs: ICustomerPoc;
  changeTab: (tab: number) => void;
}
interface DocumentTabState {
  docData: Array<IDocument>;
  isLoading: boolean;
  openConfirmationModal: boolean;
  documentsToBeSent: IDocument[];
  mode: ISelect | null;
  selectedDocs: IDocument[];
}

interface ISelect {
  value: number;
  label: string;
}

interface TableGrid {
  document_type_display_value: string;
  order_item_name: string;
  document: TableGrid;
  display_name: TableGrid;
  check_box: TableGrid;
}

interface IDocument {
  order_document_id: string;
  document_type_display_value: string;
  order_item_name: string;
  document: IDocument;
  display_name: IDocument;
  check_box: boolean;
  document_url: string;
  document_type: string;
  disclaimer_message_before_sharing: string;
}

const DocVisiblityView = (props: DocumentTabProps) => {
  const { orderId, customerPocs, changeTab } = props;

  const navigate = useNavigate();
  const { appDispatch } = useContext(AppContext);

  const [documentState, setDocumentState] = useState<DocumentTabState>({
    docData: [],
    isLoading: false,
    openConfirmationModal: false,
    documentsToBeSent: [],
    mode: null,
    selectedDocs: [] //used for checkbox
  });

  const { docData, isLoading, openConfirmationModal, documentsToBeSent, mode, selectedDocs } =
    documentState;

  useEffect(() => {
    getDocumentData();
  }, []);

  const getDocumentData = async () => {
    const documentData = await getDocuments(orderId, {
      customer_info: true
    });

    if (documentData?.success) {
      if (documentData.data?.results) {
        setDocumentState((prevState: DocumentTabState) => ({
          ...prevState,
          docData: documentData.data?.results
        }));
      }
    } else {
      notify({
        title: 'We have little problem',
        message: documentData?.error ?? 'There was an error fetching documents.',
        severity: 'error'
      });
    }
  };

  const confirmationModalHandler = (data: IDocument[]) => {
    setDocumentState((prevState: DocumentTabState) => ({
      ...prevState,
      openConfirmationModal: true,
      documentsToBeSent: data
    }));
  };

  const onChangeMode = (mode: ISelect) => {
    setDocumentState((prevState: DocumentTabState) => ({ ...prevState, mode: mode }));
  };

  const onConfirm = () => {
    switch (mode?.value) {
      case 0:
        redirectToComposeEmail();
        break;
      case 1:
        sendDocumentsToCustomer();
        break;
      default:
    }
  };

  const sendDocumentsToCustomer = async () => {
    const response = await sendDocumentsToCustomerViaDoms(orderId, {
      order_document_ids: documentsToBeSent.map((doc: IDocument) => doc.order_document_id)
    });

    if (response?.success) {
      notify({
        message: 'Documents sent successfully'
      });
      setDocumentState((prevState: DocumentTabState) => ({
        ...prevState,
        openConfirmationModal: false,
        mode: null,
        selectedDocs: []
      }));
      getDocumentData();
    } else {
      notify({
        title: 'Error',
        message: response?.error ?? 'There was an error sending documents',
        severity: 'error'
      });
    }
  };

  const redirectToComposeEmail = () => {
    appDispatch({
      type: 'setSendDocToCustomer',
      payload: {
        to: customerPocs?.email,
        documentsToBeSent: documentsToBeSent?.map((doc: IDocument) => {
          return {
            id: doc?.order_document_id,
            url: doc?.document_url,
            type: doc?.document_type,
            name: doc?.display_name,
            check_box: true
          };
        })
      }
    });
    navigate(`/email/compose/${orderId}/new`);
    setDocumentState((prevState: DocumentTabState) => ({
      ...prevState,
      openConfirmationModal: false
    }));
  };

  const [documentColumn, documentData] = useMemo(() => {
    const column: Column<TableGrid>[] = [
      {
        Header: '',
        accessor: 'check_box',
        Cell: (props: CellProps<TableGrid>) => {
          const { value } = props;
          const disableCheckbox = value?.is_inconsistent;
          return (
            <>
              {/* <Checkbox checked={value.check_box} onChange={(e) => selectUnselectDoc(e, value)} /> */}
              {!disableCheckbox ? (
                <Checkbox checked={value.check_box} onChange={(e) => selectUnselectDoc(e, value)} />
              ) : (
                <Checkbox checked={false} disabled />
              )}
            </>
          );
        }
      },
      {
        Header: 'Document Type',
        accessor: 'document_type_display_value',
        Cell: (props: CellProps<TableGrid>) => {
          const { value } = props;
          const isLatestDocVisible =
            !value?.is_inconsistent &&
            value?.customer_document_info &&
            getDiffBetweenTwoDates(
              value?.customer_document_info?.created_at as Date,
              value?.updated_at as Date,
              'seconds'
            ) > 0;

          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div className={css.docName}>{sanitizeValue(value?.document_type_display_value)}</div>
            </div>
          );
        }
      },
      {
        Header: 'Document Name',
        accessor: 'display_name',
        Cell: (props: CellProps<TableGrid>) => {
          const { value } = props;
          return (
            <div className={css.display_name}>
              <div className={css.docName}>{sanitizeValue(value.display_name)}</div>
            </div>
          );
        }
      },
      {
        Header: 'Product',
        accessor: 'order_item_name',
        Cell: (props: CellProps<TableGrid>) => {
          const { value } = props;
          return <div className={css.display_name}>{value}</div>;
        }
      },
      {
        Header: '',
        accessor: 'document',
        Cell: (props: CellProps<TableGrid>) => {
          const { value } = props;
          const isDocumentVisibleToCustomer =
            value?.customer_document_info && Object.keys(value?.customer_document_info).length > 0;
          const isLatestDocVisible =
            !value?.is_inconsistent &&
            isDocumentVisibleToCustomer &&
            getDiffBetweenTwoDates(
              value?.customer_document_info?.created_at as Date,
              value?.updated_at as Date,
              'seconds'
            ) > 0;

          if (value?.is_inconsistent) {
            return (
              <Typography variant="ptext" onClick={() => changeTab(0)} className={css.infoText2}>
                Document is inconsistent, <br /> please resolve it
              </Typography>
            );
          } else {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  position: 'relative'
                }}>
                {!isDocumentVisibleToCustomer ? (
                  <ToolTip
                    className={css.iconContainer}
                    title="Document is not visible to customer"
                    placement="left">
                    <div>
                      <IconNode
                        src="https://visualpharm.com/assets/569/Order%20History-595b40b75ba036ed117d8e0c.svg"
                        alt="pending send icon"
                        style={{ marginLeft: 3 }}
                      />
                    </div>
                  </ToolTip>
                ) : isLatestDocVisible ? (
                  <ToolTip
                    className={css.iconContainer}
                    title="Document is visible to customer"
                    placement="left">
                    <div>
                      <IconNode
                        src={Images.rightTickGreen}
                        alt="tick green icon"
                        style={{ marginLeft: 3 }}
                      />
                    </div>
                  </ToolTip>
                ) : (
                  <ToolTip
                    className={css.iconContainer}
                    title={`${
                      !value?.is_inconsistent
                        ? `Latest Document is not visible to customer`
                        : `Document is inconsistent, please resolve it`
                    }`}
                    placement="left">
                    <div>
                      <IconNode
                        src={Images.alertWarning}
                        alt="alert icon"
                        style={{ marginLeft: 3 }}
                      />
                    </div>
                  </ToolTip>
                )}
                {isDocumentVisibleToCustomer ? (
                  <ToolTip
                    className={css.iconContainer}
                    title={`Click to view customer copy. Shared on ${getFormattedDate(
                      value?.customer_document_info?.created_at
                    )} at ${getFormattedTime(value?.customer_document_info?.created_at)}`}
                    placement="left">
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        openDocumentSignedLink(
                          value.customer_document_info?.document_object ?? null
                        )
                      }>
                      <IconNode src={Images.userGroup} alt="customer icon" />
                    </div>
                  </ToolTip>
                ) : (
                  <div className={css.iconContainer}>
                    <IconNode src={Images.userGroupGrey} alt="customer icon" />
                  </div>
                )}

                <ToolTip
                  className={css.iconContainer}
                  title={`Click to view latest version of this document. Updated on ${getFormattedDate(
                    value?.updated_at
                  )} at ${getFormattedTime(value?.updated_at)}`}
                  placement="left">
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => openDocumentSignedLink(value?.document ?? null)}>
                    <IconNode src={Images.eyeRed} alt="system generated icon" />
                  </div>
                </ToolTip>

                <Button
                  variant="outlined-secondary"
                  onClick={() => confirmationModalHandler([value])}>
                  {isDocumentVisibleToCustomer ? 'Share' : 'Share'}
                </Button>
              </div>
            );
          }
        }
      }
    ];
    const row: TableGrid[] = docData.map((doc: any) => ({
      document_type_display_value: doc,
      order_item_name: doc.order_item_name ?? '-',
      document: doc,
      display_name: doc,
      check_box: doc
    }));
    return [column, row];
  }, [docData]);

  const selectUnselectDoc = (e: ChangeEvent<HTMLInputElement>, doc: IDocument) => {
    const copyOfOldSelectedDocs = selectedDocs;
    const copyOfDocData = docData;
    if (e.target.checked) {
      copyOfOldSelectedDocs.push(doc);
      copyOfDocData.forEach((item: IDocument) => {
        if (copyOfOldSelectedDocs.includes(item)) {
          item.check_box = true;
        }
      });
    } else {
      const index = copyOfOldSelectedDocs.indexOf(doc);
      if (index > -1) {
        copyOfOldSelectedDocs.splice(index, 1);
        copyOfDocData.forEach((item: IDocument) => {
          if (!copyOfOldSelectedDocs.includes(item)) {
            item.check_box = false;
          }
        });
      }
    }

    setDocumentState((prevState: DocumentTabState) => ({
      ...prevState,
      selectedDocs: copyOfOldSelectedDocs,
      docData: copyOfDocData
    }));
  };

  return (
    <>
      <Typography variant="ptext" className={css.infoText}>
        This Tab will show all the documents that can be sent to customer. You can select the
        documents and send them the latest version
      </Typography>
      <div className={css.tableHeader}>
        {selectedDocs?.length ? (
          <Button
            variant="text"
            disabled={isLoading}
            onClick={() => confirmationModalHandler(selectedDocs)}>
            {isLoading
              ? `Sharing Documents with Customer`
              : `Share ${
                  selectedDocs?.length
                    ? `${selectedDocs?.length} ${
                        selectedDocs?.length === 1 ? 'document' : 'documents'
                      }`
                    : `All`
                } with Customer`}
          </Button>
        ) : (
          <></>
        )}
      </div>
      {docData?.length ? (
        <DataGrid columns={documentColumn} data={documentData} />
      ) : (
        <ListPlaceholder
          title="No documents available to share with customer"
          supportingText="Any Documents that can be shared with customer will be displayed here once generated"
          buttonText=""
          to={''}
        />
      )}

      {openConfirmationModal && (
        <ConfirmationModal
          open={openConfirmationModal}
          onConfirm={onConfirm}
          onChangeMode={onChangeMode}
          disclaimerMessages={documentsToBeSent
            ?.filter((doc: IDocument) => doc.disclaimer_message_before_sharing)
            .map((doc: IDocument) => doc?.disclaimer_message_before_sharing)}
          mode={mode}
          onClose={() =>
            setDocumentState((prevState: DocumentTabState) => ({
              ...prevState,
              openConfirmationModal: false
            }))
          }
        />
      )}
    </>
  );
};

export default DocVisiblityView;
