import { Divider, IconNode, Typography } from '@components/base';
import css from '../index.module.scss';
import Images from '@assets/images';
import { useContext } from 'react';
import OrderContext from '@pages/order/generate-purchase-order/OrderContext';
import { useParams } from 'react-router-dom';

const Parties = () => {
  const { editRed } = Images;
  const orderInfoContext = useContext(OrderContext);
  const urlParams = useParams();
  const { supplierId } = urlParams;
  const {
    orderInfo: { elchemy_entity, suppliers },
    supplierData,
    poData
  } = orderInfoContext;
  const filteredSuppliers = suppliers?.filter(
    (item: any) => item?.supplier_id == String(supplierId)
  )[0];
  const taskData = orderInfoContext?.tasksList?.filter(
    (item: any) => item?.supplier_id === supplierId
  )[0];

  return (
    <div className={css.boxWrapper}>
      <div className={css.partiesContainer}>
        <div className={css.fieldWrapper}>
          <div className={css.partyContainer}>
            <div className={css.fieldTitle}>
              <Typography variant="pdoc">Purchaser</Typography>
            </div>
            <div className={css.wrapper}>
              <Typography variant="h3">{elchemy_entity.name}</Typography>
              <Typography variant="span">{elchemy_entity.address}</Typography>
              <Typography variant="span">GST Number:{elchemy_entity.gst_number ?? 'Not Available'}</Typography>
            </div>
          </div>
          <Divider className={css.divider} />
          <div className={css.partyContainer}>
            <div className={css.fieldTitle}>
              <Typography variant="pdoc">Supplier</Typography>
            </div>
            <div className={css.wrapper}>
              <Typography variant="h3">{supplierData?.supplier_name}</Typography>
              <Typography variant="span">
                {poData.supplier_factory_address.readable_address ??
                  supplierData?.addresses[0].readable_address}
              </Typography>
              <div>
                <Typography variant="span">{`GST Number - ${supplierData?.gst_number}`}</Typography>
              </div>
              <div>
                <Typography variant="span">{`POC - ${poData.supplier_poc?.given_name} ${poData.supplier_poc?.family_name} - ${poData.supplier_poc?.email}`}</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parties;
