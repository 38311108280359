import { Typography } from '@components/base';
import css from './index.module.scss';
import { booleanSelectFields } from '@helpers/constants';
import { IFilteredValue, IObject, IPackagingDetails } from '../../../orderOverview';
import { chunkArray } from '@helpers/utils';

interface IPackageDetails {
  filteredValue?: IFilteredValue;
  packagingDetailsFields: IObject[];
}

const PackagingDetails = (props: IPackageDetails) => {
  const { filteredValue, packagingDetailsFields } = props;

  return (
    <div className={css.detailWrapper}>
      <div className={css.detailsTitle}>
        <Typography variant="h4">Packaging Details</Typography>
      </div>
      <div className={css.detailContainer}>
        {chunkArray(packagingDetailsFields, 2).map((chunk: any[], index: number) => (
          <div className={css.rowWrapper} key={index}>
            {chunk.map((field: any, subIndex: number) => (
              <div
                className={`${css.fieldWrapper} ${
                  filteredValue && filteredValue.key === field.key ? css.filtered : ''
                }`}
                key={subIndex}>
                <Typography
                  variant="label"
                  className={`${css.fieldLabel} ${
                    filteredValue && filteredValue.key === field.key ? css.filtered : ''
                  }`}>
                  {field.label}
                </Typography>
                <div className={css.fieldValueContainer}>
                  {field.value ? (
                    <Typography
                      variant="body"
                      className={`${
                        filteredValue && filteredValue.key === field.key ? css.filtered : ''
                      }`}>
                      {field.value}
                    </Typography>
                  ) : (
                    <Typography
                      variant="label"
                      className={`${css.notAvailable} ${
                        filteredValue && filteredValue.key === field.key ? css.filtered : ''
                      }`}>
                      Not Available
                    </Typography>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PackagingDetails;
