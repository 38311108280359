import { useState, useCallback, SyntheticEvent, useEffect, useMemo } from 'react';
import css from './index.module.scss';
import { BreadCrumb, Loader, Tab, Tabs } from '@components/base';
import { Seo } from '@components/common';
import { ProductInfo } from '@helpers/types/product';
import { CLIENT_ROUTES } from '@router/routes';
import { useNavigate, useParams } from 'react-router-dom';
import ProductDetailCard from './components/product-detail-card';
import DetailsTab from './components/details-tab';
import DocumentsTab from './components/documents-tab';
import { fetchProductInfo } from '@services/product.service';
import SupplierTab from './components/supplier-tab';
import AccessWrapper from '@authorization/access-wrapper';
import { MODULE_NAMES } from '@helpers/constants';

interface ProductIndividualStates {
  tabState: number;
  productInfo: ProductInfo | null;
  isLoading: boolean;
  editable: boolean;
}
const ProductIndividual = (props: any) => {
  const { modulePermissions, otherModulePermissions } = props;
  const { actions = {} } = modulePermissions;
  const supplierActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.supplier)?.actions,
    [otherModulePermissions]
  );
  const [productState, setProductState] = useState<ProductIndividualStates>({
    tabState: 0,
    productInfo: null,
    isLoading: false,
    editable: false
  });

  const { tabState, productInfo, isLoading, editable } = productState;
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getProductInfo();
  }, []);

  const getProductInfo = async () => {
    const { id } = params;
    if (id) {
      setProductState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await fetchProductInfo(id);
      setProductState((prevState) => ({ ...prevState, isLoading: false }));
      if (response.success) {
        const { data } = response;
        setProductState((prevState) => ({ ...prevState, productInfo: data }));
      } else {
        navigate('/404');
      }
    } else {
      navigate(`/${CLIENT_ROUTES.product}`);
    }
  };

  const handleTabChange = useCallback((newValue: number) => {
    setProductState((prevState) => ({ ...prevState, tabState: newValue }));
  }, []);

  const handleProductDetailChange = (param: ProductInfo) => {
    setProductState((prevState) => ({ ...prevState, productInfo: { ...param } }));
  };

  const handleEditClick = () => {
    setProductState((prevState) => ({ ...prevState, tabState: 0, editable: !prevState.editable }));
  };

  return (
    <AccessWrapper show={actions?.read} showUnauthorised>
      <main className={css.productWrapper}>
        {productInfo && <Seo title={productInfo.name} />}
        {productInfo && (
          <BreadCrumb>
            <BreadCrumb.Item value={0} to={`/${CLIENT_ROUTES.product}`}>
              Product List
            </BreadCrumb.Item>
            <BreadCrumb.Item value={1} to={`/${CLIENT_ROUTES.product}/${params.id}`}>
              {productInfo.name}
            </BreadCrumb.Item>
          </BreadCrumb>
        )}
        {productInfo && (
          <ProductDetailCard
            productName={productInfo.name}
            CASNumber={productInfo.CAS_number}
            onEditClick={handleEditClick}
            actions={actions}
          />
        )}
        {productInfo && (
          <Tabs value={tabState}>
            <Tab label="Details" value={0} onClick={() => handleTabChange(0)} />
            <Tab label="Documents" value={1} onClick={() => handleTabChange(1)} />
            <AccessWrapper show={supplierActions?.read}>
              <Tab label="Suppliers" value={2} onClick={() => handleTabChange(2)} />
            </AccessWrapper>
          </Tabs>
        )}
        {tabState === 0 && productInfo && (
          <DetailsTab
            productInfo={productInfo}
            enableEdit={editable}
            onChange={handleProductDetailChange}
            actions={actions}
          />
        )}
        {tabState === 1 && productInfo && (
          <DocumentsTab productInfo={productInfo} onChange={handleProductDetailChange} />
        )}
        {tabState === 2 && productInfo && <SupplierTab productInfo={productInfo} />}
        <Loader open={isLoading} />
      </main>
    </AccessWrapper>
  );
};

export default ProductIndividual;
