import { CUSTOMER_SERVER_CONFIG, SERVER_CONFIG, USER_TYPES } from '@helpers/constants';
import httpClient, { httpClient2 } from './index.service';
import { getPermissionFromLocalStorage } from '@helpers/utils';

export interface ListParam {
  page?: number;
  supplier_name?: string;
  supplier_name_list?: string;
  product_list?: string;
  supplier_type_list?: string;
  business_type_list?: string;
  created_at_list?: string;
  country?: string;
  kwargs?: string;
}

export async function fetchEntityList() {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.listEntity}`);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function fetchEntityBankList(id: string) {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.listEntityBankInfo}${id}/`);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function createOrder(requestBody: any) {
  try {
    const response = await httpClient.post(`${SERVER_CONFIG.createOrder}`, requestBody);
    return response;
  } catch (error: any) {
    return error;
  }
}

// export async function fetchAllOrder(
//   page?: number,
//   searchText?: string,
//   customer_id?: string,
//   supplier_id?: string,
//   state?: string | null
// ) {
//   try {
//     const response = await httpClient.get(`${SERVER_CONFIG.listOrder}`, {
//       params: {
//         page,
//         kwargs: searchText,
//         customer_id,
//         supplier_id,
//         order_state_category: state
//       }
//     });
//     return response;
//   } catch (error: any) {
//     return error;
//   }
// }

export async function fetchAllOrder(
  page?: number,
  searchText?: string,
  customer_id?: string,
  supplier_id?: string,
  state?: string | null,
  param?: ListParam,
  reqBody?: any,
  authToken = null
) {
  //const {supplier_name_list, product_list} = reqBody ?? null;
  const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};

  const userType = getPermissionFromLocalStorage()?.user_type;

  const URL =
    userType === USER_TYPES.customer
      ? `${CUSTOMER_SERVER_CONFIG.main}/${CUSTOMER_SERVER_CONFIG.listOrder}/`
      : SERVER_CONFIG.listOrder;

  try {
    const response = await httpClient.get(URL, {
      headers: headers,
      params: {
        page,
        search_text: searchText,
        customer_id,
        supplier_id,
        order_state: state,
        param,
        supplier_name_list: reqBody?.supplier_name_list,
        product_list: reqBody?.product_list,
        customer_name_list: reqBody?.customer_name_list,
        users_list: reqBody?.users_list,
        order_created_start_date: reqBody?.order_created_start_date,
        order_created_end_date: reqBody?.order_created_end_date,
        bl_or_awb_start_date: reqBody?.bl_or_awb_start_date,
        bl_or_awb_end_date: reqBody?.bl_or_awb_end_date
      }
    });
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function fetchOrderInfo(id: string) {
  const userType = getPermissionFromLocalStorage()?.user_type;
  const URL =
    userType === USER_TYPES.customer
      ? `${CUSTOMER_SERVER_CONFIG.main}/${CUSTOMER_SERVER_CONFIG.order}/${id}/overview/`
      : `${SERVER_CONFIG.fetchOrderInfo}${id}/`;
  try {
    const response = await httpClient.get(URL);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function postPerformaInvoice(taskId: string, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `order/${taskId}/generate-proforma-invoice/`,
      requestBody
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function rectifyPerformaInvoice(taskId: string, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.rectifyPi}`,
      requestBody
    );
    return response;
  } catch (error) {
    return error;
  }
}

export async function fetchOrderInfoFinance(id: string) {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.fetchOrderInfoFinance}${id}`);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function updateOrderInfo(id: string, requestBody: any) {
  try {
    const response = await httpClient.patch(`${SERVER_CONFIG.updateOrderInfo}${id}/`, requestBody);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function addOrderPaymentDetail(id: string, requestBody: any) {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.uploadPaymentProof}`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}
export async function addCRLDetails(task_id: any, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${task_id}/${SERVER_CONFIG.createCRLDetails}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function approveOrder(id: string) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.approveOrder}/`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function addCI(taskId: string, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.createCI}`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function rejectOrder(id: string, reqBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.rejectOrder}/`,
      reqBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function assignPersonnel(requestBody: any, taskId: string) {
  try {
    const response = await httpClient.patch(
      `order/${taskId}/${SERVER_CONFIG.assignPersonnel}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function addRodtepDrawback(taskId: string, body: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/add-rodtep-drawback/`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function updateGateInTask(taskId: string, body: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/update-gate-in/`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function updateMaterialLifting(taskId: string, supplier_id: string, body: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.materialLiftingConfirmation}/${supplier_id}/`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function fetchReviewById(id: string) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.getReviewById}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function approvePerformaReview(taskId: string, body: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.approvePi}`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function updateShipmentCLearanceTask(taskId: string, body: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/update-shipment-clearance/`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function rejectPerformaReview(taskId: string, body: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.rejectPi}`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function rectifyOrder(id: string, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.rectifyOrder}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getPostDocumentsById(id: string) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.postShipment}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getPreDocumentsById(id: string) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.preShipment}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getExportShipmentDocsById(id: string) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.exportClearance}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function uploadShipmentDocuments(
  id: string,
  uploadType: 'order_related' | 'order_item_related',
  requestBody: any
) {
  const UPLOAD_TYPES = {
    order_related: SERVER_CONFIG.orderDocument,
    order_item_related: SERVER_CONFIG.orderItemDocument
  };
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.order}/${id}/${UPLOAD_TYPES?.[uploadType]}`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function updateShipmentDocuments(
  id: string,
  uploadType: 'order_related' | 'order_item_related',
  requestBody: any
) {
  const UPLOAD_TYPES = {
    order_related: SERVER_CONFIG.orderDocument,
    order_item_related: SERVER_CONFIG.orderItemDocument
  };
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${UPLOAD_TYPES?.[uploadType]}${id}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function updatePreShipment(id: string) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.preShipment}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function updatePostShipment(id: string) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.postShipment}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function updateExportClearance(id: string) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.exportClearance}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getCRLDetails(id: string) {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.crl}`);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function createPurchaseOrder(task_id: any, supplier_id: any, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.getPO}/${task_id}/generate-purchase-order/${supplier_id}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function fetchPOReviewById(order_id: string, supplierId: string) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${order_id}/${supplierId}/${SERVER_CONFIG.getPOReview}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function fetchPOReviewByIdWithFormattedAmount(
  order_id: string,
  supplierId: string,
  isFormmatted: number
) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${order_id}/${supplierId}/${SERVER_CONFIG.getPOReview}?is_formatted=${isFormmatted}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function approvePurchaseReview(taskId: string, supplierId: string, body: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.approvePo}/${supplierId}/`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function rectifyPurchaseOrder(taskId: string, supplierId: string, body: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.rectifyPo}/${supplierId}/`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function rejectPurchaseOrder(taskId: string, supplierId: string, body: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.rejectPo}/${supplierId}/`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getDocuments(order_id: string, filters?: any) {
  const query: string[] = [];
  if (filters) {
    if (filters?.tags?.length) {
      filters.tags.forEach((tag: any) => {
        query.push(`internal_tags=${tag?.value}`);
      });
    }
    if (filters?.supplier?.length) {
      filters.supplier.forEach((supplier: any) => {
        query.push(`supplier_id=${supplier?.value}`);
      });
    }
    if (filters?.order_item?.length) {
      filters.order_item.forEach((order_item: any) => {
        query.push(`order_item_id=${order_item?.value}`);
      });
    }
    if (filters?.customer_shared_docs_only) {
      query.push(`customer_shared_docs_only=${filters?.customer_shared_docs_only}`);
    }
    if (filters?.customer_info) {
      query.push(`customer_info=${filters?.customer_info}`);
    }
  }
  const userType = getPermissionFromLocalStorage()?.user_type;
  const URL =
    userType === USER_TYPES.customer
      ? `${CUSTOMER_SERVER_CONFIG.main}/${CUSTOMER_SERVER_CONFIG.order}/${order_id}/${CUSTOMER_SERVER_CONFIG.documents}/`
      : `${SERVER_CONFIG.order}/${order_id}/${SERVER_CONFIG.orderDocumentList}?${
          query?.length ? query.join('&') : ''
        }`;
  try {
    const response = await httpClient.get(URL);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getAllOrderDocZip(id: string, documentIds: Array<string>) {
  try {
    const response = await httpClient2.get(
      `order/${id}/${SERVER_CONFIG.getOrderDocZip}?document_ids=${documentIds.join(',')}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getAllOrderPhotos(id: string, page: number) {
  const userType = getPermissionFromLocalStorage()?.user_type;
  const URL =
    userType === USER_TYPES.customer
      ? `${CUSTOMER_SERVER_CONFIG.main}/${CUSTOMER_SERVER_CONFIG.order}/${id}/${CUSTOMER_SERVER_CONFIG.getOrderImages}/`
      : `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.getOrderImages}`;
  try {
    const response = await httpClient.get(URL, {
      params: {
        ...(page && { page: page })
      }
    });
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function uploadOrderPhotos(orderId: string, body: any) {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.order}/${orderId}/${SERVER_CONFIG.getOrderImages}`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getAddDocFilterOptions(id: string) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.formFilterOptions.orderDocUpload}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function uploadOrderOtherDoc(orderId: string, body: any) {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.order}/${orderId}/${SERVER_CONFIG.addOrderOtherDoc}/upload/v1/`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getOtherDocuments(order_id: string) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${order_id}/${SERVER_CONFIG.getOrderOtherDoc}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getPendingTasks(orderId: string) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${orderId}/${SERVER_CONFIG.getPendingTasks}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getShipmentTrackingDetails(orderId: string) {
  const userType = getPermissionFromLocalStorage()?.user_type;
  const URL =
    userType === USER_TYPES.customer
      ? `${CUSTOMER_SERVER_CONFIG.main}/${CUSTOMER_SERVER_CONFIG.order}/${orderId}/${CUSTOMER_SERVER_CONFIG.shipmentTracking}/`
      : `${SERVER_CONFIG.shipmentTracking}/${SERVER_CONFIG.order}/${orderId}/`;
  try {
    const response = await httpClient.get(URL);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function generateCommercialInvoice(taskId: string, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${taskId}/${SERVER_CONFIG.generateCommercialInvoice}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getPaymentTermsFilterOptions() {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.formFilterOptions.paymentTerms}`);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getPackagingListFilterOptions() {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${SERVER_CONFIG.formFilterOptions.packaging}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getOrderItemDetails(orderId: string, params?: any) {
  const paramsList: any = [];
  if (params && Object.keys(params).length) {
    Object.keys(params).forEach((key) => {
      if (key && params[key]) {
        paramsList.push(`${key}=${params[key]}`);
      }
    });
  }
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${orderId}/${SERVER_CONFIG.orderItems}/?${paramsList.join('&')}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function updateOrderOtherDoc(orderId: string, body: any, docId: string) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${orderId}/${SERVER_CONFIG.addOrderOtherDoc}/${docId}/v1/`,
      body
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function createShippingInstructions(task_id: any, requestBody: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${task_id}/${SERVER_CONFIG.generateShippingInstruction}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function deleteOrderOtherDoc(orderId: string, docId: string) {
  try {
    const response = await httpClient.delete(
      `${SERVER_CONFIG.order}/${orderId}/${SERVER_CONFIG.addOrderOtherDoc}/${docId}/v1/`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function addTaskEstimate(id: string, requestBody?: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.addTaskEstimates}`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function updateTaskEstimate(id: string, requestBody?: any) {
  try {
    const response = await httpClient.patch(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.updateTaskEstimates}`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function orderPendingActions(id: string) {
  try {
    const response = await httpClient.get(
      `${SERVER_CONFIG.order}/${id}/${SERVER_CONFIG.pendingActionsInOrder}`
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getPaymentTermSuffixFilterOptions() {
  try {
    const response = await httpClient.get(`${SERVER_CONFIG.formFilterOptions.paymentTermsSuffix}`);
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function resolveInconsistency(orderId: string, documentId: string, requestBody: any) {
  try {
    const response = await httpClient.post(
      `${SERVER_CONFIG.order}/${orderId}/${SERVER_CONFIG.addOrderOtherDoc}/${documentId}/${SERVER_CONFIG.resolveInconsistency}/`,
      requestBody
    );
    return response;
  } catch (error: any) {
    return error;
  }
}

export async function getPocs(orderId: string) {
  const userType = getPermissionFromLocalStorage()?.user_type;
  const URL =
    userType === USER_TYPES.customer
      ? `${CUSTOMER_SERVER_CONFIG.main}/${CUSTOMER_SERVER_CONFIG.order}/${orderId}/${CUSTOMER_SERVER_CONFIG.getOrderPoc}/`
      : `${SERVER_CONFIG.order}/${orderId}/${SERVER_CONFIG.getOrderPoc}`;
  try {
    const response = await httpClient.get(URL);
    return response;
  } catch (error: any) {
    return error;
  }
}
