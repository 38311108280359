import Images from '@assets/images';
import { Accordion, Divider, Loader, Typography } from '@components/base';
import Button from '@components/base/button';
import { Avatar, InputDatePicker } from '@components/common';
import notify from '@helpers/toastify-helper';
import { getFormattedDate } from '@helpers/utils';
import { postLiftingDate } from '@services/task.service';
import { format, endOfDay } from 'date-fns';
import moment from 'moment';
import { useState } from 'react';
import css from './index.module.scss';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import Footer from '../components/footer';

interface LiftingCardProps {
  taskData: Array<Partial<ITaskTabProps>>;
  onComplete?: () => void;
}

const LiftingDatesCard = (props: LiftingCardProps) => {
  let uploadText: string;
  const { taskData, onComplete } = props;
  const [liftingDate, setLiftingDate] = useState<any>();
  const getTaskStatus = taskData[0]?.is_completed;
  const [isCompleted, setIsCompleted] = useState<boolean | undefined>(getTaskStatus);
  const [loading, setLoading] = useState(false);

  if (getTaskStatus) uploadText = 'Re-upload';
  else uploadText = 'Upload';

  const handleSaveClick = async () => {
    if (!liftingDate) {
      notify({ message: 'Please enter a valid date', severity: 'error', dismissible: true });
      return;
    }
    const formattedDate = format(liftingDate, 'yyyy-MM-dd');

    const { task_id, supplier_id } = taskData[0];

    const postData = {
      material_lifting_date: formattedDate
    };

    setLoading(true);
    const response = await postLiftingDate(task_id as string, supplier_id as string, postData);
    setLoading(false);

    if (response.success) {
      notify({
        message: 'Lifting Date added successfully',
        severity: 'success',
        dismissible: true
      });
      setIsCompleted(true);
      if (onComplete) {
        onComplete();
      }
    } else {
      notify({
        message: response.error ?? 'Error adding Lifting Date',
        severity: 'error',
        dismissible: true
      });
    }

    return response;
  };

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {!getTaskStatus && isCompleted === false && (
                <Avatar src={Images.illustrationEdit} alt="" />
              )}
              {(getTaskStatus || isCompleted) && <Avatar src={Images.alertSuccessFaint} alt="" />}
              <Typography variant="h5">
                #{taskData[0]?.readable_task_id} - {taskData[0].task_name} (
                {taskData[0]?.supplier_name})
              </Typography>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content className={css.accordianContent}>
          <div className={css.dateTimeWrapper}>
            <div className={css.dateWrapper}>
              {getTaskStatus || isCompleted ? (
                <>
                  <Typography variant="subheading1" className={css.datePicker}>
                    Selected date
                  </Typography>
                  <Typography>
                    {getFormattedDate(taskData[0].material_lifting_date as string)}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="subheading1" className={css.datePicker}>
                    Select a date
                  </Typography>
                  <InputDatePicker
                    value={taskData[0].material_lifting_date ?? liftingDate}
                    selected={liftingDate}
                    onSelect={(day: Date | undefined) => setLiftingDate(day)}
                    rootClassName={css.fieldSpacing}
                  />
                </>
              )}
            </div>
          </div>
          <div className={css.rightButtonWrapper}>
            {getTaskStatus && <Divider className={css.divider} />}
            {!getTaskStatus && (
              <Button
                onClick={handleSaveClick}
                variant="outlined-secondary"
                disabled={getTaskStatus || isCompleted}
                className={css.saveButton}>
                Save & Update Ops
              </Button>
            )}
          </div>
        </Accordion.Content>
      </Accordion>
      {/* <div className={css.footer}>
        <div className={css.textWrapper}>
          <Typography variant="h5">Created On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.created_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Updated On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.updated_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Time Taken:</Typography>
          <Typography variant="p">
            {moment(taskData[0]?.updated_at)?.diff(taskData[0]?.created_at, 'days')} Day(s)
          </Typography>
        </div>
      </div> */}
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />

      <Loader open={loading} />
    </div>
  );
};

export default LiftingDatesCard;
