import { BreadCrumb, Loader, Tab, Tabs } from '@components/base';
import { LinkButton, Seo } from '@components/common';
import { OrderInfo } from '@helpers/types/order';
import { CLIENT_ROUTES } from '@router/routes';
import { fetchOrderInfo, orderPendingActions } from '@services/order.service';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ActivityLogTab from './components/activity-log-tab/components/assignment-card';
import OrderCard from './components/order-card';
import OrderOverview from './components/order-overview';
import OrderPeopleTab from './components/people-tab';
import TaskTab from './components/task-tab';
import DocumentTab from './components/document-tab';
import StuffingPhotos from './components/stuffing-photos';
import Tracking from './components/tracking';
import CommunicationTab from './components/communication-tab';
import css from './index.module.scss';
import AccessWrapper from 'src/authorization/access-wrapper';
import { EVENT_TRACKING_TYPES, MODULE_NAMES, USER_TYPES } from '@helpers/constants';
import Chip from '@components/base/chip';
import {
  executeFunctionByCondition,
  getPermissionFromLocalStorage,
  pushEventTracking
} from '@helpers/utils';

interface OrderIndiviualState {
  orderInfo: OrderInfo | null;
  activeTab: number;
  isLoading: boolean;
  pendingActions: any;
  activeSubTab?: number; //This is passed inside the tab component to show the specific subtab
}

const ORDER_TABS = [
  {
    label: EVENT_TRACKING_TYPES.ORDER_OVERVIEW_TAB_SELECT,
    value: 0
  },
  {
    label: EVENT_TRACKING_TYPES.ORDER_DOCUMENTS_TAB_SELECT,
    value: 1
  },
  {
    label: EVENT_TRACKING_TYPES.ORDER_TRACKING_TAB_SELECT,
    value: 2
  },
  {
    label: EVENT_TRACKING_TYPES.ORDER_STUFFING_PHOTOS_TAB_SELECT,
    value: 3
  },
  {
    label: EVENT_TRACKING_TYPES.ORDER_PEOPLE_TAB_SELECT,
    value: 4
  },
  {
    label: EVENT_TRACKING_TYPES.ORDER_PROGRESS_TAB_SELECT,
    value: 5
  }
];

const OrderIndividual = (props: any) => {
  const { modulePermissions, otherModulePermissions } = props;
  const { actions } = modulePermissions;

  const [queryParams, setQueryParams] = useSearchParams();

  const orderActivityActions = useMemo(
    () =>
      otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.orderActivity)?.actions,
    [otherModulePermissions]
  );
  const orderTrackingActions = useMemo(
    () =>
      otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.orderTracking)?.actions,
    [otherModulePermissions]
  );
  const orderDocumentsActions = useMemo(
    () =>
      otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.orderDocuments)?.actions,
    [otherModulePermissions]
  );
  const orderPeopleActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.orderPeople)?.actions,
    [otherModulePermissions]
  );
  const orderOverviewActions = useMemo(
    () =>
      otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.orderOverview)?.actions,
    [otherModulePermissions]
  );
  const orderStuffingPhotosActions = useMemo(
    () =>
      otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.orderStuffingPhotos)
        ?.actions,
    [otherModulePermissions]
  );
  const orderTasksActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.task)?.actions,
    [otherModulePermissions]
  );

  const emailActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.email)?.actions,
    [otherModulePermissions]
  );

  const userType = useMemo(() => getPermissionFromLocalStorage()?.user_type, []);

  const [orderState, setOrderState] = useState<OrderIndiviualState>({
    orderInfo: null,
    activeTab: userType === USER_TYPES.customer ? 0 : 6,
    isLoading: false,
    pendingActions: null,
    activeSubTab: 0
  });

  const { orderInfo, activeTab, isLoading, pendingActions, activeSubTab } = orderState;

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getOrderInfo();
    executeFunctionByCondition(orderTasksActions || emailActions, getPendingActions);
    const activeTabQuery = queryParams?.get('activeTab');
    const activeSubTab = queryParams?.get('activeSubTab');
    if (
      activeTabQuery &&
      parseInt(activeTabQuery as string) >= 0 &&
      activeSubTab &&
      parseInt(activeSubTab as string) >= 0
    ) {
      setOrderState((prevState) => ({
        ...prevState,
        activeTab: parseInt(activeTabQuery as string),
        activeSubTab: parseInt(activeSubTab as string)
      }));
    } else if (activeSubTab && parseInt(activeSubTab as string) >= 0) {
      setOrderState((prevState) => ({
        ...prevState,
        activeSubTab: parseInt(activeTabQuery as string),
        activeTab: userType === USER_TYPES.customer ? 0 : 6
      }));
    } else if (
      (activeTabQuery && parseInt(activeTabQuery as string) < 0) ||
      parseInt(activeTabQuery as string) > 7
    ) {
      setOrderState((prevState) => ({
        ...prevState,
        activeTab: userType === USER_TYPES.customer ? 0 : 6
      }));
    } else if (activeTabQuery && parseInt(activeTabQuery as string) >= 0) {
      setOrderState((prevState) => ({
        ...prevState,
        activeTab: parseInt(activeTabQuery as string)
      }));
    }
  }, []);

  const getOrderInfo = async () => {
    const { id } = params;
    if (id) {
      setOrderState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await fetchOrderInfo(id);
      if (response.success) {
        const { data } = response;
        setOrderState((prevState) => ({ ...prevState, orderInfo: data, isLoading: false }));
      } else {
        setOrderState((prevState) => ({ ...prevState, isLoading: false }));
        navigate('/404');
      }
    } else {
      navigate(`/${CLIENT_ROUTES.order}`);
    }
  };

  const getPendingActions = async () => {
    const { id } = params;
    const response = await orderPendingActions(id ?? '');
    if (response?.success) {
      setOrderState((prevState) => ({
        ...prevState,
        pendingActions: response?.data
      }));
    }
  };

  const handleTabChange = useCallback((value: number) => {
    setOrderState((prevState) => ({ ...prevState, activeTab: value }));
    pushEventTracking(ORDER_TABS.find((tab) => tab.value === value)?.label ?? '');
    const query = new URLSearchParams(window.location.search);
    query.set('activeTab', value.toString());
    setQueryParams(query.toString());
  }, []);

  return (
    <main className={css.orderWrapper}>
      {orderInfo && <Seo title={orderInfo.consignee_name} />}
      <div className={css.orderIndividualHeader}>
        {orderInfo && (
          <BreadCrumb>
            <BreadCrumb.Item value={0} to={`/${CLIENT_ROUTES.order}`}>
              Orders List
            </BreadCrumb.Item>
            <BreadCrumb.Item value={1} to={`/${CLIENT_ROUTES.order}/${params.id}`}>
              #{' '}
              {userType == USER_TYPES.customer
                ? orderInfo.po_reference_no
                : orderInfo.readable_order_id}
            </BreadCrumb.Item>
          </BreadCrumb>
        )}
        <AccessWrapper show={actions?.read} showTo={[USER_TYPES.customer]} byPassShowWithShowTo>
          <LinkButton
            variant="outlined-secondary"
            to={`/contact-us?po_reference_no=${orderInfo?.po_reference_no}`}
            eventTrackingName="RFQ_BTN_CLICK">
            Place RFQ
          </LinkButton>
        </AccessWrapper>
      </div>

      <AccessWrapper show={actions?.read}>
        {orderInfo && (
          <OrderCard
            customerName={orderInfo.customer?.name ? orderInfo.customer?.name : '-'}
            items={orderInfo.order_item}
            paymentTerm={orderInfo.payment_terms_display_value}
            placeOfDelivery={orderInfo?.place_of_delivery}
            createdAt={orderInfo.created_at as any}
            totalAmount={orderInfo.total}
            amountDue={orderInfo.amount_due}
            currentState={orderInfo.current_state_for_customer_present_tense}
            orderInfo={orderInfo}
            pendingTasks={orderInfo.pending_tasks ?? []}
            handleTabChange={handleTabChange}
            actions={actions}
          />
        )}
      </AccessWrapper>

      {orderInfo && (
        <Fragment>
          <Tabs value={activeTab}>
            <AccessWrapper show={orderOverviewActions?.read}>
              <Tab value={0} label="Order Overview" onClick={() => handleTabChange(0)} />
            </AccessWrapper>
            <AccessWrapper show={orderDocumentsActions?.read}>
              <Tab value={1} label="Documents" onClick={() => handleTabChange(1)} />
            </AccessWrapper>

            <AccessWrapper show={orderTrackingActions?.read}>
              <Tab value={2} label="Tracking" onClick={() => handleTabChange(2)} />
            </AccessWrapper>
            <AccessWrapper show={orderStuffingPhotosActions?.read}>
              <Tab value={3} label="Stuffing Photos" onClick={() => handleTabChange(3)} />
            </AccessWrapper>
            <AccessWrapper show={orderPeopleActions?.read}>
              <Tab value={4} label="People" onClick={() => handleTabChange(4)} />
            </AccessWrapper>
            <AccessWrapper show={orderActivityActions?.read}>
              <Tab value={5} label="Order Progress" onClick={() => handleTabChange(5)} />
            </AccessWrapper>

            <AccessWrapper show={orderTasksActions?.read}>
              <Tab
                value={6}
                label="Tasks"
                icon={
                  <Chip
                    variant={`filled-red`}
                    label={pendingActions?.tasks?.count ?? 0}
                    clickable={false}
                  />
                }
                iconPosition="end"
                onClick={() => handleTabChange(6)}
              />
            </AccessWrapper>
            <AccessWrapper show={emailActions?.read}>
              <Tab
                value={7}
                label="Emails"
                icon={
                  <Chip
                    variant={`filled-red`}
                    label={pendingActions?.nudge_emails?.count ?? 0}
                    clickable={false}
                  />
                }
                iconPosition="end"
                onClick={() => handleTabChange(7)}
              />
            </AccessWrapper>
          </Tabs>
          {activeTab === 0 && (
            <AccessWrapper show={orderOverviewActions?.read}>
              <OrderOverview />
            </AccessWrapper>
          )}
          {activeTab === 1 && (
            <AccessWrapper show={orderDocumentsActions?.read}>
              <DocumentTab
                orderId={orderInfo.order_id}
                readableOrderId={orderInfo.readable_order_id}
                customerPocs={orderInfo?.customer_poc}
                activeSubTab={activeSubTab}
                actions={orderDocumentsActions}
              />
            </AccessWrapper>
          )}
          {activeTab === 2 && (
            <AccessWrapper show={orderTrackingActions?.read}>
              <Tracking orderId={orderInfo.order_id} />
            </AccessWrapper>
          )}
          {activeTab == 3 && (
            <AccessWrapper show={orderStuffingPhotosActions?.read}>
              <StuffingPhotos orderId={orderInfo.order_id} actions={orderStuffingPhotosActions} />
            </AccessWrapper>
          )}

          {activeTab === 4 && (
            <AccessWrapper show={orderPeopleActions?.read}>
              <OrderPeopleTab />
            </AccessWrapper>
          )}

          {activeTab === 5 && (
            <AccessWrapper show={orderActivityActions?.read}>
              <ActivityLogTab orderId={orderInfo.order_id} actions={orderActivityActions} />
            </AccessWrapper>
          )}
          {activeTab === 6 && (
            <AccessWrapper show={orderTasksActions?.read}>
              <TaskTab
                orderInfo={orderState}
                getOrderInfo={getOrderInfo}
                getPendingActions={getPendingActions}
              />
            </AccessWrapper>
          )}

          {activeTab === 7 && (
            <AccessWrapper show={emailActions?.read}>
              <CommunicationTab orderId={orderInfo.order_id} />
            </AccessWrapper>
          )}
        </Fragment>
      )}
      <Loader open={isLoading} />
    </main>
  );
};

export default OrderIndividual;
