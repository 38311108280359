import css from './index.module.scss';
import { ListPlaceholder, OrderCard, InfiniteScroll } from '@components/common';
import { useEffect, useState, useRef } from 'react';
import { fetchAllOrder } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { OrderInfo } from '@helpers/types/order';
import { getCommaSepratedValue } from '@helpers/utils';

interface orderListProps {
  customerId?: string;
  actions?: IActions;
}

interface OrderHomeStates {
  orderList: OrderInfo[];
  hasNext: boolean;
  currentPageNumber: number;
  retry: boolean;
}

const OrdersTab = (props: orderListProps) => {
  const rootContainer = useRef<HTMLElement>(null);
  const [orderState, setOrderState] = useState<OrderHomeStates>({
    orderList: [],
    hasNext: false,
    currentPageNumber: 1,
    retry: false
  });

  const { orderList, hasNext, currentPageNumber, retry } = orderState;

  useEffect(() => {
    getOrderList(1);
  }, []);

  const getOrderList = async (page = 1) => {
    const response = await fetchAllOrder(page, undefined, props.customerId);
    if (response.success) {
      const { data } = response;
      setOrderState((prevState: OrderHomeStates) => ({
        ...prevState,
        orderList: [...prevState.orderList, ...data.results],
        hasNext: !!data.next,
        currentPageNumber: page,
        retry: false
      }));
    } else {
      setOrderState((prevState: OrderHomeStates) => ({ ...prevState, retry: true }));
      notify({ message: response.error ?? 'Unable to fetch order list', severity: 'error' });
    }
  };

  return (
    <div className={css.noOrderPlaceholder}>
      {orderList?.length ? (
        <>
          <InfiniteScroll
            rootRef={rootContainer.current!}
            currentIndex={currentPageNumber}
            hasMore={hasNext}
            hideEndText={orderList.length <= 10}
            onIntersect={getOrderList}
            retry={retry}>
            {orderList.map((order: any, index) => (
              <OrderCard
                key={index}
                readableOrderId={order.readable_order_id}
                orderId={order.order_id}
                items={order.order_item}
                customerName={order.customer?.name ? order.customer?.name : '-'}
                createdBy={order.created_by}
                currentState={order.current_state}
                totalAmount={order.total ? getCommaSepratedValue(order.total) : '-'}
                orderInfo={order}
                actions={props.actions}
              />
            ))}
          </InfiniteScroll>
        </>
      ) : (
        <ListPlaceholder
          title="No Order Added Yet"
          supportingText="When orders are added by you,
  they will show up here."
          buttonText="Add Order"
          to={`order/add`}
        />
      )}
    </div>
  );
};

export default OrdersTab;
