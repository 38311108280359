import css from './index.module.scss';
import { Seo } from '@components/common';
import { Typography } from '@components/base';
import { useNavigate, useParams } from 'react-router-dom';
import RejectModal from '../reject-modal';
import { useState, useEffect } from 'react';
import { CLIENT_ROUTES } from '@router/routes';
import PreviewForm from '../../../components/common/preview-form/index';
import { fetchOrderInfoFinance, approveOrder, rejectOrder } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import {
  IOrderCustomerForm,
  ITypeOfOrder,
  IExportConsigneeForm,
  IOrderItemForm,
  IOrderDetailForm,
  IOrderBankDetailForm,
  IContainer
} from '@helpers/types/add-order';
import { CURRENCY, UNITS_OF_WEIGHTS, CONTAINER_TYPE } from '@helpers/constants';
import { ISelect } from '@helpers/types';
import { IPackageSchema } from '@helpers/types/packing';
interface PreviewOrderProps {
  typeOfOrder: ITypeOfOrder | null;
  customerInfo: IOrderCustomerForm | null;
  consigneeInfo: IExportConsigneeForm | null;
  itemInfo: IOrderItemForm | null;
  orderInfo: IOrderDetailForm | null;
  entityBankDetail: IOrderBankDetailForm | null;
}

interface rejectOrderFormDataType {
  order_rectify_remarks: string;
}

interface orderItemType {
  product_name: string;
  product_grade: string;
  quantity: string;
  rate: string;
  order_item_document: Array<object>;
  product_grade_name: string;
  order_item_description: string;
  hs_code: string;
  country_of_origin_display_value: string;
  country_of_origin: string;
  package: IPackageSchema[];
  is_pre_shipment_sample_required: boolean;
}

const ReviewOrder = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [orderDataStates, setOrderDataStates] = useState<PreviewOrderProps>({
    typeOfOrder: null,
    customerInfo: null,
    consigneeInfo: null,
    itemInfo: null,
    orderInfo: null,
    entityBankDetail: null
  });

  useEffect(() => {
    getOrderDetails();
  }, []);

  const params = useParams();

  const handleApproveClick = async () => {
    const { taskid, orderid } = params;
    if (taskid) {
      const approveOrderStatusResponse = await approveOrder(taskid);
      if (approveOrderStatusResponse?.success) {
        navigate(`/${CLIENT_ROUTES.order}/${orderid}`, { replace: true });
      } else {
        notify({
          message: approveOrderStatusResponse.error ?? 'Something went wrong!',
          severity: 'error',
          dismissible: true
        });
      }
    } else {
      notify({ message: 'Something went wrong!', severity: 'error', dismissible: true });
    }
  };
  const handleCancelClick = () => {
    const { orderid } = params;
    navigate(`/${CLIENT_ROUTES.order}/${orderid}`, { replace: true });
  };

  const handleRejectOrder = async (data: rejectOrderFormDataType) => {
    const { taskid, orderid } = params;

    if (data && taskid) {
      const rejectOrderResponse = await rejectOrder(taskid, {
        order_rectify_remarks: data?.order_rectify_remarks
      });
      if (rejectOrderResponse?.success) {
        setShowModal(false);
        notify({ message: 'Order rejected successfully', dismissible: true });
        navigate(`/${CLIENT_ROUTES.order}/${orderid}`, { replace: true });
      } else {
        notify({
          message: rejectOrderResponse.error ?? 'Something went wrong!',
          severity: 'error',
          dismissible: true
        });
      }
    } else {
      notify({ message: 'Something went wrong!', severity: 'error', dismissible: true });
    }
  };

  const getOrderDetails = async () => {
    const { orderid } = params;
    if (orderid) {
      const orderData = await fetchOrderInfoFinance(orderid);
      if (orderData?.success) {
        const stateCopy = {
          typeOfOrder: {
            supply_type: {
              label: orderData?.data?.supply_type,
              value: orderData?.data?.supply_type
            },
            readable_order_id: orderData?.data?.readable_order_id
          },
          customerInfo: {
            customer: orderData?.data?.customer,
            customer_poc: orderData?.data?.customer_poc,
            customer_address: orderData?.data?.customer_address
          },
          consigneeInfo: {
            elchemy_entity: orderData?.data?.elchemy_entity,
            consignee_same_as_customer: orderData?.data?.consignee_same_as_customer,
            consignee_name: orderData?.data?.consignee_name,
            consignee_phone_number: orderData?.data?.consignee_phone_number,
            consignee_landline: orderData?.data?.consignee_landline,
            consignee_email_id: orderData?.data?.consignee_email_id,
            consignee_office_address: orderData?.data?.consignee_office_address
          },
          itemInfo: {
            items: orderData?.data?.order_item.map((item: orderItemType) => {
              return {
                name: {
                  name: item?.product_name
                },
                product_grade: {
                  name: item?.product_grade_name,
                  msds_doc: 'MSDS'
                },
                quantity: item?.quantity,
                rate_per_unit: item?.rate,
                supplier: {
                  documents: item?.order_item_document
                },
                description: item?.order_item_description,
                hs_code: item?.hs_code,
                country_of_origin: {
                  value: item?.country_of_origin,
                  label: item?.country_of_origin_display_value
                },
                package: item.package?.map((p: IPackageSchema) => {
                  const obj: any = {};
                  if (p) {
                    obj['type'] = { label: p.type_label, value: p.type };
                    obj['no_of_package'] = p.no_of_package;
                    obj['weight'] = p.weight;
                    obj['unit'] = { label: p.unit_of_weight, value: p.unit_of_weight };
                  }
                  return obj;
                }),
                is_pre_shipment_sample_required: item?.is_pre_shipment_sample_required
              };
            }),
            currency: orderData.data.currency
              ? CURRENCY.find((item: any) => item.value == orderData.data.currency)
              : null,
            unit_of_weight: orderData.data.unit_of_weight
              ? UNITS_OF_WEIGHTS.find((item: any) => item.value == orderData.data.unit_of_weight)
              : null
          },
          orderInfo: {
            transportation_mode: {
              label: orderData?.data?.transportation_mode,
              value: orderData?.data?.transportation_mode
            },
            inco_terms: {
              label: orderData?.data?.inco_terms,
              value: orderData?.data?.inco_terms
            },
            country_of_origin: {
              label: orderData?.data?.country_of_origin,
              value: orderData?.data?.country_of_origin
            },
            country_of_final_destination: {
              label: orderData?.data?.country_of_final_destination,
              value: orderData?.data?.country_of_final_destination
            },
            port_of_discharge: {
              value: orderData?.data?.port_of_discharge,
              label: `${orderData?.data?.port_of_discharge_display_value} - ${orderData?.data?.port_of_discharge_country_display_value}`
            },
            port_of_loading: {
              value: orderData?.data?.port_of_loading,
              label: `${orderData?.data?.port_of_loading_display_value} - ${orderData?.data?.port_of_loading_country_display_value}`
            },
            place_of_delivery: orderData?.data?.place_of_delivery,
            payment_terms: orderData.data.payment_term.map((item: any) => {
              return {
                payment_term: {
                  payment_term: item.payment_term,
                  days: item.days ? true : false,
                  percentage: item.percentage ? true : false
                },
                percentage: item.percentage,
                days: item.days ? item.days : null
              };
            }),
            is_qc_test: orderData?.data?.is_qc_test,
            place_of_quality_test: {
              label: orderData?.data?.place_of_quality_test,
              value: orderData?.data?.place_of_quality_test
            },
            palletization: orderData?.data?.palletization,
            container: orderData.data.container
              ? orderData.data.container?.map((item: IContainer) => {
                  return {
                    container_type: item.container_type
                      ? CONTAINER_TYPE.find((type: ISelect) => type.value === item.container_type)
                      : null,
                    no_of_container: item.no_of_container ? item.no_of_container : null
                  };
                })
              : []
          },
          entityBankDetail: {
            elchemy_bank: orderData?.data?.elchemy_bank,
            additional_remarks: orderData?.data?.additional_remarks
          }
        };
        setOrderDataStates(stateCopy as any);
      } else {
        notify({
          message: orderData ?? 'Something went wrong!',
          severity: 'error',
          dismissible: true
        });
      }
    } else {
      notify({ message: 'Invalid URL', severity: 'error', dismissible: true });
    }
  };

  return (
    <main className={css.mainWrapper}>
      <Seo title="Review Order" />
      <div className={css.header}>
        <div className={css.orderTextWrapper}>
          <Typography variant="h2">Review Order</Typography>
        </div>
      </div>
      <div className={css.orderFormContainer}>
        {orderDataStates.typeOfOrder &&
        orderDataStates.customerInfo &&
        orderDataStates.consigneeInfo &&
        orderDataStates.itemInfo &&
        orderDataStates.orderInfo &&
        orderDataStates.entityBankDetail ? (
          <PreviewForm
            orderInfo={orderDataStates.typeOfOrder}
            customerInfo={orderDataStates.customerInfo}
            consigneeInfo={orderDataStates.consigneeInfo}
            itemInfo={orderDataStates.itemInfo}
            orderDetail={orderDataStates.orderInfo}
            entityBankDetail={orderDataStates.entityBankDetail}
            onCancelClick={handleCancelClick}
            previewForm={false}
            onBackClick={() => setShowModal(true)}
            onFormSubmit={handleApproveClick}
          />
        ) : (
          <Typography variant="p">Order info not available</Typography>
        )}
      </div>
      {showModal && (
        <RejectModal
          open={showModal}
          onClose={() => setShowModal(false)}
          submitRejectForm={handleRejectOrder}
        />
      )}
    </main>
  );
};
export default ReviewOrder;
