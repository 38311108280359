import { Accordion, Button, IconNode, Typography } from '@components/base';
import { Avatar } from '@components/common';
import css from './index.module.scss';
import Images from '@assets/images';
import Footer from '../components/footer';
import { useState } from 'react';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import VerifyModal from './verify-modal';

interface VerifyPropType {
  taskData: Partial<ITaskTabProps>;
  onComplete?: () => void;
}

const Verify = (props: VerifyPropType) => {
  const { taskData, onComplete } = props;
  const { alertSuccessFaint, illustrationEdit } = Images;
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <div className={css.mainWrapper}>
        <Accordion defaultExpanded>
          <Accordion.Header className={css.accordionHeader}>
            <div className={css.header}>
              <div className={css.wrapper}>
                {taskData?.is_completed ? (
                  <IconNode src={alertSuccessFaint} className={css.successIcon} />
                ) : (
                  <Avatar src={illustrationEdit} alt="edit-icon" />
                )}
                <Typography variant="h5">
                  #{taskData.readable_task_id} - Verify Packaging Details ({taskData?.supplier_name}
                  )
                </Typography>
              </div>
              <div className={css.pocWrapper}>
                {!taskData?.is_completed && (
                  <Button variant="text" className={css.addBtn} onClick={() => setModalOpen(true)}>
                    Verify
                  </Button>
                )}
              </div>
            </div>
          </Accordion.Header>
        </Accordion>
        <Footer
          createdDate={taskData.created_at as Date}
          updatedDate={taskData.updated_at as Date}
        />
      </div>
      <VerifyModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        taskData={taskData}
        onComplete={onComplete}
      />
    </>
  );
};

export default Verify;
