import css from './index.module.scss';
import { Loader, Typography } from '@components/base';
import { SideBar } from '@components/common';
import PurchaseOrderDetail from './components/purchase-order-detail';
import PartiesForm from './components/parties-tab';
import { useContext, useEffect, useRef, useState } from 'react';
import RemarkTab from './components/remarks-tab';
import { FormProvider, SubmitHandler, useForm, useFormContext, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { purchaseOrderDetail } from '@helpers/yup/generate-purchase-order';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createPurchaseOrder,
  fetchOrderInfoFinance,
  fetchPOReviewById,
  getPaymentTermSuffixFilterOptions,
  rectifyOrder,
  rectifyPurchaseOrder
} from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { remarkFunction, stringified } from './remarks';
import { GeneratePurchaseOrderPrview } from '@pages/review-pages';
import OrderContext from './OrderContext';
import { getToken, parseJWT } from '@helpers/auth-helper';
import { getTaskForAnOrder } from '@services/task.service';
import { getSupplierDetail, getSupplierList } from '@services/supplier.service';
import { OrderItem } from '@helpers/types/order';
import { AppContext } from '@helpers/hooks/AppContext';
import {
  IGeneratePOProps,
  IPurchaseOrderProps,
  IRemarksData,
  ITasksProps
} from '@helpers/types/purchase-order';
import moment from 'moment';
import {
  countries,
  CURRENCY,
  DELIVERY_TERMS,
  GST_RATE,
  PAYMENT_TERMS,
  PO_PAYMENT_TERMS,
  ADDRESS_TYPE_CONSTANTS,
  UNITS_OF_WEIGHTS,
  booleanSelectFields
} from '@helpers/constants';
import { CLIENT_ROUTES } from '@router/routes';
import { fetchCountryList } from '@services/util.service';
import { IAddress } from '@helpers/types/address';
import { useBeforeUnloadAndNavigate, useScrollToTop } from '@helpers/hooks';
import { ISelectBoolean } from '@helpers/types';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const writtenNumber = require('written-number'); //intentionally written | no type definitions available

const GeneratePurchaseOrder = () => {
  const [tabState, setTabState] = useState<IGeneratePOProps>({
    activeStep: 0,
    isLoading: false,
    orderInfo: null,
    order_Items: [],
    orderItemsWithAmount: [],
    purchaser: {
      purchaser_name: '',
      purchaser_gst_no: '',
      address: ''
    },
    supplier: {
      supplier_name: '',
      vendor_id: '',
      supplier_gst_no: '',
      office_address: '',
      factory_location: '',
      supplier_poc_list: [],
      factory_address_list: []
    },
    remarksInfo: {
      string: '',
      data: {
        dispatch_date_info: null,
        fcl: null,
        amount_info: '',
        supplier_name: '',
        palletization_details: '',
        shipping_lines: '',
        text_remarks: ''
      },
      checkboxStates: {}
    },
    poData: {
      document_date: '',
      po_docx: '',
      po_pdf: '',
      po_terms_of_delivery: '',
      po_payment_terms: '',
      vendor_id: '',
      po_country_of_origin: null,
      supplier_name: '',
      gst_rate: '',
      gst_amount: '',
      order: {
        country_of_final_destination: '',
        order_item: [],
        elchemy_entity: {},
        readable_order_id: '',
        supply_type: ''
      },
      order_item: [],
      remarks: '',
      total_amount: '',
      total_amount_of_all_items_in_words: '',
      unit_of_weight: null,
      currency: null,
      rectify_remarks: '',
      payment_terms: [
        {
          payment_term: null,
          percentage: null,
          days: null
        }
      ],
      payment_term_suffix: null
    },
    remarks: '',
    rectifyRemarks: '',
    countryOptions: []
  });
  const { poData, countryOptions, supplier } = tabState;
  const [tasksState, setTasksState] = useState<ITasksProps>({
    tasksList: [],
    isLoading: false,
    tasksCount: 0,
    hasNext: false,
    currentPageNumber: 1,
    retry: false,
    searchText: '',
    searchLoading: false,
    supplierData: ''
  });

  const [remarkCheckboxTabs, setRemarkCheckboxTabs] = useState({
    dispatch_date: false,
    amount: false,
    proof_of_export: false,
    lifting_will_be_subject: false,
    product_should_be_from: false,
    palletization_detail: false,
    fumigation_certificate: false,
    standard_switch_bl_mark: false,
    shipment_to_be_processed: false,
    shipping_mark_in_the_name_of_elchemy: false,
    other_remarks: false,
    pss_confirmation: false
  });

  useBeforeUnloadAndNavigate();

  const urlParams = useParams();
  const { id, supplierId } = urlParams;
  const navigate = useNavigate();
  const { appState } = useContext(AppContext);
  const { POData, RectifyData } = appState;
  const isRectify = location.pathname.split('/')[2] === 'rectify-purchase-order';
  const { isLoading } = tasksState;
  const [backClicked, setBackClicked] = useState<boolean>(false);
  const topRef = useRef(null);
  const [paymentTermSuffixList, setPaymentTermSuffixList] = useState([]);

  const handleSidebarClick = (value: number) => {
    setTabState((prevState: IGeneratePOProps) => ({ ...prevState, activeStep: value }));
  };

  const paymentTermSuffixOptions = async () => {
    const response = await getPaymentTermSuffixFilterOptions();
    if (!response.success) {
      const data = response.results;
      setPaymentTermSuffixList(data);
    } else {
      notify({
        message: response.error ?? 'Unable to fetch data',
        severity: 'error',
        dismissible: true
      });
    }
  };

  const handleApproveClick = () => {
    setTabState((prevState: IGeneratePOProps) => ({
      ...prevState,
      activeStep: tabState.activeStep + 1
    }));
  };

  const onBack = () => {
    setTabState((prevState: IGeneratePOProps) => ({
      ...prevState,
      activeStep: tabState.activeStep - 1
    }));
  };

  const onCancel = () => {
    navigate(`/order/${id}`);
  };

  const handleSidebarEnterKey = (value: number) => {
    setTabState((prevState: IGeneratePOProps) => ({ ...prevState, activeStep: value }));
  };

  const partyForm = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      purchaserName: '',
      purchaseGstNumber: '',
      address: '',
      supplierName: '' || {
        label: '',
        value: 0
      },
      vendorId: '',
      supplierGstNumber: '',
      officeAddress: '' || {
        label: '',
        value: 0
      },
      factoryLocation: ''
    }
  });

  const remarkForm = useForm<IRemarksData>({
    defaultValues: {
      dispatch_date_info: null,
      fcl: null,
      amount_info: '',
      supplier_name: '',
      palletization_details: '',
      shipping_lines: '',
      text_remarks: ''
    }
  });

  const getAllTasks = async (supplierId: any) => {
    try {
      const accessToken = getToken('access');
      const jwtData = parseJWT(accessToken);
      const response = await getSupplierDetail(supplierId);
      if (response?.success) {
        const { data } = response;
        setTasksState((prevState) => ({
          ...prevState,
          tasksCount: data.count,
          hasNext: !!data.next,
          retry: false,
          searchLoading: false,
          supplierData: response?.data
        }));
        setTabState((prev: any) => ({
          ...prev,
          supplier: {
            supplier_name: data.supplier_name,
            vendor_id: data.vendor_id,
            supplier_gst_no: data.gst_number,
            office_address: data.addresses?.length
              ? data.addresses.find(
                  (add: IAddress) => add.address_type == ADDRESS_TYPE_CONSTANTS.OFFICE
                ).readable_address
              : null,
            factory_location: data.addresses?.[0]?.city,
            factory_address_list: data.addresses?.length
              ? data.addresses.filter(
                  (add: IAddress) => add.address_type == ADDRESS_TYPE_CONSTANTS.FACTORY
                )
              : [],
            supplier_poc_list: data.supplier_pocs ?? []
          }
        }));
      } else {
        setTasksState((prevState) => ({ ...prevState, retry: true, searchLoading: false }));
        notify({
          message: response.error ?? 'Unable to fetch supplier details',
          severity: 'error'
        });
      }
    } catch (err) {
      setTasksState((prevState) => ({ ...prevState, retry: true, searchLoading: false }));
      notify({ message: 'Unable to fetch tasks list', severity: 'error' });
    }
  };

  const PODetailsForm = useForm<IPurchaseOrderProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(purchaseOrderDetail),
    defaultValues: {
      document_date: new Date() || '',
      vendor_id: '',
      po_terms_of_delivery: '',
      po_payment_terms: '',
      po_country_of_origin: null,
      delivery_address: '',
      supplier: '',
      gst_number: '',
      currency: '',
      gst_rate: '',
      gst_amount: '',
      supplier_name: '',
      order_item: [],
      total_amount: '',
      total_amount_of_all_items_in_words: '',
      unit_of_weight: '',
      remarks: 'Good Job',
      supplier_poc: null,
      supplier_factory_address: null,
      payment_terms: [
        {
          payment_term: null,
          percentage: null,
          days: null
        }
      ],
      payment_term_suffix: null
    }
  });

  const { setValue } = PODetailsForm;
  const { setValue: setRemarksVal } = remarkForm;

  useEffect(() => {
    if (isRectify) {
      poData?.order_item?.forEach((item: OrderItem) => {
        if (typeof item.include_grade_name_in_docs === 'boolean') {
          item.include_grade_name_in_docs = booleanSelectFields.find(
            (opt: ISelectBoolean) => opt.value === item.include_grade_name_in_docs
          );
        }
      });

      setValue('document_date', poData.document_date || '');
      setValue('vendor_id', poData.vendor_id || '');
      setValue('po_terms_of_delivery', poData.po_terms_of_delivery || '');
      setValue('po_country_of_origin', poData.po_country_of_origin || null);
      setValue('po_payment_terms', poData.po_payment_terms || '');
      setValue('gst_number', tasksState?.supplierData?.gst_number || '');
      setValue('currency', poData.currency || '');
      setValue('gst_rate', poData.gst_rate || '');
      setValue('gst_amount', poData.gst_amount || '');
      setValue('supplier_name', poData.supplier_name);
      setValue('delivery_address', poData.delivery_address);
      setValue('order_item', poData?.order_item || []);
      setValue('total_amount', poData.total_amount || '');
      setValue(
        'total_amount_of_all_items_in_words',
        poData.total_amount_of_all_items_in_words || ''
      );
      setValue('gst_amount', poData.gst_amount || '');
      setRemarksVal('dispatch_date_info', tabState?.remarksInfo?.data?.dispatch_date_info || null);
      setRemarksVal('fcl', new Date(tabState?.remarksInfo?.data?.fcl as Date));
      setRemarksVal('amount_info', tabState?.remarksInfo?.data?.amount_info || '');
      setRemarksVal('supplier_name', tabState?.remarksInfo?.data?.supplier_name || '');
      setRemarksVal(
        'palletization_details',
        tabState?.remarksInfo?.data?.palletization_details || ''
      );
      setRemarksVal('shipping_lines', tabState?.remarksInfo?.data?.shipping_lines || '');
      setRemarksVal('text_remarks', tabState?.remarksInfo?.data?.text_remarks || '');
      setValue('supplier_poc', poData.supplier_poc || null);
      setValue('supplier_factory_address', poData.supplier_factory_address || null);
      setValue('unit_of_weight', poData.unit_of_weight || null);
      setValue(
        'payment_terms',
        poData?.payment_terms?.map((item: any) => {
          return {
            payment_term: {
              payment_term: item.payment_term?.payment_term,
              days: item.payment_term?.days,
              percentage: item.payment_term?.percentage
            },
            percentage: item?.percentage,
            days: item?.days ? item?.days : null
          };
        })
      );
    }
  }, [poData]);

  const previewData = () => {
    const remarkFormData = remarkForm.control._formValues;
    const remarksData = remarkFunction(remarkFormData);
    setTabState((prev: any) => ({ ...prev, remarks: remarksData }));
  };

  const submitForm = async () => {
    const remarksData = JSON.stringify(tabState.remarksInfo);
    const orderItemIds = tabState.orderInfo.order_item.filter(
      (item: any) => item.supplier_id == supplierId
    );

    const newOrder = poData?.order_item.map((item: any, index: number) => {
      item.include_grade_name_in_docs = item.include_grade_name_in_docs
        ? item.include_grade_name_in_docs.value
        : false;
      delete item['amount'];
      delete item['item_description'];
      delete item['item_name'];

      return { ...item, order_item_id: orderItemIds?.[index].order_item_id };
    });

    const formData = {
      document_date: moment(poData?.document_date).format('YYYY-MM-DD'),
      po_terms_of_delivery: poData?.po_terms_of_delivery.value,
      po_payment_terms: poData?.po_payment_terms.value,
      delivery_address: poData?.delivery_address,
      po_country_of_origin: poData?.po_country_of_origin.value,
      supplier: supplierId,
      gst_number: tasksState.supplierData.gst_number,
      currency: poData?.currency.value,
      gst_rate: poData?.gst_rate.value,
      gst_amount: Number(poData?.gst_amount).toFixed(2),
      order_items: newOrder,
      total_amount: poData?.total_amount,
      total_amount_of_all_items_in_words: poData?.total_amount_of_all_items_in_words,
      unit_of_weight: poData?.unit_of_weight?.value,
      remarks: remarksData,
      supplier_factory_address: poData?.supplier_factory_address?.address_id,
      supplier_poc: poData?.supplier_poc?.user_id,
      payment_term: poData?.payment_terms?.map((item: any) => {
        const obj: any = {};
        obj['payment_term'] = item.payment_term?.payment_term;
        obj['percentage'] = parseInt(item.percentage);
        if (item.days) {
          obj['days'] = parseInt(item.days);
        }
        return obj;
      }),
      po_payment_term_suffix: poData?.payment_term_suffix
        ? { id: (poData.payment_term_suffix as any).value }
        : null
    };

    const response = isRectify
      ? await rectifyPurchaseOrder(RectifyData?.task_id, supplierId as string, formData)
      : await createPurchaseOrder(
          isRectify ? RectifyData?.task_id : (POData?.task_id as string),
          supplierId,
          formData
        );

    if (response?.success) {
      notify({
        message: 'Purchase Order  Created successfully',
        severity: 'success',
        dismissible: true
      });
      navigate(`/${CLIENT_ROUTES.order}/${id as string}`);
    } else {
      notify({
        message: response.error ?? 'Error creating PO',
        severity: 'error',
        dismissible: true
      });
    }
    return response;
  };

  const handlePurchaseOrderSubmit: SubmitHandler<any> = (data: any) => {
    //Validation for payment terms
    let totalPercentage = 0,
      isError = false;
    data?.payment_terms?.forEach((item: any) => {
      totalPercentage += parseInt(item.percentage);
      if (item?.payment_term?.days && (!item?.days || parseInt(item?.days) <= 0)) {
        notify({
          message: `Enter valid number of days for ${item?.payment_term?.payment_term}`,
          severity: 'error'
        });
        isError = true;
        return;
      }
    });
    if (totalPercentage !== 100) {
      notify({
        message: 'Total percentage in payment terms should be 100',
        severity: 'error'
      });
      isError = true;
      return;
    }
    if (isError) return;
    const orderItems = data?.order_item.map((item: any, index: number) => {
      return {
        ...item,
        item_amount: Number(item.rate_po) * Number(item.quantity_po),
        item_name: tabState?.orderInfo?.order_item[index]?.product_name
      };
    });
    setTabState((prevState: any) => ({
      ...prevState,
      poData: {
        document_date: data.document_date,
        vendor_id: poData?.vendor_id,
        po_terms_of_delivery: data?.po_terms_of_delivery,
        po_payment_terms: data?.po_payment_terms,
        delivery_address: data?.delivery_address,
        po_country_of_origin: data?.po_country_of_origin,
        gst_number: data?.gst_number,
        currency: data?.currency,
        gst_rate: data?.gst_rate,
        gst_amount: data?.gst_amount,
        order_item: data?.order_item,
        total_amount: data.total_amount,
        total_amount_of_all_items_in_words: data.total_amount_of_all_items_in_words,
        unit_of_weight: data?.unit_of_weight,
        supplier_factory_address: data?.supplier_factory_address,
        supplier_poc: data?.supplier_poc,
        order_item_description_po: data?.order_item_description_po ?? null,
        payment_terms: data?.payment_terms,
        payment_term_suffix: data?.payment_term_suffix
      },
      orderItemsWithAmount: orderItems,
      activeStep: 2
    }));
  };

  const handleRemarkSubmit: SubmitHandler<IRemarksData> = (data: IRemarksData) => {
    //const formattedDateDispatch = `${moment(data?.dispatch_date_info).format('YYYY-MM-DD')}`;
    const fclFormattedDate = `${moment(data?.fcl).format('YYYY-MM-DD')}`;
    const formattedDateDispatch = data?.dispatch_date_info;
    const allRemarksData = {
      ...remarkCheckboxTabs,
      ...data,
      dispatch_date_info: formattedDateDispatch,
      fcl: fclFormattedDate
    };
    const remarkString = remarkFunction(allRemarksData);

    setTabState((prev: any) => ({
      ...prev,
      remarksInfo: {
        string: remarkString,
        data: {
          ...data,
          dispatch_date_info: formattedDateDispatch,
          fcl: fclFormattedDate
        },
        checkboxStates: { ...remarkCheckboxTabs }
      },
      activeStep: prev.activeStep + 1
    }));
  };

  const getPOData = async () => {
    const response = await fetchPOReviewById(RectifyData?.order_id as string, supplierId as string);
    if (response?.success && tasksState?.supplierData) {
      setTabState((prev: any) => ({ ...prev, isLoading: false }));
      const { data } = response;
      const parsedData = JSON.parse(data?.remarks);
      const formattedFcl = new Date(parsedData.data.fcl);
      const formattedDispatch = parsedData.data.dispatch_date_info;
      const filteredData = data?.order?.order_item?.filter(
        (item: any) => item.supplier_id == supplierId
      );

      setRemarkCheckboxTabs({ ...parsedData.checkboxStates });
      setTabState((prev: any) => ({
        ...prev,
        poData: {
          document_date: new Date(data?.document_date),
          po_terms_of_delivery: DELIVERY_TERMS.find(
            (item) => item.value == data?.po_terms_of_delivery
          ),
          vendor_id: tasksState?.supplierData?.vendor_id,
          po_payment_terms: PO_PAYMENT_TERMS.find((item) => item.value == data?.po_payment_terms),
          order: data?.order,
          delivery_address: data?.delivery_address,
          po_country_of_origin: {
            value: data?.po_country_of_origin,
            label: data?.po_country_of_origin_display_value
          },
          order_item: filteredData,
          gst_number: `${tasksState?.supplierData?.gst_number ?? ''}`,
          unit_of_weight: UNITS_OF_WEIGHTS.find((item) => item.value == data.unit_of_weight),
          gst_amount: data?.gst_amount,
          currency: CURRENCY.find((item) => item.value == data.currency),
          gst_rate: GST_RATE.find((item) => item.value == data.gst_rate),
          total_amount: `${data?.total_amount ?? ''}`,
          total_amount_of_all_items_in_words: `${data?.total_amount_of_all_items_in_words ?? ''}`,
          remarks: '',
          supplier_poc: data?.supplier_poc,
          supplier_factory_address: data?.supplier_factory_address,
          order_item_description_po: data?.order_item_description_po,
          payment_terms: data?.payment_term.map((item: any) => {
            return {
              payment_term: {
                payment_term: item.payment_term,
                days: item.days ? true : false,
                percentage: item.percentage ? true : false
              },
              percentage: item.percentage,
              days: item.days ? item.days : null
            };
          })
        },
        payment_term_suffix: data?.po_payment_term_suffix_value
          ? {
              value: data?.po_payment_term_suffix_value,
              name: data?.po_payment_term_suffix_label
            }
          : null,
        rectifyRemarks: data.rectify_remarks,
        remarksInfo: {
          string: parsedData.string,
          data: { ...parsedData.data, fcl: formattedFcl, dispatch_date_info: formattedDispatch },
          checkboxStates: { ...parsedData.checkboxStates }
        }
      }));
      setValue(
        'payment_term_suffix',
        data?.po_payment_term_suffix_value
          ? {
              value: data?.po_payment_term_suffix_value,
              name: data?.po_payment_term_suffix_label
            }
          : null
      );
    } else {
      setTabState((prev: any) => ({ ...prev, isLoading: false }));
      notify({
        message: 'Error fetching PO details',
        severity: 'error',
        dismissible: true
      });
    }
  };

  const orderInfo = async () => {
    const response = await fetchOrderInfoFinance(
      isRectify ? (RectifyData?.order_id as string) : (POData?.order_id as string)
    );

    if (response?.success) {
      const { data } = response;

      setTabState({
        ...tabState,
        orderInfo: response?.data,
        purchaser: {
          ...tabState.purchaser,
          purchaser_name: data.elchemy_entity.name,
          purchaser_gst_no: data.elchemy_entity.gst_number,
          address: data.elchemy_entity.address
        },
        order_Items: data?.order_item
      });
      getAllTasks(supplierId);
      const isPssCheckBoxMarked = data?.order_item?.some(
        (item: any) => item.is_pre_shipment_sample_required
      );
      setRemarkCheckboxTabs((prevState: any) => ({
        ...prevState,
        pss_confirmation: isPssCheckBoxMarked
      }));
    } else {
      notify({
        message: 'Error fetching order details',
        severity: 'error',
        dismissible: true
      });
    }

    setTabState((prev: any) => ({ ...prev, isLoading: false }));
  };

  /**
   * Filter country options based on search text used in order detail form
   */
  const filterCountryOptions = async (event?: any, param: any = null) => {
    const countryList = await fetchCountryList({ search_text: param });
    if (countryList?.success) {
      setTabState((prevState: any) => ({
        ...prevState,
        countryOptions: countryList.data.filter_options
      }));
    } else {
      notify({
        message: countryList.error ?? 'Error fetching country list',
        severity: 'error',
        dismissible: true
      });
    }
  };

  useEffect(() => {
    setTabState((prev: any) => ({ ...prev, isLoading: true }));
    orderInfo();
    paymentTermSuffixOptions();
  }, []);

  useEffect(() => {
    if (isRectify && tabState.activeStep === 1 && !backClicked) {
      setTabState((prev: any) => ({ ...prev, isLoading: true }));
      getPOData();
    }
  }, [tabState.activeStep]);

  const handleTabState = (activeState: number) => {
    setTabState((prev: any) => ({ ...tabState, activeStep: activeState }));
  };

  useEffect(() => {
    if (tabState.activeStep == 3) {
      previewData();
    }

    if (tabState.activeStep == 2) {
      setBackClicked(true);
    }
  }, [tabState.activeStep]);

  useScrollToTop({ topRef, dependencyArray: [tabState.activeStep] });

  return (
    <OrderContext.Provider value={{ ...tabState, ...tasksState }}>
      <main className={css.purchaseOrderWrapper}>
        <div className={css.titleWrapper}>
          <Typography variant="h2">{isRectify ? `Rectify` : `Generate`} Purchase Order</Typography>
          <Typography variant="subheading1">0{tabState.activeStep + 1} of 04</Typography>
        </div>
        <div className={css.purchaseOrderContainer} ref={topRef}>
          <div className={css.sideBarWrapper}>
            <SideBar
              activeStep={tabState.activeStep}
              onClick={handleSidebarClick}
              onEnter={handleSidebarEnterKey}>
              <SideBar.Item label="Parties" value={0} />
              <SideBar.Item
                label="Purchase order details"
                value={1}
                disabled={tabState.activeStep! <= 1}
              />
              <SideBar.Item label="Remarks" value={2} disabled={tabState.activeStep! <= 2} />
              <SideBar.Item label="Preview" value={3} disabled={tabState.activeStep! <= 3} />
            </SideBar>
          </div>
          <FormProvider {...partyForm}>
            {tabState.activeStep === 0 && (
              <PartiesForm
                data={{ ...tabState, ...tasksState }}
                cancelClick={onCancel}
                formSubmit={handleApproveClick}
              />
            )}
          </FormProvider>
          <FormProvider {...PODetailsForm}>
            {tabState.activeStep === 1 && tabState?.orderInfo && tasksState?.supplierData && (
              <PurchaseOrderDetail
                data={tabState}
                isRectify={isRectify}
                onBackClick={onBack}
                cancelClick={onCancel}
                formSubmit={handlePurchaseOrderSubmit}
                countryOptions={countryOptions}
                filterCountryOptions={filterCountryOptions}
                supplierData={supplier}
                paymentTermSuffixList={paymentTermSuffixList}
              />
            )}
          </FormProvider>
          <FormProvider {...remarkForm}>
            {tabState.activeStep === 2 && tabState?.orderInfo && tasksState?.supplierData && (
              <RemarkTab
                onBackClick={onBack}
                cancelClick={onCancel}
                remarkCheckboxTabs={remarkCheckboxTabs}
                setRemarkCheckboxTabs={setRemarkCheckboxTabs}
                formSubmit={handleRemarkSubmit}
              />
            )}
          </FormProvider>
          {/* <FormProvider> */}
          {tabState.activeStep === 3 && (
            <GeneratePurchaseOrderPrview
              onBackClick={onBack}
              cancelClick={onCancel}
              onFormSubmit={submitForm}
              handleTabState={handleTabState}
              isRectify={isRectify}
            />
          )}

          {/* </FormProvider> */}
        </div>
        <Loader open={tabState?.isLoading} />
      </main>
    </OrderContext.Provider>
  );
};

export default GeneratePurchaseOrder;
