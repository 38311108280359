import store from '@store/index';
import { clearToken, setToken } from './auth-helper';
import { addToken, removeToken } from '@store/slices/user.slice';
import { SERVER_CONFIG, USER_TYPES } from '@helpers/constants';
import history from './history';
import axios from 'axios';
import { CLIENT_ROUTES } from '@router/routes';
import notify from './toastify-helper';

interface IToken {
  accessToken: string;
  refreshToken: string;
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
class SessionService {
  private static readonly _refreshTokenURL = `${process.env.REACT_APP_BASE_URL}${SERVER_CONFIG.refreshToken}`;

  static saveSession(accessToken: string, refreshToken: string, user_authorization_data = {}) {
    if (accessToken && refreshToken) {
      setToken('access', accessToken);
      setToken('refresh', refreshToken);
      setToken('permissions', JSON.stringify(user_authorization_data ?? {}));
      store.dispatch(addToken({ accessToken, refreshToken }));
      return true;
    } else return false;
  }

  static getSession() {
    const reduxState = store.getState();
    const { user } = reduxState;
    const { accessToken, refreshToken } = user;
    return { accessToken, refreshToken };
  }

  static async refreshSession(): Promise<IToken | undefined> {
    const { accessToken, refreshToken } = SessionService.getSession();
    if (accessToken && refreshToken) {
      try {
        const payload = {
          refresh: refreshToken
        };
        const response = await axios.post(SessionService._refreshTokenURL, payload);
        if (response.data?.data) {
          const data = response.data.data;
          const user_authorization_data = {
            ...data?.user_auth_info,
            user_type:
              data?.role === USER_TYPES.customer ? USER_TYPES.customer : USER_TYPES.internal
          };
          const session = SessionService.saveSession(
            data?.tokens?.access,
            data?.tokens?.refresh,
            user_authorization_data
          );
          if (session) return data;
          throw new Error('Error occurred while refreshing session');
        } else throw new Error('Error occurred while refreshing session');
      } catch (error) {
        SessionService.clearSession();
      }
    }
  }

  static clearSession(hideToast = false) {
    clearToken('access');
    clearToken('refresh');
    clearToken('permissions');
    store.dispatch(removeToken());
    history.replace(`${CLIENT_ROUTES.login}`);
    !hideToast &&
      notify({ title: 'Session Expired', message: 'Please log in again.', severity: 'info' });
  }
}

export default SessionService;
