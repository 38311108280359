import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { paymentTerm } from './add-payment.schema';

export const partySchema = yup.object().shape({
  purchaseName: yup.string().trim().optional(),
  purchaserGstNumber: yup.string().trim().optional(),
  address: yup.string().trim().optional(),
  supplierName: yup.object().shape({
    label: yup.string().optional(),
    value: yup.number().optional()
  }),
  vendorId: yup.number().optional(),
  supplierGstNumber: yup.string().optional(),
  factoryAddress: yup
    .object()
    .shape({
      label: yup.string().optional(),
      value: yup.number().optional()
    })
    .optional(),
  factoryLocation: yup.string().optional()
});

export const orderItemsSchema = yup.object().shape({
  quantity_po: yup
    .number()
    .typeError('Quantity should be in number')
    .moreThan(0, 'Should be greater than 0')
    .positive('Should be greater than 0 (zero)')
    .test('decimal', 'Should be upto 4 decimal places', (value) => {
      if (value && !isNaN(value)) {
        const data = value.toString().split('.');
        if (data[1]) return data[1].length <= 4 ? true : false;
        return true;
      }
      return false;
    })
    .required('Item quantity is required'),
  rate_po: yup
    .number()
    .typeError('Rate should be in number')
    .moreThan(0, 'Should be greater than 0')
    .positive('Should be greater than 0 (zero)')
    .max(99999999999999, "Can't be more than 14 digits")
    .test('decimal', 'Should be upto 2 decimal places', (value) => {
      if (value && !isNaN(value)) {
        const data = value.toString().split('.');
        if (data[1]) return data[1].length <= 2 ? true : false;
        return true;
      }
      return false;
    })
    .required('Item rate is required')
});

const selectSchema = yup.object().shape({
  value: yup.string().required(),
  label: yup.string().required()
});

const notRequiredSelectSchema = yup.object().shape({
  value: yup.string(),
  label: yup.string()
});

export const purchaseOrderDetail = yup.object().shape({
  document_date: yup.date().required('PO Date is required'),
  po_terms_of_delivery: selectSchema
    .typeError('Delivery Terms are required')
    .required('Delivery Terms are required'),
  // po_payment_terms: selectSchema
  //   .typeError('Payment Terms are required')
  //   .required('Payment Term are required'),
  po_country_of_origin: selectSchema
    .typeError('Country of origin is required')
    .required('Country of origin is required'),
  delivery_address: yup.string().required('Delivery Address is required'),
  gst_rate: selectSchema.typeError('GST Rate is required').required('GST Rate is required'),
  order_item: yup.array().of(orderItemsSchema),
  gst_amount: yup.string(),
  remarks: yup.string().optional(),
  total_amount: yup.string(),
  total_amount_of_all_items_in_words: yup.string(),
  unit_of_weight: selectSchema
    .typeError('Unit of Weight is required')
    .required('Unit of Weight is required'),
  currency: selectSchema.typeError('Currency is required').required('Currency is required'),
  order: yup.string(),
  supplier_poc: yup.mixed().required('Supplier POC is required'),
  supplier_factory_address: yup.mixed().required('Supplier Factory Address is required'),
  payment_terms: yup.array().of(paymentTerm),
  payment_term_suffix: notRequiredSelectSchema.notRequired().nullable(),
  //   supplier: yup.string().required()
  // buying_price: yup.number().required(),
  // notify_party: yup.string().required(),
  // custom_items: yup.array.arguments({})
});

// export const
