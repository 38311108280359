import { useCallback } from 'react';
import css from './index.module.scss';
import { Typography, Modal, IconNode, Button } from '@components/base';
import { SelectLabel, DragDropUpload, TextField } from '@components/common';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { SUPPLIER_DOCUMENT_TYPE } from '@helpers/constants';
import Images from '@assets/images';
import { ISelect } from '@helpers/types';

interface UploadDocumentProps {
  open: boolean;
  onClose: () => void;
  onFormSubmit: (data: any) => void;
  editMode?: boolean;
  availableDocumentType: ISelect[];
}

/**
 * Document Name is commented for now in types and in view, but functionality is kept because it might be brought back
 */
const UploadDocument = (props: UploadDocumentProps) => {
  const { open, onClose, onFormSubmit, editMode, availableDocumentType } = props;
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isDirty, isSubmitting }
  } = useFormContext();

  const watch = useWatch({ name: 'document_object', control: control });

  const handleDeleteFile = useCallback(() => {
    setValue('document_object', null);
  }, []);

  const handleFileDrop = useCallback((file: any[]) => {
    if (file.length) {
      setValue('document_object', file[0]);
      /**
       * Prefill document_name as file name unless the document_type is OTHER
       */
      if (!(getValues('document_type')?.value == 'OTHER')) {
        setValue('document_name', file[0].name);
      } else {
        setValue('document_name', '');
      }
    }
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <div className={css.modalWrapper}>
        <section className={css.modalHeader}>
          <Typography variant="h4">Upload Document</Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>
        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
          <section className={css.modalContent}>
            <Controller
              name="document_type"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  required
                  autoFocus={!editMode}
                  isDisabled={editMode}
                  label="Document Type"
                  options={!editMode ? availableDocumentType : SUPPLIER_DOCUMENT_TYPE}
                  rootClassName={css.typeField}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            {/* <Controller
              name="document_name"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  autoFocus={editMode}
                  inputMode="text"
                  enterKeyHint="next"
                  autoComplete="name"
                  label="Document Name"
                  placeholder="Enter Document Name"
                  rootClassName={css.typeField}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            /> */}
            <Controller
              name="document_object"
              control={control}
              render={() => <DragDropUpload onDrop={handleFileDrop} />}
            />
            {watch && (
              <div className={css.fileUploadState}>
                <Typography variant="p" className={css.fileName}>
                  {watch.name}
                </Typography>
                <IconNode
                  src={Images.deleteRed}
                  alt="delete icon"
                  component="button"
                  className={css.deleteButton}
                  onClick={handleDeleteFile}
                />
              </div>
            )}
          </section>
          <section className={css.modalFooter}>
            <Button variant="outlined-secondary" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {editMode ? 'Update' : 'Save'}
            </Button>
          </section>
        </form>
      </div>
    </Modal>
  );
};

export default UploadDocument;
