import { memo } from 'react';
import { BoxContainer, TextField } from '@components/common';
import css from './index.module.scss';
import { sanitizeValue } from '@helpers/utils';
import { ITaskItemProps } from '@helpers/types/task-tabs';
import { groupCSS } from 'react-select/dist/declarations/src/components/Group';

interface IOrderFormProps {
  data: ITaskItemProps;
}

const OrderForm = (props: IOrderFormProps) => {
  const { data } = props;

  return (
    <BoxContainer title="Order Details" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        {data?.transportation_mode === 'SEA' && (
          <div className={css.rowWrapper}>
            <TextField
              disabled
              value={sanitizeValue(data?.vessel_name)}
              label="Vessel"
              placeholder="Enter Vessel Name"
              rootClassName={css.fieldWrapper}
            />
            <TextField
              disabled
              value={sanitizeValue(data?.voyage_number)}
              label="Voyage No."
              placeholder="Enter Voyage Number"
            />
          </div>
        )}
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={sanitizeValue(data?.port_of_loading)}
            label="Port Of Loading"
            placeholder="Enter Port Of Loading"
            rootClassName={css.fieldWrapper}
          />
          <TextField
            disabled
            value={sanitizeValue(data?.port_of_discharge)}
            label="Port Of Discharge"
            placeholder="Enter Port Of Discharge"
          />
        </div>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={data?.net_weight}
            label="Net Weight (KG)"
            placeholder="Enter Net Weight"
            rootClassName={css.fieldWrapper}
          />
          <TextField
            disabled
            value={data?.gross_weight}
            label="Gross Weight (KG)"
            placeholder="Enter Gross Weight"
          />
        </div>
      </div>
    </BoxContainer>
  );
};

export default memo(OrderForm);
