import css from './index.module.scss';
import PropTypes from 'prop-types';
import { Button } from '@components/base';
import { useFormContext } from 'react-hook-form';
import { ISupplierDetailForm } from '@helpers/types';
import InfoFields from './info-fields';
import SupplierAddressFields from './supplier-address-fields';
import { POCDetailContainer } from '@components/common';

export interface SupplierDetailFormProps {
  showVendorId?: boolean;
  onFormSubmit: (data: ISupplierDetailForm) => void;
  onNextClick?: () => void;
  onCancelClick?: () => void;
  userList: Array<object>;
  enableEditMode?: boolean;
}

const SupplierDetailForm = (props: SupplierDetailFormProps) => {
  const {
    onNextClick,
    showVendorId,
    onCancelClick,
    onFormSubmit,
    userList,
    enableEditMode = false
  } = props;

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty }
  } = useFormContext<ISupplierDetailForm>();

  return (
    <form className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <InfoFields
        showVendorId={showVendorId}
        disabledSupplierName={showVendorId}
      />
      <POCDetailContainer enableEditMode={enableEditMode} userList={userList} />
      <SupplierAddressFields />
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={onCancelClick} disabled={isSubmitting}>
          Cancel
        </Button>
        <div className={css.actionButtonContainer}>
          {showVendorId && (
            <Button variant="outlined-secondary" onClick={onNextClick} disabled={isSubmitting}>
              Next
            </Button>
          )}
          <Button type="submit" disabled={isSubmitting || (showVendorId ? !isDirty : false)}>
            {showVendorId ? 'Update and Proceed' : 'Save and Proceed'}
          </Button>
        </div>
      </div>
    </form>
  );
};

SupplierDetailForm.defaultProps = {
  showVendorId: false
};

SupplierDetailForm.propTypes = {
  showVendorId: PropTypes.bool,
  onNextClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onFormSubmit: PropTypes.func.isRequired
};

export default SupplierDetailForm;
