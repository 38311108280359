import css from './index.module.scss';
import { Typography } from '@components/base';
import { BoxContainer } from '@components/common';
import { IAddress } from '@helpers/types/address';
import { useMemo } from 'react';
import { BUSINESS_TYPE } from '@helpers/constants';

interface CustomerDetailBoxProps {
  country: string;
  typeOfCustomer: string;
  email: string;
  website: string;
  addresses: IAddress[];
  description: string;
}

const CustomerDetailBox = (props: CustomerDetailBoxProps) => {
  const { country, email, website, description } = props;

  const customerType = useMemo(() => {
    const { typeOfCustomer } = props;
    const type = BUSINESS_TYPE.find((item) => item.value === typeOfCustomer);
    return `${type?.label}`;
  }, [props.typeOfCustomer]);

  const { officeAddress, factoryAddresses } = useMemo(() => {
    const { addresses } = props;
    let officeAddress = '';
    const factoryAddresses: string[] = [];
    addresses.forEach((address) => {
      if (address.address_type !== 'OFC') {
        factoryAddresses.push(address.readable_address);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      } else if (address.address_type === 'OFC') {
        officeAddress = address.readable_address;
      }
    });
    return { officeAddress, factoryAddresses };
  }, [props.addresses]);

  return (
    <div className={css.mainWrapper}>
      <BoxContainer title="Details">
        <div className={css.subMainWrapper}>
          <div className={css.rowWrapper}>
            <div className={css.textWrapper}>
              <Typography variant="span">Country</Typography>
              <Typography variant="p" className={css.labelWrapper}>
                {country}
              </Typography>
            </div>
            <div className={css.textWrapper}>
              <Typography variant="span">Type of Customer</Typography>
              <Typography variant="p" className={css.labelWrapper}>
                {customerType}
              </Typography>
            </div>
            <div className={css.textWrapper}>
              <Typography variant="span">Email</Typography>
              <Typography variant="p" className={css.labelWrapper}>
                {email}
              </Typography>
            </div>
            <div className={css.textWrapper}>
              <Typography variant="span">Website</Typography>
              <Typography variant="p" className={css.labelWrapper}>
                {website?.length ? website : '-'}
              </Typography>
            </div>
          </div>
          <div className={css.rowWrapper}>
            <div className={css.textWrapper}>
              <Typography variant="span">Office Address</Typography>
              <Typography variant="p">{officeAddress}</Typography>
            </div>
          </div>
          {factoryAddresses.map((address, index) => (
            <div key={index} className={css.rowWrapper}>
              <div className={css.textWrapper}>
                <Typography variant="span">
                  {index <= 1 ? `Shipping Address  ${index + 1}` : `Other`}
                </Typography>
                <Typography variant="p">{address}</Typography>
              </div>
            </div>
          ))}
          <div className={css.rowWrapper}>
            <div className={css.textWrapper}>
              <Typography variant="span">Additional Remarks/Requirements</Typography>
              <Typography variant="p">{description.length ? description : '-'}</Typography>
            </div>
          </div>

        </div>
      </BoxContainer>
    </div>
  );
};

export default CustomerDetailBox;
