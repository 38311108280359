/* eslint-disable @typescript-eslint/no-var-requires */
import css from './index.module.scss';
import { Button, IconNode } from '@components/base';
import { BoxContainer, SelectLabel, TextField, InputDatePicker } from '@components/common';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import Images from '@assets/images';
import { PAYMENT_TERMS } from '@helpers/constants';
import { useEffect } from 'react';
import notify from '@helpers/toastify-helper';
import { IBankInfo } from '@helpers/types/bank';

const writtenNumber = require('written-number'); //intentionally written | no type definitions available

interface CommericalDetailFormProps {
  onFormSubmit: (data: any) => void;
  onBackClick?: () => void;
  onCancelClick?: () => void;
  orderInfo: any;
  customerBankInfo: IBankInfo[];
}

const CommericalDetailForm = (props: CommericalDetailFormProps) => {
  const { onFormSubmit, onBackClick, onCancelClick, orderInfo, customerBankInfo } = props;
  const { control, handleSubmit, setValue } = useFormContext();
  const watchField = useWatch({
    name: 'commercial_invoice_due_amount',
    control
  });
  const dueAmountInWords =
    watchField &&
    !isNaN(watchField) &&
    `${writtenNumber(watchField, { lang: 'en' })?.toUpperCase()}`;

  useBeforeUnloadAndNavigate();

  useEffect(() => {
    if (
      !customerBankInfo?.length &&
      orderInfo.payment_terms_display_value.includes('Letter Of Credit')
    ) {
      notify({ message: 'Please add bank details in customer module', severity: 'error' });
    } else {
      setValue('customer_bank', customerBankInfo[0]);
    }
    setValue('commercial_invoice_no', orderInfo?.order_ci?.document_number);
    const commercialInvoiceDate = new Date(orderInfo?.order_ci?.document_date);
    setValue('commercial_invoice_date', commercialInvoiceDate);
    if (orderInfo?.transportation_mode === 'AIR') {
      setValue('bl_awb_no', orderInfo?.awb_number);
      const awbDate = new Date(orderInfo?.awb_date);
      setValue('bl_awb_date', awbDate);
      setValue('awb_service_name', orderInfo?.awb_service_name);
    } else {
      setValue('bl_awb_no', orderInfo?.bl_number);
      const blAwbDate = new Date(orderInfo?.bl_date);
      setValue('bl_awb_date', blAwbDate);
    }
  }, []);

  const isBlOrAwb = orderInfo?.transportation_mode === 'AIR' ? 'AWB' : 'BL';

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
      <BoxContainer title="Details">
        <div className={css.boxWrapper}>
          <div className={css.rowWrapper}>
            <Controller
              name="commercial_invoice_no"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  autoFocus
                  disabled
                  inputMode="decimal"
                  enterKeyHint="next"
                  autoComplete="off"
                  label="Commercial Invoice Number"
                  placeholder="Enter Commercial Invoice Number"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
            <Controller
              name="commercial_invoice_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  required
                  disabled
                  label="Commercial Invoice Date"
                  placeholder="Select Commercial Invoice Date"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                />
              )}
            />
          </div>
          <Controller
            name="commercial_invoice_due_amount"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                inputMode="decimal"
                enterKeyHint="next"
                autoComplete="transaction-amount"
                label="Due Amount"
                placeholder="Enter Due Amount"
                rootClassName={css.fieldSpacing}
                error={fieldState.invalid}
                helperText={
                  fieldState.error?.message ?? 'Total invoice amount minus advance amount'
                }
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
          <TextField
            disabled
            value={
              watchField &&
              !isNaN(watchField) &&
              `${writtenNumber(watchField, { lang: 'en' })?.toUpperCase()}`
            }
            label="Due Amount (in words)"
            placeholder="Enter Due Amount in Words"
            rootClassName={css.fieldSpacing}
          />
          <div className={css.rowWrapper}>
            <Controller
              name="bl_awb_no"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  disabled
                  inputMode="decimal"
                  enterKeyHint="next"
                  autoComplete="off"
                  label={`${isBlOrAwb} Number`}
                  placeholder="Enter Commercial Invoice Number"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                  }
                />
              )}
            />
            <Controller
              name="bl_awb_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  required
                  disabled
                  label={`${isBlOrAwb} Date`}
                  placeholder="Enter Commercial Invoice Date"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                />
              )}
            />
            {orderInfo?.transportation_mode === 'AIR' && (
              <Controller
                name="awb_service_name"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label="AWB Service Name"
                    value={orderInfo?.awb_service_name}
                    required
                    disabled
                    placeholder="Enter AWB Service Name"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            )}
          </div>
          <Controller
            name="payment_terms"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                label="Payment Term"
                disabled
                value={orderInfo.payment_terms_display_value}
                rootClassName={css.fieldSpacing}
              />
            )}
          />
          <Controller
            name="starting_statement"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                enterKeyHint="next"
                autoComplete="off"
                label="Starting statement ( of this Second of Exchange )"
                placeholder="Start typing"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
          <br />
          <Controller
            name="closing_statement"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                enterKeyHint="next"
                autoComplete="off"
                label={`Closing statement ( the sum of USD ${dueAmountInWords})`}
                placeholder="Start typing"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="error icon" />
                }
              />
            )}
          />
          <br />
          {orderInfo.payment_terms && orderInfo.payment_terms.includes('LC') ? (
            <Controller
              name="customer_bank"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  required
                  label="Customer Bank"
                  isDisabled={!customerBankInfo?.length}
                  placeholder="Select"
                  options={customerBankInfo}
                  getOptionLabel={(option: any) => `${option.bank_name}-${option.account_number}`}
                  getOptionValue={(option: any) => option.bank_id}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          ) : (
            <></>
          )}
        </div>
      </BoxContainer>
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div className={css.actionButtonContainer}>
          <Button variant="outlined-secondary" onClick={onBackClick}>
            Back
          </Button>
          <Button type="submit">Save & Proceed</Button>
        </div>
      </div>
    </form>
  );
};

export default CommericalDetailForm;
