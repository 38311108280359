import Images from '@assets/images';
import { Accordion, Button, Divider, Typography } from '@components/base';
import { Avatar, DocumentRow } from '@components/common';
import { useNavigate } from 'react-router-dom';
import css from './index.module.scss';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import Footer from '../components/footer';

type generateCRLProps = {
  taskData: Array<Partial<ITaskTabProps>>;
};

const GenerateCRL = (props: generateCRLProps) => {
  const navigate = useNavigate();
  const { taskData } = props;

  const handleGenerateClick = () => {
    const data = { orderId: taskData[0]?.order };
    navigate(`/finance/customer-request-letters/${taskData[0].order}/${taskData[0]?.task_id}`, {
      state: { data }
    });
  };

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {!taskData[0]?.is_completed && <Avatar src={Images.illustrationEdit} alt="" />}
              {taskData[0]?.is_completed && <Avatar src={Images.alertSuccessFaint} alt="" />}
              <Typography variant="h5">#{taskData[0]?.readable_task_id} - Generate CRL</Typography>
            </div>
            <div className={css.pocWrapper}>
              {!taskData[0].is_completed && (
                <Button
                  onClick={handleGenerateClick}
                  className={css.generateButton}
                  variant="outlined">
                  Generate
                </Button>
              )}
            </div>
          </div>
        </Accordion.Header>
      </Accordion>
      {/* <div className={css.footer}>
        <div className={css.textWrapper}>
          <Typography variant="h5">Created On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.created_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Updated On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.updated_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Time Taken:</Typography>
          <Typography variant="p">
            {moment(taskData[0]?.updated_at)?.diff(taskData[0]?.created_at, 'days')} Day(s)
          </Typography>
        </div>
      </div> */}
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
    </div>
  );
};

export default GenerateCRL;
