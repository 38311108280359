import { useEffect, useState } from 'react';
import css from './index.module.scss';
import { BoxContainer, InputDatePicker, SelectLabel, TextField } from '@components/common';
import {
  Controller,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { ToolTip, Typography, Divider, Chip, IconNode, Button } from '@components/base';
import Images from '@assets/images';

interface IRemainingPackages {
  packageId: string;
  packageDisplayName: string;
  noOfPackages: number;
  actualNumberOfPackages: number;
}

const ItemDetails = (props: any) => {
  const { onFormSubmit, onCancelClick, data, disableForm = false } = props;
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting }
  } = useFormContext<any>();

  const { fields: itemFields } = useFieldArray({
    name: `items`,
    control
  });

  const watchField = useWatch({
    name: `items`,
    control
  });

  useEffect(() => {
    let totalNoOfPackages = 0,
      totalNetWeight = 0,
      totalGrossWeight = 0;
    watchField?.forEach((item: any) => {
      item.packaging_document_data.forEach((packagingItem: any) => {
        totalNoOfPackages += parseInt(packagingItem.no_of_packages);
        totalNetWeight += parseInt(packagingItem.net_wt);
        totalGrossWeight += parseInt(packagingItem.gross_wt);
      });
    });

    setValue('total_net_wt', totalNetWeight);
    setValue('total_gross_wt', totalGrossWeight);
    setValue('total_no_of_packages', totalNoOfPackages);
  }, [watchField]);

  return (
    <form noValidate onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper1}>
      <BoxContainer title="Item Details" className={css.boxWrapper}>
        <div className={css.packageWrapper}>
          {itemFields.map((item: any, index: number) => {
            return (
              <ItemWrapper
                key={item.id}
                item={item}
                itemIndex={index}
                control={control}
                name={`items.${index}.name`}
                data={data}
                disableForm={disableForm}
                getValues={getValues}
              />
            );
          })}
        </div>
        <Divider />
        <div className={css.totalContainer}>
          <div className={css.rowWrapper}>
            <Controller
              name="total_net_wt"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  rootClassName={css.fieldWrapper}
                  required
                  placeholder="Enter"
                  type="text"
                  label="Total Net Wt."
                  disabled
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="total_gross_wt"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  rootClassName={css.fieldWrapper}
                  required
                  placeholder="Enter"
                  type="text"
                  label="Total Gross Wt."
                  disabled
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name="total_pallets_packed"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  rootClassName={css.fieldWrapper}
                  // required
                  disabled={disableForm}
                  placeholder="Enter"
                  type="text"
                  label="Total Pallets Packed"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="total_no_of_packages"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  rootClassName={css.fieldWrapper}
                  required
                  placeholder="Enter"
                  type="text"
                  label="Total No. of Packages"
                  disabled
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={css.rowWrapper}>
            <Controller
              name="po_reference_no"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  rootClassName={css.fieldWrapper}
                  required
                  disabled={disableForm}
                  placeholder="Enter"
                  label="PO Reference No."
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="po_reference_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  label="PO Reference Date"
                  disabled={disableForm}
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldWrapper}
                />
              )}
            />
          </div>
        </div>
      </BoxContainer>
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div>
          <Button type="submit" disabled={isSubmitting}>
            Save and Proceed
          </Button>
        </div>
      </div>
    </form>
  );
};

const ItemWrapper = (props: any) => {
  const { item: itemFieldArrayValue, control, name, itemIndex, data, disableForm = false } = props;

  const {
    setError,
    formState: { errors },
    clearErrors
  } = useForm();

  const packageDetailWatch = useWatch({
    name: `items.${itemIndex}.packaging_document_data`,
    control
  });

  const {
    fields: packageFields,
    append: packageAppend,
    remove: packageRemove
  } = useFieldArray({
    name: `items.${itemIndex}.packaging_document_data`,
    control
  });

  //Remaining and Actual packages for each package type
  const [remainingPackages, setRemainingPackages] = useState<IRemainingPackages[]>([
    {
      packageId: '',
      packageDisplayName: '',
      noOfPackages: 0,
      actualNumberOfPackages: 0
    }
  ]);

  //Aggregate packages for each package type
  const aggregatePackages = () => {
    const packages = packageDetailWatch;
    const resultMap = packages.reduce((acc: any, pkg: any) => {
      if (pkg?.package_details?.id) {
        const id = pkg?.package_details?.id;
        if (!acc.has(id)) {
          acc.set(id, {
            packageId: id,
            packageDisplayName: pkg?.package_details?.package_display_name,
            noOfPackages: 0
          });
        }
        const existingEntry = acc.get(id);
        existingEntry.noOfPackages += Number(pkg?.no_of_packages);
        acc.set(id, existingEntry);
      }
      return acc;
    }, new Map());

    return Array.from(resultMap.values());
  };

  //Sets Actual packages on initial load
  useEffect(() => {
    if (data && data[itemIndex]?.package) {
      const newPackageDetails = data[itemIndex].package.map((pkg: any, index: number) => ({
        packageId: pkg.id,
        packageDisplayName: pkg.package_display_name,
        actualNumberOfPackages: pkg.no_of_package,
        noOfPackages: 0
      }));
      setRemainingPackages(newPackageDetails);
    }
  }, [data, itemIndex]);

  //Sets packages on package change and handles error messages
  useEffect(() => {
    const newPackageDetails: any = aggregatePackages();
    setRemainingPackages((prevRemainingPackages) => {
      const tempRemainingPackages = prevRemainingPackages.map((pkg: IRemainingPackages) => ({
        ...pkg,
        noOfPackages: 0
      }));
      newPackageDetails.forEach((newPkg: any) => {
        const index = tempRemainingPackages.findIndex(
          (pkg: IRemainingPackages) => pkg.packageId === newPkg.packageId
        );
        if (index !== -1) {
          tempRemainingPackages[index] = {
            ...tempRemainingPackages[index],
            noOfPackages: newPkg.noOfPackages
          };
        }
      });
      return tempRemainingPackages;
    });
    errorHandling(newPackageDetails);
  }, [packageDetailWatch]);

  //Handles error messages
  const errorHandling = (packageDetails: any) => {
    const errorMessages = remainingPackages
      .map((item: IRemainingPackages) => {
        const index = packageDetails.find((pkg: any) => pkg.packageId === item.packageId);
        if (index && item.noOfPackages !== item.actualNumberOfPackages) {
          return `${item.packageDisplayName} should be ${item.actualNumberOfPackages}`;
        }
        return null;
      })
      .filter((message) => message !== null);

    if (errorMessages.length > 0) {
      const errorMessage = `Total Number of Packages for ${errorMessages.join(', ')}`;
      setError(name, {
        type: data[itemIndex].order_item_id,
        message: errorMessage
      });
    } else {
      clearErrors(name);
    }
  };

  const handleAddPackageField = () => {
    packageAppend({
      net_wt: null,
      batch_number: null,
      start_package_no: null,
      end_package_no: null,
      tare_wt: null,
      unit_of_tare_wt: null,
      gross_wt: null,
      no_of_packages: null
    });
  };

  const handleDeletePackage = (index: any) => {
    packageRemove(index);
  };

  return (
    <>
      <div className={css.itemWrapper} key={itemIndex}>
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              rootClassName={css.fieldSpacing}
              label="Item Name"
              required
              disabled
            />
          )}
        />
        <div className={css.remainingPackages}>
          {remainingPackages.map((item: any, index: number) => {
            const packages = item.actualNumberOfPackages - item.noOfPackages;
            return (
              <div key={index}>
                <ToolTip title="Remaining Packages">
                  <div>
                    {packages > 0 && (
                      <Typography variant="h5">
                        <div className={css.packageDisplay}>
                          <Chip variant="filled-blue" label={packages.toString()} />X{' '}
                          {item.packageDisplayName}
                        </div>
                      </Typography>
                    )}
                    {packages === 0 && (
                      <Typography variant="h5">
                        <div className={css.packageDisplay}>
                          <Chip variant="filled-green" label={packages.toString()} />X{' '}
                          {item.packageDisplayName}
                        </div>
                      </Typography>
                    )}
                    {packages < 0 && (
                      <Typography variant="h5">
                        <div className={css.packageDisplay}>
                          <Chip variant="filled-red" label={'0'} />X {item.packageDisplayName}
                        </div>
                      </Typography>
                    )}
                  </div>
                </ToolTip>
              </div>
            );
          })}
        </div>

        <div className={css.packageDetailWrapper}>
          {packageFields.map((item: any, index: number) => {
            const handleDeleteClick = () => handleDeletePackage(index);
            return (
              <PackageItemWrapper
                handleDeleteClick={handleDeleteClick}
                package_details={`items.${itemIndex}.packaging_document_data.${index}.package_details`}
                batch_number={`items.${itemIndex}.packaging_document_data.${index}.batch_number`}
                start_package_no={`items.${itemIndex}.packaging_document_data.${index}.start_package_no`}
                end_package_no={`items.${itemIndex}.packaging_document_data.${index}.end_package_no`}
                tare_wt={`items.${itemIndex}.packaging_document_data.${index}.tare_wt`}
                unit_of_tare_wt={`items.${itemIndex}.packaging_document_data.${index}.unit_of_tare_wt`}
                gross_wt={`items.${itemIndex}.packaging_document_data.${index}.gross_wt`}
                no_of_packages={`items.${itemIndex}.packaging_document_data.${index}.no_of_packages`}
                net_wt={`items.${itemIndex}.packaging_document_data.${index}.net_wt`}
                showDelete={packageFields.length > 1}
                control={control}
                key={item.id}
                id={item.id}
                itemDetails={data?.find(
                  (d: any) => d.order_item_id === itemFieldArrayValue.order_item_id
                )}
                disableForm={disableForm}
              />
            );
          })}
        </div>
        <Button
          variant="text"
          onClick={handleAddPackageField}
          startIcon={<IconNode src={Images.plusRed} alt="add icon" />}>
          Add Different Batch/Package
        </Button>
      </div>
      <div className={css.itemErrors}>
        <span className={css.helperTextClass}>
          {/* @ts-ignore: Unreachable code error */}
          {errors?.items?.length && errors?.items[itemIndex]?.name?.message}
        </span>
      </div>
    </>
  );
};
const PackageItemWrapper = (props: any) => {
  const {
    handleDeleteClick,
    package_details,
    batch_number,
    start_package_no,
    end_package_no,
    tare_wt,
    unit_of_tare_wt,
    gross_wt,
    no_of_packages,
    net_wt,
    showDelete,
    control,
    itemDetails,
    disableForm = false
  } = props;
  const watchField = useWatch({
    name: [package_details, no_of_packages, gross_wt, unit_of_tare_wt],
    control
  });

  useEffect(() => {
    setValue(
      unit_of_tare_wt,
      itemDetails[0]?.package[0]
        ? itemDetails[0]?.package[0]?.unit_of_weight
        : itemDetails[0]?.package?.unit_of_weight ?? '-'
    );
  }, []);

  useEffect(() => {
    const calculatedNetWeight: any = watchField[0]?.weight * watchField[1];
    const calculatedTareWeight = parseFloat((watchField[2] - calculatedNetWeight).toFixed(4));

    setValue(net_wt, calculatedNetWeight);
    setValue(tare_wt, calculatedTareWeight);
  }, [watchField[0], watchField[1], watchField[2]]);

  const { setValue } = useFormContext();

  return (
    <div className={css.packageItemWrapper}>
      <div className={css.packageItemTitle}>
        <Typography variant="h5">Package Details</Typography>
        {showDelete && (
          <Button
            variant="text"
            title="Delete Item"
            onClick={handleDeleteClick}
            startIcon={<IconNode src={Images.deleteRed} alt="delete icon" />}>
            Delete
          </Button>
        )}
      </div>
      <div className={css.rowWrapper}>
        <Controller
          name={batch_number}
          control={control}
          render={({ field, fieldState }) => (
            <SelectLabel
              {...field}
              rootClassName={css.fieldWrapper}
              label="Batch Number"
              required
              isDisabled={disableForm}
              placeholder="Select one"
              options={itemDetails?.batch ?? []}
              getOptionLabel={(option: any) => option?.batch_number ?? '-'}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name={no_of_packages}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              rootClassName={css.fieldWrapper}
              required
              placeholder="Enter"
              type="text"
              disabled={disableForm}
              label="No of Packages"
              error={fieldState.invalid}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name={package_details}
          control={control}
          render={({ field, fieldState }) => (
            <SelectLabel
              {...field}
              rootClassName={css.fieldWrapper}
              label="Package Type"
              required
              isDisabled={disableForm}
              placeholder="Select one"
              options={itemDetails?.package ?? []}
              getOptionLabel={(option: any) => option?.package_display_name ?? '-'}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name={start_package_no}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              rootClassName={css.fieldWrapper}
              required
              placeholder="Enter"
              type="text"
              disabled={disableForm}
              label="Drum Start No."
              error={fieldState.invalid}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name={end_package_no}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              rootClassName={css.fieldWrapper}
              required
              placeholder="Enter"
              type="text"
              disabled={disableForm}
              label="Drum End No."
              error={fieldState.invalid}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </div>
      <div className={css.rowWrapper}>
        <Controller
          name={gross_wt}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              rootClassName={css.fieldWrapper}
              required
              placeholder="Enter"
              type="text"
              disabled={disableForm}
              label={`Gross Wt. ${watchField[3]}`}
              error={fieldState.invalid}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name={tare_wt}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              rootClassName={css.fieldWrapper}
              required
              placeholder="Enter"
              type="text"
              disabled
              label={`Tare Wt. ${watchField[3]}`}
              error={fieldState.invalid}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />}
              helperText={fieldState.error?.message}
            />
          )}
        />
        <Controller
          name={net_wt}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              rootClassName={css.fieldWrapper}
              required
              placeholder="Enter"
              type="text"
              disabled
              label={`Net Wt. ${watchField[3]}`}
              error={fieldState.invalid}
              endIcon={fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </div>
    </div>
  );
};

export default ItemDetails;
