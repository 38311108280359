import { memo } from 'react';
import JoditEditor from 'jodit-react';
import './index.style.scss';

interface TextEditorProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  error?: boolean;
  required?: boolean;
  labelClassName?: string;
  rootClassName?: string;
  helperText?: string;
  height?: number;
  placeholder?: string;
}

const TextEditor = (props: TextEditorProps) => {
  const {
    value,
    onChange,
    label,
    error,
    required,
    labelClassName,
    rootClassName,
    helperText,
    height = 200,
    placeholder = 'Start Typing..',
    ...otherProps
  } = props;
  const helperTextClass = error ? 'helper-text-error' : undefined;
  const showAsterisk = required ? ' *' : undefined;
  return (
    <label className={`text-editor-root ${rootClassName}`}>
      {label && (
        <span data-value={showAsterisk} className={`text-editor-label ${labelClassName}`}>
          {label}
        </span>
      )}
      <JoditEditor
        value={value}
        onChange={onChange}
        config={{
          height: height
        }}
        {...otherProps}
      />
      {helperText && <span className={`field-helperText ${helperTextClass}`}>{helperText}</span>}
    </label>
  );
};

export default memo(TextEditor);
