import css from './index.module.scss';
import { Typography } from '@components/base';
import { ListPlaceholder, DataGrid } from '@components/common';
import { useState, useEffect, useMemo, FC } from 'react';
import { fetchCustomerPaymentDetail } from '@services/customer.service';
import notify from '@helpers/toastify-helper';
import { Chip, IconNode } from '@components/base';
import Images from '@assets/images';
import { ICustomerInfo } from '@helpers/types/customer';
import { getFormattedDate } from '@helpers/utils';

interface PaymentHistoryTabProps {
  customerInfo: ICustomerInfo;
}

interface RowGridType {
  order_no: string;
  amount_due: number;
  amount_paid: number;
  order_amount: number;
  status: FC;
  doc_link: FC;
}

const PaymentHistoryTab = (props: PaymentHistoryTabProps) => {
  const [paymentDetails, setPaymentDetails] = useState<any>();
  const fetchPaymentDetails = async () => {
    const paymentDetails = await fetchCustomerPaymentDetail(props.customerInfo.customer_id);
    if (paymentDetails?.success) {
      setPaymentDetails(paymentDetails.data);
    } else {
      notify({
        message: paymentDetails.error ?? 'Unable to fetch payment data',
        severity: 'error'
      });
    }
  };

  useEffect(() => {
    fetchPaymentDetails();
  }, []);

  const [documentColumn, documentData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: 'Order No.',
        Footer: (
          <span>
            Total Order:
            <br />
            <b>{paymentDetails?.order_count}</b>
          </span>
        ),
        accessor: 'order_no'
      },
      {
        Header: 'Payment Date',
        accessor: 'payment_date'
      },
      {
        Header: 'Payment Mode',
        accessor: 'payment_mode'
      },
      // {
      //   Header: 'Amount Due',
      //   Footer: (
      //     <span>
      //       <span style={{ marginBottom: 7, textAlign: 'left' }}>Total Amount Due:</span>
      //       <br />
      //       <b>{Number(paymentDetails?.total_amount_due).toLocaleString()}</b>
      //     </span>
      //   ),
      //   accessor: 'amount_due'
      // },
      {
        Header: 'Amount Paid',
        Footer: (
          <span>
            <span style={{ marginBottom: 7 }}>Total Amount:</span>
            <br />
            <b>USD {Number(paymentDetails?.total_amount_paid).toLocaleString()}</b>
          </span>
        ),
        accessor: 'amount_paid'
      },
      {
        Header: 'Order Amount',
        Footer: (
          <span>
            <span style={{ marginBottom: 7 }}>Total Amount:</span>
            <br />
            <b>USD {Number(paymentDetails?.total_amount).toLocaleString()}</b>
          </span>
        ),
        accessor: 'order_amount'
      },
      {
        Header: 'Status',
        Footer: '',
        accessor: 'status'
      },
      {
        Header: '',
        Footer: '',
        accessor: 'doc_link'
      }
    ];

    const row: Array<RowGridType> = paymentDetails?.results?.map((prod: any) => ({
      order_no: prod.readable_order_id,
      payment_date: prod.payment_date ? getFormattedDate(prod.payment_date) : '-',
      payment_mode: prod.payment_mode ? prod.payment_mode : '-',
      // amount_due: Number(prod.amount_due).toLocaleString(),
      amount_paid: Number(prod.amount_paid).toLocaleString(),
      order_amount: Number(prod.total_amount).toLocaleString(),
      //Hardcoding Done for now, keeping conditions intact
      status: !prod.amount_due ? (
        <Chip label="Pending" className="chip-center" variant="filled-yellow" clickable={false} />
      ) : (
        <Chip label="Done" className="chip-center" variant="filled-green" clickable={false} />
      ),
      doc_link: prod.payment_proof?.length ? (
        <a
          href={prod.payment_proof}
          download={prod.payment_proof}
          target="_blank"
          rel="noopener noreferrer">
          <IconNode src={Images.downloadGrey} alt="download" />
        </a>
      ) : (
        <IconNode src={Images.downloadLightGrey} alt="download-disabled" />
      )
    }));

    return [column, row];
  }, [paymentDetails]);

  return (
    <div className={css.mainWrapper}>
      <Typography variant="h4">Payment History</Typography>
      {paymentDetails?.results?.length ? (
        <DataGrid columns={documentColumn} data={documentData} addFooter={true} />
      ) : (
        <ListPlaceholder
          title="No Payment history available"
          supportingText="When payment history is available, it will appear here"
          buttonText=""
          to={''}
        />
      )}
    </div>
  );
};

export default PaymentHistoryTab;
