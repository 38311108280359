import { useEffect, useRef, useState } from 'react';
import css from './index.module.scss';
import { Typography, Loader } from '@components/base';
import { BankDetailForm, Seo, SideBar, CustomerDetailForm } from '@components/common';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addBankDetailSchema, boeCustomerSchema } from '@helpers/yup';
import CommericalDetailForm from './components/commerical-detail-form';
import { commercialSchema } from '@helpers/yup/generate-boe.schema';
import { fetchOrderInfo, fetchOrderInfoFinance } from '@services/order.service';
import createBillOfExchange from '@services/bill-of-exchange.service';
import notify from '@helpers/toastify-helper';
import { CLIENT_ROUTES } from '@router/routes';
import { OrderInfo } from '@helpers/types/order';
import { format } from 'date-fns';
import { fetchCustomerDetail } from '@services/customer.service';
import { IBankInfo } from '@helpers/types/bank';
import { useScrollToTop } from '@helpers/hooks';
import { ISelect } from '@helpers/types';
import { cities, countries, states } from '@helpers/constants';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const writtenNumber = require('written-number');

interface GenerateBoeStates {
  activeStep: number;
  isLoading: boolean;
  orderInfo: OrderInfo | null;
  customerBankInfo: IBankInfo[];
}

const GenerateBoe = () => {
  const [billOfExchangeState, setBillOfExchangeState] = useState<GenerateBoeStates>({
    activeStep: 0,
    isLoading: false,
    orderInfo: null,
    customerBankInfo: []
  });

  const { activeStep, isLoading, orderInfo, customerBankInfo } = billOfExchangeState;

  const navigate = useNavigate();
  const urlParams = useParams();
  const { id, taskid } = urlParams;

  const location = useLocation();
  const { data } = location.state || {};

  useEffect(() => {
    getOrderInfo();
  }, [urlParams]);

  const customerDetailForm = useForm({
    resolver: yupResolver(boeCustomerSchema),
    defaultValues: {
      name: '',
      address_line1: '',
      address_line2: '',
      zip_code: '',
      city: null || '' || { value: '', label: '' },
      state: null || '' || { value: '', label: '' },
      country: null || '' || { value: '', label: '' }
    }
  });

  const commericalForm = useForm({
    resolver: yupResolver(commercialSchema),
    defaultValues: {
      commercial_invoice_no: '',
      commercial_invoice_date: '' || null,
      commercial_invoice_due_amount: '',
      bl_awb_no: '',
      bl_awb_date: '' || null,
      awb_service_name: '',
      boe_number: '',
      payment_term: 'LC',
      starting_statement: '',
      closing_statement: '',
      customer_bank: null
    }
  });

  const bankDetailForm = useForm({
    resolver: yupResolver(addBankDetailSchema),
    defaultValues: {
      bankdetails: [
        {
          bank_id: '',
          bank_name: '',
          branch_name: '',
          bank_account_holder_name: '',
          account_number: '',
          ifsc_code: '',
          swift_code: '',
          address_line1: '',
          address_line2: '',
          zip_code: '',
          city: null,
          state: null,
          country: null
        }
      ]
    }
  });

  const { reset: customerReset } = customerDetailForm;
  const { reset: elchemyBankDetails } = bankDetailForm;
  const { reset: commercialDetails } = commericalForm;
  const topRef = useRef(null);

  const getOrderInfo = async () => {
    const { id, taskid } = urlParams;
    if (id) {
      setBillOfExchangeState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await fetchOrderInfoFinance(id);
      if (response.success) {
        const { data } = response;
        const orderInfo = data as OrderInfo;

        fetchCustomerData(orderInfo.customer?.customer_id as string);

        setBillOfExchangeState((prevState) => ({
          ...prevState,
          orderInfo: data,
          isLoading: false
        }));
        
        customerReset({
          name: `${orderInfo.customer?.name}`,
          address_line1: `${
            orderInfo?.customer_address?.address_line1
              ? orderInfo.customer_address?.address_line1
              : ''
          }`,
          address_line2: `${
            orderInfo?.customer_address?.address_line2
              ? orderInfo.customer_address?.address_line2
              : ''
          }`,
          zip_code: `${
            orderInfo.customer_address?.zip_code ? orderInfo.customer_address?.zip_code : ''
          }`,
          city: orderInfo?.customer_address?.city_obj,
          state: orderInfo?.customer_address?.state_obj,
          country: orderInfo?.customer_address?.country_obj,
        });
        // elchemyBankDetails({
        //   bankdetails: [
        //     {
        //       bank_id: '',
        //       bank_name: `${orderInfo.elchemy_bank?.bank_name}`,
        //       branch_name: `${orderInfo.elchemy_bank?.branch_name}`,
        //       bank_account_holder_name: `${orderInfo.elchemy_bank?.bank_account_holder_name}`,
        //       account_number: `${orderInfo.elchemy_bank?.account_number}`,
        //       ifsc_code: `${orderInfo.elchemy_bank?.ifsc_code}`,
        //       swift_code: `${orderInfo.elchemy_bank?.swift_code}`,
        //       address_line1: `${orderInfo.elchemy_bank?.address_line1}`,
        //       address_line2: `${orderInfo.elchemy_bank?.address_line2}`,
        //       zip_code: `${orderInfo.elchemy_bank?.zip_code}`,
        //       city: null,
        //       state: null,
        //       country: null
        //     }
        //   ]
        // });
        // commercialDetails({
        //     commercial_invoice_no: `${orderInfo.order_boe?.commercial_invoice_no}`,
        //     commercial_invoice_date:`${orderInfo.order_boe?.commercial_invoice_date}`,
        //     commercial_invoice_due_amount: `${orderInfo.order_boe?.commercial_invoice_due_amount}`,
        //     bl_awb_no: `${orderInfo.order_boe?.bl_awb_no}`,
        //     bl_awb_date: `${orderInfo.order_boe?.bl_awb_date}`,
        //     boe_number: `${orderInfo.order_boe?.boe_number}`
        // })
      } else if (response.error) navigate(`/${CLIENT_ROUTES.order}`);
    } else navigate(`/${CLIENT_ROUTES.order}`);
  };

  const handleActiveStep = (value: number) => {
    setBillOfExchangeState((prevState) => ({ ...prevState, activeStep: value }));
  };

  const handleCommercialFormSubmit = async (data: any) => {
    const { orderInfo } = billOfExchangeState;
    if (orderInfo) {
      const reqBody = {
        order: orderInfo.order_id,
        commercial_invoice_no: commericalForm.control._formValues.commercial_invoice_no,
        commercial_invoice_date: `${format(
          new Date(commericalForm.control._formValues.commercial_invoice_date),
          'yyyy-MM-dd'
        )}`,
        commercial_invoice_due_amount:
          commericalForm.control._formValues.commercial_invoice_due_amount,
        commercial_invoice_due_amount_in_words: writtenNumber(
          commericalForm.control._formValues.commercial_invoice_due_amount,
          {
            lang: 'en'
          }
        )?.toUpperCase(),
        bl_awb_no: commericalForm.control._formValues.bl_awb_no,
        bl_awb_date: `${format(
          new Date(commericalForm.control._formValues.bl_awb_date),
          'yyyy-MM-dd'
        )}`,
        awb_service_name: commericalForm.control._formValues?.awb_service_name,
        starting_statement: commericalForm.control._formValues.starting_statement,
        closing_statement: commericalForm.control._formValues.closing_statement,
        customer_bank: commericalForm.control._formValues.customer_bank?.bank_id
      };

      const response = await createBillOfExchange(taskid, reqBody);
      if (response.success) {
        notify({ message: 'Bill of Exchange Generated successfully' });
        navigate(`/${CLIENT_ROUTES.order}/${orderInfo.order_id}`);
      } else notify({ message: response.error ?? 'Unable to update order', severity: 'error' });
    }
  };

  const onSave = () => {
    setBillOfExchangeState((prevState) => ({ ...prevState, activeStep: activeStep + 1 }));
  };

  const onBack = () => {
    setBillOfExchangeState((prevState) => ({ ...prevState, activeStep: activeStep - 1 }));
  };

  const onCancel = () => {
    navigate(`/order/${id}`);
  };

  const fetchCustomerData = async (customerId: string) => {
    const customerData = await fetchCustomerDetail(customerId);
    if (customerData?.success) {
      setBillOfExchangeState((prevState) => ({
        ...prevState,
        customerBankInfo: customerData.data.bankdetails
      }));
    } else {
      notify({ message: customerData.error ?? 'Unable to fetch customer data', severity: 'error' });
    }
  };

  useScrollToTop({ topRef, dependencyArray: [activeStep] });

  return (
    <main className={css.mainWrapper}>
      <Seo title="Generate Bill of Exchange" />
      <div className={css.headerWrapper}>
        <Typography variant="h4">Generate BoE</Typography>
        <Typography variant="span">0{activeStep + 1} of 03</Typography>
      </div>
      <section className={css.subMainWrapper} ref={topRef}>
        <div className={css.sidebarWrapper}>
          <SideBar activeStep={activeStep} onClick={handleActiveStep}>
            <SideBar.Item label="Customer Details" value={0} />
            <SideBar.Item label="Commercial" value={1} disabled={activeStep! <= 1} />
            {/* <SideBar.Item label="Elchemy Bank Details" value={2} disabled={activeStep! <= 2} /> */}
          </SideBar>
        </div>
        <FormProvider {...customerDetailForm}>
          {activeStep === 0 && (
            <CustomerDetailForm onCancelClick={() => onCancel()} onFormSubmit={() => onSave()} />
          )}
        </FormProvider>
        <FormProvider {...commericalForm}>
          {activeStep === 1 && (
            <CommericalDetailForm
              onCancelClick={() => onCancel()}
              onBackClick={() => onBack()}
              onFormSubmit={handleCommercialFormSubmit}
              orderInfo={orderInfo}
              customerBankInfo={customerBankInfo}
            />
          )}
        </FormProvider>
        {/* <FormProvider {...bankDetailForm}>
          {activeStep === 2 && (
            <BankDetailForm
              onCancelClick={() => onCancel()}
              onBackClick={() => onBack()}
              onFormSubmit={handleCommercialFormSubmit}
            />
          )}
        </FormProvider> */}
      </section>
      <Loader open={isLoading} />
    </main>
  );
};

export default GenerateBoe;
