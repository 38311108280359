import { CSSProperties, Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import { IconNode, Typography } from '@components/base';
import Images from '@assets/images';
import { OrderItem, OrderInfo } from '@helpers/types/order';
import { PAYMENT_TERMS } from '@helpers/constants';
import { getFormattedDate, sanitizeValue } from '@helpers/utils';
import StepProgressBar from '@components/common/progress-bar/progress-bar-step';
import { MultiplePlaceholder } from '@components/common';

interface OrderCardProps {
  customerName: string;
  items: OrderItem[];
  paymentTerm: string;
  placeOfDelivery: string;
  createdAt: Date;
  totalAmount: string;
  amountDue: string;
  currentState: string;
  cancelStatus?: any;
  orderInfo: OrderInfo;
}

interface ProgressBardState {
  labelList: any[];
  stepLabels: number[][];
  isCurrent: number;
}

const CustomerOrderCard = (props: OrderCardProps) => {
  const {
    customerName,
    placeOfDelivery,
    createdAt,
    totalAmount,
    amountDue,
    currentState,
    orderInfo,
    paymentTerm
  } = props;
  const { currency } = orderInfo;

  const [progressBarState, setProgressBarState] = useState<ProgressBardState>({
    labelList: [],
    stepLabels: [],
    isCurrent: 0
  });

  const { labelList, stepLabels, isCurrent } = progressBarState;

  const { estimatedDateValue, primaryTitle } = useMemo(() => {
    const shipmentArrivalEstimate = orderInfo?.task_estimates?.find(
      (estimate) => estimate.state_type === 'Shipment Arrival at POD'
    );

    const shipmentDepartureEstimate = orderInfo?.task_estimates?.find(
      (estimate) => estimate.state_type === 'Shipment Departure from POL'
    );

    const estimatedDateValue = shipmentArrivalEstimate
      ? shipmentArrivalEstimate.estimated_date_value
      : shipmentDepartureEstimate?.estimated_date_value;

    const primaryTitle = shipmentArrivalEstimate
      ? 'Estimated Arrival Date'
      : 'Estimated Departure Date';

    return { estimatedDateValue, primaryTitle };
  }, [orderInfo?.task_estimates]);

  const supplierName = useMemo(() => {
    const { items } = props;
    const names = items
      .filter((data) => data?.supplier_name !== undefined)
      .map((data) => data.supplier_name);
    return <MultiplePlaceholder names={names} />;
  }, [props.items]);

  const productName = useMemo(() => {
    const { items } = props;
    const names = items.map((data) => data.product_name);
    return <MultiplePlaceholder names={names} />;
  }, [props.items]);

  // const paymentTerms = useMemo(() => {
  //   const { paymentTerm } = props;
  //   const term = PAYMENT_TERMS.find((item) => item.value === paymentTerm);
  //   if (term) return term.label;
  //   return '-';
  // }, [props.paymentTerm]);

  const formattedCreatedAt = useMemo(() => {
    return getFormattedDate(createdAt);
  }, [createdAt]);

  const formattedTotalAmount = useMemo(() => {
    if (totalAmount?.length) {
      return `${currency} ${Number(totalAmount).toLocaleString()}`;
    } else {
      return '0';
    }
  }, [totalAmount]);

  const formattedAmountDue = useMemo(() => {
    if (totalAmount?.length) {
      return `${currency} ${Number(amountDue).toLocaleString()}`;
    } else {
      return '0';
    }
  }, [amountDue]);

  const cancelStatus = useMemo(() => {
    return {
      is_cancelled: orderInfo?.is_cancelled,
      cancel_remarks: orderInfo?.cancel_remarks,
      last_updated_by: orderInfo?.last_updated_by,
      updated_at: getFormattedDate(orderInfo?.updated_at)
    };
  }, [orderInfo]);

  useEffect(() => {
    if (orderInfo?.customer_state_activity_log) {
      const labels = orderInfo?.customer_state_activity_log.map((item: any) => {
        return {
          label: item.state_name,
          date:
            getFormattedDate(
              item.actual_date ? item.actual_date : item.estimated_date ? item.estimated_date : ''
            ) ?? '',
          logs: item.logs?.map((log: any) => {
            return {
              label: log.log,
              date: getFormattedDate(log.date ?? '') ?? ''
            };
          }),
          is_current_state: item.is_current_state
        };
      });

      const isCurrentState = orderInfo?.customer_state_activity_log.findIndex(
        (item: any) => item.is_current_state === true
      );
      setProgressBarState({
        labelList: labels,
        stepLabels: stepLabels,
        isCurrent: isCurrentState
      });
    }
  }, [orderInfo]);

  return (
    <div className={css.orderCardContainer}>
      {cancelStatus.is_cancelled ? (
        <div className={css.cancelWrapper}>
          <div className={css.columnWrapper}>
            <Typography variant="p" className={css.cancelOrderTitle}>
              This order has been cancelled on {cancelStatus.updated_at}
            </Typography>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={css.orderCardFirstWrapper}>
        <UserCard src={Images.productGrey} primaryTitle="Product" secondaryTitle={productName} />
        <UserCard
          showInfoIcon={false}
          showPrimaryIcon={false}
          primaryTitle="Proforma Invoice"
          secondaryTitle={orderInfo?.pi_document_no ?? '-'}
        />
        <UserCard
          showInfoIcon={false}
          showPrimaryIcon={false}
          primaryTitle="PO Reference Number"
          secondaryTitle={orderInfo?.po_reference_no ?? '-'}
        />
      </div>
      <div className={css.orderCardFirstWrapper}>
        <UserCard
          primaryTitle="Payment Terms"
          secondaryTitle={paymentTerm}
          showPrimaryIcon={false}
          showInfoIcon={false}
        />
        <UserCard
          primaryTitle="Due Payment/Total Payment"
          secondaryTitle={`${formattedAmountDue}/${formattedTotalAmount}`}
          showPrimaryIcon={false}
          showInfoIcon={false}
        />
        <UserCard
          primaryTitle="Latest Payment Date"
          secondaryTitle={getFormattedDate(orderInfo?.latest_payment_date) ?? '-'}
          showPrimaryIcon={false}
          showInfoIcon={false}
        />
        <UserCard
          primaryTitle={primaryTitle}
          secondaryTitle={sanitizeValue(getFormattedDate(estimatedDateValue))}
          showPrimaryIcon={false}
          showInfoIcon={false}
        />
      </div>

      <div className={css.rowWrapper}>
        <StepProgressBar label={labelList} currentStep={isCurrent} />
      </div>
    </div>
  );
};

interface UserCardProps {
  src?: string;
  primaryTitle: string;
  secondaryTitle: ReactNode;
  showPrimaryIcon?: boolean;
  showInfoIcon?: boolean;
  style?: CSSProperties;
}
const UserCard = (props: UserCardProps) => {
  const {
    src,
    primaryTitle,
    secondaryTitle,
    style,
    showPrimaryIcon = true,
    showInfoIcon = false
  } = props;
  return (
    <div className={css.userCardContainer} style={style}>
      {showPrimaryIcon && (
        <IconNode src={src} alt={`${primaryTitle} icon`} className={css.profileIcon} />
      )}
      <div className={css.userCardDetailColumn}>
        <div className={css.titleWrapper}>
          <Typography variant="span" className={css.primaryTitleLabel}>
            {primaryTitle}
          </Typography>
          {showInfoIcon && <IconNode src={Images.infoGrey} alt="info icon" />}
        </div>
        <Typography variant="body">{secondaryTitle}</Typography>
      </div>
    </div>
  );
};
export default CustomerOrderCard;
