import css from './index.module.scss';
import { getDiffBetweenTwoDates, getFormattedDate, sanitizeValue } from '@helpers/utils';
import { Chip, Typography } from '@components/base';
import { ITaskEstimateObj } from '../../../orderOverview';

interface EstimateProps {
  previewData?: ITaskEstimateObj[];
}

const Estimate = (props: EstimateProps) => {
  return (
    <div className={css.detailWrapper}>
      <div className={css.detailsTitle}>
        <Typography variant="h4">Estimates</Typography>
      </div>
      <div className={css.detailContainer}>
        <div className={css.itemTable}>
          <div className={css.estimateHeader}>
            <Typography variant="label" className={css.estimateHeaderLabel}>
              S. No
            </Typography>
            <Typography variant="label" className={css.estimateHeaderLabel}>
              State Type
            </Typography>
            <Typography variant="label" className={css.estimateHeaderLabel}>
              Estimated Date
            </Typography>
            <Typography variant="label" className={css.estimateHeaderLabel}>
              Status
            </Typography>
          </div>
        </div>
        {props?.previewData?.map((item: any, index: number) => {
          const estimatedDate = item.estimated_date ? item.estimated_date : item.actual_date;
          const status = item.actual_date
            ? getDiffBetweenTwoDates(estimatedDate, item.actual_date)
            : '-';

          return (
            <>
              <div className={css.estimateHeader}>
                <Typography variant="body">{index + 1}</Typography>
                <Typography variant="body">{item.state_type}</Typography>
                <Typography variant="body">
                  {sanitizeValue(getFormattedDate(item.estimated_date || item.actual_date))}
                </Typography>
                <div>
                  {status === '-' ? (
                    <Typography variant="body">-</Typography>
                  ) : (
                    <div>
                      <Chip
                        label={(() => {
                          switch (true) {
                            case Number(status) === -1:
                              return 'Delayed by 1 day';
                            case Number(status) < -1:
                              return `Delayed by ${Math.abs(Number(status))} days`;
                            case Number(status) === 1:
                              return `Early by ${status} day`;
                            case Number(status) > 1:
                              return `Early by ${status} days`;
                            default:
                              return 'On Time';
                          }
                        })()}
                        className={css.chipCenter}
                        variant={
                          Number(status) > 0
                            ? 'filled-green'
                            : Number(status) === 0
                            ? 'filled-green'
                            : 'filled-red'
                        }
                        clickable={false}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default Estimate;
