import { Fragment, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import { BreadCrumb, Loader, Tab, Tabs } from '@components/base';
import { LinkButton, Seo } from '@components/common';
import { useNavigate, useParams } from 'react-router-dom';
import { CLIENT_ROUTES } from '@router/routes';
import { fetchCustomerDetail } from '@services/customer.service';
import CustomerDetailCard from './components/customer-detail-card';
import POCDetail from './components/poc-detail';
import { ICustomerInfo } from '@helpers/types/customer';
import CustomerDetailTab from './components/customer-detail-tab';
import CustomerDocumentTab from './components/customer-document-tab';
import PaymentHistoryTab from './components/payment-history-tab';
import OrdersTab from './components/orders-tab';
import AccessWrapper from '@authorization/access-wrapper';
import { MODULE_NAMES } from '@helpers/constants';

interface ViewCustomerStates {
  customerInfo: ICustomerInfo | null;
  tabState: number;
  isLoading: boolean;
}

const ViewCustomer = (props: any) => {
  const { modulePermissions = {}, otherModulePermissions = [] } = props;
  const { actions } = modulePermissions;
  const orderActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.order)?.actions,
    [otherModulePermissions]
  );

  const [customerState, setCustomerState] = useState<ViewCustomerStates>({
    customerInfo: null,
    tabState: 0,
    isLoading: false
  });
  const navigate = useNavigate();
  const params = useParams();

  const { customerInfo, tabState, isLoading } = customerState;

  useEffect(() => {
    fetchCustomerInfo();
  }, []);

  const fetchCustomerInfo = async () => {
    const { id } = params;
    if (id) {
      setCustomerState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await fetchCustomerDetail(id);
      setCustomerState((prevState) => ({ ...prevState, isLoading: false }));
      if (response.success) {
        const { data } = response;
        setCustomerState((prevState) => ({ ...prevState, customerInfo: data }));
      } else {
        navigate('/404');
      }
    } else {
      navigate(`/${CLIENT_ROUTES.customer}`);
    }
  };

  const handleTabChange = useCallback((activeStep: number) => {
    setCustomerState((prevState) => ({ ...prevState, tabState: activeStep }));
  }, []);

  const handleChange = (data: ICustomerInfo) => {
    setCustomerState((prevState) => ({ ...prevState, customerInfo: { ...data } }));
  };

  return (
    <AccessWrapper show={actions?.read ?? false} showUnauthorised>
      <main className={css.mainWrapper}>
        {customerInfo && <Seo title={customerInfo.name} />}
        {customerInfo && (
          <div className={css.header}>
            <BreadCrumb>
              <BreadCrumb.Item to={`/${CLIENT_ROUTES.customer}`} value={0}>
                Customer List
              </BreadCrumb.Item>
              <BreadCrumb.Item
                to={`/${CLIENT_ROUTES.customer}/${customerInfo.customer_id}`}
                value={1}>
                {customerInfo.name}
              </BreadCrumb.Item>
            </BreadCrumb>
            <AccessWrapper show={orderActions?.create ?? false}>
              <LinkButton
                variant="contained"
                to={`/${CLIENT_ROUTES.order}/${CLIENT_ROUTES.addOrder}`}
                className={css.buttonWrapper}>
                Add Order
              </LinkButton>
            </AccessWrapper>
          </div>
        )}
        {customerInfo && <CustomerDetailCard customerInfo={customerInfo} />}
        {customerInfo && (
          <POCDetail
            customerId={customerInfo.customer_id}
            pocs={customerInfo.pocs}
            onChange={handleChange}
          />
        )}
        {customerInfo && (
          <Tabs value={tabState}>
            <AccessWrapper show={orderActions?.read}>
              <Tab label="Orders" value={0} onClick={() => handleTabChange(0)} />
            </AccessWrapper>
            <Tab label="Documents" value={1} onClick={() => handleTabChange(1)} />
            <Tab label="Payment History" value={2} onClick={() => handleTabChange(2)} />
            <Tab label="Customer Details" value={3} onClick={() => handleTabChange(3)} />
          </Tabs>
        )}
        {customerInfo && (
          <Fragment>
            {tabState === 0 && (
              <AccessWrapper show={orderActions?.read ?? false}>
                <OrdersTab customerId={params.id} actions={orderActions} />
              </AccessWrapper>
            )}
            {tabState === 1 && (
              <CustomerDocumentTab customerInfo={customerInfo} onChange={handleChange} />
            )}
            {tabState === 2 && <PaymentHistoryTab customerInfo={customerInfo} />}
            {tabState === 3 && (
              <CustomerDetailTab
                customerInfo={customerInfo}
                onChange={handleChange}
                actions={actions}
              />
            )}
          </Fragment>
        )}
        <Loader open={isLoading} />
      </main>
    </AccessWrapper>
  );
};

export default ViewCustomer;
