import { useMemo, useEffect, useState } from 'react';
import { DataGrid } from '@components/common';
import { Loader } from '@components/base';
import { getPendingTasks } from '@services/order.service';

import css from './index.module.scss';

interface PendingTasksProps {
  orderId: string;
}

interface PendingTasksState {
  data: Array<any>;
  isLoading: boolean;
}

interface RowGridProps {
  task_name: string;
  poc_type: string;
  pocs: Array<string>;
}

const PendingTasks = (props: PendingTasksProps) => {
  const { orderId } = props;

  const [pendingTasks, setPendingTasks] = useState<PendingTasksState>({
    data: [],
    isLoading: false
  });

  const { data, isLoading } = pendingTasks;

  useEffect(() => {
    fetchPendingTasks();
  }, []);

  const fetchPendingTasks = async () => {
    setPendingTasks((prevState: PendingTasksState) => ({ ...prevState, isLoading: true }));
    const response = await getPendingTasks(orderId);
    setPendingTasks((prevState: PendingTasksState) => ({ ...prevState, isLoading: false }));
    if (response.success) {
      const formattedData = response.data.map((item: any) => {
        return {
          task_name: item.task_name,
          poc_type: item.poc_type,
          task_id: item.task_id,
          pocs: item.pocs.length ? item.pocs.map((poc: any) => poc.full_name) : []
        };
      });
      setPendingTasks((prevState: PendingTasksState) => ({ ...prevState, data: formattedData }));
    } else {
      setPendingTasks((prevState: PendingTasksState) => ({ ...prevState, data: [] }));
    }
  };
  const [documentColumn, documentData] = useMemo(() => {
    const column: Array<any> = [
      {
        Header: 'Task Name',
        accessor: 'task_name'
      },
      {
        Header: 'User Group',
        accessor: 'poc_type'
      },
      {
        Header: 'Assigned To',
        accessor: 'pocs'
      }
    ];

    const row: Array<RowGridProps> = data?.map((item: any) => ({
      task_name: item.task_name,
      poc_type: item.poc_type,
      pocs: item.pocs.length ? item.pocs.join(', ') : '-'
    }));

    return [column, row];
  }, [data]);

  return (
    <div className={css.layoutWrapper}>
      <DataGrid tableStyle={{ width: '100%' }} columns={documentColumn} data={documentData} />
      <Loader open={isLoading} />
    </div>
  );
};

export default PendingTasks;
