import Images from '@assets/images';
import { Accordion, Button, Divider, Typography } from '@components/base';
import { Avatar } from '@components/common';
import { AppContext } from '@helpers/hooks/AppContext';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import css from './index.module.scss';
import moment from 'moment';
import Footer from '../components/footer';

interface IPICard {
  taskData: Array<Partial<ITaskTabProps>>;
}

const AddTransportDetailCard = (props: IPICard) => {
  const navigate = useNavigate();
  const { appDispatch } = useContext(AppContext);
  const { taskData } = props;
  const taskItem = taskData[0];
  const location = useLocation();

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {!taskItem.is_completed ? (
                <Avatar src={Images.illustrationEdit} alt="" />
              ) : (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              )}
              <Typography variant="h5">
                #{taskItem?.readable_task_id} - Add Transport Details
              </Typography>
            </div>
            <div className={css.pocWrapper}>
              {!taskItem.is_completed && (
                <Button
                  variant="text"
                  onClick={() => {
                    appDispatch({
                      type: 'setPIData',
                      payload: { task_id: taskItem?.task_id, order_id: taskItem?.order }
                    });
                    const data = { orderId: taskData[0]?.order };

                    navigate(
                      `/order/transport-detail/${taskItem?.task_id as string}/${
                        taskItem?.supplier_id as string
                      }`,
                      {
                        state: { data }
                      }
                    );
                  }}
                  className={css.assignButton}>
                  Add Details
                </Button>
              )}
              {!taskItem.is_completed && <Divider className={css.divider} />}
            </div>
          </div>
        </Accordion.Header>
      </Accordion>
      {/* <div className={css.footer}>
        <div className={css.textWrapper}>
          <Typography variant="h5">Created On:</Typography>
          <Typography variant="p">{getFormattedDate(taskItem?.created_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Updated On:</Typography>
          <Typography variant="p">{getFormattedDate(taskItem?.updated_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Time Taken:</Typography>
          <Typography variant="p">
            {moment(taskItem?.updated_at)?.diff(taskItem.created_at, 'days')} Day(s)
          </Typography>
        </div>
      </div> */}
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
    </div>
  );
};
export default AddTransportDetailCard;
