import * as yup from 'yup';

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Must be a valid email')
    .min(3)
    .max(255)
    .required('Email is required')
    .matches(
      /^([a-z0-9]+(?:[.+_-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*.[a-z]{2,})$/,
      'Must be a valid email'
    ),
  password: yup.string().required('Password is required')
});

const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required')
    .matches(
      /^([a-z0-9]+(?:[.+_-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*.[a-z]{2,})$/,
      'Must be a valid email'
    )
});

const resetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required')
    .matches(
      /^([a-z0-9]+(?:[.+_-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*.[a-z]{2,})$/,
      'Must be a valid email'
    ),
  password: yup
    .string()
    .trim()
    .min(8, 'Must Contain 8 Characters')
    .max(255)
    .required('Password is required')
    .matches(/[a-zA-Z]/, 'Must Contain one Character')
    .matches(/[A-Z]/, 'Must Contain one UpperCase Character')
    .matches(/[a-z]/, 'Must Contain one LoweCase Character')
    .matches(/\d/, 'Must Contain one Number')
    .matches(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/, 'Must Contain one Special Character') //eslint-disable-line
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, //eslint-disable-line
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  confirmPassword: yup
    .string()
    .trim()
    .oneOf([yup.ref('password')], "Passwords didn't match. Try again.")
});

export { loginSchema, forgotPasswordSchema, resetPasswordSchema };
