import { useEffect } from 'react';

interface scrollTopProps {
  topRef: React.MutableRefObject<any>;
  dependencyArray?: any[];
}

const useScrollToTop = (props: scrollTopProps): void => {
  const { topRef, dependencyArray = [] } = props;
  useEffect(() => {
    setTimeout(() => {
      topRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }, dependencyArray);
};

export default useScrollToTop;
