import Images from '@assets/images';
import { Accordion, Chip, Divider, IconNode, Typography } from '@components/base';
import { Avatar } from '@components/common';
import { getFormattedDate } from '@helpers/utils';
import moment from 'moment';
import { useState } from 'react';
import AddOperationCostsCard from './components';
import css from './index.module.scss';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import Footer from '../components/footer';

interface addOperationCostsProps {
  taskData: Partial<ITaskTabProps>;
  onComplete?: () => void;
}

const AddOperationCosts = (props: addOperationCostsProps) => {
  const [openModal, setopenModal] = useState(false);
  const getCompleteStatus = props.taskData.is_completed;
  const [taskIsCompleted, setTaskIsCompleted] = useState<boolean | undefined>(getCompleteStatus);
  const { taskData } = props;

  const handleStatusFromModal = (data: boolean) => {
    setTaskIsCompleted(data);
  };

  return (
    <>
      <div className={css.mainWrapper}>
        <Accordion defaultExpanded>
          <Accordion.Header className={css.accordionHeader}>
            <div className={css.header}>
              <div className={css.wrapper}>
                {getCompleteStatus || taskIsCompleted ? (
                  <Avatar src={Images.alertSuccessFaint} alt="" />
                ) : (
                  <Avatar src={Images.illustration} alt="" />
                )}
                <Typography variant="h5">
                  #{taskData?.readable_task_id} - Add Operation Costs
                </Typography>
              </div>
              <div className={css.pocWrapper}>
                {!getCompleteStatus && taskIsCompleted === false && (
                  <Chip
                    label="Add"
                    variant="outlined"
                    clickable={true}
                    className={css.uploadButton}
                    onClick={() => setopenModal(true)}
                  />
                )}
                {(!getCompleteStatus || !taskIsCompleted) && <Divider className={css.divider} />}
              </div>
            </div>
          </Accordion.Header>
        </Accordion>
        <Footer
          createdDate={props.taskData.created_at as Date}
          updatedDate={props.taskData.updated_at as Date}
        />
        {openModal && (
          <AddOperationCostsCard
            open={openModal}
            onClose={() => setopenModal(false)}
            sendStatusToCard={handleStatusFromModal}
            taskId={taskData.task_id as string}
            onComplete={props.onComplete}
          />
        )}
      </div>
    </>
  );
};

export default AddOperationCosts;
