import { memo } from 'react';
import css from './index.module.scss';
import { BoxContainer, SelectLabel, TextField } from '@components/common';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { EntityBankDetails } from '@helpers/types/entity';
import { IOrderBankDetailForm } from '@helpers/types/add-order';

interface BankDetailConatinerProps {
  options: EntityBankDetails[];
}

const BankDetailConatiner = (props: BankDetailConatinerProps) => {
  const { options } = props;

  const { control } = useFormContext<IOrderBankDetailForm>();

  const watchField = useWatch({
    name: 'elchemy_bank',
    control
  });

  const address = `${watchField?.address_line1 ?? ''} ${watchField?.address_line2 ?? ''} ${
    watchField?.city ?? ''
  } ${watchField?.state ?? ''} ${watchField?.country ?? ''} ${watchField?.zip_code ?? ''}`;

  return (
    <BoxContainer title="Entity Bank Details" className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <Controller
            name="elchemy_bank"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                autoFocus
                required
                getOptionLabel={(option: any) => option.bank_name}
                getOptionValue={(option: any) => option.bank_id}
                options={options}
                label="Bank Name"
                placeholder="Select bank"
                rootClassName={css.fieldWrapper}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <TextField
            disabled
            value={watchField?.branch_name ?? ''}
            label="Branch Name"
            placeholder="Enter branch name"
          />
        </div>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={watchField?.bank_account_holder_name ?? ''}
            label="Account Holder Name"
            placeholder="Enter account holder name"
          />
        </div>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={watchField?.account_number ?? ''}
            label="Bank Account Number"
            placeholder="Enter bank account number"
            rootClassName={css.fieldWrapper}
          />
          <TextField
            disabled
            value={watchField?.ifsc_code ?? ''}
            label="IFSC Code"
            placeholder="Enter IFSC code"
            rootClassName={css.fieldWrapper}
          />
          <TextField
            disabled
            value={watchField?.swift_code ?? ''}
            label="Swift"
            placeholder="Enter Swift code"
          />
        </div>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={address}
            label="Bank Address"
            placeholder="Enter bank address"
          />
        </div>
      </div>
    </BoxContainer>
  );
};

export default memo(BankDetailConatiner);
