/* eslint-disable @typescript-eslint/no-var-requires */
import { useContext, useEffect, useState } from 'react';
import css from './index.module.scss';
import InternalView from './internal-view';
import CustomerView from './customer-view';
import DocVisiblityView from './doc-visiblity-view';
import Chip from '@components/base/chip';
import { ICustomerPoc } from '@helpers/types/customer';
import AccessWrapper from '@authorization/access-wrapper';
import { USER_TYPES } from '@helpers/constants';
import { AppContext } from '@helpers/hooks/AppContext';

interface DocumentTabProps {
  orderId: string;
  readableOrderId: string;
  customerPocs: ICustomerPoc;
  activeSubTab?: number;
  actions: IActions;
}

const DocumentTab = (props: DocumentTabProps) => {
  const { activeSubTab, actions } = props;
  const { appState } = useContext(AppContext);
  const { userType = null } = appState;
  const [documentTabStates, setDocumentStates] = useState({
    currentTab: activeSubTab ?? 0
  });

  const { currentTab } = documentTabStates;

  const changeTab = (tab: number) => {
    setDocumentStates((prevState) => ({ ...prevState, currentTab: tab }));
  };

  useEffect(() => {
    setDocumentStates((prevState) => ({
      ...prevState,
      currentTab: userType === USER_TYPES.customer ? 1 : 0
    }));
  }, [userType]);

  return (
    <>
      <AccessWrapper show={actions?.read} showTo={[USER_TYPES.internal]} byPassShowWithShowTo>
        <div className={css.taskChipWrapper}>
          <Chip
            label="All Documents"
            variant={currentTab == 0 ? `filled` : `outlined`}
            onClick={() => changeTab(0)}
          />
          <Chip
            label="Customer View"
            variant={currentTab == 1 ? `filled` : `outlined`}
            onClick={() => changeTab(1)}
          />
          <Chip
            label="Document Visiblity"
            variant={currentTab == 2 ? `filled` : `outlined`}
            onClick={() => changeTab(2)}
          />
        </div>
        {currentTab == 0 && <InternalView {...props} />}
        {currentTab == 2 && <DocVisiblityView {...props} changeTab={changeTab} />}
      </AccessWrapper>

      {currentTab == 1 && <CustomerView {...props} />}
    </>
  );
};

export default DocumentTab;
