import { IconNode, Typography } from '@components/base';
import css from '../index.module.scss';
import Images from '@assets/images';
import { Column, CellProps } from 'react-table';
import { useContext, useMemo } from 'react';
import { DataGrid } from '@components/common';
import { useParams } from 'react-router-dom';
import OrderContext from '@pages/order/generate-purchase-order/OrderContext';
import {
  generateReadablePackingDetails,
  getCommaSepratedValue,
  getReadablePaymentTerm
} from '@helpers/utils';
import { DELIVERY_TERMS, PO_PAYMENT_TERMS } from '@helpers/constants';
import { useEffect, useState } from 'react';
import { fetchAmountInWords } from '@services/util.service';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const writtenNumber = require('written-number'); //intentionally written | no type definitions available

interface OrderTable {
  serial_no: number;
  item_details: string;
  quantity_in_kg: number;
  grades: string;
  price_per_unit: number;
  amount: number;
}

const Order = (props: any) => {
  const { editRed } = Images;
  const orderInfoContext = useContext(OrderContext);
  const urlParams = useParams();
  const { supplierId } = urlParams;
  const { orderItemsWithAmount, poData, supplier } = orderInfoContext;
  const { isRectify } = props;
  const taskData = orderInfoContext?.tasksList?.filter(
    (item: any) => item?.supplier_id === supplierId
  )[0];
  const { handleTabState } = props;
  const handleEditClick = () => handleTabState(1);

  const [totalAmountInWords, setTotalAmountInWords] = useState('-');

  const [documentColumn, documentData] = useMemo(() => {
    // const { OrderTable } = props;

    const column: Column<OrderTable>[] = [
      {
        Header: 'S.No.',
        accessor: 'serial_no',
        Cell: (props: CellProps<OrderTable>) => {
          const {
            row: { values }
          } = props;
          return <div title={values?.serial_no}>{values?.serial_no}</div>;
        }
      },
      {
        Header: 'Item Details',
        accessor: 'item_details',
        Cell: (props: CellProps<OrderTable>) => {
          const {
            row: { values }
          } = props;
          return <div title={values?.item_details}>{values?.item_details}</div>;
        }
      },
      // {
      //   Header: 'Grade',
      //   accessor: 'grades',
      //   Cell: (props: CellProps<OrderTable>) => {
      //     const {
      //       row: { values }
      //     } = props;
      //     return <div title={values?.grades}>{values?.grades}</div>;
      //   }
      // },
      {
        Header: `Qty. in ${poData.unit_of_weight?.value ?? 'MT'}`,
        accessor: 'quantity_in_kg',
        Cell: (props: CellProps<OrderTable>) => {
          const {
            row: { values }
          } = props;
          return <div title={values?.quantity_in_kg}>{values?.quantity_in_kg}</div>;
        }
      },
      {
        Header: `Price/Unit`,
        accessor: 'price_per_unit',
        Cell: (props: CellProps<OrderTable>) => {
          const {
            row: { values }
          } = props;
          return (
            <div title={values?.price_per_unit}>
              {poData?.currency.value} {values?.price_per_unit}
            </div>
          );
        }
      },
      {
        Header: `Amount`,
        accessor: 'amount',
        Cell: (props: CellProps<OrderTable>) => {
          const {
            row: { values }
          } = props;
          return <div title={values?.amount}>{values?.amount}</div>;
        }
      }
    ];

    const row: OrderTable[] = isRectify
      ? orderItemsWithAmount
          .filter((item: any) => item?.supplier_id === supplierId)
          ?.map((details: any, index: any) => ({
            serial_no: index + 1,
            item_details: (
              <>
                {details?.include_grade_name_in_docs.value === true ? (
                  <Typography variant="p">
                    {details?.item_name} - {details?.product_grade_name}
                  </Typography>
                ) : (
                  <Typography variant="p">{details?.item_name}</Typography>
                )}
                <Typography variant="label">{details?.order_item_description_po}</Typography>
                <div className={css.ItemDetailsPreview}>
                  {details?.package && (
                    <Typography variant="label">
                      Packaging Details:{' '}
                      {generateReadablePackingDetails(details?.package)?.join(', ')}
                    </Typography>
                  )}
                  <Typography variant="label">CAS Number:{' '} {details?.CAS_number}</Typography>
                  <Typography variant="label">HS Code:{' '}{details?.hs_code}</Typography>
                </div>
              </>
            ),
            // grades: `${details?.product_grade_name}`,
            quantity_in_kg: details?.quantity_po,
            price_per_unit: `${poData?.currency.value} ${details?.rate_po}`,
            amount: `${poData?.currency.value} ${getCommaSepratedValue(details?.item_amount)}`
          }))
      : orderItemsWithAmount?.map((details: any, index: any) => ({
          serial_no: index + 1,
          item_details: (
            <>
              <Typography variant="p">{details?.item_name}</Typography>{' '}
              <Typography variant="label">{details?.order_item_description_po}</Typography>
            </>
          ),
          // grades: `${details?.product_grade_name}`,
          quantity_in_kg: details?.quantity_po,
          price_per_unit: details?.rate_po,
          amount: `${poData?.currency.value} ${getCommaSepratedValue(details?.item_amount)}`
        }));

    return [column, row];
  }, []);

  const total_gst = orderItemsWithAmount.reduce((acc: number, item: any) => {
    acc += item.item_amount;
    return acc;
  }, 0);

  useEffect(() => {
    (async () => {
      if (poData.total_amount > 0) {
        const response = await fetchAmountInWords({
          amount: poData.total_amount.toString(),
          currency_type: poData.currency.value ? poData.currency.value : 'USD'
        });
        if (response?.success) {
          setTotalAmountInWords(response.data.amount_in_words);
        }
      }
    })();
  }, [poData.total_amount]);

  return (
    <div className={css.boxWrapper}>
      <div className={css.orderDetailsContainer}>
        <div className={css.fieldTitle}>
          <Typography variant="pdoc">Order Details</Typography>
          <IconNode src={editRed} onClick={handleEditClick} />
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Vendor ID</Typography>
            <Typography variant="body">{supplier?.vendor_id}</Typography>
          </div>
          <div>
            <Typography variant="label">Terms of Delivery</Typography>
            <Typography variant="body">
              {DELIVERY_TERMS.find((item: any) => poData?.po_terms_of_delivery.value == item.value)
                ?.label ?? '-'}
            </Typography>
          </div>
          {/* <div>
            <Typography variant="label">Destination</Typography>
            <Typography variant="body">{place_of_delivery}</Typography>
          </div> */}
        </div>
        <div className={css.fieldWrapper}>
          <div>
            <Typography variant="label">Country of Origin</Typography>
            <Typography variant="body">{poData?.po_country_of_origin?.label}</Typography>
          </div>
          <div>
            <Typography variant="label">Payment Terms</Typography>
            {getReadablePaymentTerm(poData?.payment_terms)?.map((item: any, index: number) => {
              return (
                <Typography variant="body" key={index}>
                  {item} {poData?.payment_term_suffix?.name || '-'}
                </Typography>
              );
            })}
          </div>
        </div>

        <DataGrid columns={documentColumn} data={documentData} />

        <div className={css.documentTabWrapper}>
          <div className={css.itemDetailsContainer}>
            <div className={css.fieldTable}>
              {poData.gst_rate.value != 0 && (
                <div className={css.gstDetails}>
                  <Typography variant="pdoc">{`GST ${poData?.gst_rate.value}% under Merchant Export`}</Typography>
                  <Typography>
                    {poData?.currency?.value}{' '}
                    {getCommaSepratedValue((total_gst * (poData?.gst_rate.value / 100)).toFixed(2))}
                  </Typography>
                </div>
              )}
              <div className={css.totalContainer}>
                <Typography variant="h4">Total</Typography>
                <Typography variant="h4">
                  {`${poData?.currency.value} ${getCommaSepratedValue(poData?.total_amount)}`}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={css.bottomDetails}>
            <div className={css.details}>
              <Typography variant="label">Total Amount in Words</Typography>
              <Typography variant="body">{totalAmountInWords}</Typography>
            </div>
            <div className={css.fieldWrapper}>
              <div>
                <Typography variant="label">Total Amount</Typography>
                <Typography variant="body">{`${poData?.currency.value} ${getCommaSepratedValue(
                  poData?.total_amount
                )}`}</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
