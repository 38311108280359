import { SelectLabel } from '@components/common';
import css from './index.module.scss';

interface FiltersProps {
  orderItemFilterOptions: any;
  tagsFilterOptions: any;
  supplierFilterOptions: any;
  onChange: (data: any, type: number) => void;
}

const Filters = (props: FiltersProps) => {
  const { orderItemFilterOptions, tagsFilterOptions, supplierFilterOptions, onChange } = props;

  const modifiedSupplierFilterOptions = supplierFilterOptions.map((supplier: any) => ({
    value: supplier.supplier_id,
    supplier_name: supplier.supplier_name
  }));
  const modifiedOderItemFilterOptions = orderItemFilterOptions.map((item: any) => ({
    value: item.order_item_id,
    order_item_name: item.order_item_name,
    supplier_id: item.supplier_id
  }));

  return (
    <>
      {tagsFilterOptions.length ? (
        <SelectLabel
          options={tagsFilterOptions}
          defaultValue={null}
          isMulti
          rootClassName={css.selectWrapper}
          isSearchable
          isClearable
          placeholder="Select Tag(s)"
          onChange={(data: any) => onChange(data, 0)}
        />
      ) : (
        <></>
      )}
      {supplierFilterOptions?.length ? (
        <SelectLabel
          options={modifiedSupplierFilterOptions}
          defaultValue={null}
          isSearchable
          isMulti
          isClearable
          rootClassName={css.selectWrapper}
          placeholder="Select Supplier"
          getOptionLabel={(option: any) => option.supplier_name}
          onChange={(data: any) => onChange(data, 1)}
        />
      ) : (
        <></>
      )}
      {orderItemFilterOptions?.length ? (
        <SelectLabel
          options={modifiedOderItemFilterOptions}
          defaultValue={null}
          rootClassName={css.selectWrapper}
          isSearchable
          isMulti
          isClearable
          placeholder="Select Product"
          getOptionLabel={(option: any) => option.order_item_name}
          onChange={(data: any) => onChange(data, 2)}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Filters;
