import css from './index.module.scss';
import { Button } from '@components/base';
import { POCDetailContainer } from '@components/common';
import AddressContainer from './components/address-container';
import { ICustomerDetailForm } from '@helpers/types/customer';
import { useFormContext } from 'react-hook-form';
import CustomerDetailContainer from './components/customer-detail-container';
import RemarkContainer from './components/remarks-container';

interface CompanyDetailFormProps {
  onCancelClick: () => void;
  onNextClick: () => void;
  onFormSubmit: (data: ICustomerDetailForm) => void;
  enableEditMode?: boolean;
  userList?: Array<object>;
}

const CompanyDetailForm = (props: CompanyDetailFormProps) => {
  const { onCancelClick, onNextClick, onFormSubmit, enableEditMode = false, userList = [] } = props;
  const {
    handleSubmit,
    formState: { isDirty, isSubmitting }
  } = useFormContext<ICustomerDetailForm>();
  return (
    <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
      <CustomerDetailContainer enableEditMode={enableEditMode} />
      <POCDetailContainer enableEditMode={enableEditMode} userList={userList} />
      <AddressContainer />
      <RemarkContainer />
      <div className={css.actionBtnWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div>
          {enableEditMode && (
            <Button variant="outlined-secondary" className={css.nextBtn} onClick={onNextClick}>
              Next
            </Button>
          )}
          <Button type="submit" disabled={isSubmitting || (enableEditMode ? !isDirty : false)}>
            {enableEditMode ? 'Update and Proceed' : 'Save and Proceed'}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CompanyDetailForm;
