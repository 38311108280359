import { Typography } from '@components/base';
import css from './index.module.scss';
import { chunkArray, getCommaSepratedValue, getFormattedDate } from '@helpers/utils';
import { IFilteredValue, IOrderPayment, IPaymentTerm } from '../../../orderOverview';

interface IPaymentDetailsProps {
  previewData: {
    paymentDetails: {
      orderPayment: IOrderPayment[];
      paymentTerm: string;
    };
    currency: string;
  };
  filteredValue?: IFilteredValue;
}

const PaymentDetails = (props: IPaymentDetailsProps) => {
  const { previewData, filteredValue } = props;
  const { paymentDetails, currency } = previewData;

  const addAdditionalClass = (key: string) => {
    return filteredValue?.key == key ? css.filtered : '';
  };

  return (
    <div className={css.detailWrapper}>
      <div className={css.detailsTitle}>
        <Typography variant="h4">Payment Details</Typography>
      </div>
      <div className={css.detailContainer}>
        {paymentDetails.orderPayment?.length > 0 && (
          <div className={css.paymentDetailsTableWrapper}>
            <div className={css.paymentDetailHeader}>
              <Typography variant="label" className={css.paymentDetailHeaderLabel}>
                S.No.
              </Typography>
              <Typography variant="label" className={css.paymentDetailHeaderLabel}>
                Payment Type
              </Typography>
              <Typography variant="label" className={css.paymentDetailHeaderLabel}>
                Payment Mode
              </Typography>
              <Typography variant="label" className={css.paymentDetailHeaderLabel}>
                Payment Date
              </Typography>
              <Typography variant="label" className={css.paymentDetailHeaderLabel}>
                Amount Paid
              </Typography>
            </div>
            {paymentDetails.orderPayment?.map((item: IOrderPayment, index: number) => (
              <>
                <div className={css.paymentDetailHeader}>
                  <Typography variant="body">{index + 1}</Typography>
                  <Typography variant="body">{item.payment_type}</Typography>
                  <Typography variant="body">{item.payment_mode}</Typography>
                  <Typography variant="body">{getFormattedDate(item.payment_date)}</Typography>
                  <Typography variant="body">
                    {currency} {getCommaSepratedValue(item.amount_paid)}
                  </Typography>
                </div>
              </>
            ))}
          </div>
        )}
        <div className={css.rowWrapper}>
          <div className={`${css.fieldWrapper} ${addAdditionalClass('paymentTerm')}`}>
            <Typography
              variant="label"
              className={`${css.fieldLabel} ${addAdditionalClass('paymentTerm')}`}>
              Payment Term
            </Typography>
            {paymentDetails?.paymentTerm ? (
              <Typography variant="body" className={`${addAdditionalClass('paymentTerm')}`}>
                {paymentDetails?.paymentTerm}
              </Typography>
            ) : (
              <Typography
                variant="label"
                className={`${css.notAvailable} ${addAdditionalClass('paymentTerm')}`}>
                Not Available
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
