import { Fragment, useEffect, useState } from 'react';
import css from './index.module.scss';
import { Accordion, Button, CheckboxLabel, Typography } from '@components/base';
import { SelectLabel } from '@components/common';
import { ISupplier } from '@helpers/types/supplier';
import sortBy from 'lodash.sortby';
import { countries } from '@helpers/constants';
import { getSupplierList } from '@services/supplier.service';
import { fetchAllProducts } from '@services/product.service';
import notify from '@helpers/toastify-helper';
import { ProductInfo } from '@helpers/types/product';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ISelect } from '@helpers/types';

interface FilterDrawerProps {
  onClose?: () => void;
  onApply?: (data: FormType) => void;
  value?: FormType | null;
}

interface FilterDrawerState {
  supplierList: ISupplier[];
  productList: ProductInfo[];
  isLoading: boolean;
}

export interface FormType {
  supplier: ISupplier[];
  product: ProductInfo[];
  country: ISelect[];
  supplier_type_indian: boolean;
  supplier_type_foreign: boolean;
  business_type_trader: boolean;
  business_type_manufacturer: boolean;
}

const FilterDrawer = (props: FilterDrawerProps) => {
  const { onClose, value } = props;
  const [drawerState, setDrawerState] = useState<FilterDrawerState>({
    supplierList: [],
    productList: [],
    isLoading: false
  });

  const { supplierList, productList, isLoading } = drawerState;

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = useForm<FormType>({
    defaultValues: {
      supplier: [],
      product: [],
      country: [],
      supplier_type_indian: false,
      supplier_type_foreign: false,
      business_type_trader: false,
      business_type_manufacturer: false
    }
  });

  useEffect(() => {
    const { value } = props;
    if (value) {
      reset(value);
    }
    fetchList();
  }, []);

  const fetchList = async () => {
    setDrawerState((prevState) => ({ ...prevState, isLoading: true }));
    const supplierResponse = await getSupplierList();
    const productResponse = await fetchAllProducts();
    if (supplierResponse.success && productResponse.success) {
      const { data: supplier_list } = supplierResponse;
      const { data: product_list } = productResponse;
      const sortedSupplierList = sortBy(supplier_list.results, (supplier: any) =>
        supplier.supplier_name.toLowerCase()
      );
      const sortedProductList = sortBy(product_list.results, (product: any) =>
        product.name.toLowerCase()
      );
      setDrawerState((prevState) => ({
        ...prevState,
        supplierList: sortedSupplierList,
        productList: sortedProductList,
        isLoading: false
      }));
    } else {
      setDrawerState((prevState) => ({ ...prevState, isLoading: false }));
      notify({ message: 'Unable to fetch list', severity: 'error' });
    }
  };

  const handleFormSubmit: SubmitHandler<FormType> = async (data) => {
    const { onApply } = props;
    onApply?.(data);
  };

  return (
    <Fragment>
      <form noValidate onSubmit={handleSubmit(handleFormSubmit)} className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h3">Filter</Typography>
          <div className={css.actionWrapper}>
            <Button type="submit" disabled={value ? !isDirty : false}>
              Apply Filters
            </Button>
            <Button variant="outlined-secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
        <div className={css.mainSection}>
          <Controller
            name="supplier"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                isLoading={isLoading}
                label="Supplier"
                options={supplierList}
                getOptionLabel={(option: any) => option.supplier_name}
                getOptionValue={(option: any) => option.supplier_id}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <Controller
            name="product"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                isLoading={isLoading}
                label="Product"
                options={productList}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.product_id}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                label="Country"
                options={countries}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <Accordion defaultExpanded>
            <Accordion.Header className={css.accordionWrapper}>
              <Typography variant="h4">Supplier Type</Typography>
            </Accordion.Header>
            <Accordion.Content>
              <ul className={css.accordionContentWrapper}>
                <li className={css.contentItemWrapper}>
                  <Controller
                    name="supplier_type_indian"
                    control={control}
                    render={({ field }) => (
                      <CheckboxLabel
                        {...field}
                        checked={!!field.value}
                        label="Indian"
                        value="INDIAN"
                      />
                    )}
                  />
                </li>
                <li className={css.contentItemWrapper}>
                  <Controller
                    name="supplier_type_foreign"
                    control={control}
                    render={({ field }) => (
                      <CheckboxLabel
                        {...field}
                        checked={!!field.value}
                        label="Foreigner"
                        value="FOREIGN"
                      />
                    )}
                  />
                </li>
              </ul>
            </Accordion.Content>
          </Accordion>
          <Accordion defaultExpanded>
            <Accordion.Header className={css.accordionWrapper}>
              <Typography variant="h4">Business Type</Typography>
            </Accordion.Header>
            <Accordion.Content>
              <ul className={css.accordionContentWrapper}>
                <li className={css.contentItemWrapper}>
                  <Controller
                    name="business_type_trader"
                    control={control}
                    render={({ field }) => (
                      <CheckboxLabel {...field} checked={!!field.value} label="Trader" value="TR" />
                    )}
                  />
                </li>
                <li className={css.contentItemWrapper}>
                  <Controller
                    name="business_type_manufacturer"
                    control={control}
                    render={({ field }) => (
                      <CheckboxLabel
                        {...field}
                        checked={!!field.value}
                        label="Manufacturer"
                        value="MF"
                      />
                    )}
                  />
                </li>
              </ul>
            </Accordion.Content>
          </Accordion>
        </div>
      </form>
    </Fragment>
  );
};

export default FilterDrawer;
