import { SyntheticEvent, useState, useEffect, useCallback, useMemo } from 'react';
import css from './index.module.scss';
import { BreadCrumb, Loader, Tab, Tabs } from '@components/base';
import { Seo } from '@components/common';
import { useNavigate, useParams } from 'react-router-dom';
import { CLIENT_ROUTES } from '@router/routes';
import { getSupplierDetail } from '@services/supplier.service';
import DocumentTab from './components/documents-tab';
import OrderTab from './components/order-tab';
import Products from './components/products';
import SupplierDetailCard from './components/supplier-detail-card';
import SupplierDetailTab from './components/supplier-detail-tab';
import { ISupplier } from '@helpers/types/supplier';
import POCDetail from './components/poc-detail';
import AccessWrapper from '@authorization/access-wrapper';
import { MODULE_NAMES } from '@helpers/constants';

interface SupplierIndividualStates {
  tabState: number;
  supplierInfo: ISupplier | null;
  loading: boolean;
}

const SupplierIndividual = (props: any) => {
  const { modulePermissions = {}, otherModulePermissions = [] } = props;
  const { actions } = modulePermissions;
  const orderActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.order)?.actions,
    [otherModulePermissions]
  );
  const productActions = useMemo(
    () => otherModulePermissions?.find((p: any) => p.module === MODULE_NAMES.product)?.actions,
    [otherModulePermissions]
  );
  const [supplierState, setSupplierState] = useState<SupplierIndividualStates>({
    tabState: 0,
    supplierInfo: null,
    loading: false
  });

  const { tabState, supplierInfo, loading } = supplierState;

  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    fetchSupplierDetail();
  }, []);

  const fetchSupplierDetail = async () => {
    setSupplierState((prevState) => ({ ...prevState, loading: true }));
    const response = await getSupplierDetail(`${params.id}`);
    if (response.success)
      setSupplierState((prevState) => ({
        ...prevState,
        supplierInfo: response.data,
        loading: false
      }));
    else navigate('/404-not-found');
  };

  const handleTabChange = useCallback((newValue: number) => {
    setSupplierState((prevState) => ({ ...prevState, tabState: newValue }));
  }, []);

  const handleFormEdit = useCallback((param: ISupplier) => {
    setSupplierState((prevState) => ({ ...prevState, supplierInfo: { ...param } }));
  }, []);

  const handleChange = (data: ISupplier) => {
    setSupplierState((prevState) => ({ ...prevState, supplierInfo: { ...data } }));
  };

  return (
    <AccessWrapper show={actions?.read}>
      <main className={css.supplierWrapper}>
        {supplierInfo && <Seo title={supplierInfo.supplier_name} />}
        {supplierInfo && (
          <BreadCrumb>
            <BreadCrumb.Item value={0} to={`/${CLIENT_ROUTES.supplier}`}>
              Supplier List
            </BreadCrumb.Item>
            <BreadCrumb.Item value={1} to={`/${CLIENT_ROUTES.supplier}/${params.id}`}>
              {supplierInfo.vendor_id}
            </BreadCrumb.Item>
          </BreadCrumb>
        )}
        {supplierInfo && (
          <SupplierDetailCard
            supplierName={supplierInfo.supplier_name}
            phoneNumber={supplierInfo.supplier_phone_number}
            landlineNumber={supplierInfo.supplier_landline}
            location={supplierInfo.addresses}
          />
        )}
        {supplierInfo && (
          <POCDetail
            supplierId={supplierInfo.supplier_id}
            pocs={supplierInfo.supplier_pocs}
            onChange={handleChange}
          />
        )}
        {supplierInfo && (
          <Tabs value={tabState}>
            <AccessWrapper show={productActions?.read}>
              <Tab label="Products" value={0} onClick={() => handleTabChange(0)} />
            </AccessWrapper>
            <Tab label="Documents" value={1} onClick={() => handleTabChange(1)} />
            <AccessWrapper show={orderActions?.read}>
              <Tab label="Orders" value={2} onClick={() => handleTabChange(2)} />
            </AccessWrapper>

            <Tab label="Supplier Details" value={3} onClick={() => handleTabChange(3)} />
          </Tabs>
        )}
        {tabState === 0 && supplierInfo && <Products supplierInfo={supplierInfo} />}
        {tabState === 1 && supplierInfo && (
          <DocumentTab supplierInfo={supplierInfo} onChange={handleFormEdit} />
        )}
        {tabState === 2 && supplierInfo && <OrderTab supplierId={supplierInfo.supplier_id} />}
        {tabState === 3 && supplierInfo && (
          <SupplierDetailTab
            supplierInfo={supplierInfo}
            onFormEdit={handleFormEdit}
            actions={actions}
          />
        )}
        <Loader open={loading} />
      </main>
    </AccessWrapper>
  );
};

export default SupplierIndividual;
