import Images from '@assets/images';
import { Accordion, Button, Divider, Select, Typography } from '@components/base';
import { Avatar } from '@components/common';
import notify from '@helpers/toastify-helper';
import { useState } from 'react';
import { updateGateInTask } from '@services/order.service';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import css from './index.module.scss';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { useNavigate } from 'react-router-dom';
import { CLIENT_ROUTES } from '@router/routes';
import Footer from '../components/footer';

interface GateInProps {
  taskId: string;
  taskData: Array<Partial<ITaskTabProps>>;
}

const UpdateGateInCard = (props: GateInProps) => {
  const { taskId, taskData } = props;
  const getTaskStatus = taskData[0]?.is_completed;
  const [isCompleted, setIsCompleted] = useState<boolean | undefined>(getTaskStatus);
  const navigate = useNavigate();

  const doc_submitted = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];

  interface GateInForm {
    gate_in_confirmation: {
      label: string;
      value: boolean;
    };
  }

  const updateGateIn = useForm<GateInForm>({
    defaultValues: {
      gate_in_confirmation: { label: 'No', value: false }
    }
  });

  const { control, getValues } = updateGateIn;

  const handleSelectChange = async () => {
    const values = getValues();
    if (values.gate_in_confirmation.value == false) {
      notify({ message: 'Please select Yes as an option', severity: 'error' });
      return;
    }
    const body = {
      gate_in_confirmation: values.gate_in_confirmation.value
    };

    const response = await updateGateInTask(taskId, body);
    if (response.success) {
      notify({ message: 'Gate-In updated!' });
      setIsCompleted(true);
      //navigate(`/${CLIENT_ROUTES.order}/${taskData[0].order}`);
      window.location.reload();
    } else if (response.error) {
      notify({ message: response.error, severity: 'error' });
    } else notify({ message: 'Some error occurred', severity: 'error' });
  };

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {getTaskStatus || isCompleted ? (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              ) : (
                <Avatar src={Images.illustration} alt="" />
              )}
              <Typography variant="h5">
                #{taskData[0]?.readable_task_id} - Update Gate-In
              </Typography>
            </div>
            <div className={css.pocWrapper}>
              <div className={css.uploadWrapper}>
                {!getTaskStatus && isCompleted === false && (
                  <Button variant="text" className={css.detailsButton} onClick={handleSelectChange}>
                    Confirm
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content className={css.accordionContent}>
          <div className={css.stickerDetailsWrapper}>
            <div className={css.stickersInnerWrapper}>
              <Typography variant="body">Confirm Gate-In</Typography>
              <div className={css.selectWrapper}>
                <Controller
                  name="gate_in_confirmation"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={doc_submitted}
                      isDisabled={getTaskStatus || isCompleted}
                      onChange={(e: any) => {
                        field.onChange(e);
                        // handleSelectChange();
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
      {/* <div className={css.footer}>
        <div className={css.textWrapper}>
          <Typography variant="h5">Created On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.created_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Updated On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.updated_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Time Taken:</Typography>
          <Typography variant="p">
            {moment(taskData[0]?.updated_at)?.diff(taskData[0]?.created_at, 'days')} Day(s)
          </Typography>
        </div>
      </div> */}
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
    </div>
  );
};
export default UpdateGateInCard;
