import { Image, Typography } from '@components/base';
import AppContextProvider from '@helpers/hooks/AppContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AppRoutes from '@router/index';
import { Fragment, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/scss/main.scss';
import css from './App.module.scss';
import Images from '@assets/images';
import { SUPPORT_EMAIL } from '@helpers/utils';

function App() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (screenWidth < 480) {
    return (
      <div className={css.wrapper}>
        <div className={css.elchemyLogoWrapper}>
          <Image src={Images.elchemyLogo} alt="Elchemy Logo" />
        </div>
        <div className={css.desktopCover}>
          <div>
            <img src={Images.girlbgsvg} alt="Login Cover" />
          </div>
          <div className={css.horizontalViewMessage}>
            <Typography variant="h4">
              For the best experience, please view our dashboard on a desktop, laptop, tablet or in
              landscape mode.
            </Typography>
            <Typography variant="h4" className={css.email}>
              If you have any queries please email us at{' '}
              <a href={`mailto:${SUPPORT_EMAIL}`} className={css.emailLink}>
                {SUPPORT_EMAIL}
              </a>
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <GoogleOAuthProvider clientId={`${process.env.REACT_APP_CLIENT_ID}`}>
        <AppContextProvider>
          <AppRoutes />
          <ToastContainer
            autoClose={4000}
            position="top-center"
            closeButton={false}
            hideProgressBar
            pauseOnFocusLoss={false}
            closeOnClick={false}
            limit={3}
          />
        </AppContextProvider>
      </GoogleOAuthProvider>
    </Fragment>
  );
}

export default App;
