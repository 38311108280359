/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { memo, ReactNode, MouseEvent, KeyboardEvent, forwardRef, Ref } from 'react';
import css from './index.module.scss';
import { Spinner, ClickAwayListener } from '@components/base';
// import { TextField } from '@components/common';
import { TextFieldProps } from '@components/common';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from 'lodash';

interface AutoCompleteProps extends TextFieldProps {
  isLoading?: boolean;
  isOpen?: boolean;
  options: any;
  onOptionSelected?: (value: string, index?: number) => void;
  onClickAway: () => void;
  key?: any;
  onInputSelection?: (event: any, param: any) => void;
  onInputChange?: () => void;
  placeholder?: string;
  label?: string;
  keyOption?: string;
  disabled?: boolean;
  value?: any;
  debounceTime?: number;
  multiple?: boolean;
  freeSolo?: boolean;
  renderTags?: (value: any, getTagProps: any) => ReactNode;
  disableClearable?: boolean;
  filterOptions?: any;
}

/**
 * Note: In case of 'multiple' being true, for required validation, use 'min(1, 'is required')'.
 */

const AutoComplete = forwardRef((props: any, ref: Ref<HTMLInputElement>) => {
  const {
    isLoading,
    isOpen = true,
    options,
    onOptionSelected,
    onClickAway,
    key,
    onInputSelection,
    onInputChange,
    placeholder,
    label,
    keyOption,
    disabled = false,
    value,
    error,
    helperText,
    required,
    width = 300,
    debounceTime = 300,
    multiple = false,
    freeSolo = false,
    renderTags,
    filterOptions = undefined,
    disableClearable = false,
    ...otherProps
  } = props;

  const debouncedInputChange = onInputChange ? debounce(onInputChange, debounceTime) : undefined;

  const styles = {
    width: width ? width : '100%',
    height: 'auto',
    '.MuiOutlinedInput-root': {
      borderRadius: '8px',
      fontFamily: 'MUKTA, sans-serif',
      fontSize: `16px`
    },
    '.MuiAutocomplete-listbox': {
      fontFamily: 'MUKTA, sans-serif',
      fontSize: `16px`
    }
  };

  const showAsterisk = required ? ' *' : '';

  return (
    <div className={css.AutoCompleteWrapper}>
      {label && (
        <span className={`${css.AutoCompleteLabel}`}>
          {label}
          <span className={css.required}>{showAsterisk}</span>
        </span>
      )}
      <Autocomplete
        multiple={multiple}
        disablePortal
        disableClearable={disableClearable}
        options={options}
        onChange={onInputSelection}
        onInputChange={debouncedInputChange}
        sx={styles}
        freeSolo={freeSolo}
        size="small"
        filterOptions={filterOptions}
        getOptionLabel={(option: any) => option[keyOption]}
        disabled={disabled}
        value={value}
        isOptionEqualToValue={(option: any, value: any) => option[keyOption] === value[keyOption]}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            required={required}
          />
        )}
        renderTags={renderTags}
      />
    </div>
  );
});

AutoComplete.defaultProps = {
  options: [],
  autoComplete: 'off',
  inputMode: 'text',
  enterKeyHint: 'next'
};

export default memo(AutoComplete);
