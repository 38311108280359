import { Modal, Typography, IconNode, Button } from "@components/base";
import Images from "@assets/images";
import css from './index.module.scss';
import TextareaLabel from "@components/common/textarea-label";

interface RejectModalProps{
    open: boolean;
    onClose?: () => void;
}
const RejectPIModal = (props: RejectModalProps) => {
    const {open, onClose} = props;

    const handleCloseClick = () => {
        //handle close here
    }

    return (
        <Modal open={true} onClose={onClose}>
            <div className={css.mainWrapper}>
                <div className={css.headerWrapper}>
                <Typography variant="h4">Write your reasons to reject this Order</Typography>
                <IconNode
                    src={Images.crossBlack}
                    alt="close icon"
                    component="div"
                    className={css.closeButton}
                    onClick={onClose}
                />
                </div>
                <form noValidate className={css.formWrapper}>
                    <div className={css.fieldsWrapper}>
                        <TextareaLabel
                            rootClassName={css.fieldSpacing}
                            placeholder="Enter a description.."
                            className={css.textArea}
                        />
                    </div>
                    <Button variant='outlined-secondary' className={css.rightButton} onClick={handleCloseClick}>Reject</Button>
                </form>
            </div>
        </Modal>
    )
}
export default RejectPIModal;