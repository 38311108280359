import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import css from './index.module.scss';
import { Accordion, Button, CheckboxLabel, Typography } from '@components/base';
import { InputDatePicker, SelectLabel } from '@components/common';
import { ISupplier } from '@helpers/types/supplier';
import sortBy from 'lodash.sortby';
import { getSupplierList } from '@services/supplier.service';
import { fetchAllProducts } from '@services/product.service';
import notify from '@helpers/toastify-helper';
import { ProductInfo } from '@helpers/types/product';
import { useForm, Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { ISelect } from '@helpers/types';
import { fetchAllCustomers } from '@services/customer.service';
import debounce from 'lodash.debounce';
import { fetchAllUsers } from '@services/user.service';

interface FilterDrawerProps {
  onClose?: () => void;
  onApply?: (data: FormType) => void;
  value?: FormType | null;
}

interface orderPOC {
  user_id: string;
  given_name: string;
  family_name: string;
}

interface FilterDrawerState {
  supplierList: ISupplier[];
  suppliersNameList: any;
  productList: ProductInfo[];
  orderPOCList: orderPOC[];
  // customerList: ICustomerInfo[];
  customerList: any[];
  isLoading: boolean;
  isOpen: boolean;
}

export interface FormType {
  supplier: ISupplier[];
  product: ProductInfo[];
  //customer: ICustomerInfo[];
  customer: any[];
  country: ISelect[];
  supplier_type_indian: boolean;
  order_type_MTT: boolean;
  order_type_indian: boolean;
  supplier_type_foreign: boolean;
  business_type_trader: boolean;
  business_type_manufacturer: boolean;
  inco_term_type_FOB: boolean;
  inco_term_type_CIF: boolean;
  inco_term_type_CFR: boolean;
  inco_term_type_DDP: boolean;
  selectedOrderPOC: orderPOC[];
  startBLAWBDate: Date | null;
  endBLAWBDate: Date | null;
  startOrderDate: Date | null;
  endOrderDate: Date | null;
}

const FilterDrawer = (props: FilterDrawerProps) => {
  const { onClose, value } = props;
  const [drawerState, setDrawerState] = useState<FilterDrawerState>({
    supplierList: [],
    suppliersNameList: [],
    productList: [],
    orderPOCList: [],
    customerList: [],
    isLoading: false,
    isOpen: false
  });
  const debounceRef = useRef<any>(null);
  //const { control, setValue, watch } = useFormContext<any>();

  const { supplierList, suppliersNameList, productList, customerList, orderPOCList, isLoading } =
    drawerState;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isDirty }
  } = useForm<FormType>({
    defaultValues: {
      supplier: [],
      product: [],
      country: [],
      customer: [],
      selectedOrderPOC: [],
      supplier_type_indian: false,
      supplier_type_foreign: false,
      business_type_trader: false,
      business_type_manufacturer: false,
      inco_term_type_FOB: false,
      inco_term_type_CIF: false,
      inco_term_type_CFR: false,
      inco_term_type_DDP: false,
      startBLAWBDate: null,
      endBLAWBDate: null,
      startOrderDate: null,
      endOrderDate: null
    }
  });

  useEffect(() => {
    const { value } = props;
    if (value) {
      reset(value);
    }
    fetchList();
  }, []);

  //Generic function to fetch all pages from API response
  async function fetchAllPaginatedItems(listItems: any, getAPI: any) {
    const totalPages = Math.floor(listItems.data.count / 10);
    let allItems: any[] = [];
    for (let page = 1; page <= totalPages; page++) {
      const pageItems = await getAPI(undefined, page); // Function to fetch items for a specific page
      allItems = allItems.concat(pageItems.data.results);
    }
    return allItems;
  }

  const fetchList = async () => {
    setDrawerState((prevState) => ({ ...prevState, isLoading: true }));
    const supplierResponse = await getSupplierList();
    const productResponse = await fetchAllProducts();
    const customerResponse = await fetchAllCustomers();
    const userResponse = await fetchAllUsers({ role: 'internal' });
    if (
      supplierResponse.success &&
      productResponse.success &&
      customerResponse.success &&
      userResponse.success
    ) {
      const { data: supplier_list } = supplierResponse;
      const { data: product_list } = productResponse;
      //const {data: customer_list } = customerResponse;

      //---------------------------------------------------------

      //fetch all pages of suppliers data
      const totalPages = Math.ceil(supplierResponse.data.count / 10);
      let allItems: any[] = [];
      for (let page = 1; page <= totalPages; page++) {
        const pageItems = await getSupplierList(undefined, page); // Function to fetch items for a specific page
        allItems = allItems.concat(pageItems.data.results);
      }
      const sortedSupplierList = sortBy(allItems, (supplier: any) =>
        supplier.supplier_name.toLowerCase()
      );
      //-------------------------------------------------------------------

      //Fetch all pages of products data
      const totalProductsPages = Math.ceil(productResponse.data.count / 10);
      let allProductItems: any[] = [];
      for (let page = 1; page <= totalProductsPages; page++) {
        const pageItems = await fetchAllProducts(page);
        allProductItems = allProductItems.concat(pageItems.data.results);
      }
      const sortedProductList = sortBy(allProductItems, (product: any) =>
        product.name.toLowerCase()
      );

      //---------------------------------------------------------------------

      //Fetch All pages of customer Data
      const totalCustomerPages = Math.ceil(customerResponse.data.count / 10);
      let allCustomerItems: any[] = [];
      for (let page = 1; page <= totalCustomerPages; page++) {
        const pageItems = await fetchAllCustomers(page);
        allCustomerItems = allCustomerItems.concat(pageItems.data.results);
      }
      const sortedCustomerList = sortBy(allCustomerItems, (customer: any) =>
        customer.name.toLowerCase()
      );

      //---------------------------------------------------------------------

      //Fetch All pages of User Data
      const allOrderPOC = userResponse.data.users;
      const sortedUserList = sortBy(allOrderPOC, (user: any) => user.given_name.toLowerCase());
      
      setDrawerState((prevState) => ({
        ...prevState,
        supplierList: sortedSupplierList,
        //supplierList: allItems,
        productList: sortedProductList,
        customerList: sortedCustomerList,
        orderPOCList: sortedUserList,
        isLoading: false
      }));
    } else {
      setDrawerState((prevState) => ({ ...prevState, isLoading: false }));
      notify({ message: 'Unable to fetch list', severity: 'error' });
    }
  };

  const handleFormSubmit: SubmitHandler<FormType> = async (data) => {
    const { onApply } = props;
    onApply?.(data);
  };

  //autocomplete handlers

  const handleChange = async (value: string) => {
    const response = await fetchAllCustomers(undefined, value);
    if (response.success) {
      const customer_list = response.data;
      const options = customer_list.results.map((customer: any) => customer.name);
      setDrawerState((prevState) => ({
        ...prevState,
        isLoading: false,
        options: options,
        isOpen: true,
        customerList: options
      }));
    } else {
      setDrawerState((prevState) => ({ ...prevState, isLoading: false }));
      notify({ message: response.error ?? 'Unable to fetch customer list', severity: 'error' });
    }
  };

  const handleOutsideClick = useCallback(() => {
    setDrawerState((prevState) => ({ ...prevState, isOpen: false, isLoading: false }));
  }, []);
  const handleInputChange = async (event: any) => {
    const value = event.currentTarget.value;
    setValue('customer', value, { shouldValidate: true });
    setDrawerState((prevState) => ({ ...prevState, isLoading: true }));
    debounceRef.current?.cancel();
    debounceRef.current = debounce(handleChange, 800);
    debounceRef.current(value.trim());
  };
  const handleOptionSelection = useCallback((value) => {
    setValue('customer', value, { shouldValidate: true });
    setDrawerState((prevState) => ({ ...prevState, isOpen: false }));
  }, []);

  return (
    <Fragment>
      <form noValidate onSubmit={handleSubmit(handleFormSubmit)} className={css.mainWrapper}>
        <div className={css.headerWrapper}>
          <Typography variant="h3">Filter</Typography>
          <div className={css.actionWrapper}>
            <Button type="submit" disabled={value ? !isDirty : false}>
              Apply Filters
            </Button>
            <Button variant="outlined-secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
        <div className={css.mainSection}>
          <Controller
            name="supplier"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                isLoading={isLoading}
                label="Supplier"
                options={supplierList}
                //options={suppliersNameList}
                getOptionLabel={(option: any) => option.supplier_name}
                // getOptionValue={(option: any) => option.supplier_id}
                getOptionValue={(option: any) => option.supplier_id}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <Controller
            name="customer"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                isLoading={isLoading}
                label="Customer"
                options={customerList}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.name}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          {/* <Controller 
            name='customer'
            control={control}
            render={({field, fieldState}) => (
            <AutoComplete
                  {...field}
                  autoFocus
                  required
                  value={field.value}
                  label="Customer Name"
                  placeholder="Enter name"
                  onClickAway={handleOutsideClick}
                  isOpen={drawerState.isOpen}
                  isLoading={drawerState.isLoading}
                  options={customerList}
                  onChange={handleInputChange}
                  onOptionSelected={handleOptionSelection}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  multiple
                />
            )}
          /> */}

          <Controller
            name="product"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                isLoading={isLoading}
                label="Product"
                options={productList}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.product_id}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          <Controller
            name="selectedOrderPOC"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                isLoading={isLoading}
                label="Order POC"
                options={orderPOCList}
                getOptionLabel={(option: any) => option.given_name + ' ' + option.family_name}
                getOptionValue={(option: any) => option.user_id}
                rootClassName={css.selectWrapper}
              />
            )}
          />
          {/* <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <SelectLabel
                {...field}
                isMulti
                isSearchable
                label="Country"
                options={countries}
                rootClassName={css.selectWrapper}
              />
            )}
          /> */}
          <div className={css.orderWrapper}>
            <Controller
              name={`startOrderDate`}
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  label="Order Start Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.dateWrapper}
                />
              )}
            />
            <Controller
              name={`endOrderDate`}
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  label="Order End Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.dateWrapper}
                />
              )}
            />
          </div>
          <div className={css.orderWrapper}>
            <Controller
              name={`startBLAWBDate`}
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  label="Start BL/AWB Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.dateWrapper}
                />
              )}
            />
            <Controller
              name={`endBLAWBDate`}
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  label="End BL/AWB Date"
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.dateWrapper}
                />
              )}
            />
          </div>
          <Accordion defaultExpanded>
            <Accordion.Header className={css.accordionWrapper}>
              <Typography variant="h4">Order Type</Typography>
            </Accordion.Header>
            <Accordion.Content>
              <ul className={css.accordionContentWrapper}>
                <li className={css.contentItemWrapper}>
                  <Controller
                    name="order_type_MTT"
                    control={control}
                    render={({ field }) => (
                      <CheckboxLabel {...field} checked={!!field.value} label="MTT" value="MTT" />
                    )}
                  />
                </li>
                <li className={css.contentItemWrapper}>
                  <Controller
                    name="order_type_indian"
                    control={control}
                    render={({ field }) => (
                      <CheckboxLabel
                        {...field}
                        checked={!!field.value}
                        label="Export from India"
                        value="IND"
                      />
                    )}
                  />
                </li>
              </ul>
            </Accordion.Content>
          </Accordion>
          {/* <Accordion defaultExpanded>
            <Accordion.Header className={css.accordionWrapper}>
              <Typography variant="h4">Supplier Type</Typography>
            </Accordion.Header>
            <Accordion.Content>
              <ul className={css.accordionContentWrapper}>
                <li className={css.contentItemWrapper}>
                  <Controller
                    name="supplier_type_indian"
                    control={control}
                    render={({ field }) => (
                      <CheckboxLabel
                        {...field}
                        checked={!!field.value}
                        label="Indian"
                        value="INDIAN"
                      />
                    )}
                  />
                </li>
                <li className={css.contentItemWrapper}>
                  <Controller
                    name="supplier_type_foreign"
                    control={control}
                    render={({ field }) => (
                      <CheckboxLabel
                        {...field}
                        checked={!!field.value}
                        label="Foreigner"
                        value="FOREIGN"
                      />
                    )}
                  />
                </li>
              </ul>
            </Accordion.Content>
          </Accordion> */}
          <Accordion defaultExpanded>
            <Accordion.Header className={css.accordionWrapper}>
              <Typography variant="h4">IncoTerms Type</Typography>
            </Accordion.Header>
            <Accordion.Content>
              <ul className={css.accordionContentWrapper}>
                <li className={css.contentItemWrapper}>
                  <Controller
                    name="inco_term_type_FOB"
                    control={control}
                    render={({ field }) => (
                      <CheckboxLabel {...field} checked={!!field.value} label="FOB" value="FOB" />
                    )}
                  />
                </li>
                <li className={css.contentItemWrapper}>
                  <Controller
                    name="inco_term_type_CIF"
                    control={control}
                    render={({ field }) => (
                      <CheckboxLabel {...field} checked={!!field.value} label="CIF" value="CIF" />
                    )}
                  />
                </li>
                <li className={css.contentItemWrapper}>
                  <Controller
                    name="inco_term_type_CFR"
                    control={control}
                    render={({ field }) => (
                      <CheckboxLabel {...field} checked={!!field.value} label="CFR" value="CFR" />
                    )}
                  />
                </li>
                <li className={css.contentItemWrapper}>
                  <Controller
                    name="inco_term_type_DDP"
                    control={control}
                    render={({ field }) => (
                      <CheckboxLabel {...field} checked={!!field.value} label="DDP" value="DDP" />
                    )}
                  />
                </li>
              </ul>
            </Accordion.Content>
          </Accordion>
        </div>
      </form>
    </Fragment>
  );
};

export default FilterDrawer;
