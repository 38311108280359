import { CSSProperties, ReactNode, useEffect, useMemo, useState } from 'react';
import css from './index.module.scss';
import { IconNode, Typography } from '@components/base';
import { EstimateDatesModal, NestedChip } from '@components/common';
import Images from '@assets/images';
import { OrderItem, OrderInfo } from '@helpers/types/order';

import { getFormattedDate } from '@helpers/utils';
import StepProgressBar from '@components/common/progress-bar/progress-bar-step';
import { MultiplePlaceholder } from '@components/common';
import moment from 'moment';
import { updateTaskEstimate } from '@services/order.service';
import notify from '@helpers/toastify-helper';

interface OrderCardProps {
  customerName: string;
  items: OrderItem[];
  paymentTerm: string;
  placeOfDelivery: string;
  createdAt: Date;
  totalAmount: string;
  amountDue: string;
  currentState: string;
  cancelStatus?: any;
  orderInfo: OrderInfo;
  pendingTasks: string[];
  handleTabChange: (newValue: number) => void;
}

const InternalOrderCard = (props: OrderCardProps) => {
  const {
    customerName,
    placeOfDelivery,
    createdAt,
    totalAmount,
    amountDue,
    currentState,
    orderInfo,
    paymentTerm,
    pendingTasks,
    handleTabChange
  } = props;
  const { currency } = orderInfo;

  const [estimateModal, setEstimateModal] = useState(false);
  const [progressBarState, setProgressBarState] = useState<any>({
    labelList: [],
    stepLabels: [],
    isCurrent: 0
  });

  const { labelList, stepLabels, isCurrent } = progressBarState;

  const supplierName = useMemo(() => {
    const { items } = props;
    const names = items
      .filter((data) => data?.supplier_name !== undefined)
      .map((data) => data.supplier_name);
    return <MultiplePlaceholder names={names} />;
  }, [props.items]);

  const productName = useMemo(() => {
    const { items } = props;
    const names = items.map((data) => data.product_name);
    return <MultiplePlaceholder names={names} />;
  }, [props.items]);

  const formattedCreatedAt = useMemo(() => {
    return getFormattedDate(createdAt);
  }, [createdAt]);

  const formattedTotalAmount = useMemo(() => {
    if (totalAmount?.length) {
      return `${currency} ${Number(totalAmount).toLocaleString()}`;
    } else {
      return '0';
    }
  }, [totalAmount]);

  const formattedAmountDue = useMemo(() => {
    if (totalAmount?.length) {
      return `${currency} ${Number(amountDue).toLocaleString()}`;
    } else {
      return '0';
    }
  }, [amountDue]);

  const cancelStatus = useMemo(() => {
    return {
      is_cancelled: orderInfo?.is_cancelled,
      cancel_remarks: orderInfo?.cancel_remarks,
      last_updated_by: orderInfo?.last_updated_by,
      updated_at: getFormattedDate(orderInfo?.updated_at)
    };
  }, [orderInfo]);

  const onEstimateFormSubmit = async (data: any) => {
    if (data?.estimate_data?.length) {
      const body = data?.estimate_data.map((item: any) => {
        return {
          state_type: item.state_type,
          estimated_date: item.estimated_date
            ? moment(item.estimated_date).format('YYYY-MM-DD')
            : null,
          id: item.id
        };
      });

      const response = await updateTaskEstimate(orderInfo?.order_id ?? '', body);
      if (response.success) {
        notify({
          message: 'Task Estimates added successfully'
        });
        setEstimateModal(false);
      } else {
        notify({ message: response.error ?? 'Something went wrong!', severity: 'error' });
      }
    }
  };

  useEffect(() => {
    if (orderInfo?.customer_state_activity_log) {
      const labels = orderInfo?.customer_state_activity_log.map((item: any) => {
        return {
          label: item.state_name,
          date:
            getFormattedDate(
              item.actual_date ? item.actual_date : item.estimated_date ? item.estimated_date : ''
            ) ?? '',
          logs: item.logs?.map((log: any) => {
            return {
              label: log.log,
              date: getFormattedDate(log.created_at ?? '') ?? ''
            };
          }),
          is_current_state: item.is_current_state
        };
      });

      const isCurrentState = orderInfo?.customer_state_activity_log.findIndex(
        (item: any) => item.is_current_state === true
      );
      setProgressBarState({
        labelList: labels,
        stepLabels: stepLabels,
        isCurrent: isCurrentState
      });
    }
  }, [orderInfo]);

  return (
    <>
      <div className={css.orderCardContainer}>
        {cancelStatus.is_cancelled ? (
          <div className={css.cancelWrapper}>
            <div className={css.columnWrapper}>
              <Typography variant="p" className={css.cancelOrderTitle}>
                This order has been cancelled by {cancelStatus.last_updated_by ?? 'Unknown User'} on{' '}
                {cancelStatus.updated_at}
              </Typography>
              <Typography variant="p">
                {cancelStatus.cancel_remarks ?? 'No Cancel Remarks'}
              </Typography>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={css.orderCardFirstWrapper}>
          <UserCard src={Images.personGrey} primaryTitle="Customer" secondaryTitle={customerName} />
          <UserCard src={Images.personGrey} primaryTitle="Supplier" secondaryTitle={supplierName} />
          <UserCard
            src={Images.productGrey}
            primaryTitle="Product"
            secondaryTitle={productName}
            style={{ borderRight: '1px solid var(--color-grey-1)' }}
          />
          <div className={css.userCardContainer}>
            <div className={css.statusContainer}>
              <Typography variant="label">Pending Tasks</Typography>
              {/* <NestedChip primaryLabel={'Post order confirmation'} secondaryLabel={'PO Generated'} /> */}
              <Typography className={css.statusText}>
                <MultiplePlaceholder
                  names={pendingTasks}
                  onClick={() => props.handleTabChange(0)}
                />
              </Typography>
            </div>
          </div>
        </div>
        <div className={css.orderCardFirstWrapper}>
          <UserCard
            primaryTitle="Payment Terms"
            secondaryTitle={paymentTerm}
            showPrimaryIcon={false}
            showInfoIcon={false}
          />
          <UserCard
            primaryTitle="Due Payment/Total Payment"
            secondaryTitle={`${formattedAmountDue}/${formattedTotalAmount}`}
            showPrimaryIcon={false}
            showInfoIcon={false}
          />
          <UserCard
            primaryTitle="Place of Delivery"
            secondaryTitle={placeOfDelivery?.length ? placeOfDelivery : '-'}
            showPrimaryIcon={false}
            showInfoIcon={false}
          />
          <UserCard
            primaryTitle="Created On"
            secondaryTitle={formattedCreatedAt}
            showPrimaryIcon={false}
            showInfoIcon={false}
          />
        </div>
        <div className={css.progressBarCard}>
          {orderInfo?.estimate_date_edit ? (
            <IconNode
              src={Images.editDark}
              alt="edit"
              className={css.editIcon}
              onClick={() => setEstimateModal(true)}
            />
          ) : (
            <></>
          )}
          <div className={css.rowWrapper}>
            <StepProgressBar label={labelList} currentStep={isCurrent} />
          </div>
        </div>
      </div>
      {estimateModal && (
        <EstimateDatesModal
          open={estimateModal}
          onClose={() => setEstimateModal(false)}
          taskData={{ task_estimates: orderInfo?.task_estimates }}
          onFormSubmit={onEstimateFormSubmit}
        />
      )}
    </>
  );
};

interface UserCardProps {
  src?: string;
  primaryTitle: string;
  secondaryTitle: ReactNode;
  showPrimaryIcon?: boolean;
  showInfoIcon?: boolean;
  style?: CSSProperties;
}
const UserCard = (props: UserCardProps) => {
  const {
    src,
    primaryTitle,
    secondaryTitle,
    style,
    showPrimaryIcon = true,
    showInfoIcon = false
  } = props;
  return (
    <div className={css.userCardContainer} style={style}>
      {showPrimaryIcon && (
        <IconNode src={src} alt={`${primaryTitle} icon`} className={css.profileIcon} />
      )}
      <div className={css.userCardDetailColumn}>
        <div className={css.titleWrapper}>
          <Typography variant="span" className={css.primaryTitleLabel}>
            {primaryTitle}
          </Typography>
          {showInfoIcon && <IconNode src={Images.infoGrey} alt="info icon" />}
        </div>
        <Typography variant="body">{secondaryTitle}</Typography>
      </div>
    </div>
  );
};
export default InternalOrderCard;
