import { Accordion, Button, IconNode, Typography } from '@components/base';
import { Avatar } from '@components/common';
import css from './index.module.scss';
import Images from '@assets/images';
import { useNavigate } from 'react-router-dom';
import { CLIENT_ROUTES } from '@router/routes';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import Footer from '../components/footer';
import { useContext } from 'react';
import { AppContext } from '@helpers/hooks/AppContext';

interface ReviewPropsType {
  taskData: Partial<ITaskTabProps>;
  onComplete?: () => void;
}

const Review = (props: ReviewPropsType) => {
  const { alertSuccessFaint, dotGrey } = Images;
  const navigate = useNavigate();
  const { appDispatch } = useContext(AppContext);

  return (
    <>
      <div className={css.mainWrapper}>
        <Accordion defaultExpanded>
          <Accordion.Header className={css.accordionHeader}>
            <div className={css.header}>
              <div className={css.wrapper}>
                {props.taskData.is_completed ? (
                  <IconNode src={alertSuccessFaint} className={css.successIcon} />
                ) : (
                  <Avatar src={dotGrey} alt="assign-icon" />
                )}
                <Typography variant="h5">
                  #{props.taskData.readable_task_id} - Review and Approve Packaging Details (
                  {props.taskData.supplier_name})
                </Typography>
              </div>
              <div className={css.pocWrapper}>
                {!props.taskData.is_completed && (
                  <Button
                    variant="outlined-secondary"
                    onClick={() => {
                      appDispatch({
                        type: 'setReviewData',
                        payload: {
                          task_id: props.taskData?.task_id,
                          order_id: props.taskData?.order,
                          supplier_id: props.taskData?.supplier_id
                        }
                      });
                      navigate(
                        `/${CLIENT_ROUTES.order}/packaging-details/${props.taskData?.order}/review`
                      );
                    }}
                    className={css.assignButton}>
                    Review
                  </Button>
                )}
              </div>
            </div>
          </Accordion.Header>
        </Accordion>
        <Footer
          createdDate={props.taskData.created_at as Date}
          updatedDate={props.taskData.updated_at as Date}
        />
      </div>
    </>
  );
};

export default Review;
