import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import css from './index.module.scss';
import { IconNode } from '@components/base';
import { BoxContainer, SelectLabel, TextField } from '@components/common';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { BUSINESS_TYPE, SUPPLIER_TYPE } from '@helpers/constants';
import Images from '@assets/images';

interface InfoFieldsProps {
  showVendorId?: boolean;
  disabledSupplierName?: boolean;
}

const InfoFields = (props: InfoFieldsProps) => {
  const { showVendorId, disabledSupplierName } = props;
  const { control, setValue } = useFormContext();
  const [requiredGSTNumber, setRequiredGSTNumber] = useState<boolean>(true);

  const supplierTypeWatch = useWatch({
    name: 'supplier_type',
    control
  });

  useEffect(() => {
    if (supplierTypeWatch.value === "FOREIGN") {
      setRequiredGSTNumber(false);
    } else {
      setRequiredGSTNumber(true);
    }
  }, [supplierTypeWatch])

  return (
    <BoxContainer title="Supplier Information">
      <div className={css.supplierInfoWrapper}>
        <div className={css.supplierTextFieldWrapper}>
          <Controller
            name="supplier_name"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                disabled={disabledSupplierName}
                required
                autoFocus={!disabledSupplierName}
                type="text"
                inputMode="text"
                enterKeyHint="next"
                autoComplete="organization"
                label="Supplier Name"
                placeholder="Enter company name"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="supplier_type"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                required
                label="Supplier Type"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                options={SUPPLIER_TYPE}
              />
            )}
          />
        </div>
        <div className={css.supplierTextFieldWrapper}>
          <Controller
            name="gst_number"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required={requiredGSTNumber}
                type="text"
                inputMode="text"
                enterKeyHint="next"
                label="GST Number"
                placeholder="Enter GST number"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="business_type"
            control={control}
            render={({ field, fieldState }) => (
              <SelectLabel
                {...field}
                required
                label="Business Type"
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                options={BUSINESS_TYPE}
              />
            )}
          />
        </div>
        <div className={css.supplierTextFieldWrapper}>
          <Controller
            name="supplier_email"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                autoFocus={disabledSupplierName}
                type="email"
                inputMode="email"
                enterKeyHint="next"
                autoComplete="email"
                label="Email"
                placeholder="Enter email address"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="supplier_website"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="url"
                inputMode="url"
                enterKeyHint="next"
                autoComplete="url"
                name="website"
                label="Website"
                placeholder="Enter website address"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={css.supplierTextFieldWrapper}>
          <Controller
            name="supplier_phone_number"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required
                type="tel"
                inputMode="tel"
                enterKeyHint="next"
                autoComplete="tel"
                label="Phone Number"
                placeholder="Enter phone number"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
          <Controller
            name="supplier_landline"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="tel"
                inputMode="tel"
                enterKeyHint="next"
                autoComplete="tel"
                label="Landline"
                placeholder="Enter landline number"
                error={fieldState.invalid}
                endIcon={
                  fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                }
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        {showVendorId && (
          <div className={css.supplierTextFieldWrapper}>
            <Controller
              name="vendor_id"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  required
                  disabled
                  type="text"
                  inputMode="text"
                  label="Vendor ID"
                  placeholder="Enter vendor ID"
                  error={fieldState.invalid}
                  endIcon={
                    fieldState.invalid && <IconNode src={Images.alertError} alt="Error Icon" />
                  }
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <div aria-hidden="true" className={css.supplierBlankField}></div>
          </div>
        )}
      </div>
    </BoxContainer>
  );
};

InfoFields.propTypes = {
  showVendorId: PropTypes.bool,
  disabledSupplierName: PropTypes.bool,
};

InfoFields.defaultProps = {
  showVendorId: true,
  disabledSupplierName: false,
};

export default memo(InfoFields);
