import Images from '@assets/images';
import { Accordion, Button, Typography } from '@components/base';
import { Avatar, DataGrid, DocumentRow } from '@components/common';
import React, { useContext, useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import css from './index.module.scss';
// import { clientRoutes } from '@router/routes';
import { AppContext } from '@helpers/hooks/AppContext';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getFormattedDate } from '@helpers/utils';
import { DOCUMENT_GENERATION_STATUS } from '@helpers/constants';
import Footer from '../components/footer';

interface GeneratePOprops {
  taskStatus: string;
  generatedBy: string;
  priority: string;
  taskData: Array<Partial<ITaskTabProps>>;
}

export type STATUS = 'not generated' | 'done' | 'rejected' | 'in review';
interface POTable {
  supplier: string;
  products: any;
  actions: STATUS | string;
  //status: STATUS | string;
}

const GeneratePOCard = (props: GeneratePOprops) => {
  //here, null represents the initial state of a task. Change the taskStatus to completed to see the UI changes on task completion.
  const navigate = useNavigate();
  const { taskData } = props;
  const tasks = taskData[0];
  const { appDispatch } = useContext(AppContext);

  const handleGeneratePOClick = () => {
    appDispatch({
      type: 'setPOData',
      payload: {
        task_id: tasks?.task_id,
        order_id: tasks?.order
      }
    });
    navigate(`/order/generate-purchase-order/${tasks.order}/${tasks?.supplier_id}`);
  };

  // TODO: Ideally memo should not be required here. Can directly use map in doc generator constants
  const docGenerationStatus = useMemo(() => {
    if (tasks?.purchase_order_generation_status as any) {
      return DOCUMENT_GENERATION_STATUS.find(
        (i) => i.value === tasks?.purchase_order_generation_status
      )?.label;
    }
    return null;
  }, [tasks]);

  const POList = [
    {
      supplier: tasks?.supplier_name,
      products: tasks?.supplier_product_names,
      actions: 'Generate PO',
      status: 'not started'
    }
  ];

  let actionsColumn: React.ReactNode;
  function renderActionDiv(actionStatus: string) {
    if (actionStatus === 'notGenerated') {
      actionsColumn = (
        <Button
          // disabled={taskData[0]?.is_completed}
          onClick={handleGeneratePOClick}
          variant="outlined"
          className={css.linkButton}>
          Generate PO
        </Button>
      );
    } else if (actionStatus === 'generated') {
      actionsColumn = (
        <div className={css.btnContainer}>
          {/* <DocumentRow.View title="" document={tasks?.purchase_order_pdf as string} />
          <DocumentRow.ViewWord document={tasks?.purchase_order_docx as string} title="" /> */}
        </div>
      );
    } else if (actionStatus === 'inProcess') {
      actionsColumn = (
        <Button variant="text" disabled className={css.assignButton}>
          Document generation in process
        </Button>
      );
    } else if (actionStatus === 'generationFailed') {
      actionsColumn = (
        <Button variant="text" disabled className={css.assignButton}>
          Document generation Failed
        </Button>
      );
    }
    return actionsColumn;
  }

  const [productColumn, productData] = useMemo(() => {
    const column: Column<POTable>[] = [
      {
        Header: 'Supplier',
        accessor: 'supplier',
        Cell: (props: any) => {
          const { value } = props;
          return (
            <div className={css.supplierWrap}>
              <Avatar src={`${value}`} alt={`${value} icon`} className={css.profileIcon} />
              <Typography variant="body">{value}</Typography>
            </div>
          );
        }
      },
      {
        Header: 'Products',
        accessor: 'products',
        Cell: (props: any) => {
          const { value } = props;
          return <Typography variant="body">{value}</Typography>;
        }
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: (props: any) => {
          // TODO: use constants for action status
          let actionStatus = 'notGenerated';
          if (tasks?.purchase_order_pdf && tasks?.purchase_order_docx) {
            actionStatus = 'generated';
          } else if (
            !tasks?.is_completed &&
            (docGenerationStatus == 'DOC_GENERATION_PROCESSING' ||
              docGenerationStatus == 'DOC_GENERATION_REQUESTED')
          ) {
            actionStatus = 'inProcess';
          } else if (docGenerationStatus == 'DOC_GENERATION_FAILED') {
            actionStatus = 'generationFailed';
          }
          // @ts-ignore: Unreachable code error
          return <>{renderActionDiv(actionStatus)}</>;
        }
      }
    ];
    const row: POTable[] = POList.map((item: any) => ({
      supplier: item?.supplier,
      products: item?.products?.length ? item?.products.join(', ') : '-',
      actions: item?.status
    }));
    return [column, row];
  }, [POList]);

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              <div className={css.title}>
                <div>
                  {!taskData[0]?.is_completed && <Avatar src={Images.illustration} alt="" />}
                  {taskData[0]?.is_completed && <Avatar src={Images.alertSuccessFaint} alt="" />}
                </div>
                <div className={css.subTitle}>
                  <Typography variant="h5">
                    #{`${tasks?.readable_task_id}`} - Generate Purchase Order
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content className={css.accordianContent}>
          <DataGrid tableStyle={{ width: '100%' }} columns={productColumn} data={productData} />
        </Accordion.Content>
      </Accordion>
      {/* <div className={css.footer}>
        <div className={css.textWrapper}>
          <Typography variant="h5">Created On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.created_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Updated On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.updated_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Time Taken:</Typography>
          <Typography variant="p">
            {moment(taskData[0]?.updated_at)?.diff(taskData[0]?.created_at, 'days')} Day(s)
          </Typography>
        </div>
      </div> */}
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
    </div>
  );
};
export default GeneratePOCard;
