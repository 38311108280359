import { memo } from 'react';
import css from './index.module.scss';
import { Divider, Typography } from '@components/base';
import { useNavigate } from 'react-router-dom';
import { CLIENT_ROUTES } from '@router/routes';

interface SupplierCardProps {
  vendorId: string;
  supplierId: string;
  supplierName: string;
  numberOfOrders: number;
  contactNumber: string;
  emailId: string;
  location: string;
}

const SupplierCard = (props: SupplierCardProps) => {
  const { supplierId, supplierName, numberOfOrders, contactNumber, emailId, location, vendorId } =
    props;

  const navigate = useNavigate();
  return (
    <div className={css.supplierCardWrapper}>
      <div
        onClick={() => navigate(`/${CLIENT_ROUTES.supplier}/${supplierId}`)}
        className={css.supplierCardLink}>
        <div className={css.supplierCardHeaderWrapper}>
          <div className={css.supplierCardHeader}>
            <Typography variant="span" className={css.supplierId}>
              ID {vendorId}
            </Typography>
            <Typography variant="h3">{supplierName}</Typography>
          </div>
          <Typography className="link-button-text">View Supplier</Typography>
        </div>
        <Divider />
        <div className={css.supplierCardFooterWrapper}>
          <div className={css.supplierCardFooterDetailWrapper}>
            <Typography variant="span" className={css.supplierDetailTitle}>
              No. of Order
            </Typography>
            <Typography variant="span" className={css.supplierDetailText}>
              {numberOfOrders}
            </Typography>
          </div>
          <div className={css.supplierCardFooterDetailWrapper}>
            <Typography variant="span" className={css.supplierDetailTitle}>
              Contact Number
            </Typography>
            <Typography variant="span" className={css.supplierDetailText}>
              {contactNumber}
            </Typography>
          </div>
          <div className={css.supplierCardFooterDetailWrapper}>
            <Typography variant="span" className={css.supplierDetailTitle}>
              Email ID
            </Typography>
            <Typography variant="span" className={css.supplierDetailText}>
              {emailId}
            </Typography>
          </div>
          <div className={css.supplierCardFooterDetailWrapper}>
            <Typography variant="span" className={css.supplierDetailTitle}>
              Location
            </Typography>
            <Typography variant="span" className={`${css.supplierDetailText} text-capitalize`}>
              {location}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SupplierCard);
