import css from './index.module.scss';
import { Button, Typography } from '@components/base';
import { BoxContainer } from '@components/common';
import Invoice from './components/invoice';
import { useContext, useState } from 'react';
import RejectPIModal from '../../RejectPIModal/index';
import { useNavigate } from 'react-router-dom';
import Parties from './components/parties';
import Order from './components/order';
import Remarks from './components/remarks';
import OrderContext from '@pages/order/generate-purchase-order/OrderContext';

interface purchaseOrderPreviewProps {
  editMode?: boolean;
  onFormSubmit: (data: any) => void;
  onBackClick?: () => void;
  onCancelClick?: () => void;
  handleTabState?: () => void;
  isRectify: boolean;
}

const POReview = (props: purchaseOrderPreviewProps) => {
  const { onFormSubmit, onBackClick, onCancelClick, isRectify, handleTabState } = props;
  const orderData = useContext(OrderContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { rectifyRemarks } = orderData;

  return (
    <div className={css.mainWrapper}>
      {isRectify && (
        <div className={css.rectifyContainer}>
          <Typography>Reasons to reject this Order</Typography>
          <ol className={css.marginsOl}>
            {rectifyRemarks?.split('.\n').map((val: any, index: any) => {
              return (
                <div key={index}>
                  <li>
                    <Typography key={index}>{val}</Typography>
                  </li>
                </div>
              );
            })}
          </ol>
        </div>
      )}
      <BoxContainer title="Preview">
        <Invoice isRectify={isRectify} />
        <Parties />
        <Order isRectify={isRectify} handleTabState={handleTabState} />
        <Remarks handleTabState={handleTabState} />
      </BoxContainer>
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div className={css.buttonContainer}>
          <Button variant="outlined-secondary" onClick={onBackClick}>
            Back
          </Button>
          <Button type="submit" onClick={onFormSubmit}>
            Send for approval and generate PO
          </Button>
        </div>
      </div>
      {showModal && <RejectPIModal open={showModal} onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default POReview;
