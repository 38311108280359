import css from './index.module.scss';
import { Button } from '@components/base';
import { useFormContext } from 'react-hook-form';
import { IBankDetailForm } from '@helpers/types/supplier';
import SupplierBankFields from './supplier-bank-fields';

interface SupplierBankDetailFormProps {
  editMode?: boolean;
  onFormSubmit: (data: IBankDetailForm) => void;
  onBackClick?: () => void;
  onCancelClick?: () => void;
}

const SupplierBankDetailForm = (props: SupplierBankDetailFormProps) => {
  const { onBackClick, onCancelClick, onFormSubmit, editMode = false } = props;

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty }
  } = useFormContext<IBankDetailForm>();

  return (
    <form className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <SupplierBankFields />
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <div className={css.actionWrapper}>
          <Button type="submit" disabled={isSubmitting || (editMode ? !isDirty : false)}>
            {editMode ? 'Save' : 'Save and Proceed'}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SupplierBankDetailForm;
