import { BoxContainer } from '@components/common';
import { memo } from 'react';
import css from './index.module.scss';
import TextField from '@components/common/text-field';
import { sanitizeValue } from '@helpers/utils';
import { IconNode, Typography } from '@components/base';
import Images from '@assets/images';
import { IOrderItem } from '@helpers/types/task-tabs';

interface IOrderItemContainerProps {
  itemDetails: IOrderItem;
  index: number;
}

const OrderItemContainer = (props: IOrderItemContainerProps) => {
  const { itemDetails, index } = props;
  const { product_name, product_grade_name, hs_code, package_details, net_wt_of_packages, gross_wt_of_packages, haz } =
    itemDetails;
  return (
    <BoxContainer title={`Item ${index + 1}`} className={css.mainWrapper}>
      <div className={css.boxWrapper}>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={sanitizeValue(product_name)}
            label="Product Name"
            placeholder="Product Name"
            rootClassName={css.fieldWrapper}
          />
          <TextField
            disabled
            value={sanitizeValue(product_grade_name)}
            label="Product Grade Name"
            placeholder="Product Grade Name"
          />
        </div>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={sanitizeValue(hs_code)}
            label="HS Code"
            placeholder="Enter HS Code"
            rootClassName={css.fieldWrapper}
          />
          <TextField
            disabled
            value={sanitizeValue(package_details)}
            label="Packaging Details"
            placeholder="Enter Packaging Details"
          />
        </div>
        <div className={css.rowWrapper}>
          <TextField
            disabled
            value={sanitizeValue(net_wt_of_packages)}
            label="Net Weight"
            placeholder="Enter Net Weight"
            rootClassName={css.fieldWrapper}
          />
          <TextField
            disabled
            value={sanitizeValue(gross_wt_of_packages)}
            label="Gross Weight"
            placeholder="Enter Gross Weight"
          />
        </div>
        {haz && (
          <div className={css.rowWrapper}>
            <div className={css.hazardousBoxWrapper}>
              <IconNode src={Images.hazardGrey} alt="Hazard Icon" />
              <Typography variant="h6" className={css.hazTitle}>
                This Product is Hazardous
              </Typography>
            </div>
          </div>
        )}
      </div>
    </BoxContainer>
  );
};

export default memo(OrderItemContainer);
