import Images from '@assets/images';
import { Accordion, Chip, Typography } from '@components/base';
import { Avatar, DocumentRow } from '@components/common';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import React, { useState } from 'react';
import AddPaymentDetails from './components/add-payment';
import css from './index.module.scss';
import { getFormattedDate } from '@helpers/utils';
import moment from 'moment';
import Footer from '../components/footer';

interface IPaymentProps {
  taskData: Array<Partial<ITaskTabProps>>;
  onComplete?: () => void;
}

const PaymentCard = (props: IPaymentProps) => {
  const { taskData } = props;
  const taskItem = taskData[0];
  const [openModal, setopenModal] = useState(false);
  const { dotGrey, alertSuccessFaint } = Images;
  const getCompleteStatus = props.taskData[0]?.is_completed;
  const [taskIsCompleted, setTaskIsCompleted] = useState<boolean | undefined>(getCompleteStatus);

  const handleStatusFromModal = (data: boolean) => {
    setTaskIsCompleted(data);
  };

  return (
    <React.Fragment>
      <div className={css.accordionContent}>
        <Accordion defaultExpanded>
          <Accordion.Header className={css.accordianHeaderParent}>
            <div className={css.accordionHeader}>
              <div className={css.header}>
                <div className={css.wrapper}>
                  {getCompleteStatus || taskIsCompleted ? (
                    <Avatar src={alertSuccessFaint} alt="payment-status" />
                  ) : (
                    <Avatar src={dotGrey} alt="payment-status" />
                  )}
                  <Typography variant="h5">
                    #{taskItem?.readable_task_id} - Payment Intimation
                  </Typography>
                </div>
                <div className={css.pocWrapper}>
                  {!(getCompleteStatus || taskIsCompleted) && (
                    <Chip
                      label="Upload Payment Proof"
                      variant="outlined"
                      clickable={true}
                      className={css.uploadButton}
                      onClick={() => setopenModal(true)}
                    />
                  )}
                </div>
              </div>
            </div>
          </Accordion.Header>
        </Accordion>
        {/* <div className={css.footer}>
          <div className={css.textWrapper}>
            <Typography variant="h5">Created On:</Typography>
            <Typography variant="p">
              {getFormattedDate(taskData[0]?.created_at as string)}
            </Typography>
          </div>
          <div className={css.textWrapper}>
            <Typography variant="h5">Updated On:</Typography>
            <Typography variant="p">
              {getFormattedDate(taskData[0]?.updated_at as string)}
            </Typography>
          </div>
          <div className={css.textWrapper}>
            <Typography variant="h5">Time Taken:</Typography>
            <Typography variant="p">
              {moment(taskData[0]?.updated_at)?.diff(taskData[0]?.created_at, 'days')} Day(s)
            </Typography>
          </div>
        </div> */}
        <Footer
          createdDate={props.taskData[0].created_at as Date}
          updatedDate={props.taskData[0].updated_at as Date}
        />
      </div>

      {openModal && (
        <AddPaymentDetails
          open={true}
          onClose={() => setopenModal(false)}
          sendStatusToCard={handleStatusFromModal}
          taskId={taskItem?.task_id as string}
          onComplete={props.onComplete}
        />
      )}
    </React.Fragment>
  );
};

export default PaymentCard;
