import React, { useEffect, useState } from 'react';
import css from './index.module.scss';
import {
  AutoComplete,
  BoxContainer,
  DeletePrompt,
  SelectLabel,
  TextEditor
} from '@components/common';
import Button from '@components/base/button';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Chip } from '@mui/material';
import { TextField } from '@components/common';
import OrderDocModal from '../order-doc-modal';
import { IconNode } from '@components/base';
import Images from '@assets/images';
import { validateEmail } from '@helpers/utils';
import notify from '@helpers/toastify-helper';

const filter = createFilterOptions<any>();

const FormWrapper = (props: any) => {
  const {
    onFormSubmit,
    onCancelClick,
    emailTemplates,
    userList,
    onTemplateChange,
    isNudge,
    orderDocumentList,
    onDocSelection,
    selectedDocList,
    orderPhotoList,
    onPhotoSelection,
    selectedPhotoList,
    handleAddStuffingPhotosSubmit,
    orderId,
    onAddDocumentFormSubmit,
    setOpenAddDocumentModal,
    openAddDocumentModal,
    onAddDocumentClose
  } = props;
  const { control, handleSubmit, setValue } = useFormContext<any>();

  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  const [openOrderDocModal, setOpenOrderDocModal] = useState(false);

  const templateWatchField = useWatch({
    name: 'template_type',
    control
  });

  const onEmailToSelection = (event: any, value: any) => {
    let setData = true;
    if (value.length) {
      value.forEach((val: any, index: number) => {
        if (val && typeof val === 'string') {
          value[index] = { value: val, label: val };
        }
      });
      value.forEach((val: any, index: number) => {
        if (val && typeof val === 'string') {
          value[index] = { value: val, label: val };
        }
        if (val?.value && !validateEmail(val.value)) {
          notify({
            message: `Email ${val.value} is not valid`,
            severity: 'error'
          });
          setData = false;
        }
      });
    }
    if (setData) setValue('to', value);
  };

  const onEmailCcSelection = (event: any, value: any) => {
    let setData = true;
    if (value.length) {
      value.forEach((val: any, index: number) => {
        if (val && typeof val === 'string') {
          value[index] = { value: val, label: val };
        }
      });
      value.forEach((val: any, index: number) => {
        if (val?.value && !validateEmail(val.value)) {
          notify({
            message: `Email ${val.value} is not valid`,
            severity: 'error'
          });
          setData = false;
        }
      });
    }
    if (setData) setValue('cc', value);
  };

  const onEmailBccSelection = (event: any, value: any) => {
    let setData = true;
    if (value.length) {
      value.forEach((val: any, index: number) => {
        if (val && typeof val === 'string') {
          value[index] = { value: val, label: val };
        }
      });
      value.forEach((val: any, index: number) => {
        if (val?.value && !validateEmail(val.value)) {
          notify({
            message: `Email ${val.value} is not valid`,
            severity: 'error'
          });
          setData = false;
        }
      });
    }
    if (setData) setValue('bcc', value);
  };

  useEffect(() => {
    if (!isNudge) {
      onTemplateChange(templateWatchField);
    }
  }, [templateWatchField]);

  return (
    <>
      <form onSubmit={handleSubmit(onFormSubmit)} className={css.formWrapper}>
        <BoxContainer title="New Email">
          <section className={css.boxWrapper}>
            <div className={css.rowWrapper}>
              <Controller
                name="to"
                control={control}
                render={({ field, fieldState }) => (
                  <AutoComplete
                    {...field}
                    multiple
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    id="tags-outlined"
                    disableClearable
                    options={userList}
                    filterOptions={(options: any, params: any) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some((option: any) => inputValue === option.value);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          value: inputValue,
                          label: inputValue
                        });
                      }
                      return filtered;
                    }}
                    keyOption="value"
                    onInputSelection={onEmailToSelection}
                    label="To"
                    width="884px"
                    placeholder="Select/Type"
                    // required={true}
                    rootClassName={css.typeField}
                    freeSolo
                    renderTags={(tagValue: any, getTagProps: any) =>
                      tagValue.map((option: any, index: any) => (
                        <Chip label={option.value} key={index} />
                      ))
                    }
                  />
                )}
              />
            </div>
            <div className={css.rowWrapper}>
              <Controller
                name="cc"
                control={control}
                render={({ field, fieldState }) => (
                  <AutoComplete
                    {...field}
                    multiple
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    id="tags-outlined"
                    disableClearable
                    options={userList}
                    filterOptions={(options: any, params: any) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some((option: any) => inputValue === option.value);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          value: inputValue,
                          label: inputValue
                        });
                      }
                      return filtered;
                    }}
                    keyOption="value"
                    onInputSelection={onEmailCcSelection}
                    label="CC (To make this email visible in your Gmail, add your email in cc)"
                    width="884px"
                    placeholder="Select/Type"
                    // required={true}
                    rootClassName={css.typeField}
                    freeSolo
                    renderTags={(tagValue: any, getTagProps: any) =>
                      tagValue.map((option: any, index: any) => (
                        <Chip label={option.value} key={index} />
                      ))
                    }
                  />
                )}
              />
            </div>
            <div className={css.rowWrapper}>
              <Controller
                name="bcc"
                control={control}
                render={({ field, fieldState }) => (
                  <AutoComplete
                    {...field}
                    multiple
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    id="tags-outlined"
                    disableClearable
                    options={userList}
                    filterOptions={(options: any, params: any) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some((option: any) => inputValue === option.value);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          value: inputValue,
                          label: inputValue
                        });
                      }
                      return filtered;
                    }}
                    keyOption="value"
                    onInputSelection={onEmailBccSelection}
                    label="BCC"
                    width="442px"
                    placeholder="Select/Type"
                    // required={true}
                    rootClassName={css.typeField}
                    freeSolo
                    renderTags={(tagValue: any, getTagProps: any) =>
                      tagValue.map((option: any, index: any) => (
                        <Chip label={option.value} key={index} />
                      ))
                    }
                  />
                )}
              />
              <Controller
                name="template_type"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    isDisabled={isNudge}
                    label="Select email template"
                    placeholder="Select one"
                    options={emailTemplates}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </div>
            <div className={css.rowWrapper}>
              <Controller
                name="subject"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Subject"
                    placeholder="Add Subject"
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
            <div className={css.rowWrapper}>
              <Controller
                name="body"
                control={control}
                render={({ field, fieldState }) => (
                  <TextEditor
                    {...field}
                    label="Body"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </div>
            <div className={css.rowWrapper}>
              <Controller
                name="reply_to"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectLabel
                    label="Reply To"
                    placeholder="Start One"
                    {...field}
                    isSearchable
                    isClearable
                    isDisabled
                    options={userList}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                  />
                )}
              />

              <div className={css.btnWrapper}>
                <Button
                  variant="outlined"
                  className={css.attachDocBtn}
                  onClick={() => setOpenOrderDocModal(true)}>
                  Attach Files
                </Button>
              </div>
            </div>
            <div className={css.rowWrapper1}>
              {selectedDocList.length > 0 && (
                <label className={css.label}>Attached Documents</label>
              )}
              <div className={css.attachedDocumentWrapper}>
                {selectedDocList.map((selectedDoc: any, index: number) => (
                  <>
                    <Button
                      key={index}
                      onClick={() =>
                        window.open(`${selectedDoc.url}`, '_blank', 'noopener,noreferrer')
                      }
                      variant="outlined"
                      className={css.downloadPdf}>
                      {selectedDoc.name} - {selectedDoc.type}
                    </Button>
                    <IconNode
                      key={index}
                      src={Images.crossBlack}
                      alt="close icon"
                      component="div"
                      className={css.closeButton}
                      onClick={() => {
                        selectedDoc.check_box = false;
                        const indexToRemove = selectedDocList.findIndex(
                          (doc: any) => doc === selectedDoc
                        );
                        if (indexToRemove !== -1) {
                          selectedDocList.splice(indexToRemove, 1);
                          onDocSelection([...selectedDocList]);
                        }
                      }}
                    />
                  </>
                ))}
              </div>
            </div>
            <div className={css.rowWrapper1}>
              {selectedPhotoList.length > 0 && (
                <label className={css.label}>Attached Stuffing Photos</label>
              )}
              <div className={css.attachedDocumentWrapper}>
                {selectedPhotoList.map((selectedImage: any, index: number) => (
                  <>
                    <Button
                      key={index}
                      onClick={() =>
                        window.open(`${selectedImage.url}`, '_blank', 'noopener,noreferrer')
                      }
                      variant="outlined"
                      className={css.downloadPdf}>
                      {selectedImage.name}
                    </Button>
                    <IconNode
                      key={index}
                      src={Images.crossBlack}
                      alt="close icon"
                      component="div"
                      className={css.closeButton}
                      onClick={() => {
                        selectedImage.check_box = false;
                        const indexToRemove = selectedPhotoList.findIndex(
                          (doc: any) => doc === selectedImage
                        );
                        if (indexToRemove !== -1) {
                          selectedPhotoList.splice(indexToRemove, 1);
                          onPhotoSelection([...selectedPhotoList]);
                        }
                      }}
                    />
                  </>
                ))}
              </div>
            </div>
          </section>
        </BoxContainer>
        <div className={css.actionBtnWrapper}>
          <Button variant="text" onClick={onCancelClick}>
            Cancel
          </Button>
          <div>
            {isNudge ? (
              <>
                <Button type="submit" className={css.approveBtn}>
                  Approve
                </Button>
                <Button variant="outlined-secondary" onClick={() => setOpenDeletePrompt(true)}>
                  Decline
                </Button>
              </>
            ) : (
              <>
                <Button type="submit">Send Email</Button>
              </>
            )}
          </div>
        </div>
        {openDeletePrompt && (
          <DeletePrompt
            open={openDeletePrompt}
            onClose={() => setOpenDeletePrompt(false)}
            onConfirm={() => onFormSubmit(null) as any}
            message="Are you sure you want to decline this nudge?"
          />
        )}
      </form>
      {openOrderDocModal && (
        <OrderDocModal
          open={openOrderDocModal}
          onClose={() => setOpenOrderDocModal(false)}
          orderDocumentList={orderDocumentList}
          onDocSelection={onDocSelection}
          orderPhotoList={orderPhotoList}
          onPhotoSelection={onPhotoSelection}
          handleAddStuffingPhotosSubmit={handleAddStuffingPhotosSubmit}
          orderId={orderId}
          onAddDocumentFormSubmit={onAddDocumentFormSubmit}
          onAddDocumentClose={onAddDocumentClose}
          openAddDocumentModal={openAddDocumentModal}
          setOpenAddDocumentModal={setOpenAddDocumentModal}
        />
      )}
    </>
  );
};

export default FormWrapper;
