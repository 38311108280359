import { useState, useEffect, useMemo } from 'react';
import { getDocuments } from '@services/order.service';

import { Chip, IconNode, Loader, ToolTip } from '@components/base';
import notify from '@helpers/toastify-helper';

import css from './index.module.scss';
import Images from '@assets/images';
import { DEFAULT_CROP_LIMIT, TAG_LIMIT_VIEW, USER_TYPES } from '@helpers/constants';
import { CellProps, Column } from 'react-table';
import {
  SUPPORT_EMAIL,
  cropValues,
  getFormattedDate,
  getPermissionFromLocalStorage
} from '@helpers/utils';
import { DataGrid, DocumentRow, ListPlaceholder } from '@components/common';

interface DocumentTabProps {
  orderId: string;
  readableOrderId: string;
}

interface DocumentTabState {
  docData: Array<TableGrid>;
  isLoading: boolean;
}

interface TableGrid {
  document_type_display_value: string;
  order_item_name: string;
  created_at: string;
  created_by: string;
  document: TableGrid;
  display_name: string;
  tags: string[];
  customer_document_info: {
    created_at: string;
    document_object: string;
    document_url: string;
  };
}

const CustomerView = (props: DocumentTabProps) => {
  const { orderId } = props;

  const [documentState, setDocumentState] = useState<DocumentTabState>({
    docData: [],
    isLoading: false
  });

  const userType = useMemo(() => {
    return getPermissionFromLocalStorage()?.user_type;
  }, []);

  const { docData, isLoading } = documentState;

  useEffect(() => {
    getDocumentData();
  }, []);

  const getDocumentData = async () => {
    setDocumentState((prevState: DocumentTabState) => ({ ...prevState, isLoading: true }));
    const documentData = await getDocuments(orderId, {
      customer_shared_docs_only: true
    });
    setDocumentState((prevState: DocumentTabState) => ({ ...prevState, isLoading: false }));
    if (documentData?.success) {
      if (documentData.data?.results) {
        setDocumentState((prevState: DocumentTabState) => ({
          ...prevState,
          docData: documentData.data?.results
        }));
      }
    } else {
      notify({
        title: 'We have little problem',
        message: documentData?.error ?? 'There was an error fetching documents.',
        severity: 'error'
      });
    }
  };

  const [documentColumn, documentData] = useMemo(() => {
    const column: Column<TableGrid>[] = [
      {
        Header: 'Document Type',
        accessor: 'document_type_display_value'
      },
      {
        Header: 'Document Name',
        accessor: 'display_name',
        Cell: (props: CellProps<TableGrid>) => {
          const { value } = props;
          return (
            <div className={css.display_name}>
              {/* {value.display_name?.length > DEFAULT_CROP_LIMIT ? (
                <ToolTip title={value.display_name}>
                  <div className={css.docName}>{cropValues(value.display_name)}</div>
                </ToolTip>
              ) : (
                <div className={css.docName}>{cropValues(value.display_name)}</div>
              )} */}
              <div className={css.docName}>{value.display_name}</div>
            </div>
          );
        }
      },

      {
        Header: 'Product',
        accessor: 'order_item_name',
        Cell: (props: CellProps<TableGrid>) => {
          const { value } = props;
          return <div>{value}</div>;
          // value?.length > DEFAULT_CROP_LIMIT ? (
          //   <ToolTip title={value}>
          //     <div>{cropValues(value)}</div>
          //   </ToolTip>
          // ) : (
          //   <div>{cropValues(value)}</div>
          // );
        }
      },

      {
        Header: 'Uploaded By',
        accessor: 'created_by',
        Cell: (props: CellProps<TableGrid>) => {
          const { value } = props;
          return value?.length > DEFAULT_CROP_LIMIT ? (
            <ToolTip title={value}>
              <div>{cropValues(value)}</div>
            </ToolTip>
          ) : (
            <div>{cropValues(value)}</div>
          );
        }
      },
      {
        Header: 'Shared On',
        accessor: 'created_at'
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        Cell: (props: CellProps<TableGrid>) => {
          const { value } = props;
          const { customer_tags = [] } = value;
          return (
            <div className={css.chipGroup}>
              {customer_tags?.length ? (
                customer_tags?.length > TAG_LIMIT_VIEW ? (
                  <>
                    {customer_tags.slice(0, TAG_LIMIT_VIEW).map((tag: string) => (
                      <Chip key={tag} label={tag} className={css.chip} />
                    ))}
                    <ToolTip
                      title={customer_tags.slice(TAG_LIMIT_VIEW).join(', ')}
                      placement="right">
                      <div>
                        <Chip
                          label={`+ ${customer_tags.length - TAG_LIMIT_VIEW}`}
                          className={css.chip}
                        />
                      </div>
                    </ToolTip>
                  </>
                ) : (
                  customer_tags?.map((tag: string) => (
                    <Chip key={tag} label={tag} className={css.chip} />
                  ))
                )
              ) : (
                <>-</>
              )}
            </div>
          );
        }
      },
      {
        Header: '',
        accessor: 'document',
        Cell: (props: CellProps<TableGrid>) => {
          const { value } = props;
          return (
            <>
              <div className={css.tableIcons}>
                {/* <div>
                  <ToolTip
                    title={value.remarks?.trim()?.length ? value.remarks : `Remarks Not Available`}>
                    <div>
                      <IconNode src={Images.alertGrey} alt="eye icon" />
                    </div>
                  </ToolTip>
                </div> */}
                <DocumentRow.View
                  showEyeIconWhenDocumentMissing={true}
                  document={value.customer_document_info?.document_object ?? '-'}
                  openViaSlug
                  title=""
                />
              </div>
            </>
          );
        }
      }
    ];
    const row: any = docData.map((doc: TableGrid) => ({
      document_type_display_value: doc.document_type_display_value ?? '-',
      order_item_name: doc.order_item_name ?? '-',
      created_at: doc?.customer_document_info?.created_at
        ? getFormattedDate(doc?.customer_document_info?.created_at)
        : '-',
      created_by: doc.created_by ?? '-',
      document: doc,
      display_name: doc,
      tags: doc
    }));
    return [column, row];
  }, [docData]);

  return (
    <>
      {docData?.length ? (
        <DataGrid columns={documentColumn} data={documentData} />
      ) : (
        <ListPlaceholder
          title={
            userType === USER_TYPES.customer
              ? `No documents available`
              : `No documents shared with customer`
          }
          supportingText={
            userType === USER_TYPES.customer
              ? `Contact us at ${SUPPORT_EMAIL} if a document is not visible`
              : `Use the Document Visibility tab to share documents with the customer.`
          }
          buttonText=""
          to={''}
        />
      )}
      <Loader open={isLoading} />
    </>
  );
};

export default CustomerView;
