import React, { useCallback, useContext, useEffect, useState } from 'react';

import css from './index.module.scss';
import Typography from '@components/base/typography';
import { SideBar } from '@components/common';
import { Loader } from '@components/base';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { CLIENT_ROUTES } from '@router/routes';
import { FormProvider, useForm } from 'react-hook-form';
import FormWrapper from './form-wrapper';
import {
  getEmailDetails,
  getFilterOptions,
  getTemplateDetails,
  sendEmailService,
  sendNudge
} from '@services/email.service';
import notify from '@helpers/toastify-helper';
import { yupResolver } from '@hookform/resolvers/yup';
import { emailSchema } from '@helpers/yup/email.schema';
import { getToken, parseJWT } from '@helpers/auth-helper';
import { uploadOrderOtherDoc, uploadOrderPhotos } from '@services/order.service';
import { AppContext } from '@helpers/hooks/AppContext';
import { ISelect } from '@helpers/types';
import { IFilterDocument, INudgeDetails } from '@helpers/types/email';

interface IFormState {
  activeStep: number;
  isLoading: boolean;
  emailTemplates: ISelect[];
  userList: ISelect[];
  isNudge: boolean;
  nudgeDetails: INudgeDetails | null;
  orderDocumentList: IFilterDocument[];
  selectedDocList: IFilterDocument[];
  currentUserData: any;
  orderPhotoList: IFilterDocument[];
  selectedPhotoList: IFilterDocument[];
}

const Compose = (props: any) => {
  const [formState, setFormState] = useState<IFormState>({
    activeStep: 0,
    isLoading: false,
    emailTemplates: [],
    userList: [],
    isNudge: false,
    nudgeDetails: null,
    orderDocumentList: [],
    selectedDocList: [],
    currentUserData: null,
    orderPhotoList: [],
    selectedPhotoList: []
  });

  const { appState } = useContext(AppContext);
  const { sendDocToCustomer } = appState;

  const [openAddDocumentModal, setOpenAddDocumentModal] = useState(false);

  const {
    activeStep,
    isLoading,
    emailTemplates,
    userList,
    isNudge,
    nudgeDetails,
    orderDocumentList,
    selectedDocList,
    currentUserData,
    orderPhotoList,
    selectedPhotoList
  } = formState;
  useBeforeUnloadAndNavigate();

  const navigate = useNavigate();

  const params = useParams();

  const newEmailForm = useForm<any>({
    resolver: yupResolver(emailSchema),
    defaultValues: {
      to: [],
      cc: [],
      bcc: [],
      subject: '',
      body: '',
      reply_to: null,
      template_type: null,
      order_images: [],
      order_documents: []
    }
  });

  const handleNavigation = (currentStep: number) => {
    setFormState((prevState: IFormState) => ({
      ...prevState,
      activeStep: currentStep
    }));
  };

  const handleSidebarClick = (value: number) => {
    handleNavigation(value);
  };

  const handleBackClick = (currentStep: number) => () => {
    handleNavigation(currentStep - 1);
  };

  const handleCancelClick = useCallback(() => {
    if (sendDocToCustomer) {
      navigate(`/${CLIENT_ROUTES.order}/${params?.orderId}?activeTab=1&activeSubTab=2`);
    } else {
      navigate(`/${CLIENT_ROUTES.order}/${params?.orderId}?activeTab=7`);
    }
  }, []);

  const sendEmail = async (data: any) => {
    const body = {
      subject: data.subject,
      email_to: data.to?.length ? data.to.map((item: any) => item.value) : [],
      email_cc: data.cc?.length ? data.cc.map((item: any) => item.value) : [],
      email_bcc: data.bcc?.length ? data.bcc.map((item: any) => item.value) : [],
      email_reply_to: data?.reply_to?.value ?? null,
      body: data.body,
      template_type: data.template_type?.value,
      order_document_ids: selectedDocList.map((item: any) => item.id),
      order_image_ids: selectedPhotoList.map((item: any) => item.id)
    };
    const response: any = await sendEmailService(params.orderId ?? '', body);
    if (response?.success) {
      notify({ message: 'Email sent successfully', severity: 'success' });
      if (sendDocToCustomer) {
        navigate(`/${CLIENT_ROUTES.order}/${params?.orderId}?activeTab=1&activeSubTab=2`);
      } else {
        navigate(`/${CLIENT_ROUTES.order}/${params?.orderId}?activeTab=7`);
      }
    } else {
      notify({ message: response?.error ?? 'Failed to send email', severity: 'error' });
    }
  };

  const getFilterOptionsForEmail = async () => {
    setFormState((prevState: IFormState) => ({ ...prevState, isLoading: true }));
    const response: any = await getFilterOptions(params.orderId ?? '');
    setFormState((prevState: IFormState) => ({ ...prevState, isLoading: false }));
    if (response?.success) {
      setFormState((prevState: IFormState) => ({
        ...prevState,
        emailTemplates: response?.data?.template_filter_options,
        userList: response?.data?.user_filter_options,
        orderDocumentList: response?.data?.order_documents_filter_options,
        orderPhotoList: response?.data?.order_images_filter_options
      }));
      if (params?.nudgeId != 'new') {
        setFormState((prevState: IFormState) => ({
          ...prevState,
          isNudge: true
        }));
        //NEED TO REWORK THIS
        getNudgeDetails(response?.data?.order_documents_filter_options);
      }
      //set default subject to generic if redirected via email
      if (sendDocToCustomer) {
        const { setValue } = newEmailForm;
        setValue(
          'template_type',
          response?.data?.template_filter_options?.find((item: ISelect) => item.default)
        );
      }
    } else {
      notify({ message: response?.error ?? 'Failed to fetch filter options', severity: 'error' });
    }
  };

  const getNudgeDetails = async (orderDocList: any) => {
    const { setValue } = newEmailForm;
    if (params?.nudgeId) {
      const response: any = await getEmailDetails(params.orderId ?? '', params?.nudgeId);
      if (response?.success) {
        setFormState((prevState: IFormState) => ({
          ...prevState,
          nudgeDetails: response?.data
        }));

        if (
          response?.data?.template_type &&
          (!response?.data?.body || !response?.data?.body?.length)
        ) {
          onTemplateChange({ value: response?.data?.template_type });
        } else {
          setValue('body', response?.data?.body);
        }
        setValue('template_type', {
          label: response?.data?.template_display_name,
          value: response?.data?.template_type
        });
        setValue(
          'to',
          response?.data?.email_to?.length
            ? response?.data?.email_to.map((item: any) => ({
                label: item,
                value: item
              }))
            : []
        );
        setValue(
          'cc',
          response?.data?.email_cc?.length
            ? response?.data?.email_cc.map((item: any) => ({
                label: item,
                value: item
              }))
            : []
        );
        setValue(
          'bcc',
          response?.data?.email_bcc?.length
            ? response?.data?.email_bcc.map((item: any) => ({
                label: item,
                value: item
              }))
            : []
        );
        setValue('subject', response?.data?.subject);
        setValue('reply_to', {
          label: response?.data?.email_reply_to,
          value: response?.data?.email_reply_to
        });
        selectionOfDocuments(response?.data?.order_documents ?? [], orderDocList);
      } else {
        notify({ message: response?.error ?? 'Failed to fetch email details', severity: 'error' });
      }
    }
  };

  const onTemplateChange = async (data: any) => {
    const { setValue } = newEmailForm;
    if (!data || !data?.value) return;
    const response: any = await getTemplateDetails(params.orderId ?? '', data?.value);
    if (response?.success) {
      setValue('body', response?.data?.body);
      setValue('subject', response?.data?.subject);
    } else {
      notify({
        message: response?.error ?? 'Unable to fetch template details. Please try again.',
        severity: 'error'
      });
    }
  };

  const submitNudge = async (data: any) => {
    if (data) {
      const body = {
        subject: data.subject,
        email_to: data.to?.length ? data.to.map((item: any) => item.value) : [],
        email_cc: data.cc?.length ? data.cc.map((item: any) => item.value) : [],
        email_bcc: data.bcc?.length ? data.bcc.map((item: any) => item.value) : [],
        email_reply_to: data?.reply_to?.value ?? null,
        body: data.body,
        template_type: data.template_type?.value,
        order_document_ids: selectedDocList.map((item: any) => item.id),
        order_image_ids: selectedPhotoList.map((item: any) => item.id),
        action: 1,
        email_from: nudgeDetails?.email_from
      };
      const response: any = await sendNudge(params.orderId ?? '', nudgeDetails?.id, body);
      if (response?.success) {
        notify({ message: 'Nudge sent successfully', severity: 'success' });
        navigate(`/${CLIENT_ROUTES.order}/${params?.orderId}?activeTab=7`);
      } else {
        notify({ message: response?.error ?? 'Failed to send nudge', severity: 'error' });
      }
    } else {
      const body = {
        action: 2
      };
      const response: any = await sendNudge(params.orderId ?? '', nudgeDetails?.id, body);
      if (response?.success) {
        notify({ message: 'Nudge declined successfully', severity: 'success' });
        navigate(`/${CLIENT_ROUTES.order}/${params?.orderId}?activeTab=7`);
      } else {
        notify({ message: response?.error ?? 'Failed to decline nudge', severity: 'error' });
      }
    }
  };

  const selectionOfDocuments = (data: any, orderDocList?: any) => {
    const copyofOrderDocList = orderDocList ?? orderDocumentList;
    copyofOrderDocList.forEach((item: any) => {
      if (data?.map((item: any) => item.id).includes(item.id)) {
        item.check_box = true;
      } else {
        item.check_box = false;
      }
    });
    setFormState((prevState: IFormState) => ({
      ...prevState,
      selectedDocList: data,
      orderDocumentList: copyofOrderDocList
    }));
  };

  const selectionOfPhotos = (data: any, orderStuffedPhotoList?: any) => {
    const copyofOrderPhotoList = orderStuffedPhotoList ?? orderPhotoList;
    copyofOrderPhotoList?.forEach((item: any) => {
      if (data?.map((item: any) => item.id).includes(item.id)) {
        item.check_box = true;
      } else {
        item.check_box = false;
      }
    });
    setFormState((prevState: IFormState) => ({
      ...prevState,
      selectedPhotoList: data,
      orderPhotoList: copyofOrderPhotoList
    }));
  };

  useEffect(() => {
    getFilterOptionsForEmail();
    const { setValue } = newEmailForm;
    if (params?.nudgeId == 'new') {
      try {
        const accessToken = getToken('access');
        const jwtData = parseJWT(accessToken);
        setFormState((prevState: IFormState) => ({
          ...prevState,
          currentUserData: jwtData
        }));
        setValue('reply_to', {
          label: jwtData?.email,
          value: jwtData?.email
        });
      } catch (error) {
        notify({ message: 'Failed to fetch user details', severity: 'error' });
      }
    }

    if (sendDocToCustomer) {
      setValue('to', [
        {
          label: sendDocToCustomer?.to,
          value: sendDocToCustomer?.to
        }
      ]);
      setFormState((prevState: IFormState) => ({
        ...prevState,
        selectedDocList: sendDocToCustomer?.documentsToBeSent
      }));
    }
  }, [params]);

  const handleAddStuffingPhotosSubmit = async (data: any) => {
    if (data?.target?.files?.length) {
      const formData = new FormData();
      formData.append('document_type', 'STUFFING');
      for (let i = 0; i < data.target.files.length; i++) {
        formData.append(`files[]`, data.target.files[i]);
      }
      const uploadResponse = await uploadOrderPhotos(`${params?.orderId}`, formData);
      if (uploadResponse?.success) {
        notify({ message: 'Stuffing photos uploaded successfully', severity: 'success' });
        const newStuffingPhoto = uploadResponse.data.map((item: any) => ({
          id: item.order_image_id,
          name: item.name,
          type: item.document_type,
          url: item.image_object
        }));
        const newStuffingPhotoArray = [...newStuffingPhoto, ...orderPhotoList];
        selectionOfPhotos(selectedPhotoList, newStuffingPhotoArray);
      } else {
        notify({
          title: 'We have little problem',
          message:
            uploadResponse.error ?? 'Unable to get stuffing photos, please try after some time!',
          severity: 'error'
        });
      }
    }
  };

  const onAddDocumentClose = () => {
    setOpenAddDocumentModal(false);
  };

  const handleAddDocumentsFormSubmit = async (data: any) => {
    const formData = new FormData();
    if (data.document_object) {
      formData.append('document_object', data.document_object as Blob);
    } else {
      notify({
        title: 'We have little problem',
        message: `Document is required`,
        severity: 'error'
      });
    }
    if (data?.type) {
      formData.append('type', data.type.value);
    }
    if (data?.name) {
      formData.append('name', data.name);
    }
    if (data?.supplier) {
      formData.append('supplier', data.supplier.supplier_id);
    }
    if (data?.remarks) {
      formData.append('remarks', data.remarks);
    }
    if (data?.order_item) {
      formData.append('order_item', data.order_item.order_item_id);
    }
    if (data?.internal_tags?.length) {
      data?.internal_tags.forEach((tag: any) => {
        formData.append('internal_tags', tag.value);
      });
    }
    formData.append('document_type', 'OTHERS');

    const response = await uploadOrderOtherDoc(`${params?.orderId}`, formData);
    if (response?.success) {
      notify({
        title: 'Success',
        message: `Document ${data.order_document_id ? `updated` : `uploaded`} successfully`,
        severity: 'success'
      });
      const newDocument = {
        id: response.data.order_document_id,
        name: response.data.display_name,
        type: response.data.document_type,
        url: response.data.document_object
      };
      const newOrderDocumentList = [newDocument, ...orderDocumentList];
      selectionOfDocuments(selectedDocList, newOrderDocumentList);
      onAddDocumentClose();
    } else {
      notify({
        title: 'We have little problem',
        message:
          response.error ??
          `There was an error ${data.order_document_id ? `updating` : `uploading`} document.`,
        severity: 'error'
      });
    }
  };

  return (
    <main className={css.formWrapper}>
      <div className={css.titleWrapper}>
        <Typography variant="h2">Compose an Email</Typography>
        <Typography variant="subheading1">0{activeStep + 1} of 02</Typography>
      </div>
      <div className={css.formContainer}>
        <div className={css.sideBarWrapper}>
          <SideBar
            activeStep={activeStep}
            onClick={handleSidebarClick}
            onEnter={handleSidebarClick}>
            <SideBar.Item label="New Email" value={0} />
            {/* <SideBar.Item label="Preview" value={1} disabled={activeStep! <= 1} /> */}
          </SideBar>
        </div>
        {activeStep === 0 && (
          <FormProvider {...newEmailForm}>
            <FormWrapper
              userList={userList}
              emailTemplates={emailTemplates}
              onCancelClick={handleCancelClick}
              onFormSubmit={isNudge ? submitNudge : sendEmail}
              onTemplateChange={onTemplateChange}
              isNudge={isNudge}
              orderDocumentList={orderDocumentList}
              onDocSelection={selectionOfDocuments}
              selectedDocList={selectedDocList}
              currentUserData={currentUserData}
              orderPhotoList={orderPhotoList}
              onPhotoSelection={selectionOfPhotos}
              selectedPhotoList={selectedPhotoList}
              handleAddStuffingPhotosSubmit={handleAddStuffingPhotosSubmit}
              orderId={params.orderId}
              onAddDocumentFormSubmit={handleAddDocumentsFormSubmit}
              onAddDocumentClose={onAddDocumentClose}
              openAddDocumentModal={openAddDocumentModal}
              setOpenAddDocumentModal={setOpenAddDocumentModal}
            />
          </FormProvider>
        )}
      </div>
      <Loader open={isLoading} />
    </main>
  );
};

export default Compose;
