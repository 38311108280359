import { Typography, Modal, IconNode, Button } from '@components/base';
import { TextField, InputDatePicker } from '@components/common';
import { useFormContext, Controller } from 'react-hook-form';
import Images from '@assets/images';
import { addBLDetails } from '@services/task.service';
import notify from '@helpers/toastify-helper';
import moment from 'moment';

import css from './index.module.scss';
import { useState } from 'react';

interface DateModalProps {
  open: boolean;
  onClose: () => void;
  onFormSubmit: (data: any) => void;
  shipmentDate: string | null;
}

const DateModal = (props: DateModalProps) => {
  const { open, onClose, onFormSubmit, shipmentDate } = props;
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useFormContext();

  const toggleDatePicker = () => {
    setIsDatePickerOpen((prevState: boolean) => !prevState);
  };
  return (
    <Modal open={open} onClose={onClose}>
      <div className={`${css.modalWrapper} ${isDatePickerOpen ? css.modalWrapperOpen : ''}`}>
        <section className={css.modalHeader}>
          <Typography variant="h4">
            {`${shipmentDate ? `Confirm` : `Enter`}`} Shipment Arrival Date
          </Typography>
          <IconNode
            src={Images.crossBlack}
            alt="close icon"
            component="button"
            className={css.closeButton}
            onClick={onClose}
          />
        </section>

        <form noValidate className={css.formWrapper} onSubmit={handleSubmit(onFormSubmit)}>
          <section className={css.modalContent}>
              <Controller
                name="shipment_arrival_state_actual_date"
                control={control}
                render={({ field, fieldState }) => (
                  <InputDatePicker
                    {...field}
                    required
                    setDatePickerOpen={setIsDatePickerOpen}
                    value={field.value}
                    placeholder="Select date"
                    label={`${shipmentDate ? `Confirm` : `Enter`} Date`}
                    onSelect={(day: Date | undefined) => {
                      field.onChange(day ?? null);
                      toggleDatePicker();
                    }}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={
                      isDatePickerOpen ? css.fieldSpacingOpen : ''
                    }
                  />
                )}
              />
          </section>
          <section className={css.modalFooter}>
            <Button variant="outlined-secondary" onClick={onClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {shipmentDate ? `Confirm` : `Save`}
            </Button>
          </section>
        </form>
      </div>
    </Modal>
  );
};

export default DateModal;
