import * as yup from 'yup';

export const rejectOrderSchema = yup.object().shape({
  order_rectify_remarks: yup.string().trim().required('Rejection reason is required')
});

const containerSchema = yup.object().shape({
  container_id: yup.string().trim().required('Container ID is required'),
  container_no: yup.string().trim().required('Container number is required')
});

export const containerDataSchema = yup.object().shape({
  container_carrier_name: yup.string().trim().required('Carrier name is required'),
  container: yup.array().of(containerSchema).required('Container is required')
});

export const blDataSchema = yup.object().shape({
  bl_date: yup.string().trim().required('BL date is required'),
  bl_number: yup.string().trim().required('BL number is required'),
  bl_type: yup.mixed().required('BL type is required')
});

export const awbDataSchema = yup.object().shape({
  awb_date: yup.string().trim().required('AWB date is required'),
  awb_number: yup.string().trim().required('AWB number is required'),
  awb_service_name: yup.string().trim().required('AWB service name is required')
});

export const proofOfExportSchema = yup.object().shape({
  not_applicable: yup.boolean().required('This is required'),
  document_array: yup.mixed().when('not_applicable', {
    is: (value: boolean) => !value,
    then(schema) {
      return schema.required('Document is required');
    },
    otherwise(schema) {
      return schema.optional().nullable();
    }
  }),
  remarks: yup.string().trim().optional().nullable()
});

export const addBatchNumberSchema = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      name: yup.string().trim().required('Name is required'),
      order_item_id: yup.string().trim().required('Order item ID is required'),
      batch: yup.array().of(
        yup.object().shape({
          batch_number: yup.string().trim().required('Batch number is required')
        })
      )
    })
  )
});

export const packingDocumentDataSchema = yup.object().shape({
  package_details: yup.mixed().required('Package details is required'),
  batch_number: yup.mixed().required('Batch number is required'),
  start_package_no: yup
    .number()
    .integer('Number should be an integer')
    .typeError('Should be a valid number')
    .required('Required'),
  end_package_no: yup
    .number()
    .integer('Number should be an integer')
    .typeError('Should be a valid number')
    .required('Required'),
  // tare_wt: yup
  //   .number()
  //   .positive('Number should be positive')
  //   .typeError('Should be a valid number')
  //   .required('Tare weight is required'),
  // gross_wt: yup
  //   .number()
  //   .positive('Number should be positive')
  //   .typeError('Should be a valid number')
  //   .required('Gross weight is required'),
  net_wt: yup
    .number()
    .positive('Number should be positive')
    .typeError('Should be a valid number')
    .required('Net weight is required'),
  no_of_packages: yup
    .number()
    .integer('Number should be an integer')
    .typeError('Should be a valid number')
    .required('Number of packages is required')
});

export const packingListItemSchema = yup.object().shape({
  name: yup.string().trim().required('Name is required'),
  order_item_id: yup.string().trim().required('Order item ID is required'),
  // total_net_wt: yup
  //   .number()
  //   .integer('Number should be an integer')
  //   .typeError('Should be a valid number')
  //   .required('Total net weight is required'),
  // total_gross_wt: yup
  //   .number()
  //   .integer('Number should be an integer')
  //   .typeError('Should be a valid number')
  //   .required('Total gross weight is required'),

  // total_no_of_packages: yup
  //   .number()
  //   .integer('Number should be an integer')
  //   .typeError('Should be a valid number')
  //   .required('Total number of packages is required'),
  packaging_document_data: yup
    .array()
    .of(packingDocumentDataSchema)
    .required('Packaging document data is required')
});

export const packingListSchema = yup.object().shape({
  items: yup.array().of(packingListItemSchema).required('Items is required'),
  total_pallets_packed: yup
    .number()
    .integer('Number should be an integer')
    .typeError('Should be a valid number')
    .optional()
    .nullable()
});

export const taskEstimateSchema = yup.object().shape({
  estimate_data: yup.array().of(
    yup.object().shape({
      state_type: yup.string().trim().required('Task type is required'),
      estimated_date: yup.string().trim().required('Estimated date is required')
    })
  )
});

export const dateConfirmSchema = yup.object().shape({
  shipment_arrival_state_actual_date: yup.string().trim().required('Date is required')
});
