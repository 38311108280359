import Images from '@assets/images';
import { Accordion, Button, Typography } from '@components/base';
import { Avatar, DocumentRow } from '@components/common';
import { getFormattedDate } from '@helpers/utils';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import css from './index.module.scss';
import { useMemo } from 'react';
import { DOCUMENT_GENERATION_STATUS } from '@helpers/constants';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import Footer from '../components/footer';

interface GenerateBOEProps {
  taskData: Array<Partial<ITaskTabProps>>;
}
const GenerateBOECard = (props: GenerateBOEProps) => {
  const { taskData } = props;
  const taskItem = taskData[0];
  const navigate = useNavigate();
  const urlParams = useParams();
  const { id } = urlParams;

  const handleGenerateClick = () => {
    navigate(`/finance/generate-bill-of-exchange/${id}/${taskData[0]?.task_id}`);
  };

  // TODO: Ideally memo should not be required here. Can directly use map in doc generator constants
  const docGenerationStatus = useMemo(() => {
    if (taskItem?.boe_generation_status as number) {
      return DOCUMENT_GENERATION_STATUS.find((i) => i.value === taskItem?.boe_generation_status)
        ?.label;
    }
    return null;
  }, [taskItem]);

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {!taskData[0]?.is_completed && <Avatar src={Images.illustrationEdit} alt="" />}
              {taskData[0]?.is_completed && <Avatar src={Images.alertSuccessFaint} alt="" />}
              <Typography variant="h5">
                #{taskData[0]?.readable_task_id} - Generate Bill of Exchange
              </Typography>
            </div>

            <div className={css.pocWrapper}>
              {/* TODO: Rethink this logic */}
              {!taskItem.is_completed && !docGenerationStatus && (
                <Button
                  onClick={handleGenerateClick}
                  className={css.generateButton}
                  variant="outlined">
                  Generate BOE
                </Button>
              )}
              {!taskItem?.is_completed &&
                (docGenerationStatus == 'DOC_GENERATION_PROCESSING' ||
                  docGenerationStatus == 'DOC_GENERATION_REQUESTED') && (
                  <Button variant="text" disabled className={css.assignButton}>
                    Document generation in process
                  </Button>
                )}
              {!taskItem?.is_completed && docGenerationStatus == 'DOC_GENERATION_FAILED' && (
                <Button variant="text" disabled className={css.assignButton}>
                  Document Generation Failed
                </Button>
              )}
            </div>
          </div>
        </Accordion.Header>
      </Accordion>
      {/* <div className={css.footer}>
        <div className={css.textWrapper}>
          <Typography variant="h5">Created On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.created_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Updated On:</Typography>
          <Typography variant="p">{getFormattedDate(taskData[0]?.updated_at as string)}</Typography>
        </div>
        <div className={css.textWrapper}>
          <Typography variant="h5">Time Taken:</Typography>
          <Typography variant="p">
            {moment(taskData[0]?.updated_at)?.diff(taskData[0]?.created_at, 'days')} Day(s)
          </Typography>
        </div>
      </div> */}
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
    </div>
  );
};
export default GenerateBOECard;
