/* eslint-disable @typescript-eslint/no-empty-function */
import { Button, Loader, Typography } from '@components/base';
import { AppContext } from '@helpers/hooks/AppContext';
import notify from '@helpers/toastify-helper';
import { PurchaseOrderStates } from '@helpers/types/po';
import {
  approvePurchaseReview,
  fetchPOReviewByIdWithFormattedAmount,
  rejectPurchaseOrder
} from '@services/order.service';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PIReview from './components/preview-tab';
import RejectPIModal from './components/preview-tab/components/reject-modal';
import css from './index.module.scss';

const PurchaseOrderReview = (props: any) => {
  const { appState } = useContext(AppContext);
  const { PIData } = appState;
  const navigate = useNavigate();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [reviewState, setReviewState] = useState<PurchaseOrderStates>({
    isLoading: false,
    activeStep: 0,
    itemDetailsInfo: null,
    purchaseOrder: null,
    remarksInfo: null,
    remarks: '',
    orderInfo: {
      invoice_number: '',
      order_type: '',
      order_number: ''
    },
    entityList: {
      name: '',
      gst_number: '',
      address: ''
    },
    customerInfo: {
      name: '',
      country: null,
      address: '',
      customer_poc: {}
    },
    consigneeInfo: {
      isSameAsCustomer: false,
      consignee_name: '',
      consignee_phone_number: '',
      consignee_email_id: '',
      consignee_office_address: '',
      address: '',
      gst_number: ''
    },
    itemInfo: {
      items: [],
      totalAmount: '',
      supplier: [],
      totalAmountInWords: ''
    },
    orderDetails: {
      transportation_mode: '',
      inco_terms: '',
      country_of_origin: '',
      country_of_final_destination: '',
      port_of_loading: '',
      port_of_discharge: '',
      place_of_delivery: '',
      payment_terms: '',
      is_qc_test: false,
      delivery_term: '',
      place_of_quality_test: '',
      vendor_id: '',
      payment_term_suffix: null
    },
    bankDetails: {
      bank_name: '',
      branch_name: '',
      bank_account_holder_name: '',
      account_number: '',
      ifsc_code: '',
      swift_code: '',
      address: ''
    },
    supplier: {
      supplier_factory_address: null,
      supplier_poc: null
    },
    previewLink: null
  });

  const { isLoading, previewLink } = reviewState;
  const { id } = params;

  useEffect(() => {
    (async () => {
      setReviewState((prevState: any) => ({ ...prevState, isLoading: !prevState.isLoading }));
      const response = await fetchPOReviewByIdWithFormattedAmount(
        params.id as string,
        params.supplierid as string,
        1
      );

      const remarksInfo = {
        string: JSON.parse(response?.data?.remarks)?.string,
        data: JSON.parse(response?.data?.remarks)?.data
      };
      if (response.success) {
        const { data: reviewinfo } = response;
        setReviewState((prevState: PurchaseOrderStates) => ({
          ...prevState,
          isLoading: !prevState.isLoading,
          orderInfo: {
            invoice_number: reviewinfo?.document_number,
            order_type: reviewinfo?.order?.supply_type,
            order_number: reviewinfo?.order?.readable_order_id
          },
          entityList: {
            name: reviewinfo?.order?.elchemy_entity?.name,
            gst_number: reviewinfo?.order?.elchemy_entity?.gst_number,
            address: reviewinfo?.order?.elchemy_entity?.address
          },
          customerInfo: {
            name: reviewinfo?.order?.customer?.name,
            country: reviewinfo?.order?.customer?.country,
            address: `${reviewinfo?.supplier?.addresses[0]?.address_line1 ?? ''} ${
              reviewinfo?.order?.customer_address?.address_line2 ?? ''
            } ${reviewinfo?.order?.customer_address?.city ?? ''} ${
              reviewinfo?.order?.customer_address?.state ?? ''
            } ${reviewinfo?.order?.customer_address?.country ?? ''}`,
            customer_poc: reviewinfo?.order?.customer_poc
          },
          consigneeInfo: {
            isSameAsCustomer: reviewinfo?.order?.consignee_same_as_customer,
            consignee_name: reviewinfo?.supplier?.supplier_name,
            consignee_phone_number: reviewinfo?.order?.consignee_phone_number,
            consignee_email_id: reviewinfo?.order?.consignee_email_id,
            consignee_office_address: `${
              reviewinfo?.supplier?.addresses[0]?.address_line1 ?? ''
            }, ${reviewinfo?.supplier?.addresses[0]?.address_line2 ?? ''}`,
            address: `${reviewinfo?.supplier?.addresses[0]?.address_line1 ?? ''}, ${
              reviewinfo?.supplier?.addresses[0]?.address_line2 ?? ''
            }, ${reviewinfo?.supplier?.addresses[0]?.city ?? ''}, ${
              reviewinfo?.supplier?.addresses[0]?.state ?? ''
            }, ${reviewinfo?.supplier?.addresses[0]?.country ?? ''}`,
            customer_poc: reviewinfo?.order?.customer_poc,
            gst_number: reviewinfo?.supplier?.gst_number
          },
          itemInfo: {
            items: reviewinfo?.order?.order_item,
            totalAmount: reviewinfo?.total_amount,
            supplier: reviewinfo?.suppliers,
            totalAmountInWords: reviewinfo?.total_amount_of_all_items_in_words,
            currency: reviewinfo?.currency,
            unit_of_weight: reviewinfo?.unit_of_weight
          },
          // bankDetails: {
          //   bank_name: reviewinfo?.order?.elchemy_bank?.bank_name,
          //   branch_name: reviewinfo?.order?.elchemy_bank?.branch_name,
          //   bank_account_holder_name: reviewinfo?.order?.elchemy_bank?.bank_account_holder_name,
          //   account_number: reviewinfo?.order?.elchemy_bank?.account_number,
          //   ifsc_code: reviewinfo?.order?.elchemy_bank?.ifsc_code,
          //   swift_code: reviewinfo?.order?.elchemy_bank?.swift_code,
          //   address: `${reviewinfo?.order?.elchemy_bank?.address_line1 ?? ''} ${
          //     reviewinfo?.elchemy_bank?.address_line2 ?? ''
          //   } ${reviewinfo?.order?.elchemy_bank?.city ?? ''} ${
          //     reviewinfo?.order?.elchemy_bank?.state ?? ''
          //   }`
          // },
          orderDetails: {
            transportation_mode: reviewinfo?.order?.transportation_mode,
            inco_terms: reviewinfo?.po_terms_of_delivery,
            country_of_origin: reviewinfo?.po_country_of_origin_display_value,
            country_of_final_destination: reviewinfo?.order?.country_of_final_destination,
            port_of_loading: reviewinfo?.order?.port_of_loading,
            port_of_discharge: reviewinfo?.order?.port_of_discharge,
            place_of_delivery: reviewinfo?.order?.country_of_final_destination,
            is_qc_test: reviewinfo?.order?.is_qc_test,
            place_of_quality_test: reviewinfo?.order?.place_of_quality_test,
            delivery_term: reviewinfo?.order?.inco_terms,
            vendor_id: reviewinfo?.supplier?.vendor_id,
            payment_terms: reviewinfo?.payment_term?.map((item: any) => {
              return {
                payment_term: {
                  payment_term: item.payment_term,
                  days: item.days ? true : false,
                  percentage: item.percentage ? true : false
                },
                percentage: item.percentage,
                days: item.days ? item.days : null
              };
            }),
            payment_term_suffix: {
              value: reviewinfo?.po_payment_term_suffix_value,
              name: reviewinfo?.po_payment_term_suffix_label
            }
          },
          itemDetailsInfo: {
            custom_items: reviewinfo?.custom_items
          },
          remarks: remarksInfo.string,
          purchaseOrder: {
            document_date: reviewinfo?.document_date,
            document_number: reviewinfo?.document_number,
            po_reference_number: reviewinfo?.po_reference_no,
            notify_party: reviewinfo?.notify_party,
            gst_rate: reviewinfo?.gst_rate,
            gst_amount: reviewinfo?.gst_amount,
            currency: reviewinfo?.currency
          },
          supplier: {
            supplier_factory_address: reviewinfo.supplier_factory_address,
            supplier_poc: reviewinfo.supplier_poc
          },
          previewLink: reviewinfo?.pdf_document_display_url
        }));
      } else {
        setReviewState((prevState) => ({ ...prevState, isLoading: !prevState.isLoading }));
        notify({
          message: response.error ?? 'Unable to fetch data',
          severity: 'error',
          dismissible: true
        });
      }
    })();
  }, []);

  const handleFormSubmit = async () => {
    setOpen(!open);
    setReviewState((prevState: PurchaseOrderStates) => ({
      ...prevState,
      isLoading: !prevState.isLoading
    }));
    const response = await approvePurchaseReview(
      PIData?.task_id as string,
      params.supplierid as string,
      {}
    );
    if (response.success) {
      notify({ message: response?.data?.message, severity: 'success', dismissible: true });
      navigate(`/order/${id}`, { replace: true });
    } else {
      notify({
        message: response.error ?? 'Something went wrong!',
        severity: 'error',
        dismissible: true
      });
    }
    setReviewState((prevState: any) => ({ ...prevState, isLoading: !prevState.isLoading }));
  };

  const handleCancelClick = () => {
    navigate(`/order/${id}`, { replace: true });
  };

  const handleRejectNoteSubmit = async (data: any) => {
    setReviewState((prevState: PurchaseOrderStates) => ({
      ...prevState,
      isLoading: !prevState.isLoading
    }));
    const response = await rejectPurchaseOrder(
      PIData?.task_id as string,
      params.supplierid as string,
      data
    );
    if (response.success) {
      notify({ message: response?.data?.message, severity: 'success', dismissible: true });
      navigate(`/order/${id}`, { replace: true });
    } else {
      notify({
        message: response.error ?? 'Something went wrong!',
        severity: 'error',
        dismissible: true
      });
    }
    setReviewState((prevState: PurchaseOrderStates) => ({
      ...prevState,
      isLoading: !prevState.isLoading
    }));
  };

  const openPreviewLink = () => {
    if (previewLink?.length) {
      window.open(previewLink, '_blank');
    }
  };

  return (
    <main className={css.purchaseOrderWrapper}>
      <div className={css.titleWrapper}>
        <Typography variant="h2">Preview Purchase Order</Typography>
        {previewLink?.length && (
          <Button variant="outlined" onClick={openPreviewLink}>
            Open in PDF
          </Button>
        )}
      </div>
      <div className={css.purchaseOrderContainer}>
        <PIReview
          previewData={reviewState}
          handleFormSubmit={handleFormSubmit}
          handleCancelClick={handleCancelClick}
          handleRejectClick={() => setOpen(!open)}
        />
      </div>
      <RejectPIModal open={open} onFormSubmit={handleRejectNoteSubmit} onClose={setOpen} />
      <Loader open={isLoading} />
    </main>
  );
};

export default PurchaseOrderReview;
