import { useFormContext } from 'react-hook-form';
import css from './index.module.scss';
import { BoxContainer, TextField } from '@components/common';
import { Button, Typography } from '@components/base';
import { remarkSI } from '@helpers/constants';
import { useEffect } from 'react';
import { IShippingFormData } from '@helpers/types/si';

interface IRemarkTabProps {
  shippingFormData: IShippingFormData;
  handleRemarksSubmit: () => void;
  handleCancelClick: () => void;
  onBackClick: () => void;
}

const RemarkTab = (props: IRemarkTabProps) => {
  const { shippingFormData, handleRemarksSubmit, handleCancelClick, onBackClick } = props;
  const bl_type = shippingFormData.blType;
  const { setValue, getValues } = useFormContext();

  useEffect(() => {
    setValue('remarks', [
      bl_type?.value == '2' ? remarkSI.obl : remarkSI.seawayBL,
      remarkSI.generalInstructions
    ]);
  }, [bl_type]);

  const remarks = getValues('remarks');

  return (
    <div className={css.formWrapper}>
      <BoxContainer title="Remarks">
        <div className={css.boxWrapper}>
          {remarks &&
            remarks.map((remark: string, index: number) => (
              <Typography key={index}>
                {index + 1}. {remark}
              </Typography>
            ))}
        </div>
      </BoxContainer>
      <div className={css.actionButtonWrapper}>
        <Button variant="text" onClick={handleCancelClick}>
          Cancel
        </Button>
        <div className={css.buttonContainer}>
          <Button onClick={onBackClick} variant="outlined-secondary">
            Back
          </Button>
          <Button type="submit" onClick={handleRemarksSubmit}>
            Save and Proceed
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RemarkTab;
