import css from './index.module.scss';
import {
  BoxContainer,
  InputDatePicker,
  SelectLabel,
  TextAreaLabel,
  TextField,
  AutoComplete,
  PaymentTerms
} from '@components/common';
import { Controller, useFormContext, useWatch, useFieldArray, SubmitHandler } from 'react-hook-form';
import { Button, CheckboxLabel } from '@components/base';
import { useContext, useEffect, useState } from 'react';
import OrderContext from '../../OrderContext';
import { useParams } from 'react-router-dom';
import {
  CURRENCY,
  DELIVERY_TERMS,
  GST_RATE,
  UNITS_OF_WEIGHTS,
  booleanSelectFields,
  QUANTITY_INPUT_DISABLE_SWITCH,
  DEFAULT_AMOUNT_ROUND_OFF
} from '@helpers/constants';
import notify from '@helpers/toastify-helper';
import { getPaymentTermsFilterOptions } from '@services/order.service';
import { Divider } from '@components/base';
import { IPaymentTermsFilter } from '@helpers/types/add-order';
import { generateQuantityFromPackingDetails, generateReadablePackingDetails } from '@helpers/utils';
import { IGeneratePOProps } from '@helpers/types/purchase-order';
import { ISelect, ISelectBoolean } from '@helpers/types';
import { OrderItem } from '@helpers/types/order';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const writtenNumber = require('written-number'); //intentionally written | no type definitions available

interface IPurchaseOrderDetailProps {
  formSubmit: SubmitHandler<any>;
  cancelClick: () => void;
  onBackClick: () => void;
  isRectify: boolean;
  data: IGeneratePOProps;
  countryOptions: ISelect[] | undefined;
  filterCountryOptions: (event?: any, param?: any) => Promise<void>;
  supplierData: any;
  paymentTermSuffixList: never[];
}

const PurchaseOrderDetail = (props: IPurchaseOrderDetailProps) => {
  const {
    formSubmit,
    cancelClick,
    onBackClick,
    isRectify,
    data,
    countryOptions,
    filterCountryOptions,
    supplierData,
    paymentTermSuffixList
  } = props;

  const { handleSubmit, control, setValue } = useFormContext();
  const urlParams = useParams();
  const { supplierId } = urlParams;
  const orderData = useContext(OrderContext);
  const [paymentTermsOptions, setPaymentTermsOptions] = useState<IPaymentTermsFilter[]>([]);
  const [filteredPaymentTermsOptions, setFilteredPaymentTermsOptions] = useState<
    IPaymentTermsFilter[]
  >([]);
  const itemsQuantity = orderData?.orderInfo?.order_item
    .filter((order: any) => order?.supplier_id == supplierId)
    .map((item: any, index: number) => [
      `order_item.${index}.quantity_po`,
      `order_item.${index}.rate_po`
    ])
    .flat();

  const watchField = useWatch({
    name: itemsQuantity,
    control
  });

  const paymentTermWatchField = useWatch({
    name: 'payment_terms',
    control
  });

  const {
    fields: paymentTermFields,
    append: paymentTermAppend,
    remove: paymentTermRemove
  } = useFieldArray({
    name: 'payment_terms',
    control
  });

  const output: any = [];
  for (let i = 0; i < watchField.length; i += 2) {
    output.push([watchField[i], watchField[i + 1]]);
  }

  const gstWatch = useWatch({
    name: 'gst_rate',
    control
  });

  const itemDataWatchFields = useWatch({
    name: ['currency', 'unit_of_weight'],
    control
  });

  const total_amount_of_items = output?.reduce((acc: number, item: Array<any>) => {
    acc += Number(item?.[0]) * Number(item?.[1]);
    return acc;
  }, 0);

  const total_with_gst =
    Number(total_amount_of_items) + Number(total_amount_of_items) * (gstWatch.value / 100);

  const gst = (Number(total_amount_of_items) * (gstWatch.value / 100)).toFixed(2).toString();

  useEffect(() => {
    setValue('total_amount', String(total_with_gst.toFixed(DEFAULT_AMOUNT_ROUND_OFF)) || '');
    setValue(
      'total_amount_of_all_items_in_words',
      writtenNumber(total_with_gst, { lang: 'en' })?.toUpperCase() || ''
    );
    setValue('gst_amount', gst);
  }, [watchField, gstWatch]);

  useEffect(() => {
    filterCountryOptions();
    data.orderInfo.order_item
      ?.filter((order: OrderItem) => order?.supplier_id == supplierId)
      ?.map((item: OrderItem, index: number) => {
        setValue(
          `order_item.${index}.order_item_description_po`,
          item?.order_item_description_po?.length
            ? item?.order_item_description_po
            : item?.order_item_description
        );
      });
    data.poData.order_item.length > 0
      ? data.poData.order_item
          ?.filter((order: OrderItem) => order?.supplier_id == supplierId)
          ?.map((item: OrderItem, index: number) => {
            setValue(
              `order_item.${index}.include_grade_name_in_docs`,
              item.include_grade_name_in_docs
            );
          })
      : data.orderInfo.order_item
          ?.filter((order: OrderItem) => order?.supplier_id == supplierId)
          ?.map((item: OrderItem, index: number) => {
            setValue(
              `order_item.${index}.include_grade_name_in_docs`,
              item.include_grade_name_in_docs
                ? booleanSelectFields.find(
                    (opt: ISelectBoolean) => opt.value === item.include_grade_name_in_docs
                  )
                : booleanSelectFields[0]
            );
          });
    getPaymentTermsOptions();
  }, []);

  const handleAddPaymentTermField = () => {
    paymentTermAppend({
      payment_term: null,
      percentage: '',
      days: ''
    });
  };

  const getPaymentTermsOptions = async () => {
    const response = await getPaymentTermsFilterOptions();
    if (response?.success) {
      setPaymentTermsOptions(response.data?.payment_terms_options);
    } else {
      notify({
        severity: 'error',
        title: 'Error',
        message: response.message ?? 'Unable to fetch payment terms'
      });
    }
  };

  /**
   * This useEffect is used to filter the payment terms options
   */
  useEffect(() => {
    const selectedPaymentTerms = paymentTermWatchField?.map(
      (item: any) => item?.payment_term?.payment_term ?? ''
    );
    const filteredPaymentTermsOptionsCopy = paymentTermsOptions.filter((item: any) => {
      return !selectedPaymentTerms.includes(item.payment_term);
    });
    setFilteredPaymentTermsOptions(filteredPaymentTermsOptionsCopy);
  }, [paymentTermWatchField]);

  useEffect(() => {
    data.orderInfo.order_item
      ?.filter((order: any) => order?.supplier_id == supplierId)
      ?.map((item: any, index: number) => {
        setValue(
          `order_item.${index}.quantity_po`,
          generateQuantityFromPackingDetails(item?.package, itemDataWatchFields[1])
        );
      });
    if (itemDataWatchFields[0].value !== 'INR') {
      setValue('gst_rate', { value: 0, label: 0 });
    }
  }, [itemDataWatchFields]);

  return (
    <form noValidate onSubmit={handleSubmit(formSubmit)} className={css.formWrapper}>
      <BoxContainer title="Purchaser Order Details">
        <div className={css.orderContainer}>
          <div className={css.fieldWrapper}>
            <Controller
              name="document_date"
              control={control}
              render={({ field, fieldState }) => (
                <InputDatePicker
                  {...field}
                  label="PO Date"
                  value={field.value}
                  onSelect={(day: Date | undefined) => field.onChange(day ?? null)}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  rootClassName={css.fieldSpacing}
                />
              )}
            />
            <Controller
              name="vendor_id"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  disabled
                  value={orderData?.supplier?.vendor_id}
                  label="Vendor ID"
                  placeholder="Enter Vendor Id"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={css.fieldWrapper}>
            <Controller
              name="po_terms_of_delivery"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  options={DELIVERY_TERMS}
                  required
                  label="Terms of Delivery"
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
            <Controller
              name="delivery_address"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  // disabled
                  // value={String(item?.total_amount).split('.')[0] ?? ''}
                  required
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  label="Delivery Address"
                />
              )}
            />
          </div>
          <Divider className={css.dividerWrapper} />
          <div>
            <div className={css.rowWrapper}>
              <PaymentTerms
                paymentTermFields={paymentTermFields}
                paymentTermRemove={paymentTermRemove}
                filteredPaymentTermsOptions={
                  filteredPaymentTermsOptions?.length
                    ? filteredPaymentTermsOptions
                    : paymentTermsOptions
                }
                handleAddPaymentTermField={handleAddPaymentTermField}
                paymentTermsOptions={paymentTermsOptions}
              />
            </div>
            <Controller
              name="payment_term_suffix"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  isClearable
                  label="Payment Term Suffix (Only if Needed)"
                  placeholder="Payment Term Suffix"
                  options={paymentTermSuffixList}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.value}
                  rootClassName={css.paymentTermSuffix}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
        </div>
      </BoxContainer>

      <BoxContainer title="Supplier Details">
        <div className={css.orderContainer}>
          <div className={css.fieldWrapper}>
            <Controller
              name="supplier_name"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={orderData?.supplier?.supplier_name}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  disabled
                  label="Supplier"
                />
              )}
            />
            <Controller
              name="gst_number"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  value={orderData?.supplier?.supplier_gst_no}
                  disabled
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  label="GST No."
                />
              )}
            />
            <Controller
              name="po_country_of_origin"
              control={control}
              render={({ field, fieldState }) => {
                const onCountrySelection = (event: any, param: any) => {
                  if (param) {
                    setValue('po_country_of_origin', param);
                  } else {
                    setValue('po_country_of_origin', null);
                  }
                };
                return (
                  <AutoComplete
                    {...field}
                    required
                    label="Country of Origin"
                    placeholder="Start typing"
                    options={countryOptions}
                    onInputChange={filterCountryOptions}
                    onInputSelection={onCountrySelection}
                    keyOption="label"
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    rootClassName={css.fieldWrapper1}
                  />
                );
              }}
            />
            {/* <Controller
              name="po_country_of_origin"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  required
                  options={countries}
                  label="Country of Origin"
                  placeholder="Select country"
                  defaultValue={''}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  isSearchable
                />
              )}
            /> */}
          </div>
          <div className={css.fieldWrapper}>
            <Controller
              name={`currency`}
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  options={CURRENCY}
                  required
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  label="Currency"
                />
              )}
            />
            <Controller
              name={`unit_of_weight`}
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  options={UNITS_OF_WEIGHTS}
                  required
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  label="Unit of Weight"
                />
              )}
            />
            <Controller
              name="gst_rate"
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  options={GST_RATE}
                  required
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  isDisabled={itemDataWatchFields[0].value !== 'INR'}
                  label="GST Rate"
                />
              )}
            />
          </div>
          <div className={css.fieldWrapper}>
            <Controller
              name={`supplier_poc`}
              control={control}
              render={({ field, fieldState }) => (
                <SelectLabel
                  {...field}
                  options={supplierData.supplier_poc_list}
                  required
                  getOptionLabel={(option: any) =>
                    `${option.family_name} ${option.given_name} ${option.email}`
                  }
                  getOptionValue={(option: any) => option.value}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  placeholder="Select POC"
                  label="Supplier POC"
                />
              )}
            />
          </div>
          <div className={css.fieldWrapper}>
            <Controller
              name={`supplier_factory_address`}
              control={control}
              render={({ field, fieldState }) => (
                  <SelectLabel
                    {...field}
                    options={supplierData.factory_address_list}
                    required
                    getOptionLabel={(option: any) => option.readable_address}
                    getOptionValue={(option: any) => option.value}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    placeholder="Select one"
                    label="Supplier Factory address"
                  />
              )}
            />
          </div>

          {/* <div className={css.fieldWrapper}> */}
          {/* </div> */}
        </div>
      </BoxContainer>

      {data.orderInfo.order_item
        ?.filter((order: any) => order?.supplier_id == supplierId)
        ?.map((item: any, index: number) => {
          return (
            <BoxContainer key={index} title="Item Details">
              <div className={css.orderContainer}>
                <div className={css.fieldWrapper}>
                  <Controller
                    name={`order_item.${index}.item_name`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectLabel
                        {...field}
                        value={{
                          value: `${item?.product_name} - ${item?.product_grade_name}`,
                          label: `${item?.product_name} - ${item?.product_grade_name}`
                        }}
                        label="Item Name"
                        isDisabled
                      />
                    )}
                  />
                </div>
                <div className={css.fieldWrapper}>
                  <TextField disabled value={item?.hs_code ?? '-'} label="HS Code" />
                  <TextField disabled value={item?.CAS_number ?? '-'} label="CAS Number" />
                </div>
                <div className={css.fieldWrapper}>
                  <Controller
                    name={`order_item.${index}.order_item_description_po`}
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <TextField
                          {...field}
                          required
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                          label="Item Description"
                        />
                      );
                    }}
                  />
                </div>
                <div className={css.fieldWrapper}>
                  <TextField disabled value={item?.product_grade_name ?? '-'} label="Grade" />
                  <Controller
                    name={`order_item.${index}.include_grade_name_in_docs`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectLabel
                        {...field}
                        required
                        isSearchable
                        label="Show grade on document"
                        placeholder="Select option"
                        options={booleanSelectFields}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </div>
                <div className={css.fieldWrapper}>
                  <TextField
                    required
                    disabled
                    value={
                      item?.package ? generateReadablePackingDetails(item?.package).join(', ') : '-'
                    }
                    label="Packaging details"
                    placeholder="Enter"
                  />
                </div>
                <div className={css.fieldWrapper}>
                  <Controller
                    name={`order_item.${index}.quantity_po`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required
                        disabled={QUANTITY_INPUT_DISABLE_SWITCH}
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        label={`Quantity ${
                          itemDataWatchFields[1] ? `in ${itemDataWatchFields[1]?.label}` : ``
                        }`}
                      />
                    )}
                  />
                  <Controller
                    name={`order_item.${index}.rate_po`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        required
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                        label={`Price/ Unit (${
                          itemDataWatchFields[0] ? itemDataWatchFields[0]?.label : ``
                        })`}
                      />
                    )}
                  />
                  <Controller
                    name={`order_item.${index}.amount`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        disabled
                        value={`${
                          output[index][0] && output[index][1]
                            ? (Number(output[index][0]) * Number(output[index][1])).toFixed(
                                DEFAULT_AMOUNT_ROUND_OFF
                              )
                            : 0
                        }`}
                        required
                        placeholder="Amount"
                        label={`Amount (${
                          itemDataWatchFields[0] ? itemDataWatchFields[0]?.label : ``
                        })`}
                      />
                    )}
                  />
                </div>
                <div className={css.fieldWrapper}>
                  <CheckboxLabel
                    label="Is pre-shipment sample required?"
                    value={item?.is_pre_shipment_sample_required}
                    checked={item?.is_pre_shipment_sample_required}
                    disabled={true}
                  />
                </div>
              </div>
            </BoxContainer>
          );
        })}
      <BoxContainer title="Total of Items">
        <div className={css.orderContainer}>
          <div className={css.fieldWrapper}>
            {gstWatch.value != 0 && (
              <Controller
                name="gst_amount"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled
                    value={
                      total_amount_of_items ? total_amount_of_items * (gstWatch.value / 100) : 0
                    }
                    label={`GST Amount (${
                      itemDataWatchFields[0] ? itemDataWatchFields[0]?.label : ``
                    })`}
                  />
                )}
              />
            )}
            <Controller
              name="total_amount"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  disabled
                  value={
                    total_amount_of_items ? total_with_gst.toFixed(DEFAULT_AMOUNT_ROUND_OFF) : 0
                  }
                  label={`Total Amount (${
                    itemDataWatchFields[0] ? itemDataWatchFields[0]?.label : ``
                  })`}
                />
              )}
            />
          </div>
          <div className={css.fieldWrapper}>
            <Controller
              name="total_amount_of_all_items_in_words"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  disabled
                  value={writtenNumber(total_with_gst, { lang: 'en' })?.toUpperCase() ?? ''}
                  label="Total amount (in words)"
                />
              )}
            />
          </div>
        </div>
      </BoxContainer>
      <div className={css.actionButtonWrapper}>
        <Button onClick={onBackClick} variant="text">
          Cancel
        </Button>
        <div className={css.actionButtonContainer}>
          <Button onClick={cancelClick} variant="outlined-secondary">
            Back
          </Button>
          <Button type="submit">Save and Proceed</Button>
        </div>
      </div>
    </form>
  );
};

export default PurchaseOrderDetail;
