import { useCallback, useState, useEffect } from 'react';
import css from './index.module.scss';
import { Button, IconNode, Typography, Loader } from '@components/base';
import {
  SideBar,
  SupplierDetailForm,
  BankDetailForm,
  BankInfo,
  BankPlaceholder
} from '@components/common';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ISupplier, ISupplierDetailForm, IBankDetailForm } from '@helpers/types/supplier';
import {
  getRequestBodyForSupplierDetail,
  getSupplierDetailFormValuesFromResponse,
  getRequestBodyForSupplierBankDetail,
  getBankFormValuesFromResponse
} from '@helpers/utils';
import { addSupplierSchema, addBankDetailSchema } from '@helpers/yup';
import Images from '@assets/images';
import UserInfo from './components/user-info';
import { updateSupplierInfo } from '@services/supplier.service';
import notify from '@helpers/toastify-helper';
import { useBeforeUnloadAndNavigate } from '@helpers/hooks';
import { fetchAllUsers } from '@services/user.service';
import AccessWrapper from '@authorization/access-wrapper';

interface SupplierDetailTabProps {
  supplierInfo: ISupplier;
  onFormEdit: (form: ISupplier) => void;
  actions: IActions;
}

interface SupplierDetailTabStates {
  activeStep: number;
  formEditable: boolean;
  userList: Array<object>;
}

const SupplierDetailTab = (props: SupplierDetailTabProps) => {
  const { supplierInfo, onFormEdit, actions } = props;

  const [supplierDetailState, setSupplierDetailState] = useState<SupplierDetailTabStates>({
    activeStep: 0,
    formEditable: false,
    userList: []
  });

  useBeforeUnloadAndNavigate();

  const { activeStep, formEditable, userList } = supplierDetailState;

  useEffect(() => {
    fetchUsersForPocList();
  }, []);

  const getSupplierDetailDefaultValues = useCallback(() => {
    const { supplierInfo } = props;
    return Promise.resolve(getSupplierDetailFormValuesFromResponse(supplierInfo));
  }, [props.supplierInfo]);

  const firstFormMethods = useForm<ISupplierDetailForm>({
    resolver: yupResolver(addSupplierSchema),
    defaultValues: getSupplierDetailDefaultValues
  });

  const getBankInfoDefaultValues = useCallback(() => {
    const { supplierInfo } = props;
    if (supplierInfo.bankdetails.length < 1) {
      return Promise.resolve({
        bankdetails: [
          {
            bank_id: '',
            bank_name: '',
            branch_name: '',
            bank_account_holder_name: '',
            account_number: '',
            ifsc_code: '',
            swift_code: '',
            address_line1: '',
            address_line2: '',
            zip_code: '',
            city: null,
            state: null,
            country: null,
            address_suffix: null
          }
        ]
      });
    }
    const bankValues = getBankFormValuesFromResponse(supplierInfo.bankdetails);
    return Promise.resolve({ bankdetails: bankValues });
  }, [props.supplierInfo]);

  const secondFormMethods = useForm<IBankDetailForm>({
    resolver: yupResolver(addBankDetailSchema),
    defaultValues: getBankInfoDefaultValues
  });

  const {
    setError: firstSetError,
    reset: firstReset,
    formState: { isSubmitting: firstIsSubmitting, isDirty: firstIsDirty }
  } = firstFormMethods;

  const {
    reset: secondReset,
    formState: { isSubmitting: secondIsSubmitting, isDirty: secondIsDirty }
  } = secondFormMethods;

  const handleStepClick = useCallback((step: number) => {
    setSupplierDetailState((prevState) => ({ ...prevState, activeStep: step }));
  }, []);

  const handleEditClick = useCallback(async () => {
    const { formEditable } = supplierDetailState;
    if (formEditable) {
      const firstFormDefaultValues = await getSupplierDetailDefaultValues();
      const secondFormDefaultValues = await getBankInfoDefaultValues();
      firstIsDirty &&
        firstReset(firstFormDefaultValues, {
          keepErrors: false,
          keepDirty: false,
          keepDirtyValues: false,
          keepValues: false,
          keepDefaultValues: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false
        });
      secondIsDirty &&
        secondReset(secondFormDefaultValues, {
          keepErrors: false,
          keepDirty: false,
          keepDirtyValues: false,
          keepValues: false,
          keepDefaultValues: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false
        });
    }
    setSupplierDetailState((prevState) => ({
      ...prevState,
      formEditable: !prevState.formEditable
    }));
  }, [
    formEditable,
    firstIsDirty,
    secondIsDirty,
    getSupplierDetailDefaultValues,
    getBankInfoDefaultValues
  ]);

  const handleFirstFormSubmit: SubmitHandler<ISupplierDetailForm> = async (param) => {
    const reqBody = getRequestBodyForSupplierDetail(param);
    const response = await updateSupplierInfo(param.supplier_id, reqBody);
    if (response.success) {
      const { data } = response;
      const newFormDefaultValues = getSupplierDetailFormValuesFromResponse(data);
      firstReset(newFormDefaultValues, {
        keepErrors: false,
        keepDirty: false,
        keepDirtyValues: false,
        keepValues: false,
        keepDefaultValues: false,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false
      });
      onFormEdit(data);
      notify({ message: 'Successfully updated supplier details', dismissible: true });
      setSupplierDetailState((prevState) => ({
        ...prevState,
        formEditable: false
      }));
    } else if (response.error) {
      const { error } = response;
      if (error.gst_number)
        firstSetError('gst_number', { message: 'Invalid GST Number' }, { shouldFocus: true });
      if (error.supplier_phone_number)
        firstSetError(
          'supplier_phone_number',
          { message: 'Enter valid Phone number' },
          { shouldFocus: true }
        );
      if (error.supplier_landline)
        firstSetError(
          'supplier_landline',
          { message: 'Enter valid Landline number' },
          { shouldFocus: true }
        );
      notify({
        message: 'Please try again with valid data',
        title: 'Form validation failed',
        severity: 'error',
        dismissible: true
      });
    }
  };

  const handleSecondFormSubmit: SubmitHandler<IBankDetailForm> = async (param) => {
    const reqBody = getRequestBodyForSupplierBankDetail(param);
    const response = await updateSupplierInfo(supplierInfo.supplier_id, reqBody);
    if (response.success) {
      const { data } = response;
      const defaultValues = getBankFormValuesFromResponse(data.bankdetails);
      secondReset(
        { bankdetails: defaultValues },
        {
          keepErrors: false,
          keepDirty: false,
          keepDirtyValues: false,
          keepValues: false,
          keepDefaultValues: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false
        }
      );
      onFormEdit(data);
      notify({ message: 'Bank details have been updated successfully!', dismissible: true });
      setSupplierDetailState((prevState) => ({
        ...prevState,
        formEditable: false
      }));
    } else {
      notify({
        message: response.error ?? 'Please try again with valid data',
        title: 'Form validation failed',
        severity: 'error',
        dismissible: true
      });
    }
  };

  const handleNavigationClick = useCallback(() => {
    const { activeStep } = supplierDetailState;
    if (activeStep === 0) handleStepClick(1);
    else handleStepClick(0);
  }, [handleStepClick, activeStep]);

  const fetchUsersForPocList = async () => {
    const userList = await fetchAllUsers({
      role: 'external',
      unassigned: true
    });
    if (userList?.success) {
      setSupplierDetailState((prevState: any) => ({ ...prevState, userList: userList.data.users }));
    }
  };

  return (
    <div className={css.supplierDetailTabWrapper}>
      <div className={css.supplierDetailWrapper}>
        <Typography variant="h4">Supplier Details</Typography>
        <AccessWrapper show={actions?.update}>
          <Button
            variant="text"
            onClick={handleEditClick}
            startIcon={<IconNode src={Images.editRed} alt="edit icon" />}>
            Edit
          </Button>
        </AccessWrapper>
      </div>
      <div className={css.supplierDetailContainer}>
        <div className={css.sideBar}>
          <SideBar activeStep={activeStep} onClick={handleStepClick} onEnter={handleStepClick}>
            <SideBar.Item value={0} label="Supplier Details" />
            <SideBar.Item value={1} label="Bank Details" />
          </SideBar>
        </div>
        <FormProvider {...firstFormMethods}>
          {activeStep === 0 &&
            (formEditable ? (
              <SupplierDetailForm
                showVendorId
                onFormSubmit={handleFirstFormSubmit}
                onCancelClick={handleEditClick}
                onNextClick={handleNavigationClick}
                userList={userList}
              />
            ) : (
              <UserInfo
                vendorId={supplierInfo.vendor_id}
                gstNumber={supplierInfo.gst_number}
                addresses={supplierInfo.addresses}
                email={supplierInfo.supplier_email}
                landlineNumber={supplierInfo.supplier_landline}
                phoneNumber={supplierInfo.supplier_phone_number}
                website={supplierInfo.supplier_website}
              />
            ))}
        </FormProvider>
        <FormProvider {...secondFormMethods}>
          {activeStep === 1 &&
            (formEditable ? (
              <BankDetailForm
                editMode={supplierInfo.bankdetails.length >= 1}
                onFormSubmit={handleSecondFormSubmit}
                onCancelClick={handleEditClick}
                onBackClick={handleNavigationClick}
              />
            ) : supplierInfo.bankdetails.length >= 1 ? (
              <BankInfo bankInfo={supplierInfo.bankdetails} />
            ) : (
              <BankPlaceholder onAddClick={handleEditClick} />
            ))}
        </FormProvider>
      </div>
      <Loader open={firstIsSubmitting || secondIsSubmitting} />
    </div>
  );
};

export default SupplierDetailTab;
