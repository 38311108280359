import * as yup from 'yup';

const selectSchema = yup.object().shape({
  value: yup.string().required(''),
  label: yup.string().required('')
});

export const shipmentInstructionFormSchema = yup.object({
  blType: selectSchema.required('BL Type is required'),
  placeOfReceipt: yup.string().trim().optional(),
  portOfFinalDestination: selectSchema.required('Port of Final Destination is required'),
  shippingBillNumber: yup.string().trim().optional(),
  shippingBillDate: yup
    .date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional(),
  freight: selectSchema.required('Freight is required'),
  switch_bl_case: yup.boolean().required('Switch BL Case is required'),
  notify_party_1: yup.string().required('Notify Party 1 is required').trim(),
  notify_party_2: yup.string().required('Notify Party 2 is required').trim(),
  order_items: yup.array().of(
    yup.object().shape({
      haz: yup.boolean(),
      packing_group: yup
        .mixed()
        .when('haz', ([haz], schema) =>
          !haz ? schema.notRequired() : selectSchema.required('Packing Group is required')
        ),
      un_number: yup
        .number()
        .when('haz', ([haz], schema) =>
          !haz
            ? schema.notRequired()
            : schema
                .typeError('UN Number must be a number')
                .min(0, 'UN Number must be at least 0')
                .max(9999, 'UN Number must be no more than 9999')
                .integer('UN Number must be an integer')
                .required('UN Number is required')
        ),
      class: yup
        .number()
        .when('haz', ([haz], schema) =>
          !haz
            ? schema.notRequired()
            : schema
                .typeError('Class must be a number')
                .min(0.0, 'Class must be at least 0.0')
                .max(9.9, 'Class must be no more than 9.9')
                .required('Class is required')
        ),
      ems: yup.string().trim().notRequired().nullable()
    })
  ),
  sealNumber: yup.array().of(
    yup.object().shape({
      sealNumber: yup.string().trim()
    })
  ),

  customSealNumber: yup.array().of(
    yup.object().shape({
      customSealNumber: yup.string().trim()
    })
  )
});

export const remarksFormSchema = yup.object().shape({
  remark1: yup.string().required('Remark 1 is required').trim(),
  remark2: yup.string().required('Remark 2 is required').trim()
});
