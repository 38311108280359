import Images from '@assets/images';
import { Accordion, Button, Typography } from '@components/base';
import { Avatar, DocumentRow } from '@components/common';
import { ITaskTabProps } from '@helpers/types/task-tabs';
import { useMemo } from 'react';

import css from './index.module.scss';
import { DOCUMENT_GENERATION_STATUS } from '@helpers/constants';
import { generateCOO } from '@services/task.service';
import Footer from '../components/footer';
import notify from '@helpers/toastify-helper';

interface RectifyPIProps {
  taskData: Array<Partial<ITaskTabProps>>;
  onComplete: () => void;
}

const GenerateCOO = (props: RectifyPIProps) => {
  const { taskData, onComplete } = props;
  const taskItem = taskData[0];

  const docGenerationStatus = useMemo(() => {
    if (taskItem?.coo_generation_status as any) {
      return DOCUMENT_GENERATION_STATUS.find((i) => i.value === taskItem?.coo_generation_status)
        ?.label;
    }
    return null;
  }, [taskItem]);

  const GenerateDoc = async () => {
    try {
      const response = await generateCOO(taskItem?.task_id as string);
      if (response?.success) {
        notify({
          message: 'COO generation request sent successfully'
        });
        onComplete();
      } else {
        notify({
          message: response?.error ?? 'Something went wrong',
          severity: 'error'
        });
      }
    } catch (error) {
      notify({
        message: 'Something went wrong',
        severity: 'error'
      });
    }
  };

  return (
    <div className={css.mainWrapper}>
      <Accordion defaultExpanded>
        <Accordion.Header className={css.accordionHeader}>
          <div className={css.header}>
            <div className={css.wrapper}>
              {!taskItem?.is_completed ? (
                <Avatar src={Images.illustrationEdit} alt="" />
              ) : (
                <Avatar src={Images.alertSuccessFaint} alt="" />
              )}
              <Typography variant="h5">
                {`#${taskItem?.readable_task_id}`} - Generate Certificate of Origin{' '}
              </Typography>
            </div>
            <div className={css.pocWrapper}>
              {!taskItem?.is_completed &&
                (!docGenerationStatus || docGenerationStatus == 'DOC_GENERATION_SUCCESS') && (
                  <Button
                    variant="text"
                    onClick={GenerateDoc}
                    disabled={!taskItem?.has_invoice_id}
                    className={css.assignButton}>
                    {taskItem?.has_invoice_id ? `Generate` : `Generate Commercial Invoice`}
                  </Button>
                )}
              {!taskItem?.is_completed &&
                (docGenerationStatus == 'DOC_GENERATION_PROCESSING' ||
                  docGenerationStatus == 'DOC_GENERATION_REQUESTED') && (
                  <Button variant="text" disabled className={css.assignButton}>
                    Document generation in process
                  </Button>
                )}
              {!taskItem?.is_completed && docGenerationStatus == 'DOC_GENERATION_FAILED' && (
                <Button variant="text" disabled className={css.assignButton}>
                  Document Generation Failed
                </Button>
              )}
            </div>
          </div>
        </Accordion.Header>
      </Accordion>
      <Footer
        createdDate={props.taskData[0].created_at as Date}
        updatedDate={props.taskData[0].updated_at as Date}
      />
    </div>
  );
};
export default GenerateCOO;
