import { Typography, Button, IconNode } from '@components/base';
import css from './index.module.scss';
import { useMemo } from 'react';
import Images from '@assets/images';
import AccessWrapper from '@authorization/access-wrapper';

interface ProductDetailCardProps {
  productName: string;
  CASNumber: string;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  actions: IActions;
}

const ProductDetailCard = (props: ProductDetailCardProps) => {
  const { productName, CASNumber, onEditClick, onDeleteClick, actions } = props;

  const nameInitial = useMemo(() => {
    const name = productName
      .split(' ')
      .map((name) => name.charAt(0))
      .join('')
      .toUpperCase();
    return name;
  }, [props.productName]);

  return (
    <div className={css.cardWrapper}>
      <div className={css.cardInfoWrapper}>
        <div className={css.cardProfileWrapper}>{nameInitial}</div>
        <div className={css.cardDetailWrapper}>
          <Typography variant="h2">{productName}</Typography>
          <Typography variant="p">{`CAS #${CASNumber}`}</Typography>
        </div>
      </div>
      <div className={css.iconsWrapper}>
        <AccessWrapper show={actions?.update}>
          <Button
            variant="text"
            onClick={onEditClick}
            startIcon={<IconNode src={Images.editRed} alt="edit icon"></IconNode>}>
            Edit
          </Button>
        </AccessWrapper>
        <AccessWrapper show={actions?.delete}>
          <Button
            variant="text"
            className={css.darkButton}
            onClick={onDeleteClick}
            startIcon={<IconNode src={Images.deleteDark} alt="edit icon" />}>
            Delete
          </Button>
        </AccessWrapper>
      </div>
    </div>
  );
};
export default ProductDetailCard;
